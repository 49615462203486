import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SessionService as Session } from '../../services/session/session.service';
import { ApiService } from '../../services/api-service/api.service';
import { OfferData } from '../../models/offers/offer-data/OfferData.model';
import { map, switchMap, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { environment as env } from '../../../environments/environment';
import { OfferTarget } from '../../models/offer-target.model';
import { CAMPAIGN_UPDATED_FIELDS } from '../shared.common';
import { ICampaignManager } from '../../models/cm.interface';
import { Campaign } from '../../models/campaign.model';
import { UserModel } from '../../models/session-init/user-model/user-model.model';
import { IFormat } from '../../models/format.model';
import { FormatsService } from '../../services/formats/formats.service';

export const ESTIMATED_DISPLAYS_PAGE_SIZE = '40';
const CAMPAIGNS_BIDS_STATS_URL = '/data/bid-stats';

@Injectable()
export class CampaignManagementService  {

  //public selectedCampaign = new BehaviorSubject<OfferData>(null);
  public selectedCampaign = null;
  private _refreshCampaign = new BehaviorSubject<OfferData>(null);
  public onRefreshCampaign = this._refreshCampaign.asObservable();
  public onCloseUpdate = new Subject();
  public currentUser: UserModel;
  public selectedFormat: IFormat;

  constructor(
      private http: HttpClient,
      private api: ApiService,
      private formatsSvc: FormatsService
    ) {
    this.currentUser = this.api.currentSessionData.user;
  }

  refreshCampaign(campaign: OfferData): void {
    this._refreshCampaign.next(campaign);
  }

  getCampaigns(params: HttpParams, uri= null): Observable<OfferData[]> {
    const variant = this.isCampaignAdmin ? 'admin' : 'manager';
    const baseUri = `/data/sql-search/offers/${variant}`;
    return this.http.get<OfferData[]>(Session.enrichApiUrl(uri || baseUri), { params })
      .pipe(
        map(data => {
          data['objects'] = data['objects'].map(d => ({
            ...d,
            schedule_start: Math.min(...d.offer_schedules.map(sched => moment(sched.schedule_start).valueOf())),
            schedule_stop: Math.max(...d.offer_schedules.map(sched => moment(sched.schedule_stop).valueOf())),
            weekdays: [...new Set(...d.offer_schedules.map(sched => sched.weekdays))]
          }));
          return data;
        }
      ),
        map(data => {
          data['objects'] = data['objects'].map(d => ({
            ...d,
            predicted_spent: this.calculatePredictedBudgetSpent(d),
            supply_predicted_spent: this.calculatePredictedBudgetSpent(d, 'supply_budget', 'supply_budget_spent')
          }));
          return data;
        })
    );
  }

  save(skipResponse = false): Observable<any> {
    let headers = {};
    if (skipResponse) {
      headers = {'X-Skip-Response': '1'};
    }

    return this.http.put<OfferData>(Session.enrichApiUrl(this.currentCampaign.uri),
      this.selectedCampaign, {headers: new HttpHeaders(headers)});
  }

  activate(campaign: OfferData, data: ICampaignManager.ICampaignActivationData): Observable<OfferData> {
    const uri = `${campaign.uri}/activate-offer`;
    return this.http.post<OfferData>(Session.enrichApiUrl(uri), data);
  }

  saveSchedule(schedule: any, comment: string = null): Observable<any> {
    return this.http.put(Session.enrichApiUrl(schedule.uri), {...schedule, _comment: comment});
  }

  saveCampaign(comment: string = null): Observable<OfferData> {
    const campaign = this.currentCampaign;
    let data = (({ budget, budget_daily, ad_plays, campaign_impressions, num_of_screens, _comment }) => ({ budget, budget_daily, ad_plays, campaign_impressions, num_of_screens, _comment }))(campaign);
    data = {...data, _comment: comment};
    return this.http.put<OfferData>(Session.enrichApiUrl(campaign.uri), data)
      .pipe(tap(() => this.refreshCampaign(campaign)));
  }

  public revertCampaign(obj): void {
    const changes = {};
    CAMPAIGN_UPDATED_FIELDS.forEach(fld => {
      changes[fld] = obj[fld];
    });
    this.selectedCampaign = {
      ...this.currentCampaign,
      ...changes,
    };
  }

  public getCampaignsBidStatus(campaigns: string[]): Observable<ICampaignManager.ICampaignBidsStats[]> {
    return this.http.post<ICampaignManager.ICampaignBidsStats[]>(`${ env.reportsServiceUrl }${ CAMPAIGNS_BIDS_STATS_URL}`, { campaigns });
  }

  public setOfferTargetStatus(uri, status): Observable<OfferTarget> {
    return this.http.put<OfferTarget>(`${ env.apiUrl }${ uri }`, { status, admin_paused: true });
  }


  public getActualDaysBetweenDates(startDate, endDate, weekdays): number {
    let sumDays = 0;
    weekdays.forEach(d => {
      const occur = this.weekdaysBetween(startDate, endDate, d);
      sumDays = sumDays + occur;
    });
    return sumDays;
  }

  private weekdaysBetween(d1, d2, weekday): number {
    d1 = moment(d1);
    d2 = moment(d2);
    const daysToAdd = ((7 + weekday) - (d1.isoWeekday() - 1)) % 7;
    const next = d1.clone().add(daysToAdd, 'days');
    if (next.isAfter(d2)) {
        return 0;
    }
    const weeksBetween = d2.diff(next, 'weeks');
    return weeksBetween + 1;
  }

  calculatePredictedBudgetSpent(campaign: OfferData, budgetFld = 'budget', budgetSpendFld = 'budget_spent'): number {
    const today = moment();
    const scheduleStop = moment(campaign.schedule_stop);
    if (today < scheduleStop) {
      const campaignDurationInDays = this.getActualDaysBetweenDates(campaign.schedule_start, campaign.schedule_stop, campaign.weekdays);
      const daysUpToday = this.getActualDaysBetweenDates(campaign.schedule_start, today, campaign.weekdays) - 1;
      const currentBudgetSpentInPercent = parseFloat(campaign[budgetSpendFld]) * 100 / parseFloat(campaign[budgetFld]);
      const predictedBudgetSpentInPercent = currentBudgetSpentInPercent * campaignDurationInDays / daysUpToday;
      return predictedBudgetSpentInPercent;
    } else {
      return -1;
    }
  }

  getEstimatedDisplays(page: number = 1): Observable<ICampaignManager.IEstimatedDisplay[]> {
    const offset = page * parseInt(ESTIMATED_DISPLAYS_PAGE_SIZE, 10);
    const params = new HttpParams()
      .set('pageSize', ESTIMATED_DISPLAYS_PAGE_SIZE)
      .set('offset', offset.toString());
    const url = `${env.offersServiceUrl}${this.currentCampaign.uri}/displays`;
    return this.http.get<ICampaignManager.IEstimatedDisplay[]>(url, { params });
  }

  updateEstimatedDisplay(payload: ICampaignManager.IEstimatedDisplay): Observable<ICampaignManager.IEstimatedDisplay> {
    const url = `${env.offersServiceUrl}${this.currentCampaign.uri}/displays/${payload.id}`;
    return this.http.put<ICampaignManager.IEstimatedDisplay>(url, payload);
  }

  deleteEstimatedDisplay(payload: ICampaignManager.IEstimatedDisplay): Observable<any> {
    const url = `${env.offersServiceUrl}${this.currentCampaign.uri}/displays/${payload.id}`;
    return this.http.delete<ICampaignManager.IEstimatedDisplay>(url);
  }

  set currentCampaign(campaign: Campaign) {
    this.selectedCampaign = campaign ? new Campaign({...campaign}) : null;
    // TODO this should be updated to support multiformat in future
    this.selectedFormat = campaign ? this.getSelectedFormat(campaign) : null;
  }

  get currentCampaign(): Campaign {
    return this.selectedCampaign;
  }

  private getSelectedFormat(campaign: OfferData): IFormat {
    const formatCode = campaign.campaign_formats && campaign.campaign_formats.length ? campaign.campaign_formats[0] :
                       campaign.offer_variant;
    return formatCode ? this.formatsSvc.getFormatByCode(formatCode) : null;
  }

  resetState(): void {
    this.selectedCampaign = null;
    this._refreshCampaign.next(null);
  }

  public get isCampaignAdmin(): boolean {
    return this.currentUser.profiles_names.includes('viooh_mgmt');
  }

  private getFileName(response): string {
    const regex = /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/gi;
    if (response.headers.get('content-disposition') !== null) {
      return regex.exec(response.headers.get('content-disposition'))[1];
    } else {
      return 'CampaignScreenReport.xlsx';
    }
  }

  public requestDownload(path: string): Observable<any> {
      const uri = `${ env.apiUrl }${ path }`;
      return this.http.head(uri)
        .pipe(
          switchMap(() => this.http.get(uri, {observe: 'response' as 'body', responseType: 'blob' as 'json'})),
          tap((response: any) => {
              const dataType = response.body.type;
              const binaryData = [];
              const filename = this.getFileName(response);
              binaryData.push(response.body);
              const downloadLink = document.createElement('a');
              downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
              if (filename) {
                downloadLink.setAttribute('download', filename);
              }
              document.body.appendChild(downloadLink);
              downloadLink.click();
              downloadLink.remove();
            })
        );
  }

  get targetStatus(): string {
    return this.selectedCampaign && this.selectedCampaign.offer_targets[0].status;
  }


}
