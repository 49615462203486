import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { trigger } from '@angular/animations';
import { SlideInOut } from '../../../../../additional/animations/animations';
import { HttpParams } from '@angular/common/http';
import { OfferService } from '../../../../../services/offer/offer.service';
import { FormatsBlockDispModel } from '../../../../../models/FormatsBlockDisp.model';
import { ApiService } from '../../../../../services/api-service/api.service';
import { Subject } from 'rxjs';
import { ISQLSearch } from '../../../../../models/sql-search.model';
import { ActivatedRoute } from '@angular/router';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { takeUntil } from 'rxjs/operators';

const CAMPAIGNS_SEARCH_URI = '/data/sql-search/offers/normal';

@Component({
  selector: 'app-tiles',
  templateUrl: './tiles.component.html',
  styleUrls: ['./tiles.component.sass'],
  animations: [
    trigger('searchDirectionSlide', SlideInOut)
  ]
})
export class TilesComponent implements OnInit, OnDestroy {

  faSearch = faSearch;
  @ViewChild('deleteConfirmation') deleteConfirmation: ElementRef;

  searchDirectionCollapsed = true;
  private searchUri = '/data/search/offers/normal';

  isLoading = false;
  private _destroy$ = new Subject<any>();

  statuses = [
    {
      id: null,
      name: 'All'
    },
    {
      id: 'submitted',
      name: 'Submitted'
    },
    {
      id: 'online',
      name: 'Online'
    },
    {
      id: 'paused',
      name: 'Paused'
    },
    {
      id: 'delivered',
      name: 'Delivered'
    },
    {
      id: 'accepted',
      name: 'Accepted'
    },
    {
      id: 'rejected',
      name: 'Rejected'
    },
  ];

  order: SortOption[] = [
    {
      sort: 'name',
      name: 'Campaign Name Ascending',
      arrow: 'up',
      group: 'order'
    },
    {
      sort: '-name',
      name: 'Campaign Name Descending',
      arrow: 'down',
      group: 'order'
    },
    {
      sort: 'created',
      name: 'Oldest',
      arrow: 'up',
      group: 'order'
    },
    {
      sort: '-created',
      name: 'Newest',
      arrow: 'down',
      group: 'order'
    },
  ];

  selected = this.order[3].sort;
  filteredStatus: string;

  get formatsBlocks(): FormatsBlockDispModel[] {
    const fb = this.api.formats;

    if (fb) {
      return fb;
    } else {
      return [];
    }
  }

  offers: ISQLSearch.IOffer[];
  next_uri: string;
  lastPage = false;

  filter = {
    text: '',
    type: this.selected
  };

  constructor(
    private service: OfferService,
    @Inject(ApiService) public api,
    private activatedRoute: ActivatedRoute,
) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['status']) {
        this.filteredStatus = params['status'];
        window.history.replaceState(null, null, window.location.pathname);
      }
      this.getObjects();
    });
  }

  public toggleSearchDirection(): void {
    this.searchDirectionCollapsed = !this.searchDirectionCollapsed;
  }

  private getObjects(append: boolean = false): void {
    if (this.lastPage) {
      return;
    }

    let addressExtension = CAMPAIGNS_SEARCH_URI;
    let params = new HttpParams()
      .set('limit', '24')
      .set('objects', '1')
      .set('sort', this.filter.type);

    if (this.filteredStatus) {
      params = params.set('status', this.filteredStatus);
    }

    if (this.filter.text.length) {
      params = params.set('query', JSON.stringify({name: this.filter.text}));
    }
    if (append) {
      addressExtension = this.next_uri;
    }

    this.isLoading = true;

    this.service.getOffers(addressExtension, params)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
      offers => {
        this.isLoading = false;
        if (append) {
          this.offers = this.offers.concat(offers.objects);
        } else {
          this.offers = offers.objects;
        }
        this.next_uri = offers.next_uri;
        if (!this.next_uri) {
          this.lastPage = true;
        }
      },
      (err) => {
        this.isLoading = false;
        console.log(err);
      }
    );
  }

  orderBy(option: SortOption): void {
    this.selected = option.sort;
    this.filter.type = option.sort;
    this.refreshList();
  }

  filterBy(option) {
    this.filteredStatus = option.id;
    this.refreshList();
  }

  searchQuery(event: Event): void {
    this.filter.text = event.srcElement['value'];
    this.refreshList();
  }

  refreshList(): void {
    this.offers = [];
    this.lastPage = false;
    this.next_uri = null;
    this.getObjects();
  }

  loadMore(): void {
    if (!this.isLoading) {
      if (this.next_uri) {
        this.getObjects(true);
      }
    }
  }

  removeOffer(childOffer: object): void {
    const index = this.offers.findIndex((offer) => (offer === childOffer));
    if (index !== -1) {
      this.offers.splice(index, 1);
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}


export class SortOption {
  name: string;
  sort: string;
  arrow: string;
  group: string;
}
