import { EventEmitter, Injectable, Output } from '@angular/core';
import { IFeedChooser } from './feed-chooser.interface';
import { environment as env } from '../../../environments/environment';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { ApiService } from '../../services/api-service/api.service';
import { HttpClient } from '@angular/common/http';
import { CoreService } from '../../services/main/core.service';
import { catchError, combineAll, concatAll, map } from 'rxjs/operators';

const DEFAULT_BUCKET_NAME = 'artwork-generator';

@Injectable()
export class FeedChooserService {

  feedConfig: IFeedChooser.IConfig[] = [];
  currentFeed: string;

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private coreService: CoreService,
  ) {
  }

  public init(): void {
    this.apiService.currentSessionData$
      .subscribe(
        (data) => {
          try {
            const customData = JSON.parse(data.company.custom_data);
            this.feedConfig = !Array.isArray(customData.feeds) ? [customData.feeds] : customData.feeds;
            if (this.feedConfig.length === 1) {
              this.currentFeed = this.feedConfig[0].folder;
            } else {
              const feedsMetadata$ = [];
              for (const feed of this.feedConfig) {
                feedsMetadata$.push(
                  this.getFeedMetadata(feed.folder)
                    .pipe(
                      catchError(err => of({folder: feed.folder, error: true}))
                    )
                );
              }
              forkJoin(feedsMetadata$)
                .subscribe((meta: IFeedChooser.IConfig[]) => {
                  this.feedConfig = this.feedConfig.map(feed => {
                    const feedMeta = meta.find(f => f.folder === feed.folder) || {};
                    return {...feed, ...feedMeta};
                  });

                  console.log('this.feedConfig', this.feedConfig);
                });
            }
          } catch (e) {
            console.log('=== No feeds config ===');
          }
        }
      );
  }

  private get bucketName(): string {
    return `${DEFAULT_BUCKET_NAME}${!env.production ? '-test' : ''}`;
  }

  isOpen = false;
  @Output() change: EventEmitter<boolean> = new EventEmitter();

  toggle(forceClose: boolean = false): void {
    this.isOpen = !forceClose ? !this.isOpen : false;
    this.reset();
    this.coreService.freezeMain.next(this.isOpen);
    this.change.emit(this.isOpen);
  }

  private getFeed(feedName: string): IFeedChooser.IConfig {
    return this.feedConfig.find(feed => feed.folder === feedName);
  }

  public getFeedMetadata(feedId): Observable<IFeedChooser.IConfig[]> {
    const url = `${ env.storageUrl }/${ this.bucketName }/artworks/${ feedId }.meta.json?ignoreCache=1`;
    return this.http.get<IFeedChooser.IConfig[]>(url);
  }

  public getLogo(): string {
    return `${ env.storageUrl }/generators/${this.getFeed(this.currentFeed).folder}/logo.png`;
  }

  public getArtworks(): Observable<IFeedChooser.IArtwork[]> {
    const url = `${ env.storageUrl }/${ this.bucketName }/artworks/${ this.getFeed(this.currentFeed).folder }/index.json?ignoreCache=1`;
    return this.http.get<IFeedChooser.IArtwork[]>(url)
      .pipe(
        map(data => data.map(i => ({...i, _selected: true}))),
      );
  }

  public reset(): void {
    this.currentFeed = null;
  }

}
