<div *ngIf="!uploadMode; else UPLOAD_MODE">
  <div class="overflow-auto">

    <ng-container *ngIf="formats && formats.length > 0; else NO_FORMATS_FOUND">
      <div class="af-row">

        <ng-container *ngFor="let format of formats; trackBy: trackFn; let idx = index">

          <app-icon-item
            [data]="format"
            [selected]="selected(format)"
            [icon]="'formats/' + format.ooh_format.split('_')[0]"
            [genericIcon]="'audiences/generic.svg'"
            [progressValue]="10"
            (clicked)="click(format)">

            <ng-template fc-iconitem-description>
              <div [innerHTML]="format.ooh_format_label"></div>
              <div *ngIf="format.artwork_spec
                          && format.artwork_spec.resolution
                          && format.artwork_spec.resolution.width
                          && format.artwork_spec.resolution.height">
                {{format.artwork_spec.resolution.width}}x{{format.artwork_spec.resolution.height}}
              </div>
            </ng-template>

          </app-icon-item>

        </ng-container>

    </div>
    <h3 class="pull-left summary" *ngIf="selectedFormats.length > 0">Selected {{ selectedFormats.length }} of {{ formats.length }} formats</h3>
    <h3 class="pull-left summary" *ngIf="formats.length > 0 && selectedFormats.length === 0">Please select at least one format</h3>
    <button class="btn fc-button pull-right" [disabled]="!isFormatSelected" (click)="toggleMode()">Upload artworks</button>
    </ng-container>
  </div>

  <ng-template #NO_FORMATS_FOUND>
    <div class="no-formats">No formats available for <b>selected audiences</b> within <b>chosen budget</b></div>
  </ng-template>
</div>


<ng-template #UPLOAD_MODE>
  <div [@toUpload]="uploadMode">

    <input #fileToUpload
         (change)="addFile($event)"
         type="file"
         [attr.multiple]="isMulti"
         [attr.accept]="acceptedFileType"
         style="display: none;"/>

    <h3 class="pull-left summary" *ngIf="selectedFormats.length > 0">Selected {{ selectedFormats.length }} of {{ formats.length }} formats</h3>
    <h3 class="pull-left summary" *ngIf="formats.length > 0 && selectedFormats.length === 0">Please select at least one format</h3>
    <button class="btn fc-button pull-right" (click)="toggleMode()">Back to format selection</button>

    <button class="btn upload-btn mt-4" (click)="selectFile()">Click to upload artworks...</button>

    <div class="staging-area" [class.visible]="noMatchingArtworks.length > 0">
      <div class="artworks">
        <ng-container *ngIf="service.isMulti && noMatchingArtworks.length > 0">
          <div class="file-thumbnail-preview"
               *ngFor="let file of noMatchingArtworks"
               [class.invalid]="file.thumbnail && !file.thumbnail_inprogress && !file.transcode_inprogress && !file._validResolution"
               [class.processing]="!file.thumbnail || file.thumbnail_inprogress || file.transcode_inprogress"
               [style.backgroundImage]="thumbnailPreviewImage(file.thumbnail)">
            <div class="overlay overlay-invalid" *ngIf="file.thumbnail && !file.thumbnail_inprogress && !file.transcode_inprogress && !file._validResolution"></div>
            <div class="overlay overlay-processing">
              <div class="loading" style="display: block"></div>
            </div>
            <div class="circular-progress"
                 [class.hidden]="!file._progress"
                 [attr.data-percent]="file._progress + '%'"
                 [ngStyle]="uploadProgressStyle(file._progress)"></div>
            <div class="delete-btn" (click)="removeItem(file, $event)">&#10005;</div>
          </div>
        </ng-container>
      </div>
    </div>

    <ng-container *ngFor="let format of selectedFormats; trackBy: trackFn; let idx = index">
      <app-format-panel
        [format]="format"
        (artworkSelected)="showPreview($event, format)">
      </app-format-panel>
    </ng-container>
  </div>

</ng-template>

<div class="left-panel">
  <app-artwork-preview-panel
    *ngIf="selectedArtwork && previewFormat"
    [artwork]="selectedArtwork"
    [format]="previewFormat"
    (dismiss)="toggle()">
  </app-artwork-preview-panel>
</div>

<div (click)="toggle()"
     class="panel-backdrop">
</div>
