import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import {ApiService} from '../services/api-service/api.service';
import {environment as env} from '../../environments/environment';
import {CONSTANTS} from '../additional/helpers/constants/constants.const';
import {CookieService} from 'ngx-cookie';
import {SessionService} from '../services/session/session.service';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class SessionDataResolver implements Resolve<any> {
  constructor(
    private apiService: ApiService,
    private cookieService: CookieService,
    private sessionService: SessionService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return new Observable(subscriber => {
      this.apiService.fetchSessionData()
        .pipe(
          filter(data => data !== null),
          distinctUntilChanged()
        )
        .subscribe(
          () => {
            const sd = this.apiService.currentSessionData;
            if (!sd) {
              this.sessionService.forceRemoveSession();
            } else {
              if (sd.company.active_plan === 'MULTI') {
                // remove angular cookie
                this.cookieService.remove(CONSTANTS.sessionKey);
                document.location.href = env.saasAppUrl;
              } else {
                subscriber.next(sd);
                subscriber.complete();
              }
            }
          },
          (err) => subscriber.error(err)
        );
    });
  }
}
