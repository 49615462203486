import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-volumetric',
  templateUrl: './volumetric.component.html',
  styleUrls: ['./volumetric.component.css']
})
export class VolumetricComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
