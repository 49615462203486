import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { ICampaignFile } from '../../../services/file/file.model';
import { FileService } from '../../../services/file/file.service';
import { IFormat } from '../../../models/format.model';

@Component({
  selector: 'app-artwork-preview-panel',
  templateUrl: './artwork-preview-panel.component.html',
  styleUrls: ['./artwork-preview-panel.component.scss']
})
export class ArtworkPreviewPanelComponent implements OnInit {

  @Input() artwork: ICampaignFile;
  @Input() format: IFormat;
  @Output() dismiss = new EventEmitter();

  constructor(
    private fileService: FileService,
  ) { }

  ngOnInit(): void {
  }

  get src(): string {
    return this.fileService.getArtworkSrc(this.artwork);
  }

  get mimeType(): string {
    return this.fileService.getMimeType(this.artwork);
  }

  onDismiss(): void {
    this.dismiss.emit(true);
  }

}
