import {Injectable, Injector} from '@angular/core';
import {SuperService} from '../super-service/super.service';
import {Observable} from 'rxjs';
import { tap,  distinctUntilChanged, filter } from 'rxjs/operators';
import {ApiService} from '../api-service/api.service';
import {SessionService as Session} from '../session/session.service';
import {BehaviorSubject} from 'rxjs';
import {NotifyService} from '../notify/notify.service';
import { HttpHeaders } from '@angular/common/http';
import { OfferData } from '../../models/offers/offer-data/OfferData.model';
import { ScheduleModel } from '../../models/schedule.model';

export const ESTIMATOR_API_VERSION = 'application/vnd.com.boldmind.flowcity-offer.v2+json;qs=0.9';
export const ESTIMATOR_API_VERSION_V3 = 'application/vnd.com.boldmind.flowcity-offer.v3+json';

export interface OfferEstimateQuery {
  budget?: number;
  duration?: number;
  hours?: number;
  postCode?: string;
  radius?: number;
}

export interface FormatTypeEstimation {
  format: string;
  reach: number;
  number: number;
  impressions: number;
  rate_card_reach: number;
  rate_card_impressions: number;
}

export interface AudienceTypeEstimation {
  audience: string;
  totalReach?: number;
  totalRateCardReach?: number;
  totalImpressions?: number;
  totalRateCardImpressions?: number;
  formats: FormatTypeEstimation[];
}

export interface DashboardInfo {
  audiences: object;
  formats: object;
}

export interface IAvailabilityStats {
    id?: number;
    type?: string;
    period_start?: string;
    period_end?: string;
    total_available_seconds: number;
    booked_domination_seconds: number;
    booked_sov_seconds: number;
    booked_non_guaranteed_seconds: number;
}

export interface IMultiEstimate {
    audience: string;
    availability: IAvailabilityStats;
    audience_uri?: string;
    formats: FormatTypeEstimation[];
    totalReach?: number;
    totalRateCardReach?: number;
    totalImpressions?: number;
    totalRateCardImpressions?: number;
    overall_demand_spend?: number;
    overall_supply_spend?: number;
    effective_cpm?: number;
    cpm?: number;
}

export interface IEstimatedCampaignData extends OfferData {
  offer_schedule_bases?: ScheduleModel[];
  audience_reach_results?: IMultiEstimate[];
}

@Injectable()
export class EstimatorService extends SuperService {

  estimatorRequest: Observable<AudienceTypeEstimation[]>;
  private requestCompleted = true;
  private dashboardInfoRequestPending = false;

  public dashboardInfoData = new BehaviorSubject<DashboardInfo>(null);
  public estimatorData = new BehaviorSubject<AudienceTypeEstimation[]>(null);

  constructor(
    injector: Injector,
    private api: ApiService,
    private notifyService: NotifyService
  ) {
    super(injector);
    this.api.currentLocation
      .pipe(
        filter(d => d !== null),
        distinctUntilChanged(),
      )
        .subscribe(location => {
      this.refreshDashboardInfo(location);
    });
  }

  private refreshDashboardInfo(location) {
    if (location) {
      this.getDashboardInfo({
        postCode: location.postcode,
        radius: this.api.currentDepartment.radius
      }, true)
        .subscribe(
          (data) => {
            console.log('DashboardInfo refreshed');
            if (data.audiences['location'] === undefined || data.audiences['location'] === null) {
              setTimeout(() => this.refreshDashboardInfo(location), 10000);
            }
          },
          (err) => {
            console.error('DasboardInfo error', err);
            this.notifyService.generateNotification(
             [{
               title: 'Estimator problem',
               alertContent: 'Please reload the page if the problem still exists contact administrator.'
             }]);
          }
        );
    }
  }

  getGlobalEstimate(query: OfferEstimateQuery): Observable<AudienceTypeEstimation[]> {
    const uri = '/data/audiences-estimate';
    return this.getEstimate(uri, query);
  }

  getDashboardInfo(query: OfferEstimateQuery, force = false): Observable<DashboardInfo> {
    const uri = '/data/dashboard-info';
    return new Observable(subscriber => {
        if ((!this.dashboardInfoData.getValue() && !this.dashboardInfoRequestPending) || (force && !this.dashboardInfoRequestPending)) {
            this.dashboardInfoRequestPending = true;
            this.http.get<DashboardInfo>(
                Session.enrichOffersService(uri))
                .pipe(
                    tap((data) => this.dashboardInfoData.next(data)),
                    tap(() => this.dashboardInfoRequestPending = false),
                    tap(() => {
                        subscriber.complete();
                    }),
                    // shareReplay(),
                    // retryWhen(errors => {
                    //     return errors
                    //         .pipe(
                    //             delayWhen(() => timer(2000)),
                    //             tap(() => console.log('retrying...')),
                    //           take(5),
                    //           throwError(new Error('Retry limit exceeded!')),
                    //         );
                    // })
                ).subscribe();
        } else {
            subscriber.complete();
        }
    });
  }

  getOfferEstimate(offerUri: string, query: OfferEstimateQuery): Observable<AudienceTypeEstimation[]> {
    const uri = `${offerUri}/estimate`;
    return this.getEstimate(uri, query);
  }

  private getEstimate(uri: string, query: OfferEstimateQuery): Observable<AudienceTypeEstimation[]> {
    if (this.requestCompleted) {
      this.requestCompleted = false;
      this.estimatorRequest = this.http.post<AudienceTypeEstimation[]>(
        Session.enrichOffersService(uri), query)
        .pipe(
          tap((data) => this.estimatorData.next(data)),
          tap(() => this.requestCompleted = true),
          // shareReplay(),
          //   retryWhen(errors => {
          //     return errors
          //       .pipe(
          //         delayWhen(() => timer(2000)),
          //         tap(() => console.log('retrying...'))
          //       )
          //       .take(5)
          //       .concat(Observable.throw(new Error('Retry limit exceeded!')));
          //   })
        );
    }

    return this.estimatorRequest;
  }

  getFormatStats(estimates: AudienceTypeEstimation[]): object {
    return estimates.reduce((acc, audience) => {
      audience.formats.forEach(f => {
        acc[f.format] = (acc[f.format] || 0) + f.number;
      });
      return acc;
    }, {});
  }

  getMultiEstimates(offerUri: string): Observable<IMultiEstimate[]> {
    const uri = `${ offerUri}/mo-estimate`;
    return this.http.post<IMultiEstimate[]>(Session.enrichOffersService(uri), {})
        .pipe(
            tap(data => data.map(el => el.audience_uri = `/data/display-groups/${ el.audience_uri.split('/').pop() }`)),
        );
  }

  getEstimates(offerUri: string): Observable<IMultiEstimate[]> {
    const uri = `${ offerUri }/estimate`;
    const headers = {
        'Accept': ESTIMATOR_API_VERSION
    };
    return this.http.post<IMultiEstimate[]>(Session.enrichOffersService(uri),
        {},
        {headers: new HttpHeaders(headers)},
        ).pipe(
            tap(data => data.map(el => el.audience_uri = el.audience_uri && `/data/display-groups/${ el.audience_uri.split('/').pop() }`)),
        );
  }

  getEstimatesV3(payload: IEstimatedCampaignData): Observable<IEstimatedCampaignData> {
    const uri = `${ payload.uri }/estimate`;
    const headers = {
        Accept: ESTIMATOR_API_VERSION_V3,
    };
    return this.http.post<IEstimatedCampaignData>(Session.enrichOffersService(uri),
        payload,
        {headers: new HttpHeaders(headers)},
        ).pipe(
            tap(data => {
              data.audience_reach_results
                ? data.audience_reach_results.map(el => el.audience_uri = el.audience_uri && `/data/display-groups/${ el.audience_uri.split('/').pop() }`)
                : data.audience_reach_results = [];
              }
            ),
        );
  }

}
