<div id="login-page">
  <div class="container">
    <form class="form-login" id="forgot-password-form" #formRef="ngForm">
      <div class="login-wrap">
        <div class="logo">Flow City</div>
          <h2 class="form-login-heading">password reset</h2>
            <p *ngIf="error" class="login-error">{{ error }}</p>
            <div class="login-form-body" [class.processing]="processing">
              <p class="password-reset-info">Please enter your new password twice.</p>

              <div ngModelGroup="passwords" #passwords="ngModelGroup" checkPassword>
                <div class="form-group">
                  <input [(ngModel)]="password"
                         type="password"
                         name="passwordA"
                         #passwordA="ngModel"
                         class="form-control"
                         placeholder="Password"
                         autocomplete="off"
                         autofocus
                         required>
                  <small class="error" *ngIf="passwordA.dirty && passwordA.errors?.required">
                    This field is required
                  </small>
                </div>

                <div class="form-group">
                  <input [(ngModel)]="passwordRetyped"
                         type="password"
                         name="passwordB"
                         #passwordB="ngModel"
                         class="form-control"
                         placeholder="Retyped password"
                         autocomplete="off"
                         required>
                  <small class="error" *ngIf="passwordB.dirty && passwordB.errors?.required">
                    This field is required
                  </small>
                  <small class="error" *ngIf="passwords.errors?.passwordCheck && (passwordB.dirty || passwordB.touched) && !passwordB.errors?.required">
                    Both passwords should match
                  </small>
                </div>
              </div>


              <br>
              <button (submitIfValid)="changePassword()" class="btn btn-theme btn-block">CHANGE PASSWORD</button>
              <br>
              <p class="text-center"><a routerLink="/login">Back to login page</a></p>
            </div>
            <div class="loading not-masked" *ngIf="processing"></div>
        </div>
    </form>
  </div>
</div>

<div id="change-password-background"></div>

