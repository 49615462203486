import {Component, ElementRef, Inject, Input, ViewChild} from '@angular/core';
import {OfferService} from '../../../../../../services/offer/offer.service';
import {ScheduleModel} from '../../../../../../models/schedule.model';

@Component({
  selector: 'app-campaign-schedule',
  templateUrl: './campaign-schedule.component.html',
  styleUrls: ['./campaign-schedule.component.sass']
})
export class CampaignScheduleComponent {

  @ViewChild('schedulesNavigation', {read: ElementRef}) schedulesNavigation: ElementRef;
  navMarginLeft = 0;

  @Input() collapsible = false;
  collapsed = true;

  private readonly navbarStep: number = 100;

  constructor(
      @Inject(OfferService) public service
  ) { }

  scrollLeft(): void {
    if (this.navMarginLeft + this.navbarStep >= 0) {
      this.navMarginLeft = 0;
    } else {
      this.navMarginLeft = this.navMarginLeft + this.navbarStep;
    }
  }

  leftArrow(): string {
    if (this.navMarginLeft < 0) {
      return '';
    } else {
      return 'none';
    }
  }

  rightArrow(): string {
    const navWidth = this.schedulesNavigation.nativeElement.offsetWidth;
    const parentWidth = this.schedulesNavigation.nativeElement.parentElement.offsetWidth;
    const dilatation = parentWidth - navWidth;

    if (this.navMarginLeft <= dilatation) {
      return 'none';
    } else {
      return '';
    }
  }

  scrollRight(): void {
    const navWidth = this.schedulesNavigation.nativeElement.offsetWidth;
    const parentWidth = this.schedulesNavigation.nativeElement.parentElement.offsetWidth;
    const dilatation = parentWidth - navWidth;

    if (this.navMarginLeft - this.navbarStep <= dilatation) {
      this.navMarginLeft = dilatation - 1;
    } else {
      this.navMarginLeft = this.navMarginLeft - this.navbarStep;
    }
  }

  isDateActive(): boolean {
    return this.isActive(this.service.scheduleTime);
  }
  isWeatherActive(): boolean {
    return this.isActive(this.service.scheduleWeather);
  }
  isTransportActive(): boolean {
    return this.isActive(this.service.scheduleDisruptions);
  }
  isDemographicsActive(): boolean {
    return this.isActive(this.service.scheduleAIM);
  }

  isStockLevelActive(): boolean {
    return this.isActive(this.service.scheduleStockLevel);
  }

  private isActive(sw: ScheduleModel): boolean {
    return sw && sw.active;
  }

  canEditParameters(): boolean {
    return this.service.currentOffer.offer_origin !== 'template' && this.service.currentOffer.offer_origin !== 'preconstructed';
  }

  get isRTB() {
    return this.service.isRTB;
  }

  get isAvailable(): boolean {
    // TODO logic should be modified in future
    return false;
  }
}
