import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignLocationComponent } from './components/campaign-location/campaign-location.component';
import { SharedModule } from '@shared/shared.module';



@NgModule({
  declarations: [CampaignLocationComponent],
  exports: [
    CampaignLocationComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class CampaignLocationModule { }
