import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CookieService} from 'ngx-cookie';
import { switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../../services/authentication/authentication.service';

const TOKEN_KEY = 'X-FlowCity-Session-User';
const AUTH_KEY = 'Authorization';
const URL_BLACKLIST = ['cdn', 'assets', 'storage', 'googleapis'];

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
      private cookieService: CookieService,
      private authService: AuthenticationService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = {};
    const token = this.cookieService.get(TOKEN_KEY);

    if (URL_BLACKLIST.some(s => request.url.includes(s))) {
      return next.handle(request);
    } else {
      return this.authService.getToken$()
        .pipe(
          switchMap(idToken => {
            if (idToken) {
              headers[AUTH_KEY] = `Bearer ${idToken.token}`;
            } else if (token) {
              headers[TOKEN_KEY] = token;
            }
            request = request.clone({
              withCredentials: true,
              setHeaders: headers
            });
            return next.handle(request);
          })
        );
    }
  }
}
