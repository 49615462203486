// region BUILT-IN MODULES
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// endregion

// region EXTERNAL MODULES

// endregion

// region APPLICATION MODULES
import { AuthorisationRoutingModule, AUTHORISATION_COMPONENTS } from './authorisation.routing';
// endregion

// region COMPONENTS
import { NotLoggedComponent } from './not-logged.component';
// endregion

import { MainPipe } from '../../pipes/main-pipe.module';

import { AuthorisedGuard } from '../../_guards/authorised.guard';
import {NotAuthorisedGuard} from '../../_guards/not-authorised.guard';
import { PasswordInstructionsSentComponent } from './password-instructions-sent/password-instructions-sent.component';
import { environment, environment as env } from '../../../environments/environment';
import {StripePaymentComponent} from '../../shared/simple/stripe-payment/stripe-payment.component';
import {SharedModule} from '../../shared/shared.module';
import { ChangePasswordComponent } from './change-password/change-password.component';

import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { SignupRtbComponent } from './signup-rtb/signup-rtb.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SignupProComponent } from './signup-pro/signup-pro.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import {
  faAngleRight,
  faQuestionCircle, faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { AngularFireModule } from '@angular/fire';
import { FireLoginComponent } from './fire-login/fire-login.component';
import { SignoutComponent } from './signout/signout.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AuthorisationRoutingModule,
    MainPipe,
    SharedModule,
    RecaptchaV3Module,
    NgSelectModule,
    FontAwesomeModule,
    NgbTooltipModule,
    AngularFireModule.initializeApp(environment.firebase)
  ],
  declarations: [
    NotLoggedComponent,
    AUTHORISATION_COMPONENTS,
    PasswordInstructionsSentComponent,
    ChangePasswordComponent,
    SignupRtbComponent,
    SignupProComponent,
    FireLoginComponent,
    SignoutComponent,
  ],
  providers: [
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: env.reCaptchaV3SiteKey },
    AuthorisedGuard,
    NotAuthorisedGuard
  ]
})
export class AuthorisationModule {
  constructor(
    library: FaIconLibrary,
  ) {
    library.addIcons(
      faQuestionCircle,
      faAngleRight,
      faSpinner
    );
  }
}
