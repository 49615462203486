import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { OfferService } from '../../../../../services/offer/offer.service';
import { ApiService } from '../../../../../services/api-service/api.service';
import { SessionData } from '../../../../../models/session-init/session-data/session-data.model';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

const IMPRESSION_MODELS = [
  {
    id: 'regular',
    title: 'Exact Flow',
    desc: 'Set exact time and place',
  },
  {
    id: 'rtb',
    title: 'Value Flow',
    desc: 'Reach your audience at the best price',
    offer_type: 'multi',
  },
  // {
  //   id: 'reach',
  //   title: 'Reach Flow',
  //   desc: 'Maximise number of people to reach',
  //   offer_type: 'multi',
  // },
  // {
  //   id: 'local',
  //   title: 'Local Flow',
  //   desc: 'Stay close to where it matters',
  //   offer_type: 'multi',
  // }
];

@Component({
  selector: 'app-select-impression-model',
  templateUrl: './select-impression-model.component.html',
  styleUrls: ['./select-impression-model.component.scss']
})
export class SelectImpressionModelComponent implements OnInit, OnDestroy {

  public impressionModels = IMPRESSION_MODELS;
  public newOfferData = {
    name: '',
    impression_model: 'rtb',
    budget: '0',
    duration: '1',
  };

  sessionData: SessionData;
  private _destroy$ = new Subject<any>();

  constructor(
      private activeModal: NgbActiveModal,
      private router: Router,
      private offerService: OfferService,
      private api: ApiService,
  ) {
    this.api.currentSessionData$
        .pipe(
          takeUntil(this._destroy$),
          distinctUntilChanged(),
        )
        .subscribe(
        data => {
          this.sessionData = data;
          // set RTB impression model as default one
          this.setImpressionModel(null, IMPRESSION_MODELS[1]);
          },
        );
  }

  ngOnInit() {
  }

  trimEntry() {
    this.newOfferData.name = this.newOfferData.name.trim();
  }

  setImpressionModel(event, impressionModel) {
    event && event.preventDefault();
    this.newOfferData.impression_model = impressionModel.id;
    if (impressionModel.offer_type) {
      this.newOfferData['offer_type'] = impressionModel.offer_type;
    }
  }

  createCampaign() {
    this.offerService.newOfferData = this.newOfferData;
    this.router.navigate(['new-campaign'], { state: { userAction: true }});
    this.activeModal.dismiss();
  }

  close() {
    this.activeModal.dismiss();
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
