<div class="panel-heading">
  <h5 class="title">Confirmation</h5>
</div>

<div class="panel-body">
  <p [innerHTML]="message"></p>
</div>

<div class="panel-footer">
  <button
      (click)="close()"
      class="btn fc-btn-big fc-btn-transparent">
    Cancel
  </button>
  <button
      (click)="confirm()"
      class="btn fc-btn-big">
    Yes
  </button>
</div>

