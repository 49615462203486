import {
    Directive,
    ElementRef,
    TemplateRef,
    ViewContainerRef,
    OnInit,
    Input,
} from '@angular/core';
import {ApiService} from '../../services/api-service/api.service';

@Directive({
    selector: '[appHasSubscription]'
})
export class HasSubscriptionDirective implements OnInit {
    private currentCompany;
    private subscription;
    private elseTemplateRef: TemplateRef<any> | null = null;

    constructor(private element: ElementRef,
                private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef,
                private api: ApiService) {
    }

    ngOnInit() {
        this.api.currentSessionData$.subscribe(sessionData => {
          if (sessionData) {
              this.currentCompany = sessionData.company;
              this.updateView();
          }
        });
    }

    @Input()
    set appHasSubscription(val) {
        this.subscription = val;
        this.updateView();
    }

    @Input()
    set appHasSubscriptionElse(templateRef: TemplateRef<any> | null) {
        assertTemplate('hasSubscriptionElse', templateRef);
        this.elseTemplateRef = templateRef;
        this.updateView();
    }

    private updateView() {
        this.viewContainer.clear();
        if (this.checkSubscriptionPlan()) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            if (this.elseTemplateRef) {
                this.viewContainer.createEmbeddedView(this.elseTemplateRef);
            }
        }
    }

    private checkSubscriptionPlan() {
        if (this.currentCompany && this.currentCompany.active_plan) {
          return this.currentCompany.active_plan === this.subscription;
        }
    }
}

function assertTemplate(property: string, templateRef: TemplateRef<any> | null): void {
    const isTemplateRefOrNull = !!(!templateRef || templateRef.createEmbeddedView);
    if (!isTemplateRefOrNull) {
        throw new Error(`${property} must be a TemplateRef, but received '${JSON.stringify(templateRef)}'.`);
    }
}
