import {EventEmitter, Injectable, Injector, Output} from '@angular/core';
import 'fabric';
import {SuperService} from '../../services/super-service/super.service';
import {SessionService as Session} from '../../services/session/session.service';
import {Observable} from 'rxjs';
import * as qrcode from '../../services/artwork-generator/qrcode';
import * as WebFont from 'webfontloader';
import '../../services/artwork-generator/perspective';
import {BehaviorSubject} from 'rxjs';
import {CoreService} from '../../services/main/core.service';
import {ApiService} from '../../services/api-service/api.service';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

declare const fabric: any;
declare const html5jp: any;

export interface InstagramConfig {
  account?: string;
  tags: [string];
}

@Injectable()
export class InstagramService extends SuperService {

  private canvas: any;
  private json: JSON;
  public format: any;
  private _cachedFeed: any = null;

  public selected = new BehaviorSubject<any>(null);

  public instagram_config: InstagramConfig = null;

  constructor(
    injector: Injector,
    private coreService: CoreService,
    private apiService: ApiService
  ) {
    super(injector);

    this.apiService.currentSessionData$
      .subscribe(
        (data) => {
          try {
            const customData = JSON.parse(data.company.custom_data);
            this.instagram_config = customData.instagram;
          } catch (e) {
            console.log('=== No instagram config ===');
          }
        }
      );
  }

  isOpen = false;
  @Output() change: EventEmitter<boolean> = new EventEmitter();

  toggle(forceClose: boolean = false) {
    if (!forceClose) {
      this.isOpen = !this.isOpen;
      this.coreService.freezeMain.next(this.isOpen);
      this.change.emit(this.isOpen);
    } else {
      this.isOpen = false;
      this.coreService.freezeMain.next(this.isOpen);
      this.change.emit(this.isOpen);
    }
  }

  getFeed() {
    return new Observable(subscriber => {
      if (this._cachedFeed) {
        subscriber.next(this._cachedFeed);
        subscriber.complete();
      } else {
        const uri = Session.enrichGCSUrl(`/data-stream/${this.instagram_config.account}.json`);
        return this.apiService.getProxiedRequest(uri)
          .pipe(
          map(posts => {
            console.log(this.instagram_config.tags);
            if (this.instagram_config.tags && this.instagram_config.tags.length > 0) {
              return posts.filter(post => post['tags'] && post['tags']
                .map(e => e.toLowerCase())
                .filter(x => this.instagram_config.tags
                  .map(e => e.toLowerCase()).includes(x)).length > 0
              );
            } else {
              return posts;
            }
          }))
          .subscribe(
          (data) => {
            this._cachedFeed = data;
            subscriber.next(this._cachedFeed);
            subscriber.complete();
          });
      }
    });
  }

  getLayoutFormat() {
    if (this.format && this.format.artworkSpec && this.format.artworkSpec.resolution) {
      return this.format.artworkSpec.resolution.width > this.format.artworkSpec.resolution.height ? 'landscape' : 'portrait';
    } else {
      return 'landscape';
    }
  }

  public getLogo(): string {
    return Session.enrichGCSUrl(`/generators/${this.instagram_config.account}/logo.png`);
  }

  getConfig(): Observable<JSON> {
    const uri = Session.enrichGCSUrl(`/generators/${this.instagram_config.account}/config.json`);
    return this.apiService.getProxiedRequest(uri);
  }

  prepare(config) {
    this.canvas = new fabric.Canvas('canvas', {
      hoverCursor: 'pointer',
      selection: false,
      selectionBorderColor: 'blue',
      selectionLineWidth: 5,
      enableRetinaScaling: false
    });

    this.canvas.setDimensions({width: config.layout.width, height: config.layout.height});
    this.canvas.backgroundColor = '#ffffff';
  }

  getImage() {
    if (!fabric.Canvas.supports('toDataURL')) {
      alert('This browser doesn\'t provide means to serialize canvas to an image');
    } else {
      return this.canvas.toDataURL('png');
    }
  }

  loadImage(url) {
    return new Observable(subscriber => {
      fabric.Image.fromURL(url, (image) => {
        subscriber.next(image.toDataUrl());
        subscriber.complete();
      });
    });
  }

  getArtwork(config): Observable<string> {
    // and load everything from the same json
    this.prepare(config);

    WebFont.load({
      custom: {
        families: config.layout.fonts.families,
        urls: config.layout.fonts.urls.map(url => Session.enrichGCSUrl(url))
      }
    });

    return new Observable(subscriber => {
      this.apiService.getProxiedRequest(Session.enrichGCSUrl(config.layout.src))
        .subscribe(
        json => {
          this.json = json;
          this.loadJSON(this.json, config).subscribe(
            () => {
              subscriber.next(this.getImage());
              subscriber.complete();
            }
          );
        },
        err => {
          console.error(err);
        }
      );
    });

  }

  private setImage(url, params) {
    const self = this;
    return new Observable(subscriber => {
      fabric.Image.fromURL(url, (image) => {
        const scale = params.baseline / (params.scaleWidth ? image.width : image.height);

        const getRightPos = (img, p) => {
          if (p.maxWidth && p.maxWidth < img.getScaledWidth()) {
            return -(img.getScaledWidth() - p.maxWidth) / 2;
          } else {
            return p.right;
          }
        };

        image.set({
          scaleX: scale,
          scaleY: scale
        });


        if (params.left !== undefined) {
          image.set({
            left: params.left,
            top: params.top,
            scaleX: scale,
            scaleY: scale,
          });

        } else if (params.right !== undefined) {
          image.set({
            left: this.canvas.getWidth() - image.getScaledWidth() - getRightPos(image, params),
            top: params.top,
            scaleX: scale,
            scaleY: scale
          });
        }

        this.canvas.add(image);

        if (params.clipTo) {
          const clipRect = new fabric.Rect(params.clipTo);
          this.canvas.add(clipRect);
        }

        if (params.toBack) {
          image.sendToBack();
        }
        subscriber.next();
        subscriber.complete();
      });
    });
  }

  private generateQRCode(content, params) {
    const self = this;
    return new Observable(subscriber => {
      const QRCode = qrcode(0, 'M');
      QRCode.addData(content);
      QRCode.make();
      const data = QRCode.createDataURL(5, 20);
      self.setImage(data, params)
        .subscribe(
          () => {
            subscriber.next(data);
            subscriber.complete();
          });
    });
  }

  private getTempCanvas(w, h) {
    const canvas = document.createElement('canvas');
    canvas.width = w;
    canvas.height = h;
    return canvas;
  }

  private preprocessBackground(config) {
    return new Observable(subscriber => {
      // background processing
      const bgCanvas = this.getTempCanvas(config.layout.width, config.layout.height);
      const ctx = bgCanvas.getContext('2d');

      if (config.layout.background) {
        // add default background image
        if (config.layout.background.src) {
          // const bgImage = new Image();
          // bgImage.onload = function () {
          //   ctx.drawImage(bgImage, 0, 0);
          //
          //   // process overlay if specified
          //   if (config.layout.background.overlay) {
          //     const image = new Image();
          //     image.onload = function () {
          //       const op = new html5jp.perspective(ctx, image);
          //       op.draw(config.layout.background.overlay.perspective);
          //       subscriber.next(bgCanvas.toDataURL());
          //       subscriber.complete();
          //     };
          //     image.src = config.offer[config.layout.background.overlay.src];
          //   } else {
          //     // no overlay specified
          //     subscriber.next(bgCanvas.toDataURL());
          //     subscriber.complete();
          //   }
          //
          // };

          this.apiService.getImageAsBlob(Session.enrichGCSUrl(config.layout.background.src))
            .subscribe(
              (imageSrc) => {
                const bgImage = new Image();
                bgImage.onload = function () {
                  ctx.drawImage(bgImage, 0, 0);

                  // process overlay if specified
                  if (config.layout.background.overlay) {
                    const image = new Image();
                    image.onload = function () {
                      const op = new html5jp.perspective(ctx, image);
                      op.draw(config.layout.background.overlay.perspective);
                      subscriber.next(bgCanvas.toDataURL());
                      subscriber.complete();
                    };
                    image.src = config.offer[config.layout.background.overlay.src];
                  } else {
                    // no overlay specified
                    subscriber.next(bgCanvas.toDataURL());
                    subscriber.complete();
                  }
                };

                bgImage.src = imageSrc;
              }
            );
        } else {
          // no background processing needed
          subscriber.next(bgCanvas.toDataURL());
          subscriber.complete();
        }
      } else {
        // no background processing needed
        subscriber.next(bgCanvas.toDataURL());
        subscriber.complete();
      }
    });
  }

  loadJSON(layoutJSON, config): Observable<any> {
    return new Observable(subscriber => {
      console.log(layoutJSON);

      this.preprocessBackground(config)
        .subscribe(
          (bgImage) => {
            layoutJSON.background.source = bgImage;
            this.canvas.loadFromJSON(layoutJSON, () => {
              combineLatest([
                this.setImage(config.offer.display_url, config.layout.images.display_url),
                // this.generateQRCode(config.offer.qrContent || 'Set qrContent', config.layout.images.qrcode)
              ]).subscribe(() => {

                  const c = this.canvas;
                  if (config.layout.texts) {
                    config.layout.texts.forEach((txtConfig) => {
                      let txt = config.offer[txtConfig.field] || txtConfig.default || '';
                      if (txtConfig.upperCase) {
                        txt = txt.toUpperCase();
                      }
                      c.add(new fabric.Textbox(txt, txtConfig));
                    });
                  }

                  this.canvas.renderAll();
                  console.log(this.canvas);
                  subscriber.next({});
                  subscriber.complete();
                });
            });
          }
        );


    });
  }

}
