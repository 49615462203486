<a (click)="_closePopup()" class="close-modal">&times;</a>

<div class="panel-heading">
  <h1>Artwork confirmation!</h1>
  <p>
    This is how your artwork will look like on the screen.<br>
    Make sure it’s the right size.
  </p>
</div>
<div class="panel-body">

  <div class="data-row">

    <app-artwork-preview
        [previewSrc]="fileSrc"
        [fileType]="artworkType"
        [chosenFormat]="chosenFormat"
        [fileFeedback]="fileFeedback"
        [uploadButton]="false"

        [enableCarousel]="true">
    </app-artwork-preview>

  </div>

</div>
<div class="panel-footer">

  <div *ngIf="chosenFormat" class="data-desc">

    <p>
      Please confirm that: <br>
      Your artwork is <b><span [innerHTML]="chosenFormat.title"></span></b> size
      <b>{{chosenFormat.artwork_spec.resolution.width}}</b> x
      <b>{{chosenFormat.artwork_spec.resolution.height}} px</b> resolution.
    </p>

  </div>
  <div style="clear: both;"></div>

<!--  <button (click)="_uploadNew()"-->
<!--          class="btn upload-new-btn"-->
<!--          *ngIf="canEditArtwork"-->
<!--          [class.disabled]="offerService.fileFeedback.fileUploading"-->
<!--  >Upload New</button>-->
  <button (click)="_closePopup()"
          class="btn upload-new-btn">
    Cancel
  </button>
  <button (click)="_confirmCpgn()"
          class="btn confirm-btn"
          [class.disabled]="fileFeedback.fileUploading"
  >
    Confirm
  </button>
  <div style="clear: both;"></div>
  <div class="loading" *ngIf="startingCampaign"></div>
</div>
