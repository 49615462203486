import {Injectable, Injector} from '@angular/core';
import { SuperService } from '../super-service/super.service';
import {SessionService as Session} from '../session/session.service';
import {ApiService} from '../api-service/api.service';
import {environment as env} from '../../../environments/environment';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {LocationPostcodeModel} from '../../models/location-postcode.model';
import {Location} from '../../models/location.model';
import {BillingInfoModel} from '../../models/session-init/billing-info/billing-info.model';
import {InvoiceModel} from '../../models/session-init/session-data/company-model/invoices/invoice.model';
import { Observable, timer } from 'rxjs';
import {PaymentsModel} from '../../models/session-init/session-data/company-model/payments/payments.model';
import {BehaviorSubject} from 'rxjs';
import {UserModel} from '../../models/session-init/user-model/user-model.model';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { LocationService } from '../location.service';
import { IPostcodesIO } from '../../models/postcodesio.model';


@Injectable()
export class ProfileService extends SuperService {
  private _billingInfo: BillingInfoModel;
  private _invoices: InvoiceModel[];
  private billignInfoProcessing = false;
  private invoicesInfoProcessing = false;

  public billingInfoData = new BehaviorSubject({});
  public avatarTimestamp =  Date.now();

  constructor(
    injector: Injector,
    private api: ApiService,
    private locationService: LocationService,
  ) {
    super(injector);
  }

  /**
   * Procedure responsible for changing subscription plan by posting its name to endpoint.
   * If succeed it reloads page.
   * @param subscriptionPlanName Name of new subscription plan.
   */
  changeSubscriptionPlan(subscriptionPlanName: string): Observable<PaymentsModel> {
    const self = this;

    if (!self.api.sessionData) {
      return;
    }

    return self.http.post<PaymentsModel>(
      Session.enrichApiUrl(self.api.sessionData.company.uris.Payments),
      JSON.stringify({'plan_name': subscriptionPlanName})
    );
  }

  // region BILLING INFO
  /**
   * If 'billingInfo' exists, it returns object, otherwise it calls getBillingInfo() method and returns null.
   */
  get billingInfo(): BillingInfoModel {
    if (this._billingInfo) {
      return this._billingInfo;
    } else {
      this.getBillingInfo();
      return null;
    }
  }
  set billingInfo(currentCompany: BillingInfoModel) {
    this._billingInfo = currentCompany;
  }


  public fetchBillingInfo() {
    const address = this.api.sessionData.company.uris.BillingInfo;

    this.http.get<BillingInfoModel>(
      Session.enrichApiUrl(address)
    ).subscribe(
      (data) => this.billingInfoData.next(data)
    );
  }

  private getBillingInfo() {
    const self = this;

    if (self.billignInfoProcessing) {
      return;
    }
    if (!self.api.sessionData) {
      return;
    }

    self.billignInfoProcessing = true;
    const address = self.api.sessionData.company.uris.BillingInfo;

    self.http.get<BillingInfoModel>(
      Session.enrichApiUrl(address)
    )
      .subscribe(
        currentCompany => {
          self.billingInfo = currentCompany;
          self.billignInfoProcessing = false;
        },
        err => {
          console.error(err);
          self.billignInfoProcessing = false;
        }
      );
  }
  // endregion

  // region INVOICES
  /**
   * If 'invoices' exists, it returns object, otherwise it calls getInvoices() method and returns null.
   */
  get invoices(): InvoiceModel[] {
    if (this._invoices) {
      return this._invoices;
    } else {
      this.getInvoices();
      return [];
    }
  }
  set invoices(invoices: InvoiceModel[]) {
    this._invoices = invoices;
  }

  public getInvoices() {
    const self = this;

    if (self.invoicesInfoProcessing) {
      return;
    }

    self.invoicesInfoProcessing = true;
    const address = self.api.sessionData.company.uris.Invoices;

    self.http.get<InvoiceModel[]>(Session.enrichApiUrl(address))
      .subscribe(
        invoices => {
          self.invoices = invoices;
          self.invoicesInfoProcessing = false;
        },
        err => {
          console.error(err);
          self.invoicesInfoProcessing = false;
        }
      );
  }

  private getInvoice(uri: string): Observable<InvoiceModel> {
    return this.http.get<InvoiceModel>(Session.enrichApiUrl(uri));
  }

  public generateInvoice(invoice: InvoiceModel): Observable<InvoiceModel> {
    return new Observable<InvoiceModel>(subscriber => {

      const _refreshInvoice = (uri): Observable<InvoiceModel> => {
        return new Observable(_subscriber => {
          this.getInvoice(uri)
            .subscribe(
              (data) => {
                if (data.download_link) {
                  _subscriber.next(data);
                  _subscriber.complete();
                } else {
                  _subscriber.error(false);
                }
              });
        });
      };

      this.http.get<InvoiceModel>(Session.enrichApiUrl(invoice.uris.Generate))
        .subscribe(
          (invoiceData) => {
            if (invoiceData.download_link) {
              subscriber.next(invoiceData);
              subscriber.complete();
            } else {
              _refreshInvoice(invoiceData.uri).pipe(
                // shareReplay(),
                // retryWhen(errors => {
                //   return errors
                //     .pipe(
                //       delayWhen(() => timer(2000)),
                //       tap(() => console.log('retrying invoice...'))
                //     );
                // })
              ).subscribe(
                (data) => {
                  subscriber.next(data);
                  subscriber.complete();
                }
              );
            }
          }
        );
    });
  }
  // endregion

  /**
   * If session data is known, method returns uri of user's avatar. If there is no session data, it returns path to local avatar file.
   * @param size
   */
  getAvatar(size: number): string {
    const sessionData = this.api.currentSessionData;

    if (sessionData) {
      const avatarUri = sessionData.user.uris.Avatar;
      return Session.enrichApiUrl(`${avatarUri}?_=${this.avatarTimestamp}&s=${size}`);
    } else {
      return 'assets/images/avatar.svg';
    }
  }

  get isAvatarUploaded(): boolean {
    const sessionData = this.api.currentSessionData;
    if (sessionData) {
      return !!sessionData.user.AvatarSHA;
    } else {
      return false;
    }
  }

  getSubscriptionPlans(): Observable<any> {
    return new Observable(subscriber => {
      this.api.currentSessionData$
        .pipe(
          filter(data => data !== null),
          distinctUntilChanged(),
        )
        .subscribe(
          sessionData => {
            const plans = sessionData.subscription_plans.default.subscriptionPlans;
            subscriber.next(Object.keys(plans).map((k) => Object.assign(plans[k], {id: k})));
            subscriber.complete();
          },
          error => subscriber.error(error)
        );
    });
  }

  public validatePostcode(): Observable<any> {
    const postcode = this.api.location.postcode;

    return new Observable<any>(subscriber => {

      // validate postcode server-side
      this.locationService.verifyPostcode(postcode)
        .pipe(
          map(res => res.result)
        )
        .subscribe(
          result => {
            this.savePostcode(result).subscribe(
              location => {
                this.api.location = location;
                this.api.currentLocation.next(location);
                this.api.deleteSessionCache().subscribe();
                subscriber.next(location);
                subscriber.complete();
                },
                err => subscriber.error(err)
            );
          },
          err => subscriber.error(err)
        );
    });
  }

  private savePostcode(postcodeLocation: IPostcodesIO.IResult) {
    const self = this;

    const newLocation = <Location>{};
    newLocation.city = postcodeLocation.primary_care_trust;
    newLocation.country = postcodeLocation.country;
    newLocation.latitude = postcodeLocation.latitude;
    newLocation.location = postcodeLocation.parliamentary_constituency;
    newLocation.longitude = postcodeLocation.longitude;
    newLocation.name = postcodeLocation.parliamentary_constituency;
    newLocation.postcode = postcodeLocation.postcode;

    return self.http.post<Location>(
      Session.enrichApiUrl(self.api.currentDepartment.uris.Locations),
      newLocation
    );
  }

  /**
   * Sends latitude and longitude to googleapis geocode endpoint to get postcode of given position.
   * If it succeeds, it sets api.location.postcode field.
   * @param latitude
   * @param longitude
   * @param onSuccess
   * @param onError
   */
  displayLocation(
    latitude,
    longitude,
    onSuccess: () => void,
    onError: () => void
  ): void {
    const self = this;
    const params = new HttpParams()
      .set('latlng', latitude + ',' + longitude)
      .set('sensor', 'true')
      .set('key', env.mapsApiKey);
    self.http.get<JSON>(env.geocode, {params: params}).subscribe(
      (resp) => {
        const address = resp['results'];

        for (let i = 0; i < address.length; i++) {
          if (address[i].types.includes('postal_code')) {
            self.api.location.postcode = address[i].address_components[0].long_name;
            self.api.location.latitude = latitude;
            self.api.location.longitude = longitude;
            break;
          }
        }
        onSuccess();
      },
      () => {
        onError();
        alert('Could not get address.');
      }
    );
  }

  /**
   * Creates POST request to send avatar file in request's body.
   * @param file
   * @param onSuccess
   * @param onError
   */
  sendNewAvatar(
    file: File,
    onSuccess: (val?) => void,
    onError: (err?) => void
  ): void {
    const self = this;

    const headers = new HttpHeaders({
      'X-FlowCity-Session-User': self.cookieService.get('X-FlowCity-Session-User'),
      'content-type': file.type,
      'X-Requested-With': 'XMLHttpRequest'
    });

    self.http.post(
      Session.enrichApiUrl(self.api.sessionData.user.uris.Avatar),
      file,
      {
        headers: headers,
        reportProgress: true
      }
    ).subscribe(
      value => {
        self.avatarTimestamp = Date.now();
        onSuccess(value);
      },
      err => onError(err)
    );
  }

  saveUser(
    user: UserModel
  ): Observable<any> {
    return this.api.save(user)
      .pipe(
        tap((data) => {
          console.log('data', data);
          this.api.updateUser(data);
        })
      );
  }

}
