<ng-template #afterSubmitPopup let-d="dismiss">

  <app-campaign-created-info
    [offerService]="service"
    [location]="service.api.location"
    [modal]="this.afterSubmitModal"
    (finish)="redirectView($event)"
    class="panel">
  </app-campaign-created-info>

</ng-template>

<ng-template #creatingOffer>
    <div class="awaiting-new-campaign">
      <span>Creating new Campaign</span>
      <app-loading style="position:  static;"></app-loading>
    </div>
</ng-template>

<ng-container *ngIf="newOffer; else creatingOffer">

  <div class="row budget-row create-campaign-row">

    <div class="col-md-3 sidebar-info" [style.opacity]="budget_wrapped ? 0.5 : 1">
      <div><span>1</span></div>
      <h2>Budgets <br>& Duration</h2>
    </div>

    <div class="col-md creating-main-column" [class.disabled]="budget_wrapped">

      <div class="cpgn_editing">
        <p>Decide how much you want to spend on your campaign. Use your credits as a currency to pay for this.</p>

        <ng-container *appHasSubscription="'PLUS'; else STANDARD">
          <app-rtb-budget
                  [disableDuration]="true"
                  (data)="modifySchedule($event, true)">
          </app-rtb-budget>
        </ng-container>
        <ng-template #STANDARD>
          <app-rtb-budget
                  (data)="modifySchedule($event)">
          </app-rtb-budget>
          <div *ngIf="audiences_unlocked && !audiences_editable && service.currentOffer.budget" class="next-step-info">
            <p class="next-step-p">
              You’ve set your budget, now choose your Location & Audience.
            </p>

            <button (click)="openAudiences()" class="btn next-step-btn">NEXT</button>
          </div>
        </ng-template>
      </div>

      <div class="cpgn_wrapped">
        <div class="wrapped_edit" (click)="editBudget()">
          <span>
            EDIT
          </span>
        </div>
          <div class="wrapped_circle">
          <span style="font-size: calc(1vw + 4px);">

            <span class="inner-span">
              {{ budget }}<br>
              credits
            </span>

            <span class="inner-span" [innerHTML]="duration"></span>

          </span>
        </div>
      </div>

    </div>

    <ng-container *appHasSubscription="'PLUS'">
      <div class="col-sm-12 creating-main-column" [class.disabled]="budget_wrapped">
          <div class="cpgn_editing">
            <h3>
              Control when your campaign runs
            </h3>
            <p>
              Change dates and add custom triggers to make your campaign super relevant.
              Our clever tech constantly monitors situation around your location to
              automatically show ads when they are most likely to convert.
            </p>
            <app-campaign-schedule></app-campaign-schedule>
            <div *ngIf="audiences_unlocked && !audiences_editable && service.currentOffer.budget" class="next-step-info">
              <p class="next-step-p">
                You’ve set your budget, now choose your Location & Audience.
              </p>

              <button (click)="openAudiences()" class="btn next-step-btn">NEXT</button>
            </div>
          </div>
        </div>
    </ng-container>
  </div>

  <div class="audience-row create-campaign-row">
    <div class="row"
         [class.disabled]="!audiences_editable || +service.currentOffer.budget < 1 && newOffer">
      <div class="col-md-3 sidebar-info" [style.opacity]="audiences_wrapped ? 0.5 : 1">
        <div>
          <span>2</span>
        </div>
        <h2>
          Location <br>
          & Audience
        </h2>

        <app-campaign-location
          *ngIf="location && !audiences_wrapped"
          style="width: 100%"
          [latitude]="latitude"
          [longitude]="longitude"
          [locationMap]="locationMap"
          [radius]="radius"
          [postalCode]="postcode"
          (updated)="onLocationChange($event)">
        </app-campaign-location>

      </div>
      <div
          class="col-md creating-main-column"
          [class.disabled]="audiences_wrapped">
        <div class="cpgn_editing">
          <h3>Select your target audience</h3>
          <p>Audience shows the number of people you can reach daily with your campaign.<br>This number will be adjusted once you choose format.</p>

          <app-audience-selector
                [location]="service.api.location"
                [postcode]="postcode"
                [estimations]="offerEstimations"
                [offerAudiences]="offerAudiences"
                (selectAudience)="updateAudiences($event)">
          </app-audience-selector>

          <div *ngIf="formats_unlocked && !formats_editable" class="next-step-info">
            <p class="next-step-p">
              Now choose your Format & Artwork.
            </p>

            <button (click)="openFormats()" class="btn next-step-btn">NEXT</button>
          </div>
        </div>
        <div *ngIf="audiences_editable" class="cpgn_wrapped">
          <div class="wrapped_edit" (click)="editAudience()">
            <span>
              EDIT
            </span>
          </div>
          <div class="wrapped_circle">
            <span style="font-size: calc(1vw + 2px); width: 92%;">
              <ng-container *ngIf="selectedAudiencesNumber > 1; else single">{{ selectedAudiencesNumber }}<br>audiences</ng-container>
              <ng-template #single>
                <ng-container *ngIf="selectedAudiencesNumber === 1; else NO_AUDIENCE_SELECTED">
                  {{ offerAudiences.getNames() }}
                </ng-container>
                <ng-template #NO_AUDIENCE_SELECTED>
                No Audience Selected
                </ng-template>
              </ng-template>
            </span>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="audience-row create-campaign-row">
    <div class="row"
         [class.disabled]="!formats_editable || +service.currentOffer.budget < 1 && newOffer">
      <div class="col-md-3 sidebar-info" [style.opacity]="formats_wrapped ? 0.5 : 1">
        <div>
          <span>3</span>
        </div>
        <h2>
          Format <br>
          & Artwork
        </h2>
      </div>
      <div
          class="col-md-12 creating-main-column"
          [class.disabled]="formats_wrapped"
      >
        <div class="cpgn_editing" style="padding-left: 0px; padding-top:20px">

          <ng-container *ngIf="!service.chosenFormat; else formatChosen">
            <h3>Select Screens</h3>
            <p>Formats show the number and type of screens available to publish your campaign.</p>
          </ng-container>
          <ng-template #formatChosen>
            <h3>Upload your Artwork</h3>
            <p>Now, let's upload your artwork. This is what you will share with the world.</p>
          </ng-template>

          <ng-container *appHasSubscription="'PLUSS'; else STANDARD_FORMAT_SELECTOR">
            <app-multi-format-selector
              #appFormat
              [estimations]="offerEstimations"
              [offerAudiences]="offerAudiences"
              (changed)="onSelectedFormatsChanged()">
            </app-multi-format-selector>
          </ng-container>
          <ng-template #STANDARD_FORMAT_SELECTOR>
            <app-format-selector
              #appFormat
              [estimations]="offerEstimations"
              [offerAudiences]="offerAudiences"
              (selectFormat)="selectFormat($event)">
            </app-format-selector>
          </ng-template>

        </div>
        <div *ngIf="formats_editable" class="cpgn_wrapped">
          <div class="wrapped_edit" (click)="editFormat()">
            <span>
              EDIT
            </span>
          </div>
          <div class="wrapped_circle">
            <span style="font-size: calc(1vw + 2px); width: 92%;">
              <ng-container *ngIf="service.chosenFormat; else NO_FORMAT_SELECTED">
                {{ service.chosenFormat?.ooh_format_label }}
              </ng-container>
              <ng-template #NO_FORMAT_SELECTED>
                No Format Selected
              </ng-template>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="submit-buttons-row" [class.disabled]="newOffer">
    <div class="submit-buttons">
      <button (click)="showConfirmationPopup()"
              id="create-campaign-button"
              [disabled]="!(canSubmit | async)"
              class="btn">
        <span>
          Submit Now
          <fa-icon *ngIf="startingCampaign" [icon]="['fas', 'spinner']" [spin]="true"></fa-icon>
        </span>
      </button>
    </div>
  </div>

  <div class="loading"
         style="display: block"
         [class.sidebarShown]="sidebarShown"
         *ngIf="service.loading | async">
    </div>

  <div class="loading custom"
       style="display: block"
       [class.sidebarShown]="sidebarShown"
       *ngIf="loadingEstimates | async">
    <div class="estimating">
      <span>Estimating Available Bids</span>
    </div>
  </div>

</ng-container>

<!--<ng-container *appHasProfile="'offer_creator_books'">-->
<!--  <app-hachette-layout></app-hachette-layout>-->
<!--</ng-container>-->

<!--<ng-container *appHasProfile="'offer_creator_nike'">-->
<!--  <app-instagram-layout *ngIf="instagramService.instagram_config"></app-instagram-layout>-->
<!--</ng-container>-->

<ng-container *appHasProfile="'artwork_generator'">
  <app-artwork-generator-layout></app-artwork-generator-layout>
</ng-container>

<!--<ng-container *appHasProfile="'ecommerce'">-->
<!--  <app-feed-chooser-layout></app-feed-chooser-layout>-->
<!--</ng-container>-->
