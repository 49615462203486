import { Component,  OnDestroy, OnInit } from '@angular/core';
import {OfferService} from '../../../../../../../services/offer/offer.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-stock-level',
  templateUrl: './stock-level.component.html',
  styleUrls: ['./stock-level.component.scss']
})
export class StockLevelComponent implements OnInit, OnDestroy {

  private _destroy$ = new Subject<any>();

  minStockLevel = 1;
  _updated = new BehaviorSubject(null);

  constructor(
      private offerService: OfferService,
  ) {
    this._updated
      .pipe(
        filter(d => d !== null),
        debounceTime(2000),
        takeUntil(this._destroy$),
      )
      .subscribe(
        val => this.offerService.editStockLevelSchedule('min_stock_level', val),
      );
  }

  ngOnInit() {
    this.minStockLevel = this.offerService.scheduleStockLevel ? this.offerService.scheduleStockLevel.min_stock_level : 1;
  }

  get active() {
    return this.offerService.scheduleStockLevel && this.offerService.scheduleStockLevel.active;
  }

  toggleActivation() {
    const _active = !this.active;
    this.offerService.editStockLevelSchedule('active', _active);
  }

  update(value) {
    console.log(value);
    this._updated.next(value);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
