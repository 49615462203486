import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  private _message$ = new BehaviorSubject<string>(null);
  public message$ = this._message$.asObservable();

  constructor() {}

  public set message(msg: string) {
    if (msg === '') {
      this.clear();
    } else {
      this._message$.next(msg);
    }
  }

  public get message(): string {
    return this._message$.getValue();
  }

  public clear(): void {
    this._message$.next(null);
  }

  public setMessage(message: string, hideAfter = 5000): void {
    this.message = message;
    setTimeout(() => this.clear(), hideAfter);
  }
}
