import {Component, Inject, OnInit} from '@angular/core';
import {CoreService} from '../../../services/main/core.service';
import {ProfileService} from '../../../services/profile/profile.service';
import {UserModel} from '../../../models/session-init/user-model/user-model.model';
import {CompanyModel} from '../../../models/session-init/session-data/company-model/company-model.model';
import {ApiService} from '../../../services/api-service/api.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.sass']
})
export class UserInfoComponent implements OnInit {

  user: UserModel = null;
  company: CompanyModel = null;

  constructor(
    public coreService: CoreService,
    public profileService: ProfileService,
    public api: ApiService
  ) {
    this.api.currentSessionData$.
      subscribe((data) => {
        if (data) {
          this.user = data.user;
          this.company = data.company;
        }
      }
    );
  }

  ngOnInit() {
  }

  get isAvatarUploaded(): boolean {
    return this.profileService.isAvatarUploaded;
  }

  get avatar(): string {
    return this.profileService.getAvatar(100);
  }

}
