import {Injectable, Injector} from '@angular/core';
import {
  ScheduleAIM,
  ScheduleDisruptions, ScheduleTime, ScheduleWeather,
  ScheduleModel, SCHEDULE_PATTERN_FIELDS as SPF, ScheduleStockLevel
} from '../../models/schedule.model';
import {SuperService} from '../super-service/super.service';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import {SessionService as Session} from '../session/session.service';
import {OffersReceiveModel} from '../../models/offers/offers-receive.model';
import {ApiService} from '../api-service/api.service';
import {OfferData} from '../../models/offers/offer-data/OfferData.model';
import { DisplayGroup, DisplayGroups } from '../../models/DisplayGroups.model';
import {UploadedFiles} from '../../models/UploadedFiles.model';
import { combineLatest, Subscription } from 'rxjs';
import {OfferTarget} from '../../models/offer-target.model';
import * as moment from 'moment';
import { AudienceTypeEstimation, IEstimatedCampaignData, IMultiEstimate } from '../estimator/estimator.service';
import {BehaviorSubject} from 'rxjs';
import {Observable} from 'rxjs';
import { debounceTime, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import {environment as env} from '../../../environments/environment';
import {LocationPostcodeModel} from '../../models/location-postcode.model';
import { from, of, Subject } from 'rxjs';
import { IFormat } from '../../models/format.model';
import { Moment } from 'moment';
import { ISQLSearch } from '../../models/sql-search.model';
import { IRTBBudgetStats } from '../../models/rtb-budget-stats.model';
import { IFeedChooser } from '../../modules/feed-chooser/feed-chooser.interface';
import { ICampaignFile } from '../file/file.model';
import { IEstimatedBudget } from '../../layouts/core/main-content/create-campaign/estimated-budget/estimated-budget.model';
import { CAMPAIGN_IGNORED_FIELDS_ON_SAVE } from '../../layouts/core/main-content/offers/offer/campaign.const';
import { omit } from '../../additional/helpers';
import { CookieService } from 'ngx-cookie';
import { Router } from '@angular/router';
import { FormatsService } from '../formats/formats.service';


const REPORTS_API_RTB_BUDGET_CHECK = '/api/rtb-campaign-stats/';

export interface SelectedAudience {
  audience: DisplayGroups;
  reach: number;
  target?: OfferTarget;
  effectiveCPM?: number;
  cpm?: number;
  overall_demand_spend?: number;
}

export interface IMultiOfferTarget {
  audience_uri: string;
  audience_name: string;
  uri: string;
  offer: string;
  status: string;
}

export class OfferAudiences {

  _audiences: SelectedAudience[];
  _service: any;
  _multi: boolean;
  private _changed = new Subject();

  constructor(
    offerService: OfferService,
    multi = false
  ) {
    this._audiences = [];
    this._service = offerService;
    this._multi = multi;
  }

  get changed() {
    return this._changed;
  }

  private getExisting(audienceUri): SelectedAudience {
    return this._audiences.find(a => a.audience.uri === audienceUri);
  }

  public setMulti(): void {
    this._multi = true;
  }

  public isMulti(): boolean {
    return this._multi;
  }

  public set(obj: SelectedAudience): Observable<any> {
    return new Observable(subscriber => {
      if (obj.audience && obj.audience.uri) {
        if (!this.getExisting(obj.audience.uri)) {
          // handle draft target generation
          if (!obj.target) {
            this._service.addTarget(obj.audience)
                .subscribe(
                    (target) => {
                      obj.target = target;
                      this._audiences.push(obj);
                      this._changed.next();
                      console.debug('AUDIENCES: ', this._audiences);
                      subscriber.next();
                      subscriber.complete();
                    }
                );
          } else {
            this._audiences.push(obj);
            this._changed.next();
            console.debug('AUDIENCES: ', this._audiences);
            subscriber.next();
            subscriber.complete();
          }
        } else {
          subscriber.next();
          subscriber.complete();
        }
      } else {
        subscriber.next();
        subscriber.complete();
      }
    });
  }

  public getNames(): string {
    return this._audiences.map(a => a.audience.value || a.audience.display_name).join(', ');
  }

  public getSingle(): any {
    if (this._audiences.length === 1) {
      return this._audiences[0];
    } else {
      return null;
    }
  }

  public get(audienceUri = null): any {
    if (audienceUri) {
      return this.getExisting(audienceUri);
    } else {
      return this._audiences;
    }
  }

  private reset(): Observable<any> {
    return new Observable(subscriber => {
      const toRemove = [];
      this._audiences.forEach(aud => {
        if (aud.target.uri) {
          toRemove.push(aud.target.uri);
        }
      });
      this._audiences = [];

      if (toRemove.length > 0) {
        return from(toRemove)
          .pipe(
            mergeMap(uri => this._service.deleteTarget(uri), null, 4)
          )
          .subscribe(
            () => {
              this._changed.next();
              subscriber.next();
              subscriber.complete();
            },
            (err) => subscriber.error(err)
          );
      } else {
        subscriber.next();
        subscriber.complete();
      }
    });
  }

  public updateEstimates(estimates: IMultiEstimate[]) {
    this.get().forEach(aud => {
      const est = estimates.find(es => es.audience === aud.audience.audience_code );
      if (est) {
        aud.reach = est.totalReach;
        aud.overall_demand_spend = Math.round(est.overall_demand_spend);
        aud.effectiveCPM = est.effective_cpm;
        aud.cpm = est.cpm;
      } else {
        aud.overall_demand_spend = 0;
        aud.effectiveCPM = null;
        aud.cpm = null;
      }
    });
  }

  public toggle(audiences: SelectedAudience[], options = null): Observable<boolean> {
    return new Observable(observer => {
      this._service.toggleMultiTargets(audiences, options)
        .subscribe(
          targets => {
            // Reset audiences and populate with data received from backend
            this._audiences = [];
            if (targets.length > 0) {
              const setAudiences$ = [];
              targets.forEach(offerTarget => {
                setAudiences$.push(
                  this.set({
                    audience: offerTarget.target,
                    target: offerTarget
                  } as SelectedAudience)
                );
              });

              combineLatest(setAudiences$).subscribe(
                () => {
                  this._changed.next();
                  console.debug('AUDIENCES: ', this._audiences);
                  observer.next(true);
                  observer.complete();
                }
              );
            } else {
              this._changed.next();
              console.debug('AUDIENCES: ', this._audiences);
              observer.next(true);
              observer.complete();
            }
          },
          err => {
            console.log(err);
            observer.error(err);
          }
        );
    });
  }
}

@Injectable()
export class OfferService extends SuperService {

  updatingOffer: Subscription;
  updatingSchedules: Subscription;
  downloadingOffer = false;
  unpublishingCampaign = false;
  estimatedSpend: number;

  _estimatedBudgetData = new BehaviorSubject<IEstimatedBudget>(null);
  estimatedBudget$ = this._estimatedBudgetData.asObservable();

  _updated = new Subject();

  resetOffer = true;

  private _cancelSave$ = new Subject<any>();

  private _chosenFormat: IFormat;
  public formatChanged = new Subject();

  public loading = new BehaviorSubject(false);
  public currentOffer$ = new BehaviorSubject<OfferData>(null);

  private _offerDuration$ = new BehaviorSubject(null);
  public offerDuration$ = this._offerDuration$.asObservable();

  public newOfferData = {};

  public timeScheduleUpdated = new BehaviorSubject<ScheduleTime>(null);
  public savingOffer = new BehaviorSubject(false);

  displayTime = '1 day';

  public customArtwork = new BehaviorSubject<any>(null);

  offerTargets: OfferTarget[];

  set estimatedBudget(data) {
    this._estimatedBudgetData.next(data);
  }

  private _currentOffer: OfferData = null;
  /**
   * If 'currentOffer' exists, it returns object, otherwise it calls downloadOffer() method and returns null.
   * When currentOffer is being set, it actualises schedules.
   */
  get currentOffer(): OfferData {
    if (!this._currentOffer) {
      this.downloadOffer();
    }
    return this._currentOffer;
  }
  set currentOffer(co: OfferData) {
    this._currentOffer = co;

    if (this._currentOffer) {
      this.getSchedules();
    }
  }

  get chosenFormat(): IFormat {
    return this._chosenFormat;
  }

  set chosenFormat(format: IFormat) {
    this._chosenFormat = format;
    this.formatChanged.next();
  }

  updateCustomArtwork(image) {
    this.customArtwork.next(image);
  }

  get chosenAudience(): DisplayGroup {
    const audienceCode = this.currentOffer.audience;
    return this.displayGroups.find(displayGroup => displayGroup.audience_code === audienceCode);
  }

  // get chosenFormat(): FormatsBlockDispModel {
  //   if (!this.currentOffer) {
  //     return null;
  //   }
  //   const formatName = this.currentOffer.offer_variant;
  //   return this.api.formats.find(formatsBlock => formatsBlock.format_name === formatName);
  // }

  uploadedFiles: UploadedFiles = null;


  get displayGroups(): DisplayGroup[] {
    return this.api.displayGroups;
  }

  currentSchedules: ScheduleModel[] = [];
  _scheduleTime: ScheduleTime = null;
  scheduleWeather: ScheduleWeather = null;
  scheduleDisruptions: ScheduleDisruptions = null;
  scheduleAIM: ScheduleAIM = null;
  scheduleStockLevel: ScheduleStockLevel = null;

  get scheduleTime(): ScheduleTime {
    return this._scheduleTime;
  }
  set scheduleTime(scheduleTime: ScheduleTime) {
    this._scheduleTime = scheduleTime;
  }

  fileFeedback = {
    fileUploading: false,
    filePercent: 0
  };

  static createStockLevelSchedule(): ScheduleStockLevel {
    const schedule = new ScheduleStockLevel();
    schedule.type = 'DbOfferScheduleStockLevel';
    schedule.min_stock_level = 1;
    schedule.active = false;
    return schedule;
  }

  static createTimeSchedule(startDate: Date, stopDate: Date): ScheduleTime {
    const scheduleTime = new ScheduleTime();
    scheduleTime.type = 'DbOfferScheduleTime';
    scheduleTime.schedule_start = SuperService.formatDate(startDate, true);
    scheduleTime.schedule_stop = SuperService.formatDate(stopDate);
    scheduleTime.weekdays = [0, 1, 2, 3, 4, 5, 6];
    scheduleTime.time_start = null;
    scheduleTime.time_stop = null;
    scheduleTime.all_day = true;
    scheduleTime.active = true;
    return scheduleTime;
  }
  static createWeatherSchedule(): ScheduleWeather {
    const scheduleWeather = new ScheduleWeather();
    scheduleWeather.type = 'DbOfferScheduleWeather';
    scheduleWeather.sky_condition = '';
    scheduleWeather.temperature = '';
    return scheduleWeather;
  }
  private static createTransportSchedule(): ScheduleDisruptions {
    const scheduleTransport = new ScheduleDisruptions();
    scheduleTransport.type = 'DbOfferScheduleDisruptions';
    scheduleTransport.disruptions = '';
    return scheduleTransport;
  }
  private static createAIMSchedule(): ScheduleAIM {
    const scheduleAIM = new ScheduleAIM();
    scheduleAIM.type = 'DbOfferScheduleAIM';
    scheduleAIM.age = '';
    scheduleAIM.age_range = [0, 100];
    scheduleAIM.gender = '';
    scheduleAIM.gender_range = [0, 100];
    return scheduleAIM;
  }

  /**
   * Substitutes fields of schedule object with passed data if fields keys are on list of allowed keys.
   * @param data Passed data object.
   * @param scheduleObject Schedule object with fields intended to fill with 'data' fields.
   */
  private static substituteFields(data, scheduleObject): void {
    for (const key in data) {
      if (data.hasOwnProperty(key) && SPF.indexOf(key) > -1) {
        scheduleObject[key] = data[key];
      }
    }
  }

  constructor(
    injector: Injector,
    public api: ApiService,
    private formatsService: FormatsService,
  ) {
    super(injector);
    this._updated
      .pipe(
        debounceTime(2000)
      )
        .subscribe(
        () => this.save(true, true)
          .subscribe(
            () => this.loading.next(false),
          () => this.loading.next(false),
          ));
  }

  campaignUpdated() {
    this._updated.next(this.currentOffer);
  }

  private static getDefaultDate(duration: number) {
    const schedule_start = new Date();
    schedule_start.setDate(schedule_start.getDate() + 3);
    schedule_start.setHours(0, 0, 0, 0);
    const schedule_stop = new Date(schedule_start);
    schedule_stop.setDate(schedule_stop.getDate() + duration - 1);
    schedule_start.setHours(23, 59, 59, 999);


    return {schedule_start: schedule_start, schedule_stop: schedule_stop};
  }

  pendingRequests(): boolean {
    return !!this.updatingOffer || !!this.updatingSchedules;
  }

  // /**
  //  * Gets offers to display on tiles component on My Campaigns.
  //  * @param address
  //  * @param params Params info with objects limit and sort type.
  //  * @param onSuccess
  //  * @param onError
  //  */
  // getOffers(
  //   address: string,
  //   params: HttpParams,
  //   onSuccess: (offers: OffersReceiveModel) => void,
  //   onError: (error) => void
  // ): void {
  //   const self = this;
  //
  //   self.http.get<OffersReceiveModel>(Session.enrichApiUrl(address), {headers: self.getTokenToHeader(), params: params})
  //     .subscribe(
  //       offers => {
  //         onSuccess(offers);
  //       },
  //       err => {
  //         onError(err);
  //         console.log(err);
  //       }
  //     );
  // }

  getOffers(address: string, params): Observable<ISQLSearch.IOffersResponse> {
    return this.http.get<ISQLSearch.IOffersResponse>(Session.enrichApiUrl(address), {params: params});
  }

  /**
   * If schedule exists and field 'active' is set to 'true', method returns true.
   * If schedule does not exist or field 'active' is set to 'false', method returns false.
   * @param schedule Schedule passed
   * @returns {boolean} Information about activeness of passed schedule
   */
  public isActive(schedule): boolean {
    if (schedule) {  return  schedule.active; }
    return false;
  }

  fetchOfferByUri(uri: string): Observable<OfferData> {
    return this.http.get<OfferData>(
      Session.enrichApiUrl(uri)
    ).pipe(
      tap(offer => {
        this.currentOffer = offer;
      })
    );
  }

  fetchOffer(offerId) {
    if (!this.api.sessionData) {
      return;
    }

    return this.http.get<OfferData>(
      Session.enrichApiUrl(this.api.sessionData.company.uris.Offers + '/' + offerId)
    ).pipe(
      tap(offer => {
        this.currentOffer = offer;
      })
    );


  }

  // region OFFER OPERATIONS
  /**
   * Gets offer from server using address passed as parameter.
   * Then it assigns variable to currentOffer and calls successCB if succeed,
   * or only calls errorCB and passes err parameter if error occurred.
   * @param {() => void} successCB
   * @param {(err) => void} errorCB
   * @param reload
   */
  downloadOffer(
      successCB?: () => void,
      errorCB?: (err) => void,
      reload: boolean = false
  ): void {
    const self = this;

    if (!self.api.sessionData) {
      return;
    }
    const urlNumbers = window.location.pathname.match(/\d+/);
    let address: string;
    if (reload) {
      address = self.currentOffer.uri;
    } else {
      if (!urlNumbers) {
        return;
      }
      address = self.api.sessionData.company.uris.Offers + '/' + urlNumbers[0];
    }

    if (self.downloadingOffer) {
      return;
    }
    self.downloadingOffer = true;
    self.http.get<OfferData>(
      Session.enrichApiUrl(address)
    ).subscribe(
        offer => {
          self.currentOffer = offer;
          self.downloadingOffer = false;
          if (successCB) {
            successCB();
          }
        },
        error => {
          self.downloadingOffer = true;
          if (errorCB) {
            errorCB(error);
          }
        }
      );
  }

  public saveOffer(): Observable<OfferData> {
    this.savingOffer.next(true);
    return this.http.put<OfferData>(Session.enrichApiUrl(this.currentOffer.uri), this.currentOffer)
      .pipe(
        tap(() => this.savingOffer.next(false))
      );
  }

  save(skipResponse = false, silent = false): Observable<any> {
    !silent && this.loading.next(true);
    let headers = {};
    if (skipResponse) {
      headers = {'X-Skip-Response': '1'};
    }

    this._cancelSave$.next();
    if (this.currentOffer && this.currentOffer.uri) {
      const payload = {...this.currentOffer};
      delete payload.multi_offer_artworks;
      return this.http.put<OfferData>(Session.enrichApiUrl(this.currentOffer.uri), payload, {headers: new HttpHeaders(headers)})
        .pipe(
          tap(() => !silent && this.loading.next(false)),
          tap(() => this.currentOffer$.next({...this.currentOffer})),
          takeUntil(this._cancelSave$)
        );
    } else {
      return of(true);
    }
  }

  // endregion

  // region GETTING SCHEDULES
  /**
   * Downloads schedules using 'currentOffer.uris.Schedules'.
   * Then assings them to currentSchedules and every single one of them to
   * 'scheduleTime', 'scheduleWeather', 'scheduleDisruptions' and 'scheduleAIM'.
   * @param {() => void} successCB
   * @param {(err) => void} errorCB
   */
  getSchedules(
      successCB?: () => void,
      errorCB?: (err) => void
  ): void {
    const self = this;

    self.http.get<ScheduleModel[]>(
      Session.enrichApiUrl(self.currentOffer.uris.Schedules)
    ).subscribe(
        downloaded_schedules => {
          self.currentSchedules = downloaded_schedules;
          for (const schedule of downloaded_schedules) {
            self.fitSchedule(schedule);
          }
          if (successCB) {
            successCB();
          }
        },
        error => {
          if (errorCB) {
            errorCB(error);
          }
        }
    );
  }

  public fetchOfferSchedules(): Observable<ScheduleModel[]> {
    return this.http.get<ScheduleModel[]>(
      Session.enrichApiUrl(this.currentOffer.uris.Schedules)
    );
  }

  public assignScheduleTypes(schedules) {
    this.currentSchedules = schedules;
    for (const schedule of schedules) {
      this.fitSchedule(schedule);
    }
  }

  private fitSchedule(schedule: ScheduleModel) {
    const self = this;
    switch (schedule.type) {
      case 'DbOfferScheduleTime': {
        self.scheduleTime = schedule;
        break;
      }
      case 'DbOfferScheduleWeather': {
        self.scheduleWeather = schedule;
        break;
      }
      case 'DbOfferScheduleDisruptions': {
        self.scheduleDisruptions = schedule;
        break;
      }
      case 'DbOfferScheduleAIM': {
        self.scheduleAIM = schedule;
        break;
      }
      case 'DbOfferScheduleStockLevel': {
        self.scheduleStockLevel = schedule;
      }
    }
  }
  // endregion

  // region SCHEDULE CREATING

  /**
   * Creates schedule to start campaign
   * @param successCB called when schedule is successfully created
   * @param errorCB called when schedule is not successfully created
   * @param schedule optional schedule
   */
  startCampaign(
      successCB: () => void,
      errorCB: (err) => void,
      schedule?: string
  ): void {
    this.saveSchedules().subscribe(
      () => successCB(),
      (err) => errorCB(err)
    );
  }

  /**
   * adds 'back-to-draft' field to current offer field and POSTs it to server
   * then it assigns local currentOffer field with server response
   */
  unpublishCampaign(): Observable<OfferData> {
    return new Observable(subscriber => {

      const offer: OfferData = this.currentOffer;

      if (!this.currentOffer.locked) {
        subscriber.next(this.currentOffer);
        subscriber.complete();
      } else {
        offer['back-to-draft'] = true;
        this.http.put<OfferData>(Session.enrichApiUrl(this.currentOffer.uri), this.currentOffer)
          .subscribe(
            (data) => {
              this.currentOffer = data;
              subscriber.next(data);
              subscriber.complete();
            },
            (err) => subscriber.error(err)
          );
      }
    });
  }

  // endregion

  // region EDIT SCHEDULE
  /**
   * Method used for editing particular time schedule field.
   * If time schedule exists, it selects 'PUT' request, if it doesn't, 'POST' request is used.
   * If there are no values passed as parameters, method sends schedule object as is.
   * @param field_name Field name of time schedule to edit.
   * @param field_value New value of field.
   */
  editTimeSchedule(field_name?: string, field_value?: any) {
    const self = this;
    let method: string,
        address: string;

    if (!self.scheduleTime || !self.scheduleTime.uri) {
      const dates = OfferService.getDefaultDate(parseInt(self.currentOffer.duration, 10));
      self.scheduleTime = OfferService.createTimeSchedule(dates.schedule_start, dates.schedule_stop);
      method = 'POST';
      address  = Session.enrichApiUrl(self.currentOffer.uris.Schedules);
    } else {
      method = 'PUT';
      address  = Session.enrichApiUrl(self.scheduleTime.uri);
    }

    if (field_name && field_value !== undefined) {
      self.scheduleTime[field_name] = field_value;
    } else if (field_name === 'duration') {
      const dates = OfferService.getDefaultDate(parseInt(self.currentOffer.duration, 10));
      self.scheduleTime = OfferService.createTimeSchedule(dates.schedule_start, dates.schedule_stop);
    }
    self.syncSchedule(
        method, address,
        self.scheduleTime,
    data => OfferService.substituteFields(data, self.scheduleTime)
    );
    this.currentOffer.duration = this.getSchedulerTimeDuration().toString();
    this.timeScheduleUpdated.next(self.scheduleTime);
  }

  /**
   * Method used for editing particular weather schedule field.
   * If weather schedule exists, it selects 'PUT' request, if it doesn't, 'POST' request is used.
   * @param field_name Field name of time schedule to edit.
   * @param field_value New value of field.
   */
  editWeatherSchedule(field_name: string, field_value: any) {
    const self = this;
    let method: string,
        address: string;

    if (!self.scheduleWeather) {
      self.scheduleWeather = OfferService.createWeatherSchedule();
      method = 'POST';
      address  = Session.enrichApiUrl(self.currentOffer.uris.Schedules);
    } else {
      method = 'PUT';
      address  = Session.enrichApiUrl(self.scheduleWeather.uri);
    }

    self.scheduleWeather[field_name] = field_value;
    self.scheduleWeather.active = self.scheduleWeather.sky_condition.length > 0 || self.scheduleWeather.temperature.length > 0;

    self.syncSchedule(
        method, address,
        self.scheduleWeather,
    data => OfferService.substituteFields(data, self.scheduleWeather)
    );
  }

  editStockLevelSchedule(field_name: string, field_value: any) {
    const self = this;
    let method: string,
        address: string;

    if (!self.scheduleStockLevel) {
      self.scheduleStockLevel = OfferService.createStockLevelSchedule();
      method = 'POST';
      address  = Session.enrichApiUrl(self.currentOffer.uris.Schedules);
    } else {
      method = 'PUT';
      address  = Session.enrichApiUrl(self.scheduleStockLevel.uri);
    }

    self.scheduleStockLevel[field_name] = field_value;

    self.syncSchedule(
        method, address,
        self.scheduleStockLevel,
    data => OfferService.substituteFields(data, self.scheduleStockLevel)
    );
  }

  /**
   * Method used for editing particular transport schedule field.
   * If transport schedule exists, it selects 'PUT' request, if it doesn't, 'POST' request is used.
   * @param field_name Field name of time schedule to edit.
   * @param field_value New value of field.
   */
  editTransportSchedule(field_name: string, field_value: any) {
    const self = this;
    let method: string,
        address: string;

    if (!self.scheduleDisruptions) {
      self.scheduleDisruptions = OfferService.createTransportSchedule();
      method = 'POST';
      address  = Session.enrichApiUrl(self.currentOffer.uris.Schedules);
    } else {
      method = 'PUT';
      address  = Session.enrichApiUrl(self.scheduleDisruptions.uri);
    }

    self.scheduleDisruptions[field_name] = field_value;
    self.scheduleDisruptions.active = self.scheduleDisruptions.disruptions.length > 0;

    self.syncSchedule(
        method, address,
        self.scheduleDisruptions,
        data => OfferService.substituteFields(data, self.scheduleDisruptions)
    );
  }

  /**
   * Method used for editing particular AIM schedule field.
   * If AIM schedule exists, it selects 'PUT' request, if it doesn't, 'POST' request is used.
   * @param field_name Field name of time schedule to edit.
   * @param field_value New value of field.
   */
  editAIMSchedule(field_name: string, field_value: any) {
    const self = this;
    let method: string,
        address: string;

    if (!self.scheduleAIM) {
      self.scheduleAIM = OfferService.createAIMSchedule();
      method = 'POST';
      address  = Session.enrichApiUrl(self.currentOffer.uris.Schedules);
    } else {
      method = 'PUT';
      address  = Session.enrichApiUrl(self.scheduleAIM.uri);
    }

    self.scheduleAIM[field_name] = field_value;
    self.scheduleAIM.active = self.scheduleAIM.gender.length > 0 || self.scheduleAIM.age.length > 0;

    self.syncSchedule(
        method, address,
        self.scheduleAIM,
        data => OfferService.substituteFields(data, self.scheduleAIM)
    );
  }

  private saveSchedules(): Observable<any> {

    if (this.currentSchedules.length === 0) {
      return of(true);
    }

    const schedulesUri = Session.enrichApiUrl(this.currentOffer.uris.Schedules);
    const scheduleObservables = [];

    this.currentSchedules.forEach((schedule) => {
      if (schedule.uri) {
        scheduleObservables.push(this.http.put<ScheduleModel>(Session.enrichApiUrl(schedule.uri), schedule));
      } else {
        scheduleObservables.push(this.http.post<ScheduleModel>(schedulesUri, schedule));
      }
    });

    console.log(scheduleObservables);

    return combineLatest(scheduleObservables);
  }

  private syncSchedule(
      method: string,
      address,
      body,
      successCB?: (data) => void): void {
    const self = this;

    if (self.updatingSchedules) {
      self.updatingSchedules.unsubscribe();
    }

    self.updatingSchedules = self.http.request(
        method,
        address,
        {
          body: body
        })
        .subscribe(
            scheduleWeatherFromServer => {
              delete self.updatingSchedules;
              successCB(scheduleWeatherFromServer);
            },
            error => {
              delete self.updatingSchedules;
              console.error(error);
            }
    );
  }
  // endregion

  // region SEND FILE
  /**
   * This method sends name of file in JSON form to currentOffer.uris.Files address.
   * After backend response it calls either 'successCB' or 'errorCB'.
   * @param file File to send.
   * @param successCB
   * @param errorCB
   */
  sendFileName(file: File,
               successCB?: (data: any, file: File) => void,
               errorCB?: (err) => void): void {
    const self = this;

    const requestBody = {
      'name': file.name
    };

    self.http.post<any>(
      Session.enrichApiUrl(self.currentOffer.uris.Files),
      requestBody
    )
        .subscribe(
            data => successCB(data, file),
            err => errorCB(err)
        );
  }

  /**
   * This method realises sending file itself using 'POST' request.
   * It can monitor uploading progress thanks to 'reportProgress' set to true.
   * I did not use it as it was not top priority, but Primo can finish this concept :)
   * @param sendingData FileSendingData object taken from sendFileName request.
   * @param file File object.
   * @param successCB
   * @param errorCB
   */
  sendFile(sendingData: any,
           file: File,
           successCB?: (data, fType) => void,
           errorCB?: (err) => void): void {
    const self = this;

    const fileType = file.type;
    const address = sendingData.uris.Upload;
    const headers = new HttpHeaders({
      'X-FlowCity-Session-User': self.cookieService.get('X-FlowCity-Session-User'),
      'content-type': fileType,
      'X-Upload-Content-Type': fileType
    });
    self.http.request(new HttpRequest('POST', address, file, { headers: headers, reportProgress: true }))
        .subscribe(
            data => successCB(data, fileType),
            error => errorCB(error)
        );
  }

  informServerAboutFile(sendingData, data, fileType: string,
                        successCB?: (data) => void,
                        errorCB?: (err) => void,
                        complete?: () => void): void {

    const self = this;
    const address = Session.enrichApiUrl(sendingData.uris.UploadedFiles);

    const response_content = data.body;
    response_content['contentType'] = fileType;

    const newOfferData = {
      'response_content': response_content,
      'status_code': data.status
    };
    self.http.post<UploadedFiles>(
      address,
      newOfferData
    )
      .subscribe(
          _data => {
            successCB(_data);
            self.uploadedFiles = _data;
          },
          err => errorCB(err),
          () => complete()
      );
  }
  // endregion

  sendNewOffer(address: string,
               newOfferData: OfferData,
               successCB?: (data: OfferData) => void,
               errorCB?: (err) => void
  ): void {
    const self = this;

    const headers = new HttpHeaders({
      'X-Skip-Sync': '1',
    });

    self.http.post<OfferData>(
      address,
      newOfferData,
      { headers }
    ).subscribe(
        offerData => successCB(offerData),
        err => errorCB(err)
    );
  }

  /**
   * Calculates hours based on schedulerTime settings
   * @returns {number}
   */
  getSchedulerTimeHours(): number {
    let hours = 24;
    if (this.scheduleTime && !this.scheduleTime.all_day) {
      hours = moment.duration(
        moment(`2018-01-01 ${this.scheduleTime.time_stop}`)
          .diff(moment(`2018-01-01 ${this.scheduleTime.time_start}`))
      ).asHours();
    }
    return hours;
  }

  /**
   * Calculates duration in days based on schedulerTime settings
   * @returns {number}
   */
  // getSchedulerTimeDuration(): number {
  //   if (this.scheduleTime) {
  //     return (moment(this.scheduleTime.schedule_stop)
  //       .endOf('day')
  //       .diff(moment(this.scheduleTime.schedule_start)
  //         .startOf('day'), 'days')
  //       + 1);
  //   } else {
  //     return parseInt(this.currentOffer.duration, 10);
  //   }
  // }

  getSchedulerTimeDuration(): number {
    if (this.scheduleTime) {
      const startDate = moment(this.scheduleTime.schedule_start),
            endDate = moment(this.scheduleTime.schedule_stop),
            weekdays = this.scheduleTime.weekdays;

      const sumDays = this.getActualDaysBetweenDates(startDate, endDate, weekdays);
      this._offerDuration$.next(sumDays);
      return sumDays;
    } else {
      const val = parseInt(this.currentOffer.duration, 10);
      this._offerDuration$.next(val);
      return val;
    }
  }

  private getActualDaysBetweenDates(startDate, endDate, weekdays): number {
    let sumDays = 0;
    weekdays.forEach(d => {
      const occur = this.weekdaysBetween(startDate, endDate, d);
      sumDays = sumDays + occur;
    });
    return sumDays;
  }

  updateOfferEstimates(estimations: AudienceTypeEstimation[]) {
    if (this.chosenAudience && this.chosenFormat && this.currentOffer) {
      if (this.chosenAudience.audience_group) {
        const currEst = estimations.find(est => est.audience === this.chosenAudience.display_name.toLowerCase());
        if (currEst) {
          // this.currentOffer.reach = currEst.totalReach;
          const currFormat = currEst.formats.find(f => f.format === this.chosenFormat.ooh_format);
          if (currFormat) {
            this.currentOffer.reach = currFormat.reach;
            this.currentOffer.num_of_screens = currFormat.number;
          }
        }
      } else {
        this.currentOffer.reach = this.chosenAudience.daily_reach * this.getSchedulerTimeDuration();
        this.currentOffer.num_of_screens = this.chosenAudience.num_of_screens;
      }
    }
  }

  public resetCurrentOffer() {
    this.currentOffer = null;
    this.scheduleTime = null;
    this.chosenFormat = null;
    this.scheduleWeather = null;
    this.scheduleDisruptions = null;
    this.scheduleAIM = null;
    this.scheduleStockLevel = null;
    this._estimatedBudgetData.next(null);
  }



  canUploadNewArtwork(): boolean {
      return this.currentOffer ? !['preconstructed', 'generated'].includes(this.currentOffer.offer_origin) : true;
  }

  setCurrentOfferType(offer_type: string) {
    const sessionData = this.api.currentSessionData;
    if (offer_type === 'video') {
      this.currentOffer.offer_type = 'video';
      this.currentOffer.source_template = this.api.defaultOfferVideoTemplate;
    } else {
      this.currentOffer.offer_type = 'image';
      this.currentOffer.source_template = this.api.defaultOfferImageTemplate;
    }
  }

  public validatePostcode(postcode): Observable<any> {
    const url = env.sessionServiceUrl + '/api/location',
          params = new HttpParams().set('postcode', postcode);
    return this.http.get<LocationPostcodeModel>(url, {params: params});
  }

  private getFileName(response): string {
    const regex = /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/gi;
    if (response.headers.get('content-disposition') !== null) {
      return regex.exec(response.headers.get('content-disposition'))[1];
    } else {
      return 'CampaignScreenReport.xlsx';
    }
  }

  public downloadScreenReport(offer: OfferData = null): Observable<any> {
    return new Observable(subscriber => {
      const sessionId = this.cookieService.get('X-FlowCity-Session-User');
      const uri = Session.enrichOffersService(`${offer ? offer.uri : this.currentOffer.uri}/media-plan-xlsx?_t=${Date.now()}`);
      this.http.head(uri).subscribe(
          () => {
            this.http.get(uri, {observe: 'response' as 'body', responseType: 'blob' as 'json'})
              .subscribe(
              (response: any) => {
                const dataType = response.body.type;
                const binaryData = [];
                const filename = this.getFileName(response);
                binaryData.push(response.body);
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
                if (filename) {
                  downloadLink.setAttribute('download', filename);
                }
                document.body.appendChild(downloadLink);
                downloadLink.click();
                downloadLink.remove();
                subscriber.next(true);
                subscriber.complete();
              });
          },
          () => subscriber.error()
        );
    });
  }

  get impressionModel() {
    return this.currentOffer.impression_model;
  }

  getTargets(withTarget = false): Observable<OfferTarget[]> {
    let params = new HttpParams();
    if (withTarget) {
      params = params.set('recursive', '1');
    }
    return this.http.get<OfferTarget[]>(Session.enrichApiUrl(this.currentOffer.uris.OfferTargets), {params: params})
      .pipe(
        tap((offerTargets) => this.offerTargets = offerTargets)
      );
  }

  // *********************************************************************
  // NEW ELEMENTS
  // *********************************************************************


  fetch(address: string = null): Observable<OfferData> {
    const url = address || Session.enrichApiUrl(this.currentOffer.uri);
    return this.http.get<OfferData>(url)
      .pipe(
        tap((offer) => this.currentOffer = offer )
      );
  }

  // NEW MULTI TARGET SUPPORT
  toggleMultiTargets(offerTargets, options = null): Observable<OfferTarget[]> {
    const targetUris = offerTargets.map(t => t.audience.uri);
    const body = {
      targets: targetUris,
      status: 'draft'
    }

    if (options && options.hasOwnProperty('autoPublish')) {
      body['auto_publish'] = options.autoPublish;
    }

    if (options && options.hasOwnProperty('isMulti')) {
      body['is_multi'] = options.isMulti;
    }

    this.loading.next(true);
    return this.http.post<any>(Session.enrichApiUrl(`${this.currentOffer.uris.MultiOfferTargets}?recursive=1`), body)
      .pipe(tap(() => this.loading.next(false)));
  }


  // NEW
  deleteTarget(targetUri): Observable<any> {
    this.loading.next(true);
    return this.http.delete<any>(Session.enrichApiUrl(targetUri))
      .pipe(tap(() => this.loading.next(false)));
  }

  // NEW
  addTarget(displayGroup: DisplayGroups): Observable<OfferTarget> {
    this.loading.next(true);
    return this.http.post<OfferTarget>(Session.enrichApiUrl(this.currentOffer.uris.OfferTargets), {
      status: 'draft',
      target: displayGroup.uri
    }).pipe(
      tap((data) => {
        console.log(data);
        this.loading.next(false);
        this.getTargets().subscribe();
        // this.fetch(AppConfig.enrichApiUrl(this.currentOffer.uri)).subscribe(
        //   () => {
        //     this.loading.next(false);
        //     this.getTargets().subscribe()
        //   }
        // );
      })
    );
  }

  submitTargets(targets): Observable<any> {
    const uri = Session.enrichApiUrl(this.currentOffer.uris.SubmitMultipleOfferTargets);
    this.loading.next(true);
    return this.http.post<any>(uri, { targets: targets })
      .pipe(tap(() => this.loading.next(false)));
  }

  submitTargetStatus(targetUri, status, reason = null): Observable<any> {
    this.loading.next(true);
    return this.http.put<OfferTarget>(Session.enrichApiUrl(targetUri), {
      status: status,
      rejection_reason: reason
    }).pipe(
      tap(() => this.loading.next(true))
    );
  }

  setStatus(status: string, displayGroup: DisplayGroups): Observable<OfferTarget> {
    let req;
    this.loading.next(true);
    if (this.offerTargets.length > 0) {

      const current = this.offerTargets.find(offerTarget => offerTarget.target === displayGroup.uri);

      if (current) {
        req = this.http.put<OfferTarget>(Session.enrichApiUrl(current.uri), {
          status: status
        });
      } else {
        req = this.http.post<OfferTarget>(Session.enrichApiUrl(this.currentOffer.uris.OfferTargets), {
          status: status,
          target: displayGroup.uri
        });
      }
    } else {
      req = this.http.post<OfferTarget>(Session.enrichApiUrl(this.currentOffer.uris.OfferTargets), {
        status: status,
        target: displayGroup.uri
      });
    }

    return req.pipe(
      tap((data) => {
        console.log(data);
        this.fetch(Session.enrichApiUrl(this.currentOffer.uri)).subscribe(
          () => {
            this.loading.next(false);
            this.getTargets().subscribe();
          }
        );
      })
    );
  }

  getArtworkUrl(): string {
    return this.currentOffer.media_file ?
        `${Session.enrichApiUrl(this.currentOffer.media_file)}${this.currentOffer.media_file.split('?').length > 1 ? '&' : '?'}os_user=${this.cookieService.get('X-FlowCity-Session-User')}` :
        '';
  }

  // ******************************************************************************
  //                              RTB calculations
  // ******************************************************************************
  private weekdaysBetween(d1, d2, weekday) {
    d1 = moment(d1);
    d2 = moment(d2);
    const daysToAdd = ((7 + weekday) - (d1.isoWeekday() - 1)) % 7;
    const next = d1.clone().add(daysToAdd, 'days');
    if (next.isAfter(d2)) {
        return 0;
    }
    const weeksBetween = d2.diff(next, 'weeks');
    return weeksBetween + 1;
  }

  private calculateDatesOnDuration(startDate: Moment, duration: number, days) {
    const endDate = startDate.clone().add(duration / days.length, 'weeks');
    let sumDays = 0;
    days.forEach(d => {
      const occur = this.weekdaysBetween(startDate, endDate, d);
      sumDays = sumDays + occur;
    });
    return {
      startDate: startDate,
      endDate: sumDays > duration ? endDate.subtract(sumDays - duration, 'days') : endDate,
    };
  }

  setSchedulerDatesBasedOnDuration(duration: number, silent = false) {
    const startDate = moment(this.scheduleTime.schedule_start);
    const weekdays = this.scheduleTime.weekdays;
    const dates = this.calculateDatesOnDuration(startDate, duration, weekdays);
    this.scheduleTime.schedule_start = dates.startDate.format('YYYY-MM-DDT00:00');
    this.scheduleTime.schedule_stop = dates.endDate.format('YYYY-MM-DDT23:59');

    // TODO refactoring needed
    this.syncSchedule('PUT', Session.enrichApiUrl(this.scheduleTime.uri), this.scheduleTime,
    data => OfferService.substituteFields(data, this.scheduleTime)
    );
    if (!silent) {
      this.timeScheduleUpdated.next(this.scheduleTime);
    }
  }

  public get isRTB() {
    return this.impressionModel === 'rtb';
  }

  public get isRegular() {
    return this.impressionModel === 'regular';
  }

  public getMultiOfferTargets(body): Observable<IMultiOfferTarget[]> {
    return this.http.post<IMultiOfferTarget[]>(Session.enrichApiUrl(this.api.currentDepartment.uris.MultiOfferTargets), body);
  }

  public setTargetsStatus(targets, status, reason = null): Observable<any> {
    const uri = Session.enrichApiUrl(this.currentOffer.uris.UpdateMultipleOfferTargets);
    this.loading.next(true);
    return this.http.post<any>(uri, {
      targets: targets,
      status: status,
      rejection_reason: reason,
      auto_publish: true,
    })
      .pipe(
        tap(() => this.loading.next(false))
      );
  }

  public getRTBBudgetStats(dealId: string): Observable<IRTBBudgetStats> {
    return this.http.get<IRTBBudgetStats>(Session.enrichReportService(`${REPORTS_API_RTB_BUDGET_CHECK}${dealId}`));
  }

  public updateDailyBudget(offer: OfferData): Observable<any> {
    const uri = Session.enrichApiUrl(offer.uri);
    const payload = {
      'set_daily_budget': true,
      'budget_daily': offer.budget_daily
    };
    const headers = {'X-Skip-Response': '1'};
    return this.http.put(uri, payload, {headers: new HttpHeaders(headers)});
  }

  public createArtworksFromFeed(data: IFeedChooser.IArtwork[]): Observable<ICampaignFile[]> {
    const uri = Session.enrichApiUrl(this.currentOffer.uris['CreateFilesFromFeedTest']);
    return this.http.post<ICampaignFile[]>(uri, { artworks: data });
  }

  public getCampaignForEstimation(): IEstimatedCampaignData {
    const data: IEstimatedCampaignData = {
      ...omit(...CAMPAIGN_IGNORED_FIELDS_ON_SAVE)(this.currentOffer),
      offer_postcode: this.currentOffer.offer_postcode || this.api.location.postcode,
      offer_radius: this.currentOffer.offer_radius !== null && this.currentOffer.offer_radius >= 0
        ? this.currentOffer.offer_radius
        : this.api.currentDepartment.radius,
      audience: this.currentOffer.audience === '' ? null : this.currentOffer.audience,
      offer_variant: this.currentOffer.offer_variant === '' ? null : this.currentOffer.offer_variant,
      offer_schedule_bases: this.getAllSchedules()
    };
    return data;
  }

  getAllSchedules() {
    const schedules = this.currentSchedules;
    if (schedules) {
      const timeScheduleIdx = schedules.findIndex(schedule => schedule.type === 'DbOfferScheduleTime');
      if (timeScheduleIdx !== -1) {
        const timeSchedule = {...this.currentSchedules[timeScheduleIdx], ...this.scheduleTime};
        schedules[timeScheduleIdx] = timeSchedule;
      } else {
        this.currentSchedules.push(this.scheduleTime);
      }
    } else {
      if (this.scheduleTime) {
        this.currentSchedules.push(this.scheduleTime);
      }
    }
    return schedules;
  }

  public get isLocked(): boolean {
    return this.currentOffer && this.currentOffer.locked;
  }

  public set campaignFormats(formats: string[]) {
    this.currentOffer.campaign_formats = formats && formats.length > 0 ? formats : null;
  }

  public get campaignFormats(): string[] {
    return this.currentOffer.campaign_formats;
  }

  public updateCampaignFormatData(audience: SelectedAudience): void {
    const formats = this.formatsService.getSelectedFormats(this.campaignFormats);
    console.log(formats);

    // backward compatibility
    this.chosenFormat = formats.length > 0 ? formats[0] : null;
    this.currentOffer.offer_variant = this.chosenFormat ? this.chosenFormat.format : null;

    this.currentOffer.num_of_screens = formats.reduce((acc, obj) => acc + obj.number, 0);
    this.currentOffer.ad_plays = formats.reduce((acc, obj) => acc + obj.impressions, 0);
    this.currentOffer.reach = formats.reduce((acc, obj) => acc + obj.reach, 0);
    if (formats && formats.length > 0) {
      this.estimatedBudget = {
        budget: parseInt(this.currentOffer.budget, 10),
        estimatedSpend: formats.reduce((acc, obj) => acc + obj.demand_spend, 0),
      };
    } else {
      this.estimatedBudget = {
        budget: parseInt(this.currentOffer.budget, 10),
        estimatedSpend: audience ? audience.overall_demand_spend : 0,
      };
      this.currentOffer.reach = audience && audience.reach;
    }
  }
}


