<app-buy-more-credits
  #buyMoreCredits
></app-buy-more-credits>

<ng-template #afterSubmitPopup let-d="dismiss">

  <app-campaign-created-info
    [offerService]="service"
    [location]="service.api.location"
    [advanced]="true"
    [modal]="afterSubmitModal"
    (finish)="backToTiles()"
    class="panel">
  </app-campaign-created-info>

</ng-template>

<div class="row campaign-row" [class.disabled]="processingToDraft">

  <div *ngIf="processingToDraft" id="back-to-draft">
    <div class="back-to-draft-ctn">
      <span>Changing to Draft...</span>
      <div class="spinner"></div></div>
  </div>

  <div id="campaigns-main" class="col-sm-12">

    <div class="row budget-row create-campaign-row" [class.disabled]="!service.currentOffer">
      <div id="schedules-info" class="sidebar-info" [style.opacity]="!schedulesFocused ? 0.5 : 1">
        <h2>
          Campaign Schedule <br>
          & Triggers
        </h2>
      </div>
      <div class="creating-main-column">
        <div [@budgetFocused]="schedulesFocused" class="cpgn_editing">
          <h3>
            Control when your campaign runs
          </h3>
          <p>
            Change dates and add custom triggers to make your campaign super relevant.
            Our clever tech constantly monitors situation around your location to
            automatically show ads when they are most likely to convert.
          </p>
          <app-campaign-schedule></app-campaign-schedule>
        </div>
        <div [@budgetFocused]="!schedulesFocused" class="cpgn_wrapped">
          <div class="wrapped_edit" (click)="editSchedules()">
            <span>
              EDIT
            </span>
          </div>
          <div class="wrapped_circle">
            <span style="font-size: calc(1vw + 5px);">
              {{triggersActive()}}
            </span>
          </div>
        </div>

      </div>
    </div>


    <div class="row budget-row create-campaign-row" [class.disabled]="!service.currentOffer">
      <div id="budget-info" class="sidebar-info" [style.opacity]="!budgetFocused ? 0.5 : 1">
        <h2>
          Budget & Schedule
        </h2>
      </div>
      <div class="creating-main-column">
        <div [@budgetFocused]="budgetFocused" class="cpgn_editing">
          <p>Decide how much you want to spend on your campaign. Use your credits as a currency to pay for this.</p>

          <app-rtb-budget
                  [disableDuration]="true"
                  (data)="modifySchedule($event)">
          </app-rtb-budget>
          <ng-container *appHasProfile="'shopify'; else REGULAR"></ng-container>
          <ng-template #REGULAR>
            <button (click)="buyMoreCreditsOpen()" class="btn buy-credits-btn">Buy more credits</button>
          </ng-template>
        </div>
        <div [@budgetFocused]="!budgetFocused" class="cpgn_wrapped">
          <div class="wrapped_edit" (click)="editBudget()" *ngIf="canEditParameters()">
            <span>
              EDIT
            </span>
          </div>
          <div class="wrapped_circle">
            <span style="font-size: calc(1vw + 5px);">
              {{ budget }}<br>
              Credits
            </span>
          </div>
        </div>

      </div>
    </div>

    <div class="row audience-row create-campaign-row">
      <div id="audience-info" class="sidebar-info" [style.opacity]="!audienceFocused ? 0.5 : 1">
        <h2>
          Location <br>
          & Audience
        </h2>
      </div>
      <div class="creating-main-column">

        <app-campaign-location
          [latitude]="latitude"
          [longitude]="longitude"
          [locationMap]="locationMap"
          [radius]="radius"
          [postalCode]="postcode"
          [@audienceLocationFocused]="audienceFocused"
          (updated)="onLocationChange($event)">
        </app-campaign-location>

        <div [@budgetFocused]="audienceFocused" class="cpgn_editing" style="padding-left: 0;">
          <h3>Select your target audience</h3>
          <p>Audience shows the number of people you can reach daily with your campaign.<br>This number will be adjusted once you choose format.</p>

          <app-audience-selector
            [location]="service.api.location"
            [postcode]="postcode"
            [estimations]="offerEstimations"
            [offerAudiences]="offerAudiences"
            (selectAudience)="updateAudiences($event)">
          </app-audience-selector>

        </div>

        <div [@budgetFocused]="!audienceFocused" class="cpgn_wrapped">
          <div class="wrapped_edit" (click)="editAudience()" *ngIf="canEditParameters()">
            <span>
              EDIT
            </span>
          </div>
          <div class="wrapped_circle">
            <span style="font-size: calc(1vw + 2px); width: 92%;">
              <ng-container *ngIf="selectedAudiencesNumber > 1; else single">{{ selectedAudiencesNumber }}<br>audiences</ng-container>
              <ng-template #single>
                <ng-container *ngIf="selectedAudiencesNumber === 1; else NO_AUDIENCE_SELECTED">
                  {{ offerAudiences.getNames() }}
                </ng-container>
                <ng-template #NO_AUDIENCE_SELECTED>
                No Audience Selected
                </ng-template>
              </ng-template>
            </span>
          </div>
        </div>

      </div>
    </div>

    <div class="row audience-row create-campaign-row">
      <div class="sidebar-info" [style.opacity]="!formatFocused ? 0.5 : 1">
        <h2>
          Format <br>
          & Artwork
        </h2>
      </div>
      <div class="creating-main-column">
        <div [@budgetFocused]="formatFocused" class="cpgn_editing" style="padding-left: 0; margin-top:20px">

          <ng-container *ngIf="!service.chosenFormat; else formatChosen">
            <h3>Select Screens</h3>
            <p>Formats show the number and type of screens available to publish your campaign.</p>
          </ng-container>
          <ng-template #formatChosen>
            <h3>Upload your Artwork</h3>
            <p>Now, let's upload your artwork. This is what you will share with the world.</p>
          </ng-template>

          <ng-container *appHasSubscription="'PLUSS'; else STANDARD_FORMAT_SELECTOR">
            <app-multi-format-selector
              #appFormat
              [estimations]="offerEstimations"
              [offerAudiences]="offerAudiences"
              (changed)="onSelectedFormatsChanged()">
            </app-multi-format-selector>
          </ng-container>
          <ng-template #STANDARD_FORMAT_SELECTOR>
            <app-format-selector
              #appFormat
              [estimations]="offerEstimations"
              [offerAudiences]="offerAudiences"
              (selectFormat)="selectFormat($event)">
            </app-format-selector>
          </ng-template>

        </div>
        <div [@budgetFocused]="!formatFocused" class="cpgn_wrapped">
          <div class="wrapped_edit" (click)="editFormat()" *ngIf="canEditArtwork()">
            <span>
              EDIT
            </span>
          </div>
          <div class="wrapped_circle">
            <span style="font-size: calc(1vw + 2px); width: 92%;">
              <ng-container *ngIf="selectedFormatsNumber > 0; else NO_FORMAT_SELECTED">
                {{ selectedFormatsNumber }} formats
              </ng-container>
              <ng-template #NO_FORMAT_SELECTED>
                No Format Selected
              </ng-template>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="submit-buttons-row">
      <div class="submit-buttons">
        <button (click)="showConfirmationPopup()"
           [disabled]="!(canSubmit | async)"
           id="create-campaign-button"
           class="btn">
          <span>
            Submit Now
          </span>
        </button>
        <button (click)="backToTiles()"
           id="save-draft"
           class="btn">
          <span>
            Save as Draft
          </span>
        </button>
      </div>
    </div>

    <div class="loading"
         style="display: block"
         [class.sidebarShown]="sidebarShown"
         *ngIf="service.loading | async">
    </div>

    <div class="loading custom"
         style="display: block"
         [class.sidebarShown]="sidebarShown"
         *ngIf="loadingEstimates$ | async">
      <div class="estimating">
        <span>Estimating Available Bids</span>
      </div>
    </div>

  </div>
</div>

<!--<ng-container *appHasProfile="'offer_creator_books'">-->
<!--  <app-hachette-layout></app-hachette-layout>-->
<!--</ng-container>-->

<ng-container *appHasProfile="'artwork_generator'">
  <app-artwork-generator-layout></app-artwork-generator-layout>
</ng-container>

<!--<ng-container *appHasProfile="'offer_creator_nike'">-->
<!--  <app-feed-chooser-layout></app-feed-chooser-layout>-->
<!--</ng-container>-->
