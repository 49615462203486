import { Component, OnInit } from '@angular/core';
import { InstagramService } from '../instagram.service';

@Component({
  selector: 'app-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.sass']
})
export class SelectorComponent implements OnInit {

  posts = null;
  processing = false;
  username = 'wit.fitness';

  constructor(
    private instagramService: InstagramService
  ) { }

  ngOnInit() {
    this.getFeed();
  }

  getFeed() {
    this.posts = null;
    this.processing = true;
    this.instagramService.getFeed()
      .subscribe(
        (data) => {
          console.log(data);
          this.posts = data;
          this.processing = false;
        }
      );
  }

  selectPost(post) {
    this.instagramService.selected.next(post);
  }

}
