<div class="offer-schedule">

  <h5>Our algorithm analyzes stock levels of all featured in the campaign items in all locations and based on stock level trigger value decides on which screens the item should be advertised.</h5>

  <div class="offer-schedule-content">

    <div class="checkbox checkbox-flash-blue checkbox-inline">
      <input type="checkbox"
             id="active"
             [checked]="active"
             (change)="toggleActivation()">
      <label for="active">activate stock level trigger</label>
    </div>

    <div class="slider-wrapper" [class.inactive]="!active">
      <p>This campaign will play only if the stock level of the item in current location is more than:</p>
      <app-range-step-slider
          [value]="minStockLevel"
          [min]="1"
          [max]="1000"
          [steps]="[1]"
          [disabled]="!active"
          [thumbPointerSubline]="'Items'"
          (change)="update($event)">
      </app-range-step-slider>
    </div>

  </div>
</div>
