import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MediaPlansStatusColor } from './media-plans/media-plans.status.color';
import { MediaPlansFilter } from './media-plans/media-plans.filter';
import { OrderPipe } from './list-order/list-order.filter';
import { InvoicesFilter } from './invoices/invoices.filter';
import { RaportsOverviewPipe } from './raports-overview/raports-overview.pipe';
import { KeysPipe } from './keys/keys.pipe';
import { CapitalizePipe } from './capitalize/capitalize.pipe';
import { FriendlyFieldPipe } from './friendly-field/friendly-field.pipe';
import { SafePipe } from './safe/safe.pipe';
import { ThousandSuffixPipe } from './thousand-suffix/thousand-suffix.pipe';
import {FindObjectInArrayPipe} from './find-object-in-array/find-object-in-array.pipe';
import { RedirectUriParserPipe } from './redirect-uri-parser.pipe';

@NgModule({
  declarations: [
    MediaPlansStatusColor,
    MediaPlansFilter,
    OrderPipe,
    InvoicesFilter,
    RaportsOverviewPipe,
    KeysPipe,
    CapitalizePipe,
    FriendlyFieldPipe,
    SafePipe,
    ThousandSuffixPipe,
    FindObjectInArrayPipe,
    RedirectUriParserPipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    MediaPlansStatusColor,
    MediaPlansFilter,
    OrderPipe,
    InvoicesFilter,
    RaportsOverviewPipe,
    KeysPipe,
    CapitalizePipe,
    FriendlyFieldPipe,
    SafePipe,
    ThousandSuffixPipe,
    FindObjectInArrayPipe,
    RedirectUriParserPipe,
  ]
})

export class MainPipe {}
