<div class="offer-schedule">

  <h5>I would like to increse the priority of the offer when the following criteria are met:</h5>

  <div class="offer-schedule-content">
    <p>Gender of the audience:</p>
    <div class="text-center">
      <div class="btn-group date-range-selector" style="left: -100px;">

        <button *ngFor="let gender of genderButtons"
                class="btn btn-default btn-demographic {{gender.iconClass}}"
                [class.active]="isGenderActive(gender)"
                (click)="changeGender(gender)">
          {{gender.description}}
        </button>

      </div>
    </div>

    <p>Minimum ratio:</p>
    <mat-slider
          (change)="sendGenderPercent()"
          [(ngModel)]="genderHint"
          [min]="0"
          [max]="100"
          [step]="1"
          [disabled]="isGenderDisabled()"
    ></mat-slider>
    <div style="width: 98%;position: relative; height: 35px;">
      <label *ngFor="let a of [0, 20, 40, 60, 80, 100]" [style.left.%]="a" style="position: absolute;">{{a}}</label>
    </div>

    <p>Age of the audience:</p>
    <div class="text-center">
      <div class="btn-group date-range-selector">

        <button *ngFor="let age of ageButtons"
                class="btn btn-default btn-demographic"
                [class.active]="isAgeActive(age)"
                (click)="changeAge(age)">
          <span>{{age.span}}</span>
          {{age.description}}
        </button>
      </div>
    </div>

    <p>Minimum ratio:</p>
    <mat-slider
          (change)="sendAgePercent()"
          [(ngModel)]="ageHint"
          [min]="0"
          [max]="100"
          [step]="1"
          [disabled]="isAgeDisabled()"
    ></mat-slider>
    <div style="width: 98%;position: relative; height: 35px;">
      <label *ngFor="let a of [0, 20, 40, 60, 80, 100]" [style.left.%]="a" style="position: absolute;">{{a}}</label>
    </div>

  </div>
</div>
