import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import {CoreService} from '../../services/main/core.service';
import {OfferService} from '../../services/offer/offer.service';
import { NavigationEnd, Router } from '@angular/router';
import { LoadingService } from '@shared/services/loading.service';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FeedbackService } from '@shared/services/feedback.service';

@Component({
  selector: 'app-main',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.sass']
})
export class CoreComponent implements OnInit {

  @ViewChild('main') private main: ElementRef;
  private lastScrollPos: number;
  private lastScrollPosUrl: string;

  constructor(
    @Inject(CoreService) public coreService,
    @Inject(OfferService) public offerService,
    private router: Router,
    private loadingService: LoadingService,
    private feedbackSvc: FeedbackService,
  ) {
    this.coreService.freezeMain.subscribe(
      (state) => {
        if (state) {
          this.lastScrollPos = this.main.nativeElement.scrollTop;
          this.lastScrollPosUrl = this.router.url;
          this.main.nativeElement.scrollTop = 0;
        } else {
          if (this.lastScrollPos && this.lastScrollPosUrl === this.router.url) {
            this.main.nativeElement.scrollTop = this.lastScrollPos;
          }
        }
      }
    );

    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe(value => {
        this.feedbackSvc.clear();
      });
  }

  ngOnInit() {
    // this.helpCrunch.boot(this.coreService.api.currentSessionData.getValue());
    // this.coreService.api.currentSessionData.subscribe(
    //   (data) => this.helpCrunch.updateUser(data)
    // );
  }

  get isLoading(): Observable<boolean> {
    return this.loadingService.loading$;
  }

}
