import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IFormat } from '../../models/format.model';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '@env/environment';
import { tap } from 'rxjs/operators';

export const ALL_FORMATS_URL = '/data/display-format';

@Injectable({
  providedIn: 'root'
})
export class FormatsService {

  public allFormats = new BehaviorSubject<IFormat[]>(null);
  public availableFormats = new BehaviorSubject<IFormat[]>([]);
  public allFormats$ = this.allFormats.asObservable();

  constructor(
    private http: HttpClient,
  ) {
  }

  public getAllFormats(): Observable<IFormat[]> {
    return this.http.get<IFormat[]>(`${env.displaysServiceUrl}${ALL_FORMATS_URL}`)
      .pipe(tap(data => {
          const formats = data.filter(f =>
            f.ooh_format
            && f.artwork_spec.resolution
            && f.artwork_spec.resolution.width > 0
            && f.artwork_spec.resolution.height > 0);
          this.allFormats.next(formats.map(f => ({
            ...f,
            ooh_format_label: f.ooh_format_label || f.ooh_format.split('_')[0]
          })));
        }));
  }

  public updateFormatsEstimates(audiences, estimations): void {
    if (this.allFormats.getValue() && estimations && audiences.length > 0) {
      let formats = [];

      const estAudience = estimations.find(est => est.audience_uri === audiences[0].audience.uri);
      if (estAudience) {
        formats = [...formats, ...estAudience.formats];
      }

      const availFormats = [];
      formats.forEach(f => {
        const format = this.allFormats.getValue().find(af => af.ooh_format === f.format);
        if (format) {
          availFormats.push({
            ...f,
            ooh_format: f.format,
            ooh_format_label: format.ooh_format_label,
            artwork_spec: format.artwork_spec,
          });
        }
      });
      this.availableFormats.next(availFormats);
    }
  }

  getCurrentFormat(format: IFormat): IFormat {
    return this.availableFormats.getValue().find(af => af.ooh_format === format.ooh_format);
  }

  getFormatByCode(code: string): IFormat {
    return this.allFormats.getValue().find(af => af.ooh_format === code);
  }

  getSelectedFormats(formats: string[]): IFormat[] {
    return formats ? this.availableFormats.getValue().filter(af => formats.includes(af.ooh_format)) : [];
  }

  allFormatsReady(campaignFormats, campaignFiles): Observable<any> {
    return new Observable(subscriber => {
      let ready = true;
      const formats = this.allFormats.getValue().filter(af => campaignFormats.includes(af.ooh_format));
      const availFormats = formats.map(f => `${f.artwork_spec.resolution.width}x${f.artwork_spec.resolution.height}`);
      availFormats.map(f => {
        const files = campaignFiles.filter(c => `${c.width}x${c.height}` === f);
        if (files.length === 0) {
          ready = false;
        }
      });
      subscriber.next(ready);
      subscriber.complete();
    });
  }

}
