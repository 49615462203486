import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-epos',
  templateUrl: './epos.component.html',
  styleUrls: ['./epos.component.css']
})
export class EposComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
