import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IPostcodesIO } from '../models/postcodesio.model';

const BASE_URL = 'https://api.postcodes.io';
const VERIFY_POSTCODE = '/postcodes';

@Injectable()
export class LocationService {

  constructor(
    private http: HttpClient,
  ) { }

  verifyPostcode(postcode: string): Observable<IPostcodesIO.IResponse> {
    return this.http.get<IPostcodesIO.IResponse>(`${BASE_URL}${VERIFY_POSTCODE}/${postcode}`);
  }

}
