import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keys'
})
export class KeysPipe implements PipeTransform {

  transform(objects, args: string[]): any {
    const keys = [];
    const keyValues = Object.entries(objects);
    for (const keyValue of keyValues) {
      keys.push({key: keyValue[0], value: keyValue[1]});
    }
    return keys;
  }

}
