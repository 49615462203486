import { Component, OnInit } from '@angular/core';
import { ArtworkGeneratorService } from '@module/artwork-generator/artwork-generator.service';
import { OfferService } from '../../../../services/offer/offer.service';
import { IFeedChooser } from '@module/feed-chooser/feed-chooser.interface';
import { FileService } from '../../../../services/file/file.service';
import { IFormat } from '../../../../models/format.model';

@Component({
  selector: 'app-artwork-generator',
  templateUrl: './artwork-generator.component.html',
  styleUrls: ['./artwork-generator.component.scss']
})
export class ArtworkGeneratorComponent implements OnInit {

  public processing = false;
  public cover = null;

  public previewUrl = null;

  public desc: string;
  public hashtags: string;
  public customData = {};
  public layout;
  private output: IFeedChooser.IArtwork;

  fontSize = 30;

  constructor(
    private service: ArtworkGeneratorService,
    private offerSvc: OfferService,
    private fileService: FileService,
  ) { }

  ngOnInit(): void {
    this.refreshArtwork();
  }

  set artworkData(data: any) {
    this.service.artworkData = data;
  }

  get artworkData(): any {
    return this.service.artworkData;
  }

  get artworkDataMap(): any {
    return Object.keys(this.artworkData).map(key => this.artworkData[key]);
  }

  get config(): any {
    return this.service.configJson;
  }

  get selectedImageId(): number {
    return this.service.selectedImageId;
  }

  set selectedImageId(id: number) {
    this.service.selectedImageId = id;
  }

  refreshArtwork(): void {
    this.processing = true;
    this.service.generateArtwork(this.artworkData).subscribe(
      res => {
        this.output = {...res.data[0], id: null};
        this.previewUrl = res.data[0].artwork_url;
        this.processing = false;
      },
      () => this.processing = false,
    );
  }

  cancel(): void {
    this.service.toggle();
  }

  saveArtwork(): void{
    this.processing = true;
    this.offerSvc.createArtworksFromFeed([this.output])
      .subscribe(
        files => {
          this.fileService.append(files.map(f => ({...f, redirect_link: f.download_link})));
          this.processing = false;
          this.service.toggle(true);
        },
      );
  }

  trackFn(index, item): number {
    return index;
  }

  get format(): IFormat {
    return this.service.format;
  }

  updatedFontSize(val): void {
    this.artworkData.desc.fontSize = val;
  }

}
