<div class="offer-schedule">
  <div class="top-toggle" *ngIf="canEditParameters">
    <h5>This campaign will play </h5>
    <div class="btn-group scheduler-option">
      <button class="btn btn-default on-demand-drawer"
              [class.active]="!whenActive()"
              (click)="campaignPlay(false)">
        NOW
      </button>
      <button class="btn btn-default on-demand-drawer"
              [class.active]="whenActive()"
              (click)="campaignPlay(true)">
        when scheduled
      </button>
    </div>
  </div>

  <div *ngIf="whenActive() && canEditParameters" class="drawer weekdays">

    <div class="play-everyday">
      <p class="text">I want to play my campaign</p>
      <div class="checkbox checkbox-flash-blue checkbox-inline">
        <input type="checkbox"
               value="everyday"
               id="everyday"
               name="everyday"
               [checked]="playEveryday() && everyday"
               (change)="toggleEveryday()"
        >
        <label for="everyday">everyday</label>
      </div>
    </div>

    <div class="row weekdays-selector"
         style="clear: both; margin-top: 20px; text-align: left;"
         [style.display]="playEveryday() && everyday ? 'none' : ''">
      <div class="col-sm-4 col-sm-push-2">

        <div *ngFor="let day of days1" class="checkbox checkbox-flash-blue">
          <input type="checkbox" [checked]="chosenDay(day.day)" (change)="toggleDay(day.day)" [id]="'weekday_' + day.day">
          <label [for]="'weekday_' + day.day">{{day.label}}</label>
        </div>

      </div>
      <div class="col-sm-4 col-sm-push-2">

        <div *ngFor="let day of days2" class="checkbox checkbox-flash-blue">
          <input type="checkbox" [checked]="chosenDay(day.day)" (change)="toggleDay(day.day)" [id]="'weekday_' + day.day">
          <label [for]="'weekday_' + day.day">{{day.label}}</label>
        </div>

      </div>
    </div>

<!--    <div class="text-center date-range-buttons">-->
<!--      <div class="btn-group date-range-selector">-->
<!--        <button class="btn btn-sm btn-default" (click)="dateButton('this-week')">this week</button>-->
<!--        <button class="btn btn-sm btn-default" (click)="dateButton('next-week')">next week</button>-->
<!--        <button class="btn btn-sm btn-default" (click)="dateButton('this-month')">this month</button>-->
<!--        <button class="btn btn-sm btn-default" (click)="dateButton('next-month')">next month</button>-->
<!--        <button class="btn btn-sm btn-default" (click)="dateButton('until-unpublished')">until unpublished</button>-->
<!--        <button class="btn btn-sm btn-default" (click)="dateButton('custom')">custom date...</button>-->
<!--      </div>-->
<!--    </div>-->

    <ngb-datepicker #dp ngModel
                    (select)="onDateChange($event)"
                    [displayMonths]="2"
                    [dayTemplate]="t"
                    [minDate]="minDate"
                    [style.display]="showCalendar ? '' : 'none'"
                    [navigation]="'arrows'"
                    [outsideDays]="'visible'">
    </ngb-datepicker>

    <ng-template #t let-date="date" let-focused="focused">
  <span class="custom-day"
        [class.focused]="focused"
        [class.range]="isFrom(date) || isTo(date) || isInside(date) || isHovered(date)"
        [class.faded]="isHovered(date) || isInside(date)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null">
    {{ date.day }}
  </span>
    </ng-template>


    <div *ngIf="service && service.scheduleTime" class="schedule-timeframe">
      <p><i class="fa fa-clock-o"></i> Time of day</p>
      <div class="checkbox checkbox-flash-blue checkbox-inline">
        <input type="checkbox"
               value="1"
               id="all_day"
               name="all_day"
               [checked]="service.scheduleTime.all_day"
               (change)="toggleAllDay()"
        >
        <label for="all_day">all day</label>
      </div>

      <div class="schedule-timeframe-slider" *ngIf="!service.scheduleTime.all_day">

        <div class="budget-thumb-railway">

          <div class="budget-thumb-label"
               [style.visibility]="!slidersOverlap() ? 'hidden' : ''"
               [style.left.%]="(fromThumb + toThumb) / 2">
            <h3>{{fromValue}} - {{toValue}}</h3>
          </div>

          <div #fromLabel
               class="budget-thumb-label"
               [style.visibility]="slidersOverlap() ? 'hidden' : ''"
               [style.left.%]="fromThumb">
            <h3>{{fromValue}}</h3>
            <div class="budget-thumb-pointer"></div>
          </div>
          <div #toLabel
               class="budget-thumb-label"
               [style.visibility]="slidersOverlap() ? 'hidden' : ''"
               [style.left.%]="toThumb">
            <h3>{{toValue}}</h3>
            <div class="budget-thumb-pointer"></div>
          </div>

          <div class="slider">
            <p-slider [(ngModel)]="rangeValues"
                      [range]="true"
                      [max]="hoursValuesFormatted.length - 1"
                      (onSlideEnd)="updateFromSlider()">
            </p-slider>
          </div>

        </div>






<!--        <ion-range-slider *ngIf="service.scheduleTime.time_start && service.scheduleTime.time_stop" #ion-->
<!--                          type="double"-->
<!--                          [from]="sliderFromTo(service.scheduleTime.time_start)"-->
<!--                          [to]="sliderFromTo(service.scheduleTime.time_stop)"-->
<!--                          [values]='hoursValues'-->
<!--                          [hide_min_max]="true"-->
<!--                          [hide_from_to]="true"-->
<!--                          (onChange)="changed($event)"-->
<!--                          (onFinish)="sliderChange($event)">-->
<!--        </ion-range-slider>-->


      </div>

    </div>

  </div>

  <div *ngIf="!canEditParameters" class="drawer">
    <h5>This campaign will start playing on:</h5>
    <form class="form-inline">
      <div class="form-group">
        <div class="input-group">
          <input class="form-control" placeholder="yyyy-mm-dd"
                 name="dp"
                 [displayMonths]="1"
                 [showWeekNumbers]="false"
                 [minDate]="minDate"
                 [container]="'body'"
                 [navigation]="'arrows'"
                 [(ngModel)]="fromDate"
                 (ngModelChange)="onDateChangeMovePeriod($event)"
                 ngbDatepicker
                 #d="ngbDatepicker">
          <div class="input-group-append"
               (click)="d.toggle()">
            <span class="input-group-text">
              <i class="fa fa-calendar" aria-hidden="true"></i>
            </span>
          </div>
        </div>
      </div>
    </form>


  </div>
</div>
