import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArtworkGeneratorComponent } from './components/artwork-generator/artwork-generator.component';
import { ArtworkGeneratorLayoutComponent } from './components/artwork-generator-layout/artwork-generator-layout.component';
import { SharedModule } from '@shared/shared.module';
import { ArtworkGeneratorService } from '@module/artwork-generator/artwork-generator.service';
import { FormsModule } from '@angular/forms';
import { ImageChooserComponent } from './components/image-chooser/image-chooser.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ArtworkGeneratorBtnComponent } from './components/artwork-generator-btn/artwork-generator-btn.component';

@NgModule({
  declarations: [
    ArtworkGeneratorComponent,
    ArtworkGeneratorLayoutComponent,
    ImageChooserComponent,
    ArtworkGeneratorBtnComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    MatTabsModule
  ],
  exports: [
    ArtworkGeneratorLayoutComponent,
    ArtworkGeneratorBtnComponent
  ],
  providers: [
    ArtworkGeneratorService,
  ]
})
export class ArtworkGeneratorModule { }
