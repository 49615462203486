<div class="row" style="height: 80%">
  <div class="col-7 generator-output">
    <app-artwork-preview
      [previewSrc]="cover"
      [fileType]="'image'"
      [chosenFormat]="getFormat()"
      [uploadButton]="false">
    </app-artwork-preview>
    <div class="zoom-zoom"
         (click)="previewArtwork(cover)">
    </div>
  </div>
  <div class="col-5" *ngIf="layout">

    <div class="form-group"
         *ngFor="let field of layout.texts">
      <label class="control-label">{{ field.title }}</label>

      <textarea *ngIf="field.type === 'textarea'"
                [(ngModel)]="customData[field['field']]"
                class="form-control"
                [name]="field['field']"
                rows="4"
                required>
      </textarea>
      <input *ngIf="field.type != 'textarea'"
             type="text"
             [(ngModel)]="customData[field['field']]"
             class="form-control"
             [name]="field['field']"
             required/>
    </div>
    <button class="btn pull-right" (click)="refreshArtwork()">Refresh</button>
  </div>

</div>
<div class="row" style="margin-top: 30px">
  <div class="col-12 text-center">
    <button class="btn btn-back" (click)="back()"><i class="fa fa-angle-left" aria-hidden="true"></i> Change image</button>
    &nbsp;
    <button class="btn btn-back" (click)="cancel()">Cancel</button>
    &nbsp;
    <button class="btn" (click)="saveArtwork()">Use artwork</button>
  </div>
</div>

<div class="loading" *ngIf="processing"></div>