<div class="artwork-generator-content">
  <div class="row h-100">

    <div class="col-7 generator-output">
      <app-artwork-preview
        [previewSrc]="previewUrl"
        [fileType]="'image'"
        [chosenFormat]="format"
        [uploadButton]="false"
        [downloadButton]="true">
      </app-artwork-preview>
    </div>

    <div class="col-5 pt-3">
    <ng-container *ngIf="artworkData">
      <mat-tab-group mat-align-tabs="start" [animationDuration]="0">
        <mat-tab *ngFor="let item of artworkDataMap; trackBy: trackFn;" [label]="item.title">
          <div class="inner-tab">
            <ng-container *ngIf="item.type === 'textarea'">
              <h4>{{ item.title || 'Unknown' }}</h4>
              <div class="form-group mb-3">
                <textarea class="form-control"
                          [(ngModel)]="artworkData[item.src].value"
                          [name]="item.src"
                          rows="4"
                          required>
                </textarea>
              </div>

              <h4>Font size</h4>
              <fc-slider
                [value]="artworkData[item.src].fontSize"
                [min]="10"
                [max]="500"
                (updateFromSlider)="updatedFontSize($event)">
              </fc-slider>
            </ng-container>

            <ng-container *ngIf="item.type === 'image'">
              <app-image-chooser
                *ngIf="config?.images"
                [images]="config.images"
                [selectedImageId]="selectedImageId"
                (selected)="selectedImageId = $event">
              </app-image-chooser>
            </ng-container>

          </div>
        </mat-tab>
      </mat-tab-group>
      <div class="mt-4">
        <button class="btn btn-lg btn-primary float-right" (click)="refreshArtwork()">Refresh Artwork</button>
      </div>
    </ng-container>
  </div>
  </div>
</div>

<div class="artwork-generator-footer">
  <div class="col-12 text-center p-0 pt-2">
    <button class="btn btn-lg btn-default pull-left" (click)="cancel()">Back</button>
    <button class="btn btn-lg btn-primary pull-right" (click)="saveArtwork()">Add to Campaign</button>
  </div>
</div>

<div class="loading" *ngIf="processing"></div>
