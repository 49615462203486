import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild} from '@angular/router';
import { Observable, of } from 'rxjs';
import {SuperGuard} from './super.guard';
import { filter, skip, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class AuthorisedGuard extends SuperGuard implements CanActivate, CanActivateChild {

  readonly redirectLink = ['/login'];

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    const test = this.isAvailable(state);
    console.log('IS AUTHENTICATED: ', this.authService.isAuthenticated);
    return test;
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    const test = this.isAvailable(state);
    console.log('IS AUTHENTICATED: ', this.authService.isAuthenticated);
    return test;
  }


  isAvailable(state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isAuthenticated$
      .pipe(
        filter(s => s !== null),
        tap(s => !s && this.redirect(state)),
        switchMap(s => of(s))
      );
  }

  redirect(state: RouterStateSnapshot): void {
    const self = this;

    self.router.navigate(self.redirectLink, {queryParams: {returnUrl: state.url}})
      .catch(
        reason => console.error('Could not log in. \n' + reason)
      );
  }
}
