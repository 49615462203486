<div id="signup-page">
  <div class="container">

    <ul class="signup-progress" *ngIf="step < 5">
      <li [ngClass]="{'current': step === 1, 'complete': step > 1}">
        <span>1</span>
      </li>
      <li [ngClass]="{'future': step === 1, 'current': step === 2, 'complete': step > 2}">
        <span>2</span>
      </li>
      <li [ngClass]="{'future': step === 1 || step === 2, 'current': step === 3, 'complete': step > 3}">
        <span>3</span>
      </li>
    </ul>

    <form *ngIf="step === 1 || step === 2"
        [formGroup]="userRegisterForm"
        class="form-login"
        (ngSubmit)="onSubmitUserRegisterForm(userRegisterForm.value)">
      <div class="login-wrap">

        <div [class.hidden]="step !== 1">

          <p class="see-again-header info extend">
            Get a PRO account to take advantage of the full Flow City solution!
          </p>

          <div *ngIf="error" class="alert alert-error" role="alert">
            {{error}}
          </div>

          <input type="text"
                 formControlName="name"
                 class="form-control"
                 placeholder="Company name"
                 style="margin-top: 10px;">

          <div *ngFor="let validation of validation_messages.name">
            <small class="error" *ngIf="userRegisterForm.get('name').hasError(validation.type) && (userRegisterForm.get('name').dirty || userRegisterForm.get('name').touched)">{{validation.message}}</small>
          </div>

          <br>
          <input formControlName="email"
                 type="text"
                 id="signup-email"
                 class="form-control"
                 placeholder="Email address"
                 autocomplete="off">

          <div *ngFor="let validation of validation_messages.email">
            <small class="error" *ngIf="userRegisterForm.get('email').hasError(validation.type) && (userRegisterForm.get('email').dirty || userRegisterForm.get('email').touched)">{{validation.message}}</small>
          </div>

          <br>
          <input formControlName="password"
                 [type]="passwordInput"
                 type="password"
                 id="signup-password"
                 class="form-control"
                 placeholder="Password">

          <span (click)="showHidePassword()" class="show-password" style="float: right;">{{showHide}} Password</span>

          <div *ngFor="let validation of validation_messages.password">
            <small class="error" *ngIf="userRegisterForm.get('password').hasError(validation.type) && (userRegisterForm.get('password').dirty || userRegisterForm.get('password').touched)">{{validation.message}}</small>
          </div>

          <div class="form-group form-checkbox">
            <div class="checkbox checkbox-flash-blue checkbox-inline">
              <input type="checkbox"
                     id="signup-accept-tnc"
                     name="signup-accept-tnc"
                     formControlName="accept_tnc">
              <label for="signup-accept-tnc"> I accept <a href="http://www.flow.city/app-terms-conditions/current" target="_blank">Flow City Terms and Conditions</a></label>
              <small *ngIf="termsError" class="error">
                {{termsError}}
              </small>
            </div>
          </div>

<!--          -->
<!--          <input formControlName="accept_tnc"-->
<!--                 type="checkbox"-->
<!--                 id="signup-accept-tnc"-->
<!--                 style="top: 3px; position: relative;">-->
<!--          <label id='signup-accept-tnc-label' for='signup-accept-tnc'>-->
<!--            I accept-->
<!--            <a href="http://www.flow.city/app-terms-conditions/current" target="_blank">Flow City Terms and Conditions</a>-->
<!--          </label>-->
<!--          <small *ngIf="termsError" class="error">-->
<!--            {{termsError}}-->
<!--          </small>-->
        </div>

        <div [class.hidden]="step !== 2">

          <p class="see-again-header info extend">
            So that we can customise your account tell us about your<br/>business, product and type of ad you wish to run
          </p>

          <div *ngIf="error" class="alert alert-error" role="alert">
            {{error}}
          </div>

          <label>Business type</label>
          <ng-select
              *ngIf="categories"
              class="fc"
              formControlName="business_category"
              [items]="categories.business_type"
              [selectOnTab]="true"
              [searchable]="false"
              bindValue="id"
              bindLabel="name"
              [clearable]="false"
              placeholder="Select...">
          </ng-select>

          <label style="margin-top: 15px">Product type</label>
          <ng-select
              *ngIf="categories"
              class="fc"
              formControlName="product_type"
              [items]="categories.product_type"
              [selectOnTab]="true"
              [searchable]="false"
              bindValue="id"
              bindLabel="name"
              [clearable]="false"
              placeholder="Select...">
          </ng-select>

          <label style="margin-top: 15px">Monthly spend</label>
          <ng-select
              *ngIf="categories"
              class="fc"
              formControlName="monthly_spend"
              [items]="categories.monthly_spend"
              [selectOnTab]="true"
              [searchable]="false"
              bindValue="id"
              bindLabel="name"
              [clearable]="false"
              placeholder="Select...">
          </ng-select>

          <div class="form-group form-checkbox">
            <div class="checkbox checkbox-flash-blue checkbox-inline">
              <input type="checkbox"
                     id="data-1st"
                     name="data-1st"
                     formControlName="data_1st">
              <label for="data-1st">I have 1st party Data available
                <fa-icon [icon]="['fas', 'question-circle']"
                         ngbTooltip="Data that is collected directly from your audience that you own and manage."
                         container="body"
                         [placement]="'bottom'">
                </fa-icon>
              </label>
            </div>
          </div>

          <div class="form-group form-checkbox">
            <div class="checkbox checkbox-flash-blue checkbox-inline">
              <input type="checkbox"
                     id="data-3rd"
                     name="data-3rd"
                     formControlName="data_3rd">
              <label for="data-3rd">I want to access 3rd party Data
                <fa-icon [icon]="['fas', 'question-circle']"
                         ngbTooltip="Data from one of our trusted partners, ready connected and available for purchase."
                         container="body"
                         [placement]="'bottom'">
                </fa-icon>
              </label>
            </div>
          </div>

        </div>

        <button id="std-signup-btn"
                (click)="step = 2"
                *ngIf="step === 1"
                [disabled]="!userRegisterForm.get('name').valid || !userRegisterForm.get('email').valid || !userRegisterForm.get('password').valid || !userRegisterForm.get('accept_tnc').valid || isRequestPending"
                class="btn btn-theme btn-block"
                type="button" style="margin-top: 5px;">
          Next <fa-icon [icon]="['fas', 'angle-right']" aria-hidden="true"></fa-icon>
          <fa-icon *ngIf="isRequestPending" [icon]="['fas', 'spinner']" [spin]="true"></fa-icon>
        </button>

        <button id="std-signup-btn"
                *ngIf="step === 2"
                [disabled]="!userRegisterForm.valid || isRequestPending"
                class="btn btn-theme btn-block"
                type="submit" style="margin-top: 5px;">
          Next <fa-icon [icon]="['fas', 'angle-right']" aria-hidden="true"></fa-icon>
          <fa-icon *ngIf="isRequestPending" [icon]="['fas', 'spinner']" [spin]="true"></fa-icon>
        </button>


      </div>
    </form>

    <div id="plan-page" *ngIf="step === 3">
      <h3 class="see-again-header info extend thank-you">Thank you!</h3>
        <p class="see-again-header info extend">We aim to get in touch to set up your bespoke solution the same day.<br>
          Please make sure you entered work address and correct contact details.<br>
          To arrange a demo please email <a href="mailto:ads@flow.city">ads@flow.city</a></p>
    </div>

    <div class="loading not-masked" *ngIf="isRequestPending"></div>

  </div>
</div>

<div class="logo"></div>

