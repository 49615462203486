import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { CoreService } from '../services/main/core.service';

@Injectable()
export class BackwardGuard implements CanDeactivate<any> {
  constructor(
    private coreSvc: CoreService,
  ){}

  canDeactivate(component: any, currentRoute: ActivatedRouteSnapshot,
                currentState: RouterStateSnapshot, nextState: RouterStateSnapshot): boolean {
    return !this.coreSvc.isGoingBack;
  }
}
