<div id="offerScheduleVolumetrics" class="offer-schedule">

  <h5 class="text-left">I want to play my campaign in the areas with estimated daily impact* between:</h5>
  <div class="offer-schedule-content">

    <input id="impactInputMin" data-min="0" data-max="100" data-width="120" data-height="120" data-control="impact" data-value="impact" value="0" style="display: none;"/>
    <input id="impactInputMax" data-min="0" data-max="100" data-width="120" data-height="120" data-control="impact" data-value="impact" value="0" style="display: none;"/>
    <div class="slider-volumetrics" id="slider-impact"></div>
    <p style="margin-top: -60px; font-size: 10px; font-family: 'Open Sans', sans-serif;">
      *Impacts are calculated from footfall and reach around the panel
    </p>

  </div>

  <h5 class="text-left">I want to play my campaign in the areas with daily impressions between:</h5>
  <div class="offer-schedule-content">

    <input id="impressionsInput" data-min="0" data-max="100" data-width="120" data-height="120" data-control="impressions" data-value="impressions" value="0" style="display: none;"/>
    <div class="slider-volumetrics" id="slider-impressions"></div>

  </div>

  <h5 class="text-left">I want to play my campaign with average frequency:</h5>
  <div class="offer-schedule-content">

    <input id="frequencyInput" data-min="0" data-max="100" data-width="120" data-height="120" data-control="frequency" data-value="frequency" value="0" style="display: none;"/>
    <div class="slider-volumetrics" id="slider-frequency"></div>

  </div>

  <h5 class="text-left">I would like to increase the priority of the offer when the GRP's (gross rating point) is between:</h5>
  <div class="offer-schedule-content">

    <input id="GRPInput" data-min="0" data-max="100" data-width="120" data-height="120" data-control="GRP" data-value="GRP" value="0" style="display: none;"/>
    <div class="slider-volumetrics" id="slider-GRP"></div>

  </div>
</div>