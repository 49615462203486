<div class="logo perm-active">
  <span>Flow City</span>
  <div class="sidebar-switch" (click)="toggleSidebar()">
    <i class="fa fa-caret-down"></i>
  </div>
</div>
<div class="topbar-info" [class.create_campaign-btn_visible]="!offerEditionMode() && !reportsPage()">

  <div class="topbar-info-wrapper">

    <ng-template #regularMode>
      <div class="title-row">
        <h1 class="page_title">{{pageTitle}}</h1>
      </div>
      <div class="title-row desc_row">
        <p class="page_desc">{{pageDescription}}</p>
      </div>

<!--      <button *ngIf="!reportsPage()" routerLink="/new-campaign" id="create_campaign" class="btn action-button">Start a New Campaign</button>-->
      <button *ngIf="!onboarding" (click)="startNewCampaign()" id="create_campaign" class="btn action-button"
        joyrideStep="step02"  [stepContent]="step02">Start a New Campaign</button>
<!--      <button *ngIf="reportsPage()" class="btn action-button" (click)="openReportGenerator()">Generate Report</button>-->
    </ng-template>

    <ng-container *ngIf="offerEditionMode() && offerService.currentOffer; else regularMode">

      <div class="title-row">
        <input type="text" AutoSizeInput [extraWidth]="10" [(ngModel)]="offerService.currentOffer.name" (change)="saveOffer()" placeholder="Campaign name" maxlength="70">
        <span *ngIf="offerService.currentOffer.name.length < 1">(add name)</span>
        <fa-icon *ngIf="offerService.pendingRequests()" [icon]="['fas', 'spinner']" [spin]="true"></fa-icon>
      </div>


      <div class="summary">
        <div>
          <ng-container *ngIf="offerService.chosenAudience">
            Your campaign will reach <b><span>{{reach() | thousandSuffix}}</span></b> people
            in <b><span>{{audience() | uppercase}}</span></b> audience<ng-container *ngIf="offerService.chosenFormat; else noFormatChosen">
            in <b><span>{{nrOfLocations}}</span></b> locations.</ng-container>
            <ng-template #noFormatChosen>&#46;</ng-template>
            <br>
          </ng-container>
          <ng-container *ngIf="offerService.scheduleTime">
            It will run for <b><span>{{duration}}</span></b>&nbsp;<span [innerHTML]="timeFrames"></span>
            <!--from <b><span>{{formatDate(offerService.scheduleTime.schedule_start)}}</span></b> to <b><span>{{formatDate(offerService.scheduleTime.schedule_stop)}}</span></b>.-->
          </ng-container>
          <span *ngIf="offerService.scheduleDisruptions" [innerHTML]="offerService.scheduleDisruptions.description"></span>
          <span *ngIf="offerService.scheduleWeather" [innerHTML]="offerService.scheduleWeather.description"></span>
          <span *ngIf="offerService.scheduleStockLevel && offerService.scheduleStockLevel.active" [innerHTML]="offerService.scheduleStockLevel.description"></span>
          <span *ngIf="offerService.scheduleAIM" [innerHTML]="offerService.scheduleAIM.description"></span>.
        </div>

        <div class="estimated-budget">
          <app-estimated-budget
                  [estimatedBudget]="offerService.estimatedBudget$ | async">
          </app-estimated-budget>
        </div>

        <div class="go-to-schedule">

          <button (click)="goToMediaPlane()"
                  *ngIf="campaignScreen"
                  class="btn fc-button">
            Media Plan
          </button>

<!--          <button (click)="goToMediaPlane()"-->
<!--                  *ngIf="medi"-->
<!--                  class="btn fc-button">-->
<!--            Media Plan-->
<!--          </button>-->

          <button *ngIf="creatingOffer()"
                  (click)="goToSchedule()"
                  class="btn fc-btn-icon-only">
            <i class="fa fa-clock-o" aria-hidden="true"></i>
          </button>
        </div>
      </div>

    </ng-container>

  </div>
</div>

<ng-template #step02>
  <h2>Not sure how much credits you need?</h2>
  <p>Start a Campaign to check out our estimation engine in action...</p>
</ng-template>
