import {Component, Inject, OnInit} from '@angular/core';
import { Location } from '@angular/common';
import {AuthenticationService} from '../../../services/authentication/authentication.service';
import {FormBuilder, FormGroup, Validators, FormControl, AbstractControl} from '@angular/forms';
import {SignInValidators} from '../validators';
import {CoreService} from '../../../services/main/core.service';
import {SessionService} from '../../../services/session/session.service';
import {CONSTANTS} from '../../../additional/helpers/constants/constants.const';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { HttpClient } from '@angular/common/http';
import { CDN_URL } from '../authorization.const';
import { ActivatedRoute } from '@angular/router';

export interface ISignupCategories {
  business_type: [
    {
      id: number;
      name: string;
    }
  ];
  product_type: [
    {
      id: number;
      name: string;
    }
  ];
}

const PACKAGES = [
  {
    amount: 50,
    desc: 'Reach<br><strong>4 000</strong> People<br>in UK'
  },
  {
    amount: 150,
    desc: 'Reach<br><strong>12 000</strong> People<br>in UK'
  },
  {
    amount: 250,
    desc: 'Reach<br><strong>23 000</strong> People<br>in UK'
  },
  {
    amount: 500,
    desc: 'Reach<br><strong>48 000</strong> People<br>in UK'
  },
  {
    amount: 1000,
    desc: 'Reach<br><strong>98 000</strong> People<br>in UK'
  },
];

const LOCATION = {
  city: 'Newcastle',
  country: 'England',
  latitude: 54.970837,
  location: 'Newcastle upon Tyne Central',
  longitude: -1.612755,
  name: 'Newcastle upon Tyne Central',
  postcode: 'NE1 1EH'
};

@Component({
  selector: 'app-signup-rtb',
  templateUrl: './signup-rtb.component.html',
  styleUrls: ['./signup-rtb.component.scss']
})
export class SignupRtbComponent implements OnInit {

  userRegisterForm: FormGroup;
  // paymentForm: FormGroup;
  companyData: object = null;
  paymentData: object = null;
  error: string = null;
  registrationData;


  credits: number = null;

  categories: ISignupCategories;

  step = 1;

  isRequestPending = false;
  passwordInput = 'password';

  packages = PACKAGES;

  validation_messages = {
    'name': [
      { type: 'required', message: 'Please enter company name' },
      { type: 'companyTaken', message: 'Company already registered'}
    ],
    'email': [
      { type: 'required', message: 'Please enter your email address' },
      { type: 'email', message: 'Valid email address is required' },
      { type: 'emailTaken', message: 'Email address already used'}
    ],
    'password': [
      { type: 'required', message: 'Please enter password' },
    ],
    'business_type': [
      { type: 'required', message: 'Please select business type' },
    ],
    'product_type': [
      { type: 'required', message: 'Please select product type' },
    ]
  };

  constructor(
    private _location: Location,
    private fb: FormBuilder,
    public service: AuthenticationService,
    public coreService: CoreService,
    private session: SessionService,
    private http: HttpClient,
    private recaptchaV3Service: ReCaptchaV3Service,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.loadCategories();
    this.createForms();

    // after company has been created on page refresh go to payment page
    if (sessionStorage.getItem('tmp-session')) {
      if (sessionStorage.getItem('tmp-company-data')) {
        this.companyData = JSON.parse(sessionStorage.getItem('tmp-company-data'))
      }
      this.step = 3;
    }
  }

  createForms() {
    this.route.queryParams
      .subscribe(params => {
        this.userRegisterForm = this.fb.group({

          accept_tnc: ['', Validators.requiredTrue],
          accept_content: ['', Validators.requiredTrue],

          name: new FormControl('', {
            asyncValidators: [SignInValidators.validateCompanyNotTaken(this.service)],
            updateOn: 'blur'
          }),

          email: new FormControl(params.email || '', {
            validators: [Validators.required, Validators.email],
            asyncValidators: [SignInValidators.validateEmailNotTaken(this.service)],
            updateOn: 'blur'
          }),

          password: ['', Validators.required],
          business_category: ['', Validators.required],
          product_type: ['', Validators.required]
        });
      });
  }

  get showHide(): string {
    return this.passwordInput === 'password' ? 'Show' : 'Hide';
  }

  showHidePassword(): void {
    this.passwordInput = this.passwordInput === 'password' ? 'text' : 'password';
  }

  onSubmitUserRegisterForm(registerCompanyData) {
    registerCompanyData['business_category'] = registerCompanyData['business_category'].toString();
    registerCompanyData['accept_tnc_timestamp'] = new Date().toISOString();
    registerCompanyData['profiles_set'] = 'rtb';
    this.registrationData = registerCompanyData;
    this.step = 3;
  }

  createPayment() {
    this.error = null;
    this.userRegisterForm.disable();
    this.isRequestPending = true;

    // case - change payment amount
    if (this.paymentData && this.paymentData['uri']) {
      this.error = null;
      this.userRegisterForm.disable();
      this.isRequestPending = true;
      this.paymentData['uri'] = this.paymentData['uri'].split('/').splice(0, 5).join('/');
      const sessionKey = sessionStorage.getItem('tmp-session');
      this.service.sendNewPayment(this.paymentData['uri'], {credits: this.credits}, sessionKey)
        .subscribe(
          payment => {
            this.userRegisterForm.enable();
            this.isRequestPending = false;
            this.paymentData = payment;
            this.step = 4;
          },
        );
    } else {
      this.recaptchaV3Service.execute('rtb_registration')
        .subscribe((token) => {
          this.error = null;
          this.userRegisterForm.disable();
          this.isRequestPending = true;
          this.registrationData['token'] = token;
          this.registrationData['payment'] = {credits: this.credits};

          this.service.registerCompany(this.registrationData, true)
            .subscribe(
              (request) => {
                if (request.headers.get(CONSTANTS.sessionKey)) {
                  sessionStorage.setItem('tmp-session', request.headers.get(CONSTANTS.sessionKey));
                }

                this.userRegisterForm.enable();
                this.isRequestPending = false;
                this.paymentData = request.body;
                this.step = 4;
                (<any>window).ga('gtm1.send', 'event', 'signup', 'sent', 'camden-signup');
              },
              () => {
                this.userRegisterForm.enable();
                this.isRequestPending = false;
                this.error = 'An error occurred while creating your account. Please contact administrator.';
              }
            );
        });
    }

  }

  goBack(event) {
    event && event.preventDefault();
    this.step = 3;
  }

  onCompletePayment(data) {
    this.error = null;
    this.isRequestPending = false;

    localStorage.setItem('_onboarding', 'true');

    if (data['need_acceptance']) {
      sessionStorage.removeItem('tmp-session');
      this.step = 5;
    } else {
      if (sessionStorage.getItem('tmp-session')) {
        this.session.saveToken(sessionStorage.getItem('tmp-session'));
        sessionStorage.removeItem('tmp-session');
      }
      window.location.href = '/';
    }
  }

  toggleRequestPending(state) {
    this.isRequestPending = state;
  }

  loadCategories() {
    this.http.get<ISignupCategories>(`${ CDN_URL }/json/categories.json`).subscribe(
      categories => this.categories = categories,
    );
  }

}
