import {Injectable, Injector} from '@angular/core';
import {SessionData} from '../../models/session-init/session-data/session-data.model';
import {SuperService} from '../super-service/super.service';
import {CurrentCompany} from '../../models/currentCompany.model';
import {DisplayGroup} from '../../models/DisplayGroups.model';
import {Location} from '../../models/location.model';
import {SessionService as Session, SessionService} from '../session/session.service';
import {CurrentDepartment} from '../../models/currentDepartment.model';
import {environment as env} from '../../../environments/environment';
import {SessionInitModel} from '../../models/session-init/session-init.model';
import {FormatsBlockDispModel} from '../../models/FormatsBlockDisp.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';
import {HttpHeaders} from '@angular/common/http';
import Bugsnag from '@bugsnag/js';
import { CompanyModel } from '../../models/session-init/session-data/company-model/company-model.model';



@Injectable()
export class ApiService extends SuperService {

  // region FILLED ON INIT
  _sessionData: SessionData;
  // endregion

  private sessionServiceProcessing = false;
  private departmentProcessing = false;
  private displayGroupsProcessing = false;
  private formatsProcessing = false;
  private locationProcessing = false;
  private currentCompanyProcessing = false;
  private allowedDisplayGroups: any = null;

  // region CARRIERS
  private _currentDepartment: CurrentDepartment;
  private _displayGroups: DisplayGroup[];
  private _formatsData: FormatsBlockDispModel[];
  private _location: Location;
  private _currentCompany: CurrentCompany;
  // endregion

  public currentLocation = new BehaviorSubject<Location>(null);
  private _currentSessionData$ = new BehaviorSubject<SessionData>(null);
  public currentSessionData$ = this._currentSessionData$.asObservable();
  public displayGroupsData = new BehaviorSubject<DisplayGroup[]>(null);
  determinedLocation = false;

  public defaultOfferImageTemplate = '';
  public defaultOfferVideoTemplate = '';
  public RTBExternalConsumers;

  constructor(
    injector: Injector
  ) {
    super(injector);

    this._currentSessionData$
      .pipe(
        filter(data => data !== null),
        distinctUntilChanged()
      )
      .subscribe(data => {
        // start loading necessary data while session is ready
        this.getDepartment().subscribe(
            () => this.getLocation().subscribe(),
        );
        this.getDisplayGroups();
      });
  }

  set currentSessionData(data: SessionData) {
    this._currentSessionData$.next(data);
  }

  get currentSessionData(): SessionData {
    return this._currentSessionData$.getValue();
  }

  public save(
    object,
  ) {
    return new Observable(subscriber => {
      const uri = object['uri'];
      if (!uri) {
        console.error('object has no uri ' + object);
        subscriber.error('object has no uri ' + object);
      }

      this.http.put<JSON>(SessionService.enrichApiUrl(uri), object)
        .subscribe(
          (data) => {
            subscriber.next(data);
            subscriber.complete();
          },
          (err) => {
            subscriber.error(err);
          }
        );
    });
  }

  public updateUser(user) {
    this.sessionData.user = user;
    this.currentSessionData = this.sessionData;
    this.deleteSessionCache().subscribe();
  }

  public create(
    object,
    uri
  ) {
    return new Observable(subscriber => {
      if (!uri) {
        console.error('object has no uri ' + object);
        subscriber.error('object has no uri ' + object);
      }

      this.http.post<JSON>(SessionService.enrichApiUrl(uri), object)
        .subscribe(
          (data) => {
            subscriber.next(data);
            subscriber.complete();
          },
          (err) => {
            subscriber.error(err);
          }
        );
    });
  }

  /**
   * Universal method to save (with PUT request) passed object.
   * It uses object's 'uri' field to establish address, ads sends it there.
   * @param object Passed object to save.
   * @param success Method called on success
   * @param error Method called on error
   */
  saveObject<T>(
    object: T,
    success?: (msg?: string) => void,
    error?: () => void
  ): void {
    const self = this;

    const address = object['uri'];
    if (!address) {
      console.error('object has no uri ' + object);
      return;
    }

    self.http.put<T>(
      SessionService.enrichApiUrl(address),
      object,
    ).subscribe(
      value => {
        object = value;

        if (success) {
          success();
        }
      },
      err => {
        console.error(err);

        if (error) {
          error();
        }
      }
    );
  }

  // region SESSION DATA
  /**
   * If 'sessionData' exists, it returns object, otherwise it calls loadSessionService() method and returns null.
   */
  get sessionData(): SessionData {
    if (!this._sessionData) {
      this.loadSessionService();
    }
    return this._sessionData;
  }
  set sessionData(sessionData: SessionData) {
    this._sessionData = sessionData;
  }
  // endregion

  // region CURRENT DEPARTMENT
  /**
   * If 'currentDepartment' exists, it returns object, otherwise it calls getDepartment() method and returns null.
   */
  get currentDepartment(): CurrentDepartment {
    if (!this._currentDepartment) {
      this.getDepartment();
    }
    return this._currentDepartment;
  }

  set currentDepartment(currDepartment: CurrentDepartment) {
    this._currentDepartment = currDepartment;
  }

  private getDepartment(): Observable<any> {
    const self = this;

    return new Observable(subscriber => {



      if (self.departmentProcessing) {
        subscriber.complete();
        return;
      }
      // if (!self.sessionData) {
      //   return;
      // }
      self.departmentProcessing = true;

      const address = self.sessionData.user.department;

      self.http.get<CurrentDepartment>(
          SessionService.enrichApiUrl(address),
      )
          .subscribe(
              currentDepartments => {
                self.currentDepartment = currentDepartments;
                subscriber.next(currentDepartments);
                subscriber.complete();
                self.departmentProcessing = false;
              },
              err => {
                console.error(err);
                subscriber.error(err);
                self.departmentProcessing = false;
              }
          );
    });
  }
  // endregion

  // region DISPLAY GROUPS
  /**
   * If 'displayGroups' exists, it returns object, otherwise it calls getDisplayGroups() method and returns null.
   */
  get displayGroups(): DisplayGroup[] {
    if (this._displayGroups && this._displayGroups.length) {
      return this._displayGroups;
    } else {
      this.getDisplayGroups();
      return [];
    }
  }

  public hasProfile(profile): boolean {
    return this.sessionData.user.profiles_names.includes(profile);
  }

  getDashboardDisplayGroups(): DisplayGroup[] {
    if (this._displayGroups && this._displayGroups.length) {

      if (this.allowedDisplayGroups) {
        return this._displayGroups.filter(
          dg =>
            this.allowedDisplayGroups.includes(dg.display_name.toLowerCase()) || dg.display_name.toLowerCase() === 'location');
      } else {
        return this._displayGroups.filter(dg => dg.audience_group);
      }
    } else {
      this.getDisplayGroups();
      return [];
    }
  }

  set displayGroups(dispGroups: DisplayGroup[]) {
    this._displayGroups = dispGroups;
  }

  getDisplayGroups<T>(): void {
    const self = this;

    if (self.displayGroupsProcessing) {
      return;
    }
    if (!self.currentDepartment) {
      self.getDepartment().subscribe();
      return;
    }

    self.displayGroupsProcessing = true;
    const address = self.currentDepartment.uris.DisplayGroups;

    if (this.displayGroupsData.getValue() !== null) {
      return;
    }

    self.http.get<DisplayGroup[]>(
      SessionService.enrichApiUrl(address),
    )
      .subscribe(
        displayGroups => {
          self.displayGroups = displayGroups;

          self.displayGroups = self.displayGroups.filter(f => f.display_name === 'Location')
            .concat(
              self.displayGroups.filter(f => f.display_name !== 'Location')
                .sort((a, b) => a.text > b.text ? 1 : a.text < b.text ? -1 : 0));
          self.displayGroupsData.next(self.displayGroups);
          self.displayGroupsProcessing = false;
        },
        err => {
          console.error(err);
          self.displayGroupsProcessing = false;
        }
      );
  }
  // endregion

  // region FORMATS
  /**
   * If 'formats' exists, it returns object, otherwise it calls getFormatsData() method and returns null.
   */
  get formats(): FormatsBlockDispModel[] {
    if (this._formatsData && this._formatsData.length) {
      return this._formatsData;
    } else {
      this.getFormatsData();
      return [];
    }
  }
  set formats(dispGroups: FormatsBlockDispModel[]) {
    this._formatsData = dispGroups;
  }

  /**
   * Returns formats data from local file.
   * @param successCB
   * @param errorCB
   */
  private getFormatsData(
    successCB?: (data: FormatsBlockDispModel[]) => void,
    errorCB?: (err) => void
  ): void {
    const self = this;

    if (self.formatsProcessing) {
      return;
    }

    self.formatsProcessing = true;
    self.http.get<FormatsBlockDispModel[]>(
      self.formatsSourceUrl_2018
    ).subscribe(
      formats => {
        self.formats = formats;
        self.formatsProcessing = false;
      },
      err => {
        console.error(err);
        self.formatsProcessing = false;
      }
    );
  }
  // endregion

  // region LOCATION
  /**
   * If 'location' exists, it returns object, otherwise it calls getLocation() method and returns null.
   */
  get location(): Location {
    if (this._location) {
      return this._location;
    } else {
      this.getLocation().subscribe();
      return null;
    }
  }
  set location(dispGroups: Location) {
    this._location = dispGroups;
  }

  // region LOCATION LOADING
  private getLocation(address?: string): Observable<any> {
    const self = this;
    const defaultLocationAddress = '/data/locations?defaults=1';

    return new Observable(subscriber => {
      if (this.locationProcessing) {
        subscriber.complete();
        return;
      }
      const req = (url) => {
        this.http.get<Location[]>(
            SessionService.enrichApiUrl(url),
        ).subscribe(
            locations => {
              if (locations.length) {
                this.location = locations[0];
                this.currentLocation.next(this.location);
                this.determinedLocation = true;
                this.locationProcessing = false;
                subscriber.next(this.location);
                subscriber.complete();
              } else {
                req(defaultLocationAddress);
              }
            },
            err => {
              console.error(err);
              self.locationProcessing = false;
            });
      };

      // if (!address) {
      //   if (self.locationProcessing) {
      //     return;
      //   }
      //   if (!self.currentDepartment) {
      //     self.getDepartment();
      //     return;
      //   }
      // }

      self.locationProcessing = true;
      if (!address) {
        address = self.currentDepartment.uris.Locations;
      }

      req(address);

    });
  }



  private loadDefaultLocation(): void {
    const self = this;

    const defaultLocationAddress = '/data/locations?defaults=1';
    self.getLocation(defaultLocationAddress);
    self.determinedLocation = false;
  }
  // endregion
  // endregion

  get company(): CompanyModel {
    return this.currentSessionData.company;
  }

  // region CURRENT COMPANY
  /**
   * If 'currentCompany' exists, it returns object, otherwise it calls getCurrentCompany() method and returns null.
   */
  get currentCompany(): CurrentCompany {
    if (this._currentCompany) {
      return this._currentCompany;
    } else {
      this.getCurrentCompany();
      return null;
    }
  }
  set currentCompany(currentCompany: CurrentCompany) {
    this._currentCompany = currentCompany;
  }

  private getCurrentCompany() {
    const self = this;

    if (self.currentCompanyProcessing) {
      return;
    }
    if (!self.sessionData) {
      return;
    }

    self.currentCompanyProcessing = true;
    const address = self.sessionData.company.uri;

    self.http.get<CurrentCompany>(
      SessionService.enrichApiUrl(address),
    )
      .subscribe(
        currentCompany => {
          self.currentCompany = currentCompany;
          self.currentCompanyProcessing = false;
        },
        err => {
          console.error(err);
          self.currentCompanyProcessing = false;
        }
      );
  }
  // endregion

  deleteSessionCache(): Observable<any> {
    return this.http.delete(env.sessionServiceUrl + '/cache')
      .pipe(
        tap(() => console.log('Session Cache refreshed'))
      );
  }

  fetchSessionData() {
    return this.http.get<SessionInitModel>(env.sessionServiceUrl)
      .pipe(
        tap(data => {
          this.setupGlobalVariables(data);
          if (this.sessionData) {
            this.currentSessionData = this.sessionData;
            Bugsnag.addOnError((event) => {
              const userData = this.getUserForErrorReporting();
              event.setUser(userData.id, userData.email, userData.name);
            });

            // get allowed display groups
            if (this.sessionData.user.allowed_groups) {
              try {
                this.allowedDisplayGroups = JSON.parse(this.sessionData.user.allowed_groups);
                console.log('ALLOWED DISPLAY GROUPS', this.allowedDisplayGroups);
              } catch (e) {
                ;
              }
            }

          }

          // this.sessionServiceProcessing = false;
        },
        err => {
          console.error(err);
          // this.sessionServiceProcessing = false;
        }
      ));
  }


  /**
   * Loads session data and fills necessary fields.
   */
  loadSessionService(force = false): void {
    const self = this;

    if (self.sessionServiceProcessing && !force) {
      return;
    }
    self.sessionServiceProcessing = true;

    self.http.get<SessionInitModel>(env.sessionServiceUrl)
      .subscribe(
        data => {
          this.setupGlobalVariables(data);
          if (self.sessionData) {

            self.currentSessionData = self.sessionData;

            // get allowed display groups
            if (this.sessionData.user.allowed_groups) {
              try {
                this.allowedDisplayGroups = JSON.parse(this.sessionData.user.allowed_groups);
                console.log('ALLOWED DISPLAY GROUPS', this.allowedDisplayGroups);
              } catch (e) {
                ;
              }
            }
          }

          self.sessionServiceProcessing = false;
        },
        err => {
          console.error(err);
          self.sessionServiceProcessing = false;
        }
      );
  }

  public getProxiedRequest(url: string, responseType = null): Observable<any> {
    const options = {
      headers: new HttpHeaders({'x-proxy-url': url})
    };
    if (responseType) {
      options['responseType'] = responseType;
    }
    return this.http.get(Session.enrichApiUrl('/data/proxy-request'), options);
  }

  public getImageAsBlob(imageUrl: string): Observable<any> {
    return new Observable(subscriber => {
      this.http.get(Session.enrichApiUrl('/data/proxy-request'), {
        headers: new HttpHeaders({'x-proxy-url': imageUrl}),
        responseType: 'blob'
      }).subscribe(
        (blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            subscriber.next(reader.result);
            subscriber.complete();
          };
        }
      );
    });
  }

  public getLocalImage(imageUrl: string): Observable<string> {
    return new Observable(subscriber => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.naturalWidth;
        canvas.height = img.naturalHeight;
        canvas.getContext('2d').drawImage(img, 0, 0);
        subscriber.next(canvas.toDataURL());
        subscriber.complete();
      };
      img.src = imageUrl;
    });
  }

  private setupGlobalVariables(data: SessionInitModel): void {
    this.sessionData = data.sessionData;
    this.defaultOfferImageTemplate = data.defaultOfferImageTemplate;
    this.defaultOfferVideoTemplate = data.defaultOfferVideoTemplate;
    this.RTBExternalConsumers = data.rtbExternalConsumers;
  }

  /**
   * Sends 'activity' POST request.
   */
  private registerActivity(): void {
    this.http.post(
      env.sessionMonitorServiceUrl + '/register/activity',
      ''
    ).subscribe(
      () => {},
      () => {}
    );
  }

  getUserForErrorReporting(): any {
    if (this.currentSessionData) {
      const user = this.currentSessionData.user;
      return {
        id: user.uri,
        email: user.email,
        name: user.displayName
      };
    } else {
      return {};
    }
  }

}
