<div class="campaign-preview-body">
  <div class="artwork-area"
       [ngStyle]="{'flex': '0 0 ' + artworkAreaWidth + 'px'}"
       *ngIf="currentFormat">
    <app-artwork-preview
      *ngIf="selectedFormat"
      [offer]="offer"
      [previewSrc]="fileSrc"
      [fileType]="artworkType"
      [chosenFormat]="currentFormat"
      [uploadButton]="false"
      [useThumbnail]="true"
      [enableCarousel]="true">
    </app-artwork-preview>
  </div>

  <div class="info-area">

    <div class="summary-circles" *ngIf="formatsReady">

      <ng-container *ngIf="currentFormat">

        <div class="column">
          <div class="af_icon">
            <span class="inlineSVG" [inlineSVG]="formatIcon" style="width:  80%;"></span>
          </div>

          <div class="af_description">
            <h1 class="count">{{offer?.num_of_screens}}</h1>
            <h2  class="no-margin" [innerHTML]="currentFormat?.ooh_format_label"></h2>
          </div>
        </div>

        <div *ngIf="!currentFormat && offer?.num_of_screens" class="column">
          <div class="af_icon">
            <span class="inlineSVG" [inlineSVG]="'/assets/images/icons/dashboard_2018/generic.svg'" style="width:  80%;"></span>
          </div>

          <div class="af_description">
            <h1 class="count">{{offer?.num_of_screens}}</h1>
            <h2  class="no-margin">Generic</h2>
          </div>
        </div>
      </ng-container>

      <div *ngIf="offer && audience" class="column">
        <div class="af_icon">
          <span *ngIf="audience.display_name !== 'All'; else postCode"
                class="inlineSVG"
                [inlineSVG]="regularise(audience)"
                style="width:  80%;"></span>
          <ng-template #postCode>
            <span>{{postcode}}</span>
          </ng-template>
        </div>

        <div class="af_description">
          <h1 class="count">{{offer?.reach | thousandSuffix}}</h1>
          <h2 class="no-margin">{{audience?.display_name}}</h2>
        </div>
      </div>

      <div *ngIf="offer" class="column">
        <div class="af_icon">
          <span class="inlineSVG" [inlineSVG]="'/assets/images/icons/dashboard_2018/duration.svg'" style="width: 30%;"></span>
        </div>

        <div class="af_description">
          <h1 class="count">{{duration}}</h1>
          <h2 class="no-margin">Duration</h2>
        </div>
      </div>

      <div *ngIf="offer" class="column">
        <div class="af_icon">
          <span class="inlineSVG" [inlineSVG]="'/assets/images/icons/dashboard_2018/intensity.svg'" style="width:  40%;"></span>
        </div>

        <div class="af_description">
          <h1 class="count">Medium</h1>
          <h2 class="no-margin">Intensity</h2>
        </div>
      </div>

      <div *ngIf="offer && offer.ad_plays" class="column">
        <div class="af_icon">
          <span class="inlineSVG" [inlineSVG]="'/assets/images/icons/dashboard_2018/impressions.svg'" style="width: 80%;"></span>
        </div>

        <div class="af_description">
          <h1 class="count" *ngIf="offer?.ad_plays">{{offer.ad_plays | thousandSuffix}}</h1>
          <h2 class="no-margin">Ad Plays</h2>
        </div>
      </div>

    </div>

  </div>

</div>
<div class="campaign-preview-footer"
     *ngIf="!showSimpleInfo">
  <app-screen-list
    [offer]="offer">
  </app-screen-list>

  <button (click)="_openOffer()"
          class="btn finish-btn ml-2"
          *ngIf="offerStatus !== 'delivered'">
    <ng-container *ngIf="!offerStatus || offerStatus === 'draft'; else BACK_TO_DRAFT">Edit</ng-container>
    <ng-template #BACK_TO_DRAFT>Back to Draft</ng-template>
  </button>
</div>
