import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthorisationModule } from './layouts/authorisation/authorisation.module';
import { CoreModule } from './layouts/core/core.module';
import { AuthorisedGuard } from './_guards/authorised.guard';
import { NotAuthorisedGuard } from './_guards/not-authorised.guard';
import { CoreService } from './services/main/core.service';
import { OfferService } from './services/offer/offer.service';
import { ReportsService } from './services/reports/reports.service';
import { ProfileService } from './services/profile/profile.service';
import { SessionService } from './services/session/session.service';
import { ApiService } from './services/api-service/api.service';
import { StripePaymentService } from './services/stripe-payment/stripe-payment.service';
import { AuthenticationService } from './services/authentication/authentication.service';
import { NotifyService } from './services/notify/notify.service';
import { EstimatorService } from './services/estimator/estimator.service';
import { LayoutService } from './services/layout/layout.service';
import { TokenInterceptor } from './interceptors/http/token.interceptor';
import { RetryOnErrorInterceptor } from './interceptors/http/retry-on-error.interceptor';
import { SessionDataResolver } from './resolvers/sessionData.resolver';
import { ArtworkGeneratorService } from './services/artwork-generator/artwork-generator.service';
import { FeedChooserService } from './modules/feed-chooser/feed-chooser.service';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ToastrModule } from 'ngx-toastr';
import { CookieModule } from 'ngx-cookie';
import { CookieService } from 'ngx-cookie-service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { environment as env } from '../environments/environment';
import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { FeedbackService } from './shared/services/feedback.service';
import { LoadingService } from '@shared/services/loading.service';
import { BackwardGuard } from '@guards/backward.guard';
import { FormatsResolver } from './resolvers/formats.resolver';

// Bugsnag.start({
//   apiKey: '45a4c586ee289f9e301d39df8b8f3a84',
//   releaseStage: env.envName,
// });
//
// export function errorHandlerFactory(): any {
//   // @ts-ignore
//   return new BugsnagErrorHandler();
// }

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgCircleProgressModule.forRoot(),
    CookieModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule,
    AuthorisationModule,
    CoreModule,
    AppRoutingModule,
    // NgxDateRangePickerModule,
    ToastrModule.forRoot(),
    FontAwesomeModule,
  ],
  providers: [
    AuthorisedGuard,
    NotAuthorisedGuard,
    BackwardGuard,
    CoreService,
    CookieService,
    OfferService,
    ReportsService,
    ProfileService,
    SessionService,
    ApiService,
    AuthenticationService,
    NotifyService,
    StripePaymentService,
    EstimatorService,
    LayoutService,
    // {
    //   provide: ErrorHandler,
    //   useFactory: errorHandlerFactory,
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryOnErrorInterceptor,
      multi: true
    },
    SessionDataResolver,
    FormatsResolver,
    ArtworkGeneratorService,
    FeedChooserService,
    FeedbackService,
    LoadingService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
