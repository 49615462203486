import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { OfferData } from '../../../models/offers/offer-data/OfferData.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormatsBlockDispModel } from '../../../models/FormatsBlockDisp.model';
import { Router } from '@angular/router';
import { SchedulerService } from '../../../services/scheduler/scheduler.service';

const TARGET_COLOR_MAP = {
  draft: 'gray',
  submitted: 'orange',
  rejected: 'red',
  accepted: 'green',
  booked: 'green',
  online: 'blue',
  paused: 'gray',
  suspended: 'gray'
};

@Component({
  selector: 'app-campaign-preview-popup',
  templateUrl: './campaign-preview-popup.component.html',
  styleUrls: ['./campaign-preview-popup.component.scss']
})
export class CampaignPreviewPopupComponent implements OnInit, OnDestroy {

  @Input() offer: OfferData;
  @Input() formatsBlocks: FormatsBlockDispModel[] = [];

  private _destroy$ = new Subject<any>();

  constructor(
    private activeModal: NgbActiveModal,
    private router: Router,
    private schedulerService: SchedulerService,
  ) { }

  ngOnInit(): void {
    if (this.offer.offer_schedules) {
      this.schedulerService.init(this.offer.offer_schedules);
    }
  }

  get offerNextScheduleStep(): string {
    const target = this.offer.offer_targets.find(t => t.status === 'booked' || t.status === 'paused' || t.status === 'online');
    if (target && target.next_change) {
      if (['booked', 'paused'].includes(target.status)) {
        if (!target.is_displayed && target.next_change) {
          return ` on ${moment(target.next_change).format('MMMM Do YYYY, h:mm:ss a')}`;
        } else {
          return ' shortly';
        }
      } else if (target && target.status === 'online') {
        return ` until ${moment(target.next_change).format('MMMM Do YYYY, h:mm:ss a')}`;
      }
    } else {
      return '';
    }
  }

  get rejectionReason(): string {
    const target = this.offer.offer_targets.find(t => t.status === 'rejected');
    return target && target.rejection_status ? `<br>${target.rejection_status}` : '';
  }

  get draftOrLive(): boolean {
    const status = !this.offer.offer_targets ? 'draft' : this.offer.offer_targets[0] ? this.offer.offer_targets[0].status : 'draft';
    return status === 'online' || status === 'draft';
  }

  get scheduleDescription(): string {
    return this.schedulerService.description;
  }

  get offerStatus(): string {
    return !this.offer.offer_targets || this.offer.offer_targets.length === 0 ? 'draft' : this.offer.offer_targets[0] ? this.offer.offer_targets[0].status.toLowerCase() : null;
  }

  get statusColor(): string {
    const status = this.offer.offer_targets[0] ? this.offer.offer_targets[0].status : 'draft';
    return TARGET_COLOR_MAP[status.toLowerCase()];
  }

  close(): void {
    this.activeModal.dismiss();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
