import {EventEmitter, Injectable, Injector, Output} from '@angular/core';
import 'fabric';
import {SuperService} from '../../services/super-service/super.service';
import {SessionService as Session} from '../../services/session/session.service';
import {Observable} from 'rxjs';
import * as qrcode from '../../services/artwork-generator/qrcode';
import '../../services/artwork-generator/perspective';
import {BehaviorSubject} from 'rxjs';
import {CoreService} from '../../services/main/core.service';
import { combineLatest } from 'rxjs';

declare const fabric: any;
declare const html5jp: any;


@Injectable()
export class HachetteService extends SuperService {

  private canvas: any;
  private json: JSON;

  public selectedBook = new BehaviorSubject<number>(null);

  constructor(
    injector: Injector,
    private coreService: CoreService
  ) {
    super(injector);
  }

  isOpen = false;
  @Output() change: EventEmitter<boolean> = new EventEmitter();

  toggle() {
    this.isOpen = !this.isOpen;
    this.coreService.freezeMain.next(this.isOpen);
    this.change.emit(this.isOpen);
  }

  getBooks() {
    const uri = Session.enrichApiUrl(`/data/hachette`);
    return this.http.get(uri);
  }

  getBookDetails(isbn: number) {
    const uri = Session.enrichApiUrl(`/data/hachette?isbn=${isbn}`);
    return this.http.get(uri);
  }

  prepare(config) {
    this.canvas = new fabric.Canvas('canvas', {
      hoverCursor: 'pointer',
      selection: false,
      selectionBorderColor: 'blue',
      selectionLineWidth: 5,
      enableRetinaScaling: false
    });

    this.canvas.setDimensions({width: config.layout.width, height: config.layout.height});
    this.canvas.backgroundColor = '#ffffff';
  }

  getImage() {
    if (!fabric.Canvas.supports('toDataURL')) {
      alert('This browser doesn\'t provide means to serialize canvas to an image');
    } else {
      return this.canvas.toDataURL('png');
    }
  }

  loadImage(url) {
    return new Observable(subscriber => {
      fabric.Image.fromURL(url, (image) => {
        subscriber.next(image.toDataUrl());
        subscriber.complete();
      });
    });
  }

  getArtwork(config): Observable<string> {
    // and load everything from the same json
    this.prepare(config);

    return new Observable(subscriber => {

      this.http.get<JSON>(config.layout.src)
        .subscribe(
        json => {
          this.json = json;
          this.loadJSON(this.json, config).subscribe(
            () => {
              subscriber.next(this.getImage());
              subscriber.complete();
            }
          );
        },
        err => {
          console.error(err);
        }
      );
    });

  }

  private setImage(url, params) {
    const self = this;
    return new Observable(subscriber => {
      fabric.Image.fromURL(url, (image) => {
        const scale = params.baseline / (params.scaleWidth ? image.width : image.height);
        image.set({
          left: params.left,
          top: params.top,
          scaleX: scale,
          scaleY: scale
        });
        this.canvas.add(image);

        if (params.toBack) {
          image.sendToBack();
        }
        subscriber.next();
        subscriber.complete();
      });
    });
  }

  private generateQRCode(content, params) {
    const self = this;
    return new Observable(subscriber => {
      const QRCode = qrcode(0, 'M');
      QRCode.addData(content);
      QRCode.make();
      const data = QRCode.createDataURL(5, 20);
      self.setImage(data, params)
        .subscribe(
          () => {
            subscriber.next(data);
            subscriber.complete();
          });
    });
  }

  private getTempCanvas(w, h) {
    const canvas = document.createElement('canvas');
    canvas.width = w;
    canvas.height = h;
    return canvas;
  }

  private preprocessBackground(config) {
    return new Observable(subscriber => {
      // background processing
      const bgCanvas = this.getTempCanvas(config.layout.width, config.layout.height);
      const ctx = bgCanvas.getContext('2d');

      if (config.layout.background) {
        // add default background image
        if (config.layout.background.src) {
          const bgImage = new Image();
          bgImage.onload = function () {
            ctx.drawImage(bgImage, 0, 0);

            // process overlay if specified
            if (config.layout.background.overlay) {
              const image = new Image();
              image.onload = function () {
                const op = new html5jp.perspective(ctx, image);
                op.draw(config.layout.background.overlay.perspective);
                subscriber.next(bgCanvas.toDataURL());
                subscriber.complete();
              };
              image.src = config.offer[config.layout.background.overlay.src];
            } else {
              // no overlay specified
              subscriber.next(bgCanvas.toDataURL());
              subscriber.complete();
            }

          };
          bgImage.src = config.layout.background.src;
        } else {
          // no background processing needed
          subscriber.next(bgCanvas.toDataURL());
          subscriber.complete();
        }
      }
    });
  }

  loadJSON(layoutJSON, config): Observable<any> {
    return new Observable(subscriber => {
      console.log(layoutJSON);

      this.preprocessBackground(config)
        .subscribe(
          (bgImage) => {
            layoutJSON.background.source = bgImage;
            this.canvas.loadFromJSON(layoutJSON, () => {
              combineLatest([
                this.setImage(config.offer.pricetag, config.layout.images.pricetag),
                this.generateQRCode(config.offer.qrContent || 'Set qrContent', config.layout.images.qrcode)
              ]).subscribe(() => {
                  const txt = `"${config.offer.title}"`;

                  let title = new fabric.Textbox(txt, {
                      left: 975,
                      top: 230,
                      width: 920,
                      fixedWidth: 920,
                      textAlign: 'center',
                      fontSize: 80,
                      fontFamily: 'GTWalsheim',
                      fontWeight: '500'
                    });


                  if (title.getScaledHeight() > 400) {
                      title = new fabric.Textbox(txt, {
                        left: 975,
                        top: 130,
                        width: 920,
                        fixedWidth: 920,
                        textAlign: 'center',
                        fontSize: 70,
                        fontFamily: 'GTWalsheim',
                        fontWeight: '500'
                      });
                  }

                  this.canvas.add(title);

                  this.canvas.add(
                    new fabric.Textbox(config.offer.author, {
                      left: 1130,
                      top: 230 + title.getScaledHeight() + 40,
                      width: 640,
                      fixedWidth: 640,
                      textAlign: 'center',
                      fontSize: 50,
                      fontFamily: 'GTWalsheim',
                      fontWeight: '300'
                    })
                  );

                  this.canvas.add(
                    new fabric.Textbox(config.offer.price, {
                      left: 100,
                      top: 805,
                      width: 210,
                      fixedWidth: 210,
                      textAlign: 'center',
                      fontSize: 60,
                      fontFamily: 'GTWalsheim',
                      fontWeight: '500',
                      fill: '#ffffff'
                    })
                  );

                  this.canvas.add(
                    new fabric.Textbox(`       ${config.offer.intro}`, {
                      left: 1040,
                      top: 705,
                      width: 816,
                      fixedWidth: 816,
                      textAlign: 'left',
                      fontSize: 32,
                      fontFamily: 'GTWalsheim',
                      fontWeight: '500',
                      lineHeight : 1.5
                    })
                  );


                  this.canvas.renderAll();
                  console.log(this.canvas);
                  subscriber.next({});
                  subscriber.complete();
                });
            });
          }
        );


    });
  }

}
