export const TEMPLATE = 'gbnews';

export const DEFAULT_ARTWORK_DATA = {
  desc: {
    value: 'Not the\n9 o’clock\nnews.',
    fontSize: 200,
    alignMiddle: true
  },
  footer: {
      value: 'Flow *City* & CO\n*WEEKDAYS* 6-7PM',
      fill: '#000000',
      lineHeight: 1,
      fontSize: 30,
      styles: {fill: 'red'}
  }
};
