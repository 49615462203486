import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {InstagramService} from '../instagram.service';
import {ApiService} from '../../../services/api-service/api.service';

@Component({
  selector: 'app-instagram-layout',
  templateUrl: './instagram-layout.component.html',
  styleUrls: ['./instagram-layout.component.sass']
})
export class InstagramLayoutComponent implements OnInit, OnDestroy {

  @HostBinding('class.is-open')
  isOpen = false;

  constructor(
    public instagramService: InstagramService,
    public apiService: ApiService
  ) {
    this.instagramService.change.subscribe(isOpen => {
      this.isOpen = isOpen;
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.instagramService.toggle(true);
  }

  get logo() {
    return this.instagramService.getLogo();
  }

}
