import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {SessionService as Session} from '../services/session/session.service';
import { AuthenticationService } from '../services/authentication/authentication.service';

@Injectable()
export class SuperGuard {
  constructor(
    protected session: Session,
    protected router: Router,
    protected authService: AuthenticationService,
  ) {
    this.authService.initFirebase();
  }


  isLogged(): boolean {
    return this.session.hasCookieUserToken();
  }
}
