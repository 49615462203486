import {Component, Inject, OnInit} from '@angular/core';
import { Location } from '@angular/common';
import {AuthenticationService} from '../../../services/authentication/authentication.service';
import {FormBuilder, FormGroup, Validators, FormControl, AbstractControl} from '@angular/forms';
import {SignInValidators} from '../validators';
import {CoreService} from '../../../services/main/core.service';
import {SessionService} from '../../../services/session/session.service';
import {CONSTANTS} from '../../../additional/helpers/constants/constants.const';
import {ActivatedRoute} from '@angular/router';
import * as moment from 'moment';
import { ReCaptchaV3Service } from 'ng-recaptcha';

interface PromoPackage {
  audience: string;
  format: string;
  budget: number;
  duration: number;
  hours: number;
  plan_name: string;
  advance_months: number;
}

const TEMPLATES = {
  hh500: {
    audience: 'corporate',
    format: 'all',
    budget: 500,
    duration: 30,
    hours: 17.0,
    plan_name: 'PLUS',
    advance_months: 1
  },
  hh3000: {
    audience: 'corporate',
    format: 'all',
    budget: 3000,
    duration: 180,
    hours: 17.0,
    plan_name: 'PLUS',
    advance_months: 6
  },
  hh6000: {
    audience: 'corporate',
    format: 'all',
    budget: 6000,
    duration: 360,
    hours: 17.0,
    plan_name: 'PLUS',
    advance_months: 12
  }
};


@Component({
  selector: 'app-signup-package',
  templateUrl: './signup-package.component.html',
  styleUrls: ['./signup-package.component.sass']
})
export class SignupPackageComponent implements OnInit {

  userRegisterForm: FormGroup;
  companyData: object = null;
  paymentData: object = null;
  error: string = null;
  promoParams: PromoPackage;

  credits: number = null;

  step = 1;

  isRequestPending = false;
  passwordInput = 'password';

  validation_messages = {
    'name': [
      { type: 'required', message: 'Please enter company name' },
      { type: 'companyTaken', message: 'Company already registered'}
    ],
    'email': [
      { type: 'required', message: 'Please enter your email address' },
      { type: 'email', message: 'Valid email address is required' },
      { type: 'emailTaken', message: 'Email address already used'}
    ],
    'password': [
      { type: 'required', message: 'Please enter password' },
    ]
  };

  constructor(
    private _location: Location,
    private fb: FormBuilder,
    @Inject(AuthenticationService) public service,
    public coreService: CoreService,
    private session: SessionService,
    private route: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) { }

  ngOnInit() {
    this.createForms();

    this.route.params.subscribe(params => {
       const _package = (<any>window).atob(params['package']);
       this.promoParams = TEMPLATES[_package] || TEMPLATES['hh500'];
       if (!this.promoParams.plan_name) {
         this.service.router.navigate(['/signup']);
       }
    });

    // after company has been created on page refresh go to payment page
    if (sessionStorage.getItem('tmp-session')) {
      if (sessionStorage.getItem('tmp-company-data')) {
        this.companyData = JSON.parse(sessionStorage.getItem('tmp-company-data'));
      }
      this.step = 2;
    }
  }

  createForms() {
    this.userRegisterForm = this.fb.group({

      accept_tnc: ['', Validators.requiredTrue ],

      name: new FormControl('', {
        asyncValidators: [SignInValidators.validateCompanyNotTaken(this.service)],
        updateOn: 'blur'
      }),

      email: new FormControl('', {
        validators: [Validators.required, Validators.email],
        asyncValidators: [SignInValidators.validateEmailNotTaken(this.service)],
        updateOn: 'blur'
      }),

      password: ['', Validators.required ]
    });
  }

  get showHide(): string {
    return this.passwordInput === 'password' ? 'Show' : 'Hide';
  }

  showHidePassword(): void {
    this.passwordInput = this.passwordInput === 'password' ? 'text' : 'password';
  }

  onSubmitUserRegisterForm(registerCompanyData) {
    this.recaptchaV3Service.execute('camden_registration')
      .subscribe((token) => {
        this.error = null;
        this.userRegisterForm.disable();
        this.isRequestPending = true;
        registerCompanyData['accept_tnc_timestamp'] = new Date().toISOString();
        registerCompanyData['profiles_set'] = 'house_hunter';
        registerCompanyData['promo_params'] = this.promoParams;
        registerCompanyData['token'] = token;
        this.service.registerCompany(registerCompanyData)
          .subscribe(
            (request) => {

              // set temporary session key
              if (request.headers.get(CONSTANTS.sessionKey)) {
                sessionStorage.setItem('tmp-session', request.headers.get(CONSTANTS.sessionKey));
                sessionStorage.setItem('tmp-company-data', JSON.stringify(request.body));
              }

              this.userRegisterForm.enable();
              // this.isRequestPending = false;
              this.companyData = request.body;
              this.createPayment();
              // (<any>window).ga('gtm1.send', 'event', 'signup', 'sent', 'camden-signup');
            },
            () => {
              this.userRegisterForm.enable();
              this.isRequestPending = false;
              this.error = 'An error occurred while creating your account. Please contact administrator.';
            }
          );
      });
  }

  createPayment() {
    this.error = null;
    this.isRequestPending = true;
    this.coreService.makePayment({
      'plan_name': this.promoParams.plan_name,
      'advance_months': this.promoParams.advance_months
    },
      this.companyData['uris'].Payments,
      sessionStorage.getItem('tmp-session'))
      .subscribe(
        (payment) => {
          this.isRequestPending = false;
          this.paymentData = payment;
          console.log(this.paymentData);
          this.step = 2;
        },
        () => {
          this.isRequestPending = false;
          this.error = 'An error occurred while creating your account. Please contact administrator.';
        }
      );
  }

  goBack(event) {
    event && event.preventDefault();
    // this.step = 2;
  }

  onCompletePayment(data) {
    this.error = null;
    this.isRequestPending = false;

    // remove temporary session and initialize the real session
    if (sessionStorage.getItem('tmp-session')) {
      this.session.saveToken(sessionStorage.getItem('tmp-session'));
      sessionStorage.removeItem('tmp-session');
      sessionStorage.removeItem('tmp-company-data');
    }

    window.location.href = '/';
  }

  toggleRequestPending(state) {
    this.isRequestPending = state;
  }

}
