<input #fileToUpload
       (change)="addFile($event)"
       type="file"
       [attr.multiple]="fileService.isMulti"
       [attr.accept]="acceptedFileType"
       style="display: none;"/>

<div id="all-formats" class="create-campaign-formats" [class.hidden]="chosenFormat">
  <div class="af-row">

    <ng-container *ngIf="formats && formats.length > 0; else NO_FORMATS_FOUND">

      <ng-container *ngFor="let format of formats; trackBy: trackFn; let idx = index">

          <app-icon-item
            [data]="format"
            [selected]="selected(format)"
            [unselected]="unselected(format)"
            [icon]="'formats/' + format.ooh_format.split('_')[0]"
            [genericIcon]="'audiences/generic.svg'"
            [progressValue]="getPercentage(format)"
            [class.warning]="getPercentage(format) > 100"
            (clicked)="formatClicked(format)">

            <ng-template fc-iconitem-description>
              <div [innerHTML]="format.ooh_format_label"></div>
              <div *ngIf="format.artwork_spec
                          && format.artwork_spec.resolution
                          && format.artwork_spec.resolution.width
                          && format.artwork_spec.resolution.height">
                {{format.artwork_spec.resolution.width}}x{{format.artwork_spec.resolution.height}}
              </div>
            </ng-template>

            <ng-template fc-iconitem-percent>
              <div *ngIf="getPercentage(format)" style="margin: 2px 6px;">
                <ng-container  *appHasProfile="'viooh_mgmt'; else REGULAR_USER">
                  {{ getPercentage(format) | number:'1.0-0' }}%
                </ng-container>
                <ng-template #REGULAR_USER>
                  {{ (getPercentage(format) <= 100 ? getPercentage(format) : 100) | number:'1.0-0' }}%
                </ng-template>
              </div>
            </ng-template>

            <ng-template fc-iconitem-tooltip>
              <span [innerHTML]="tooltipHelper(format)"></span>

              <ng-container *appHasProfile="'viooh_mgmt'">
                <div style="color: red;" *ngIf="getPercentage(format) > 100">Please verify data for this audience.</div>
              </ng-container>

            </ng-template>

          </app-icon-item>

      </ng-container>

    </ng-container>
    <ng-template #NO_FORMATS_FOUND>
      <div class="no-formats">No formats available for <b>selected audiences</b> within <b>chosen budget</b></div>
    </ng-template>

  </div>
</div>

<div *ngIf="chosenFormat" class="selectedFormat row af-row">
  <div class="format selected col-2" style="border-right: solid 1px rgb(206, 209, 219);">

    <div class="af_icon">
      <img [src]="getIcon(chosenFormat)"
           style="filter: invert(.5) sepia(1) saturate(5) hue-rotate(-184deg) brightness(145%); width: 65%;">
    </div>

    <div class="af_description" style="
      margin-bottom: 50px;
    ">
      <h2><span [innerHTML]="chosenFormat.ooh_format_label"></span></h2>
      <!--<h1 class="no-margin count">{{getFormatEstimatorData(chosenFormat)}}</h1>-->
      <!--<h2>{{getFormatEstimatorData(chosenFormat, 'reach') | thousandSuffix}}</h2>-->
    </div>

    <button (click)="unsetFormat()" class="btn change-format">Change</button>
  </div>

  <div class="col-10">

    <div class="row af-row" style="min-height: 230px">

      <div class="col-6">
        <div>
          <table>
            <tr>
              <td class="row-key">FILE TYPE</td>
              <td class="row-value">{{chosenFormat.artwork_spec.file_types.join(', ')}}</td>
            </tr>
            <tr>
              <td class="row-key">SIZE UP TO</td>
              <td class="row-value">{{chosenFormat.artwork_spec.max_size_bytes}}</td>
            </tr>
            <tr>
              <td class="row-key">RESOLUTION</td>
              <td class="row-value">
                {{chosenFormat.artwork_spec.resolution.width}} x {{chosenFormat.artwork_spec.resolution.height}}
              </td>
            </tr>
          </table>
        </div>
        <ng-container *ngIf="fileService.isMulti">
          <p style="margin-bottom: 10px;"><b>UPLOADED ARTWORKS:</b></p>
          <div class="file-thumbnail-preview"
               *ngFor="let file of fileService.campaignFiles"
               (click)="onThumbnailClick(file)"
               [ngStyle]="thumbnailPreviewStyle"
               [class.invalid]="file.thumbnail && !file.thumbnail_inprogress && !file.transcode_inprogress && !file._validResolution"
               [class.processing]="file.thumbnail_inprogress || file.transcode_inprogress"
               [style.backgroundImage]="thumbnailPreviewImage(file.thumbnail)">
            <div class="overlay overlay-invalid" *ngIf="file.thumbnail && !file.thumbnail_inprogress && !file.transcode_inprogress && !file._validResolution"></div>
            <div class="overlay overlay-processing">
              <div class="loading" style="display: block"></div>
            </div>
            <div class="qrcode"
                 *ngIf="file.qrcode_detected"
                 [class.rendered]="file.qrcode_rendered">
              <app-svg-icon [path]="'assets/images/icon-qrcode.svg'" [alt]="'QR Code detected'">
              </app-svg-icon>
            </div>
            <div class="circular-progress"
                 [class.hidden]="!file._progress"
                 [attr.data-percent]="file._progress + '%'"
                 [ngStyle]="uploadProgressStyle(file._progress)"></div>
            <div class="delete-btn" (click)="removeItem(file, $event)">&#10005;</div>
          </div>
        </ng-container>


      </div>

      <div class="col-6 artwork-col">
        <app-artwork-preview
            [previewSrc]="selectedArtworkSrc"
            [fileType]="fileMimeType"
            [chosenFormat]="chosenFormat"
            [fileFeedback]="fileFeedback"
            [file]="singleArtworkFile"

            [uploadButton]="false">
        </app-artwork-preview>
      </div>

    </div>

    <div class="row" style="margin-top: 20px;">
      <div class="col-6">
        <div class="upload-artwork-buttons">
          <button class="upload-button btn"
                  (click)="selectFile()">
            Upload New
          </button>
          <span class="incorrect-file" *ngIf="incorrectFormat">Incorrect file format</span>
<!--          <button (click)="openTemplates()" class="btn no-artwork-btn">Choose template</button>-->
          <!--<button (click)="openChat()" class="btn no-artwork-btn">Don't have any artwork?</button>-->

            <app-artwork-generator-btn [format]="chosenFormat"></app-artwork-generator-btn>

<!--            <ng-container *appHasProfile="'artwork_generator'">-->
<!--              <app-artwork-generator-layout [format]="chosenFormat"></app-artwork-generator-layout>-->
<!--            </ng-container>-->

        </div>
      </div>
      <div class="col-6">
        <div class="upload-artwork-buttons text-center">
          <button
            *ngIf="this.canQRCodeBeGenerated"
            class="btn" (click)="generateQRCode()">
            Generate QR Code
          </button>
        </div>
      </div>
    </div>
  </div>

</div>
