import {Component, Inject} from '@angular/core';
import {OfferService} from '../../../../../../../services/offer/offer.service';
// import {MatSlider} from "@angular/material";
// import {OfferService} from "../../../../../services/offer/offer.service";

@Component({
  selector: 'app-demographics',
  templateUrl: './demographics.component.html',
  styleUrls: ['./demographics.component.sass']
})
export class DemographicsComponent {
  //
  genderButtons: GenderButton[] = DEMOGRAPHIC_GENDER;
  ageButtons: AgeButton[] = DEMOGRAPHIC_AGE;

  genderHint: string;
  ageHint: string;

  genderDisabled = true;
  ageDisabled = true;

  constructor(
      @Inject(OfferService) public service
  ) { }

  isAgeActive(age: AgeButton) {
    const sa = this.service.scheduleAIM;
    if (sa) {
      return sa.age === age.value;
    }

    return age.value.length < 1;
  }
  changeAge(age: AgeButton) {
    this.service.editAIMSchedule('age', age.value);
  }

  isGenderActive(gender: GenderButton) {
    const sa = this.service.scheduleAIM;
    if (sa) {
      return sa.gender === gender.value;
    }

    return gender.value.length < 1;
  }
  changeGender(gender: GenderButton) {
    this.service.editAIMSchedule('gender', gender.value);
  }

  sendGenderPercent(): void {
    const percent = parseInt(this.genderHint, 10);
    this.service.editAIMSchedule('gender_range', [percent, 100]);
  }

  sendAgePercent(): void {
    const percent = parseInt(this.ageHint, 10);
    this.service.editAIMSchedule('age_range', [percent, 100]);
  }

  isAgeDisabled(): boolean {
    const sa = this.service.scheduleAIM;
    if (sa) {
      return sa.age.length < 1;
    }

    return true;
  }

  isGenderDisabled(): boolean {
    const sa = this.service.scheduleAIM;
    if (sa) {
      return sa.gender.length < 1;
    }

    return true;
  }


  // active: false
  // age: ""
  // age_range: [0, 100]
  // gender: ""
  // gender_range: [0, 100]
  // type: "DbOfferScheduleAIM"
}


interface GenderButton {
  value: string;
  iconClass: string;
  description: string;
}
interface AgeButton {
  value: string;
  span: string;
  description: string;
}

const DEMOGRAPHIC_GENDER: GenderButton[] = [
  {
    value: '',
    iconClass: 'icon-demographic-off',
    description: 'Off'
  },
  {
    value: 'male',
    iconClass: 'icon-demographic-men',
    description: 'Men'
  },
  {
    value: 'female',
    iconClass: 'icon-demographic-women',
    description: 'Women'
  }
];
const DEMOGRAPHIC_AGE: AgeButton[] = [
  {
    value: '',
    span: '',
    description: 'Off'
  },
  {
    value: 'child',
    span: '(<24)',
    description: 'Under 24\'s'
  },
  {
    value: 'young_adult',
    span: '(25-39)',
    description: 'Young Adults'
  },
  {
    value: 'older_adult',
    span: '(40-65)',
    description: 'Adults'
  },
  {
    value: 'senior',
    span: '(66+)',
    description: 'Seniors'
  }
];

