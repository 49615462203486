import {Component, Inject} from '@angular/core';
import {CoreService} from '../../../services/main/core.service';
import {ProfileService} from '../../../services/profile/profile.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.sass']
})
export class SideMenuComponent {

  constructor(
    @Inject(CoreService) public coreService,
    @Inject(ProfileService) public profileService
  ) { }

  toggleSidebar(): void {
    const self = this;
    self.coreService.sidebarShown = !self.coreService.sidebarShown;
  }

  signOut() {
    this.coreService.requestLogout();
  }
}
