import {Component, HostBinding, OnInit} from '@angular/core';
import {HachetteService} from '../hachette.service';

@Component({
  selector: 'app-hachette-layout',
  templateUrl: './hachette-layout.component.html',
  styleUrls: ['./hachette-layout.component.sass']
})
export class HachetteLayoutComponent implements OnInit {

  @HostBinding('class.is-open')
  isOpen = false;

  constructor(
    public hachetteService: HachetteService
  ) {
    this.hachetteService.change.subscribe(isOpen => {
      this.isOpen = isOpen;
    });
  }

  ngOnInit() {
  }

}
