import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HachetteService} from '../hachette.service';
import { map } from 'rxjs/operators';
import {ApiService} from '../../../services/api-service/api.service';
import {OfferService} from '../../../services/offer/offer.service';
import { combineLatest } from 'rxjs';

const LAYOUTS = {
  landscape: {
    src: 'assets/generator-layouts/hachette-landscape/layout.json',
    width: 1920,
    height: 1080,
    background: {
      src: '/assets/generator-layouts/hachette-landscape/background.png',
      overlay: {
        src: 'image',
        perspective: [[224, 142], [696, 126], [696, 975], [232, 931]]
      }
    },
    images: {
      pricetag: {
        type: 'image',
        top: 703,
        left: 60,
        scaleWidth: true,
        baseline: 280
      },
      qrcode: {
        type: 'qrcode',
        top: 972,
        left: 160,
        scaleWidth: true,
        baseline: 92
      }
    }
  }
};


@Component({
  selector: 'app-book-preview',
  templateUrl: './book-preview.component.html',
  styleUrls: ['./book-preview.component.sass']
})
export class BookPreviewComponent implements OnInit {

  public isbn: number;
  public processing = false;
  public cover = null;

  public formatLandscape = {
    'artworkSpec': {
      'resolution': {
        'height': 1080,
        'width': 1920
      }
    }
  };

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private hachetteService: HachetteService,
    private offerService: OfferService
  ) { }

  ngOnInit() {
    // this.route.params.subscribe(params => {
    //   this.isbn = +params['isbn'];
    //   this.processing = true;
    //   this.hachetteService.getBookDetails(this.isbn)
    //     .subscribe(
    //       (data) => this.generateArtwork(data)
    //     );
    // });
    this.hachetteService.selectedBook.subscribe(
      (isbn) => {
        if (isbn) {
          this.isbn = isbn;
          this.processing = true;
          this.hachetteService.getBookDetails(this.isbn)
            .subscribe(
              (data) => this.generateArtwork(data)
            );
        }
      }
    );
  }

  generateArtwork(data) {
    combineLatest([
      this.apiService.getImageAsBlob(data.image),
      this.apiService.getLocalImage('/assets/generator-layouts/hachette-landscape/price-tag.png')
    ]).pipe(
      map(([image, pricetag]) => {
        return { image, pricetag };
      })
    ).subscribe(
        (images) => {
          data.qrContent = `https://www.hachette.co.uk/books/detail.page?isbn=${this.isbn}`;
          this.hachetteService.getArtwork({offer: {...data, ...images}, layout: LAYOUTS.landscape})
            .subscribe(
              (img) => this.cover = img
            );
          this.processing = false;
        }
    );
  }

  previewArtwork(src) {
    const image = new Image();
    image.src = src;
    const w = (<any>window).open('');
    w.document.write(image.outerHTML);
  }

  back() {
    this.hachetteService.selectedBook.next(null);
  }

  cancel() {
    this.hachetteService.selectedBook.next(null);
    this.hachetteService.toggle();
  }

  saveArtwork() {
    this.offerService.currentOffer.offer_type = 'image';
    this.offerService.updateCustomArtwork(this.cover);
    this.hachetteService.selectedBook.next(null);
    this.hachetteService.toggle();
  }


}
