import {Component, Inject, Input} from '@angular/core';
import {CoreService} from '../../../services/main/core.service';
import {Router} from '@angular/router';
import {SessionData} from '../../../models/session-init/session-data/session-data.model';
import {OfferService} from '../../../services/offer/offer.service';
import {AudienceTypeEstimation, EstimatorService} from '../../../services/estimator/estimator.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectImpressionModelComponent } from '../main-content/create-campaign/select-impression-model/select-impression-model.component';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.sass']
})
export class TopBarComponent {

  estimations: AudienceTypeEstimation[];

  constructor(
    @Inject(OfferService) public offerService,
    @Inject(CoreService) public coreService,
    @Inject(Router) private router,
    private estimatorService: EstimatorService,
    private modalService: NgbModal,
  ) {
    estimatorService.estimatorData.subscribe(
      (data) => {
        if (data) {
          this.estimations = data;
        }
      }
    );
  }

  get duration() {
    const duration = this.offerService.currentOffer.duration;
    return `${duration} day${duration > 1 ? 's' : ''}`;
  }

  get nrOfLocations() {
    const formatName = this.offerService.chosenFormat.format_name;
    if (this.estimations) {
      if (this.offerService.chosenAudience.audience_group) {
        const currAudience = this.estimations.find(a => a.audience === this.offerService.chosenAudience.display_name.toLowerCase());
        if (currAudience) {
          const currFormat = currAudience.formats.find(f => f.format === formatName);
          return currFormat ? currFormat.number : 0;
        }
      } else {
        return this.offerService.chosenAudience.num_of_screens;
      }
    } else {
      return this.offerService.currentOffer ? this.offerService.currentOffer.num_of_screens : 0;
    }
  }

  get timeFrames(): string {
    const desc = this.offerService.scheduleTime.description;
    if (desc) {
      return desc.replace('<br>', ' ');
    } else {
      return '';
    }
  }

  reach(): number {
    if (!this.offerService.chosenAudience) {
      return 0;
    }

    // if (this.estimations) {
    //   if (this.offerService.chosenAudience.audience_group) {
    //     const currAudience = this.estimations.find(a => a.audience === this.offerService.chosenAudience.display_name.toLowerCase());
    //     if (currAudience && this.offerService.chosenFormat) {
    //       const currFormat = currAudience.formats.find(f => f.format === this.offerService.chosenFormat.format_name);
    //       return currFormat ? currFormat.reach : currAudience.totalReach;
    //     } else {
    //       return currAudience ? currAudience.totalReach : 0;
    //     }
    //   } else {
    //     return this.offerService.getSchedulerTimeDuration() * this.offerService.chosenAudience.daily_reach;
    //   }
    // } else {
    return this.offerService.currentOffer ? this.offerService.currentOffer.reach : 0;
    // }
  }

  audience(): string {

    if (this.offerService.chosenAudience.display_name !== 'Location') {
      return this.offerService.chosenAudience.display_name;
    }
    if (!this.offerService.api.location) {
      return '';
    }

    return this.offerService.api.location.postcode;
  }


  toggleSidebar(): void {
    const self = this;
    self.coreService.sidebarShown = !self.coreService.sidebarShown;
  }

  goToSchedule(): void {
    const offerId = this.offerService.currentOffer.id;
    this.offerService.resetOffer = false;
    this.offerService.save().subscribe(
      () => this.router.navigate(['offers', offerId])
        .catch(
          reason => console.error('Could not go to schedule. \n' + reason)
        ),
    );
  }

  goToMediaPlane(): void {
    const offerId = this.offerService.currentOffer.id;
    this.offerService.resetOffer = false;
    this.offerService.save().subscribe(
      () => this.router.navigate(['media-planner', offerId])
        .catch(
          reason => console.error('Could not go to schedule. \n' + reason)
        ),
    );
  }

  get pageTitle(): string {
    const self = this;

    const route = self.router.url;

    if (route.includes('user-details')) {
      return 'User Details';
    } else if (route.includes('location')) {
      return 'Location Settings';
    } else if (route.includes('billing-info')) {
      return 'Billing Info';
    } else if (route.includes('invoices')) {
      return 'Invoices';
    } else if (route.includes('media-planner')) {
      return 'Media Plans';
    } else if (route.includes('subscription-plan')) {
      return 'Subscription Plan';
    } else if (route.includes('dashboard')) {
      return `Welcome, ${self.userName()}`;
    } else if (route.includes('start-first-campaign')) {
      return `Welcome, ${self.userName()}`;
    } else if (route.includes('new-campaign')) {
      return 'Campaign name';
    } else if (route.includes('offers')) {
      return 'My Campaigns';
    } else if (route.includes('reports')) {
      return 'Reports';
    } else if (route.includes('campaign-management')) {
      return 'Campaign Management';
    } else if (route.includes('creative-spec')) {
      return 'Creative Specs';
    }
    return '';
  }

  get pageDescription(): string {
    const self = this;
    const route = self.router.url;

    if (route.includes('subscription-plan')) {
      return 'Choose your subscription plan or buy credits.';
    } else if (route.includes('location')) {
      return 'Set up the location you want to find audience around.';
    } else if (route.includes('invoices')) {
      return 'Here are listed all the invoices that have been generated for your account, these are available for viewing and downloading in PDF format.';
    } else if (route.includes('user-details')) {
      return 'Here you can manage your account basics and change your password.';
    } else if (route.includes('dashboard')) {
      return 'Flow City makes out of home as easy to plan and buy as online.';
    } else if (route.includes('new-campaign')) {
      return 'It\'s easy to start your campaign with Flow City. It takes only 3 steps.';
    } else if (route.includes('start-first-campaign')) {
      return 'It\'s easy to start your campaign with Flow City. It takes only 3 steps.';
    } else if (route.includes('offers')) {
      return 'Here, you have all the campaigns you created and their status.';
    } else if (route.includes('reports')) {
      return 'Here is Real-time progress of your campaigns.';
    } else if (route.includes('campaign-management')) {
      return 'Manage your campaigns.';
    } else if (route.includes('creative-spec')) {
      return 'Specifications and guidelines for running digital ads via Flow City platform.';
    }
    return '';
  }

  get sessionData(): SessionData {
    const sessionData = this.coreService.api.sessionData;
    return sessionData ? sessionData : null;
  }

  offerEditionMode(): boolean {
    const self = this;
    const url = self.router.url;
    const nums = url.match(/\d+/);
    const hasCurrentOffer = self.offerService.currentOffer;
    return url.includes('new-campaign') || url.includes('offers') || url.includes('media-planner') && nums && nums[0].length && hasCurrentOffer;
  }

  reportsPage(): boolean {
    const url = this.router.url;
    return url.includes('reports');
  }

  creatingOffer(): boolean {
    const self = this;
    const url = self.router.url;

    return url.includes('new-campaign');
  }

  get mediaPlanScreen(): boolean {
    return this.router.url.includes('media-planner');
  }

  get campaignScreen(): boolean {
    return this.router.url.includes('offers') || this.router.url.includes('new-campaign');
  }

  get onboarding() {
    const url = this.router.url;
    return url.includes('start-first-campaign');
  }

  private userName(): string {
    if (!this.sessionData) {
      return '';
    }
    return this.sessionData.user.firstname ? this.sessionData.user.firstname : '';
  }

  startNewCampaign() {
    this.modalService.open(SelectImpressionModelComponent, {size: 'lg'});
  }

  saveOffer(): void {
    this.offerService.saveOffer()
      .subscribe();
  }
}
