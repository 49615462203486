import {Component, Inject, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../services/authentication/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {

  loginError = null;
  wrongFacebook = false;

  loginPending = false;

  loginBody = {
    email: '',
    password: ''
  };

  passwordInput = 'password';

  constructor(
    @Inject(AuthenticationService) public service,
  ) { }

  ngOnInit() {
  }

  get showHide(): string {
    return this.passwordInput === 'password' ? 'Show' : 'Hide';
  }

  showHidePassword(): void {
    this.passwordInput = this.passwordInput === 'password' ? 'text' : 'password';
  }

  requestLogin() {
    this.loginError = null;
    this.loginPending = true;
    this.service.requestLogin(JSON.stringify(this.loginBody)).subscribe(
      () => {},
      err => {
        this.loginError = err.error.reason;
        this.loginPending = false;
      }
    );
  }

}
