import { Component, OnInit } from '@angular/core';
import { HachetteService } from '../hachette.service';

@Component({
  selector: 'app-book-selector',
  templateUrl: './book-selector.component.html',
  styleUrls: ['./book-selector.component.sass']
})
export class BookSelectorComponent implements OnInit {

  books = null;
  processing = false;

  constructor(
    private hachetteService: HachetteService
  ) { }

  ngOnInit() {
    this.getBooks();
  }

  getBooks() {
    this.books = null;
    this.processing = true;
    this.hachetteService.getBooks()
      .subscribe(
        (data) => {
          console.log(data);
          this.books = data;
          this.processing = false;
        }
      );
  }

  selectBook(isbn) {
    this.hachetteService.selectedBook.next(isbn);
  }

}
