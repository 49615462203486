// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  envName: 'STAGING',
  apiUrl: 'https://test-api.flow.city',
  appUrl: 'https://testapp.flow.city',
  saasAppUrl: 'https://test-saas.flow.city',
  storageUrl: 'https://storage.googleapis.com',
  defaultBucket: 'flow-ads-test.appspot.com',
  appEnv: 'dev',
  sessionServiceUrl: 'https://test-auth.flow.city/',
  sessionMonitorServiceUrl: 'https://test-auth.flow.city/',
  creditsMonitorServiceUrl: 'https://credits-monitor-service-dot-flow-ads-test.appspot.com',
  offersServiceUrl: 'https://test-offer.flow.city',
  utilsServiceUrl: 'https://test-sql.flow.city/flowcity-utils',
  sqlServiceUrl:  'https://test-sql.flow.city/flowcity-sql',
  displaysServiceUrl: 'https://test-display.flow.city',
  reportsServiceUrl: 'https://test-report.flow.city',
  enableDebug: true,
  geocode: 'https://maps.googleapis.com/maps/api/geocode/json',
  mapsApiKey: 'AIzaSyCFvujdonUFvzqhHygallawnLompguuRqg',
  stripeKey: 'pk_test_61Fe73g3g66olX1lgVi3Sa1m',
  fontsApiKey: 'AIzaSyBuGLemH6gMcgieBpwF7rcUFytofCF6tJY',
  darkSkyApiKey: '7b2981f68806aca8e213be5d99cca9a3',
  helpCrunch: {
    organization: 'flowcity',
    applicationId: 3851,
    applicationSecret: 'y7AtoHOnGHvS7z0UyitvPo4bYLlLEqmBFh0xC9N+l5TjKOgKyTNi6xLIUYfVB4jUPEa5xQXH5pGTq+/UiJXQbg==',
  },
  zooplaApiKey: 'hmvyggua9rbdwv3y8d3bvtug',
  reCaptchaV3SiteKey: '6LdcuOkUAAAAAGq0JFp5JOHIlXV9OBV5hXATJCj3',
  firebase: {
    apiKey: 'AIzaSyCbwnTi-AKYcp_cyW7nbpGshrZELYcMm8E',
    authDomain: 'flow-ads-test.firebaseapp.com',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
