import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ICampaignSchedulerBase, ICampaignScheduleTime } from './scheduler.model';
import {
  SCHEDULER_FIELDSET,
  TIMESCHEDULE_INPUT_DATE_FORMAT,
  TIMESCHEDULE_INPUT_TIME_FORMAT,
  TIMESCHEDULE_OUTPUT_DATE_FORMAT,
  TIMESCHEDULE_OUTPUT_TIME_FORMAT,
  TIMESCHEDULE_WEEKDAYS
} from './scheduler.const';
import * as moment from 'moment';
import { ScheduleModel } from '../../models/schedule.model';

const pick = (...props) => o => props.reduce((a, e) => ({...a, [e]: o[e]}), {});

@Injectable({
  providedIn: 'root'
})
export class SchedulerService {

  readonly _schedules$ = new BehaviorSubject<ICampaignSchedulerBase[]>(null);

  constructor() { }

  static getScheduleProps(schedule): ICampaignSchedulerBase {
    return schedule ? pick(...SCHEDULER_FIELDSET[schedule.type])(schedule) : null;
  }

  static getActualDaysBetweenDates(startDate, endDate, weekdays): number {
    let sumDays = 0;
    weekdays.forEach(d => {
      const occur = this.weekdaysBetween(startDate, endDate, d);
      sumDays = sumDays + occur;
    });
    return sumDays;
  }

  static weekdaysBetween(d1, d2, weekday): number {
    d1 = moment(d1);
    d2 = moment(d2);
    const daysToAdd = ((7 + weekday) - (d1.isoWeekday() - 1)) % 7;
    const next = d1.clone().add(daysToAdd, 'days');
    if (next.isAfter(d2)) {
        return 0;
    }
    const weeksBetween = d2.diff(next, 'weeks');
    return weeksBetween + 1;
  }

  static getWeekdaysDesc(weekdays): string {
    if (Object.keys(TIMESCHEDULE_WEEKDAYS).filter(x => weekdays.includes(parseInt(x, 10))).length === 7) {
      return '<b>everyday</b>';
    } else {
      return `on <b>${ weekdays.map(d => TIMESCHEDULE_WEEKDAYS[d]).join(', ') }</b>`;
    }
  }

  init(schedules: ScheduleModel[]): void {
    console.log('Initializing schedules - count: ', schedules.length);
    this._schedules$.next(schedules as ICampaignSchedulerBase[]);
  }

  get all(): ICampaignSchedulerBase[] {
    return this._schedules$.getValue().map(schedule => SchedulerService.getScheduleProps(schedule));
  }

  get(type = 'DbOfferScheduleTime'): ICampaignSchedulerBase {
    const schedule = this._schedules$.getValue().find(s => s.type === type);
    return schedule ? SchedulerService.getScheduleProps(schedule) : null;
  }

  valid(type = 'DbOfferScheduleTime'): boolean {
    const timeSchedule = this.get('DbOfferScheduleTime') as ICampaignScheduleTime;
    return timeSchedule.active ? moment() <= moment(timeSchedule.schedule_stop) : true;
  }

  get description(): string {
    let output = '';

    // Time Schedule description
    const sch = this.get('DbOfferScheduleTime') as ICampaignScheduleTime;
    if (sch) {
      if (!sch.active) {
        output = 'This offer will play <b>now</b>';
      } else {
        const scheduleStart = moment(sch.schedule_start, TIMESCHEDULE_INPUT_DATE_FORMAT).format(TIMESCHEDULE_OUTPUT_DATE_FORMAT);
        const scheduleStop = moment(sch.schedule_stop, TIMESCHEDULE_INPUT_DATE_FORMAT).format(TIMESCHEDULE_OUTPUT_DATE_FORMAT);
        const weekdaysDesc = SchedulerService.getWeekdaysDesc(sch.weekdays);
        const duration = SchedulerService.getActualDaysBetweenDates(sch.schedule_start, sch.schedule_stop, sch.weekdays);
        output += `It will run for <b>${duration} day${duration > 1 ? 's' : ''}</b> ${weekdaysDesc} from <b>${scheduleStart}</b> till <b>${scheduleStop}</b>`;
        if (!sch.all_day) {
          const timeStart = moment(sch.time_start, TIMESCHEDULE_INPUT_TIME_FORMAT).format(TIMESCHEDULE_OUTPUT_TIME_FORMAT);
          const timeStop = moment(sch.time_stop, TIMESCHEDULE_INPUT_TIME_FORMAT).format(TIMESCHEDULE_OUTPUT_TIME_FORMAT);
          output += ` from <b>${timeStart}</b> till <b>${timeStop}</b>`;
        } else {
          output += ' all day long';
        }
      }
    }
    return `${output}.`;
  }

  reset(): void {
    this._schedules$.next(null);
  }

}
