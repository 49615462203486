<mat-tab-group dynamicHeight [disableRipple]="true">
  <mat-tab class="with-checkbox">
    <app-date-time *ngIf="service.scheduleTime">
      Loading...
    </app-date-time>
    <ng-template mat-tab-label>
      <mat-icon
        class="example-tab-icon"
        [class.active-scheduler]="isDateActive()">
        <fa-icon [icon]="['far', 'check-circle']"></fa-icon>
      </mat-icon>
      Date and Time
    </ng-template>
  </mat-tab>


  <mat-tab class="with-checkbox"
           *appHasProfile="'ecommerce'"
           [disabled]="!canEditParameters()">
    <app-stock-level></app-stock-level>
    <ng-template mat-tab-label>
      <mat-icon
        class="example-tab-icon"
        [class.active-scheduler]="isStockLevelActive()">
        <fa-icon [icon]="['far', 'check-circle']"></fa-icon>
      </mat-icon>
      Stock Level
    </ng-template>
  </mat-tab>

  <mat-tab class="with-checkbox" [disabled]="!isAvailable || !canEditParameters()">
    <app-weather>
      Weather
    </app-weather>
    <ng-template mat-tab-label>
      <mat-icon
        class="example-tab-icon"
        [class.active-scheduler]="isWeatherActive()">
        <fa-icon [icon]="['far', 'check-circle']"></fa-icon>
      </mat-icon>
      Weather
    </ng-template>
  </mat-tab>

  <mat-tab class="with-checkbox" [disabled]="!isAvailable || !canEditParameters()">
    <app-transport>
      Public Transport
    </app-transport>
    <ng-template mat-tab-label>
      <mat-icon
        class="example-tab-icon"
        [class.active-scheduler]="isTransportActive()">
        <fa-icon [icon]="['far', 'check-circle']"></fa-icon>
      </mat-icon>
      Public Transport
    </ng-template>
  </mat-tab>

  <mat-tab class="with-checkbox" [disabled]="!isAvailable || !canEditParameters()">
    <app-demographics>
      Loading...
    </app-demographics>
    <ng-template mat-tab-label>
      <mat-icon
        class="example-tab-icon"
        [class.active-scheduler]="isDemographicsActive()">
        <fa-icon [icon]="['far', 'check-circle']"></fa-icon>
      </mat-icon>
      Demographics
    </ng-template>
  </mat-tab>

  <mat-tab [disabled]="true">
    <app-pollen>
      Pollen Index
    </app-pollen>
    <ng-template mat-tab-label>
      <mat-icon
        class="example-tab-icon"
      >
        <fa-icon [icon]="['far', 'check-circle']"></fa-icon>
      </mat-icon>
      Pollen Index
    </ng-template>
  </mat-tab>

  <mat-tab [disabled]="true">
    <app-sensors>
      Sensors
    </app-sensors>
    <ng-template mat-tab-label>
      <mat-icon
        class="example-tab-icon"
      >
        <fa-icon [icon]="['far', 'check-circle']"></fa-icon>
      </mat-icon>
      Sensors
    </ng-template>
  </mat-tab>

  <mat-tab [disabled]="true">
    <app-volumetric>
      Volumetrics
    </app-volumetric>
    <ng-template mat-tab-label>
      <mat-icon
        class="example-tab-icon"
      >
        <fa-icon [icon]="['far', 'check-circle']"></fa-icon>
      </mat-icon>
      Volumetrics
    </ng-template>
  </mat-tab>

  <mat-tab [disabled]="true">
    <app-epos>
      ePos
    </app-epos>
    <ng-template mat-tab-label>
      <mat-icon
        class="example-tab-icon"
      >
        <fa-icon [icon]="['far', 'check-circle']"></fa-icon>
      </mat-icon>
      ePos
    </ng-template>
  </mat-tab>
</mat-tab-group>

<!--<div class="schedule-navigation">-->
  <!--<div (click)="scrollLeft()" [style.display]="leftArrow()" id="scroll-left" class="arrow-navigation" >&lt;</div>-->
  <!--<div (click)="scrollRight()" [style.display]="rightArrow()" id="scroll-right" class="arrow-navigation" style="right: 0;">&gt;</div>-->
  <!--<ul #schedulesNavigation [style.marginLeft.px]="navMarginLeft" class="nav nav-tabs scheduler-tabs" >-->
    <!--<li routerLinkActive="active" class="with-checkbox" [class.active-scheduler]="isDateActive()">-->
      <!--<a routerLink="date_time">-->
        <!--<i class="fa fa-check-circle"></i> Date and Time-->
      <!--</a>-->
    <!--</li>-->
    <!--<li routerLinkActive="active" class="with-checkbox" [class.active-scheduler]="isWeatherActive()">-->
      <!--<a routerLink="weather">-->
        <!--<i class="fa fa-check-circle"></i> Weather-->
      <!--</a>-->
    <!--</li>-->
    <!--<li routerLinkActive="active" class="with-checkbox" [class.active-scheduler]="isTransportActive()">-->
      <!--<a routerLink="transport">-->
        <!--<i class="fa fa-check-circle"></i> Public Transport-->
      <!--</a>-->
    <!--</li>-->
    <!--<li routerLinkActive="active" class="with-checkbox" [class.active-scheduler]="isDemographicsActive()">-->
      <!--<a routerLink="demographics">-->
        <!--<i class="fa fa-check-circle"></i> Demographics-->
      <!--</a>-->
    <!--</li>-->
    <!--<li routerLinkActive="active" class="with-checkbox" style="background: gray; pointer-events: none;">-->
      <!--<a routerLink="pollen">-->
        <!--<i class="fa fa-check-circle"></i> Pollen Index-->
      <!--</a>-->
    <!--</li>-->
    <!--<li routerLinkActive="active" class="with-checkbox" style="background: gray; pointer-events: none;">-->
      <!--<a routerLink="sensors">-->
        <!--<i class="fa fa-check-circle"></i> Sensors-->
      <!--</a>-->
    <!--</li>-->
    <!--<li routerLinkActive="active" class="with-checkbox" style="background: gray; pointer-events: none;">-->
      <!--<a routerLink="volumetrics">-->
        <!--<i class="fa fa-check-circle"></i> Volumetrics-->
      <!--</a>-->
    <!--</li>-->
    <!--<li routerLinkActive="active" class="with-checkbox" style="background: gray; pointer-events: none;">-->
      <!--<a routerLink="epos">-->
        <!--<i class="fa fa-check-circle"></i> ePos-->
      <!--</a>-->
    <!--</li>-->
  <!--</ul>-->
<!--</div>-->


<!--<div class="schedules tab-content">-->
  <!--<router-outlet style="display:  block;"></router-outlet>-->
<!--</div>-->
<!--&lt;!&ndash;<div class="schedules tab-content">&ndash;&gt;-->
  <!--&lt;!&ndash;<div class="offer-schedules-loading-mask"></div>&ndash;&gt;-->
<!--&lt;!&ndash;</div>&ndash;&gt;-->
<!--&lt;!&ndash;<div class="user-profile">&ndash;&gt;-->
  <!--&lt;!&ndash;<div id="profile-menu">&ndash;&gt;-->
    <!--&lt;!&ndash;<ul class="top-profile-menu" style="margin-bottom: -3px;">&ndash;&gt;-->
      <!--&lt;!&ndash;<li routerLinkActive="active"><a routerLink="/profile/user_details">User details</a></li>&ndash;&gt;-->
      <!--&lt;!&ndash;<li routerLinkActive="active"><a routerLink="/profile/location">Location settings</a></li>&ndash;&gt;-->
      <!--&lt;!&ndash;<li routerLinkActive="active"><a routerLink="/profile/billing_info">Billing info</a></li>&ndash;&gt;-->
      <!--&lt;!&ndash;<li routerLinkActive="active"><a routerLink="/profile/subscription_plan">Subscription Plan</a></li>&ndash;&gt;-->
      <!--&lt;!&ndash;&lt;!&ndash;<li *ngIf="payable" routerLinkActive="active"><a routerLink="/profile/subscription_plan">Subscription Plan</a></li>&ndash;&gt;&ndash;&gt;-->
      <!--&lt;!&ndash;&lt;!&ndash;<li routerLinkActive="active"><a routerLink="/profile/media_plans">Media Plans</a></li>&ndash;&gt;&ndash;&gt;-->
      <!--&lt;!&ndash;<li routerLinkActive="active"><a routerLink="/profile/invoices">Invoices</a></li>&ndash;&gt;-->
    <!--&lt;!&ndash;</ul>&ndash;&gt;-->
  <!--&lt;!&ndash;</div>&ndash;&gt;-->
  <!--&lt;!&ndash;<div class="form-panel profile-form row no-background">&ndash;&gt;-->
    <!--&lt;!&ndash;<router-outlet></router-outlet>&ndash;&gt;-->
  <!--&lt;!&ndash;</div>&ndash;&gt;-->
<!--&lt;!&ndash;</div>&ndash;&gt;-->

<!--&lt;!&ndash;Type template name or choose it from a list&ndash;&gt;-->

<!--&lt;!&ndash;<div class="row"></div>&ndash;&gt;-->

<!--&lt;!&ndash;<div class="dropdown">&ndash;&gt;-->
  <!--&lt;!&ndash;<div class="btn btn-primary dropdown-toggle upload-template-button disabled" data-toggle="dropdown" aria-expanded="false" >&ndash;&gt;-->
    <!--&lt;!&ndash;<p>Start typing...</p>&ndash;&gt;-->
    <!--&lt;!&ndash;<div>&ndash;&gt;-->
      <!--&lt;!&ndash;<span class="fa fa-angle-down"></span>&ndash;&gt;-->
    <!--&lt;!&ndash;</div>&ndash;&gt;-->

  <!--&lt;!&ndash;</div>&ndash;&gt;-->
  <!--&lt;!&ndash;<ul class="dropdown-menu upload-template-dropdown" >&ndash;&gt;-->
    <!--&lt;!&ndash;&lt;!&ndash;<li><a href="#">Lorem </a></li>&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;&lt;!&ndash;<li><a href="#">ipsum </a></li>&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;&lt;!&ndash;<li><a href="#">dolor </a></li>&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;&lt;!&ndash;<li><a href="#">sit </a></li>&ndash;&gt;&ndash;&gt;-->
  <!--&lt;!&ndash;</ul>&ndash;&gt;-->
<!--&lt;!&ndash;</div>&ndash;&gt;-->
