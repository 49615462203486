<div id="login-page">
  <div class="container">

    <form class="form-login">
      <div class="login-wrap">
        <div class="logo">Flow City</div>
        <p class="see-again-header">Nice to see you again!</p>

        <p *ngIf="loginError" class="login-error">{{ loginError }}</p>
        <p *ngIf="wrongFacebook" class="login-error">Wrong Facebook account</p>

          <div class="login-form-body" [class.processing]="loginPending">

            <input [(ngModel)]="loginBody.email"
                   [ngModelOptions]="{standalone: true}"
                   type="email"
                   class="form-control"
                   placeholder='Your e-mail'
                   autofocus>
            <br>
            <input [(ngModel)]="loginBody.password"
                   [type]="passwordInput"
                   [ngModelOptions]="{standalone: true}"
                   class="form-control"
                   placeholder='Password'/>
            <div>
              <span style="float: left;">
                <a routerLink="/forgot-password">Forgot Password?</a>
              </span>
              <span (click)="showHidePassword()" class="show-password" style="float: right;">
                {{showHide}} Password
              </span>
            </div>
            <br>
            <button (click)="requestLogin()" class="btn btn-block" type="submit">
              LOGIN
            </button>
            <span class="text-center" style="padding: 15px 15px 0 15px; display: block; cursor: auto;">
                New to Flow City? <a routerLink="/signup"><b>Sign up here</b></a>
            </span>
            <div class="loading not-masked" *ngIf="loginPending"></div>
        </div>
      </div>
    </form>
  </div>
</div>

<div id="login-background"></div>
