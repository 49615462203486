import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-image-chooser',
  templateUrl: './image-chooser.component.html',
  styleUrls: ['./image-chooser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageChooserComponent implements OnInit {

  @Input() images: any;
  @Input() selectedImageId: any;
  @Output() selected = new EventEmitter<number>();

  currentId: number;

  constructor() {
     this.currentId = this.selectedImageId || 0;
  }

  ngOnInit(): void {
  }

  onSelect(idx): void {
    this.currentId = idx;
    this.selected.emit(idx);
  }

  trackFn(index, item): number {
    return item.desc;
  }

}
