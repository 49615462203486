import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { delay, filter, tap } from 'rxjs/operators';
import { FormatsService } from '../services/formats/formats.service';
import { LoadingService } from '@shared/services/loading.service';


@Injectable()
export class FormatsResolver implements Resolve<any> {
  constructor(
    private formatService: FormatsService,
    private loadingService: LoadingService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    this.loadingService.loading = true;
    return this.formatService.getAllFormats()
      .pipe(
        tap(_ => this.loadingService.loading = false)
      );
  }
}

