<div class="icon-item"
     [class.selected]="selected"
     [class.disabled]="disabled"
     (click)="onClick(data)">

    <div class="af_icon"
         [style.border-color]="unselected"
         [style.color]="unselected"
         [ngbTooltip]="tooltipContent"
         container="body"
         [placement]="'top'">
      <div class="circular-progress"
                 [attr.data-percent]="progressPercent + '%'"
                 [ngStyle]="progressStyle"></div>
      <div class="inlineSVG">
        <img *ngIf="iconSrc" [src]="iconSrc" [alt]="description" style="width: 68%;">
      </div>

      <div class="percent-info">
        <div class="percent-info-content" *ngIf="percentTemplate">
          <ng-template class="test" [ngTemplateOutlet]="percentTemplate"></ng-template>
        </div>
      </div>

    </div>

    <div class="af_description">

      <ng-container *ngIf="descriptionTemplate; else DEFAULT_DESCRIPTION">
        <ng-template [ngTemplateOutlet]="descriptionTemplate"></ng-template>
      </ng-container>

      <ng-template #DEFAULT_DESCRIPTION>
        <h2><span [innerHTML]="description"></span></h2>
        <h1 class="no-margin count" [innerHTML]="summary"></h1>
      </ng-template>

      <ng-container *ngIf="summaryTemplate">
        <ng-template [ngTemplateOutlet]="summaryTemplate"></ng-template>
      </ng-container>

    </div>

</div>
