import { Component, OnInit, EventEmitter, Output, Input, ContentChild, TemplateRef } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {
  FCIconItemDescriptionDirective,
  FCIconItemPercentDirective,
  FCIconItemSummaryDirective,
  FCIconItemTooltipDirective
} from '../icon-item.directives';
import { IconItemService } from '../icon-item.service';

const BASE_ICON_PATH = 'https://cdn.flow.city';

@Component({
  selector: 'app-icon-item',
  templateUrl: './icon-item.component.html',
  styleUrls: ['./icon-item.component.scss']
})
export class IconItemComponent implements OnInit {

  @Input() data: any;
  @Input() selected: any;
  @Input() disabled: any;
  @Input() unselected: any;
  @Input() description: string;
  @Input() summary: string;
  @Input() icon: any;
  @Input() genericIcon: string;
  @Input() progressValue: number;
  @Input() tooltip: string;

  @Output() clicked = new EventEmitter();

  @ContentChild(FCIconItemSummaryDirective, { read: TemplateRef, static: true }) summaryTemplate: TemplateRef<any>;
  @ContentChild(FCIconItemDescriptionDirective, { read: TemplateRef, static: true }) descriptionTemplate: TemplateRef<any>;
  @ContentChild(FCIconItemTooltipDirective, { read: TemplateRef, static: true }) tooltipTemplate: TemplateRef<any>;
  @ContentChild(FCIconItemPercentDirective, { read: TemplateRef, static: true }) percentTemplate: TemplateRef<any>;

  iconSrc: SafeUrl = null;

  constructor(
    private service: IconItemService,
    private domSanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.getIcon();
  }

  public onClick($event): void {
    this.clicked.emit($event);
  }

  public getIcon(): void {
    const path = `${BASE_ICON_PATH}/${this.icon}.svg`;
    const genericIcon = `${BASE_ICON_PATH}/${this.genericIcon}`;
    if (this.service.fromCache(this.icon)) {
      this.iconSrc = this.service.fromCache(this.icon);
    } else {
      this.service.getIcon(path, genericIcon)
        .subscribe((response: any) => {
          const reader = new FileReader();
          reader.readAsDataURL(response.body);
          reader.onloadend = () => {
            const base64data = reader.result;
            this.iconSrc = this.domSanitizer.bypassSecurityTrustUrl(base64data.toString());
            this.service.toCache(this.icon, this.iconSrc);
          };
        });
    }
  }

  get progressStyle(): any {
    let bg;
    const percent = this.progressPercent;
    if (percent < 50) {
      const deg = 90 + (3.6 * percent);
      bg = `linear-gradient(90deg, #ffffff 50%, transparent 50%, transparent), linear-gradient(${deg}deg, #03F7FF 50%, #ffffff 50%, #ffffff)`;
    } else {
      const deg = -90 + (3.6 * (percent - 50));
      bg = `linear-gradient(${deg}deg, #03F7FF 50%, transparent 50%, transparent), linear-gradient(270deg, #03F7FF 50%, #ffffff 50%, #ffffff)`;

    }

    return {background: bg};
  }

  public get progressPercent(): number {
    if (this.progressValue && this.progressValue <= 100) {
      return this.progressValue;
    } else if (this.progressValue > 100) {
      return 100;
    } else {
      return 0;
    }
  }

  get tooltipContent(): TemplateRef<any> | string {
    return this.tooltipTemplate || this.tooltip;
  }

}
