<div class="location-block"
     [class.editor]="editor">
  <div class="spot"
       [class.thumb-map]="!editor"
       [style.background-image]="!editor ? 'url(' + data?.locationMap + ')' : null"
       (click)="toggleMode()">
    <ng-container *ngIf="editor">
      <div id="map"></div>
      <div class="search-box">
        <input #input id="pac-input" class="form-control" type="text" placeholder="Enter a location" />
        <div class="mt-2"><strong>Radius: </strong>{{ data.radius }} miles</div>
      </div>
      <div class="button-box">
        <button class="btn" (click)="close(true, $event)">Confirm</button>
        <button class="btn btn-close" (click)="close(false, $event)">&times;</button>
      </div>
    </ng-container>
  </div>
  <div class="title">{{ data.postalCode }}</div>

  <div class="custom-slider">
    <app-range-step-slider
        [value]="data.radius"
        [min]="0"
        [max]="100"
        [steps]="[0.1, 1, 1]"
        [thumbPointerSubline]="'miles'"
        [numPrecision]="2"
        (change)="onRadiusChange($event)">
    </app-range-step-slider>
  </div>
</div>


<div class="mask"
     *ngIf="editor"
     (click)="toggleMode()">
</div>

