import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {SessionService} from '../../services/session/session.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

const SIGNIN_URI = '/data/login';
const LOCATION_URI = '/api/location';
const RETRY_COUNT = 4;

@Injectable()
export class RetryOnErrorInterceptor implements HttpInterceptor {

  constructor(
    protected http: HttpClient,
    protected sessionService: SessionService,
    protected toastr: ToastrService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error) => {

        if (error.status === 401) {
          // check if the error occurred on sign-in
          if (error.url.indexOf(SIGNIN_URI) === -1 && error.url.indexOf(LOCATION_URI) === -1) {
            this.handleUnauthorizedRequest(error);
          } else {
            // this is initial sign-in error - skip error handling
            return throwError(error);
          }
        }

        if (error.status === 400) {
          this.toastr.error(
            error.error.reason,
            'Error',
            {
              timeOut: 10000,
              closeButton: true,
            }
          );
          return throwError(error);
        }

        if (error.status === 404 || error.status === 409) {
          return throwError(error);
        }

        const newReq = request.clone();
        return next.handle(newReq);
          // .pipe(
          //   shareReplay(),
          //   retryWhen(errors => {
          //     return errors
          //       .pipe(
          //         tap(() => console.log(`retrying HTTP request: ${request.url}`)),
          //         take(RETRY_COUNT),
          //         concat(next.handle(newReq))
          //       );
          //   })
          // );
      }));
  }

  private handleUnauthorizedRequest(err: HttpErrorResponse): Observable<any> {
        if (err.status === 401 || err.status === 403) {
            this.sessionService.forceRemoveSession();
            return of(err.message);
        }
        // handle your auth error or rethrow
        return throwError(err);
    }
}
