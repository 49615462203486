<div class="row" style="height: 80%">
  <div class="col-8 generator-output">
    <app-artwork-preview
      [previewSrc]="cover"
      [fileType]="'image'"
      [chosenFormat]="formatLandscape"
      [uploadButton]="false">
    </app-artwork-preview>
    <div class="zoom-zoom"
         (click)="previewArtwork(cover)">
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12 text-center">
    <button class="btn btn-back" (click)="back()"><i class="fa fa-angle-left" aria-hidden="true"></i> Change book</button>
    &nbsp;
    <button class="btn btn-back" (click)="cancel()">Cancel</button>
    &nbsp;
    <button class="btn" (click)="saveArtwork()">Use artwork</button>
  </div>
</div>

<div class="loading" *ngIf="processing"></div>