import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {CustomValidators} from '../../../additional/validators/custom.validators';
import {BillingInfoModel} from '../../../models/session-init/billing-info/billing-info.model';
import {ProfileService} from '../../../services/profile/profile.service';
import {Subject} from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';


@Component({
  selector: 'app-billing-info-form',
  templateUrl: './billing-info-form.component.html',
  styleUrls: ['./billing-info-form.component.sass']
})
export class BillingInfoFormComponent implements OnInit {

  // @Input() billingInfo;
  @Output() isValid = new EventEmitter();
  @Output() onFormReady = new EventEmitter();
  billingInfoForm: FormGroup;

  billingInfo: any;
  phoneNumberChanged: Subject<string> = new Subject<string>();

  validation_messages = {
    'company_name': [
      { type: 'whitespace', message: 'Please enter company name.' }
    ],
    'first_name': [
      { type: 'whitespace', message: 'Please enter your first name.' }
    ],
    'last_name': [
      { type: 'whitespace', message: 'Please enter your last name.' }
    ],
    'billing_taxid': [
      { type: 'whitespace', message: 'Please enter your TAX ID.' }
    ],
    'billing_address1': [
      { type: 'whitespace', message: 'Please enter your address.' }
    ],
    'billing_postcode': [
      { type: 'whitespace', message: 'Please enter your post code.' }
    ],
    'billing_town': [
      { type: 'whitespace', message: 'Please enter your city.' }
    ]
  };

  constructor(
    private fb: FormBuilder,
    private profileService: ProfileService
  ) {
    this.phoneNumberChanged
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
      )
      .subscribe(() => {
        this.updateFormData();
      });
  }

  ngOnInit() {

    this.profileService.billingInfoData.subscribe(
      (data) => this.billingInfo = data
    );

    this.billingInfoForm = this.fb.group({

      company_name: new FormControl(this.billingInfo ? this.billingInfo.company_name : '', {
        validators: [CustomValidators.NoWhitespace()]
      }),

      first_name: new FormControl(this.billingInfo ? this.billingInfo.first_name : '', {
        validators: [CustomValidators.NoWhitespace()]
      }),

      last_name: new FormControl(this.billingInfo ? this.billingInfo.last_name : '', {
        validators: [CustomValidators.NoWhitespace()]
      }),

      billing_postcode: new FormControl(this.billingInfo ? this.billingInfo.billing_postcode : '', {
        validators: [CustomValidators.NoWhitespace()]
      }),

      billing_taxid: new FormControl(this.billingInfo ? this.billingInfo.billing_taxid : '', {
        validators: [CustomValidators.NoWhitespace()]
      }),

      billing_address1: new FormControl(this.billingInfo ? this.billingInfo.billing_address1 : '', {
        validators: [CustomValidators.NoWhitespace()]
      }),

      billing_address2: [this.billingInfo ? this.billingInfo.billing_address2 : ''],

      billing_town: new FormControl(this.billingInfo ? this.billingInfo.billing_town : '', {
        validators: [CustomValidators.NoWhitespace()]
      })
    });

    this.billingInfoForm.valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
      )
      .subscribe(() => this.updateFormData());

    // initial check
    this.isValid.emit(this.billingInfoForm.valid);
  }

  private updateFormData() {
    this.isValid.emit(this.billingInfoForm.valid);
      console.log(this.billingInfoForm.value);
      if (this.billingInfoForm.valid) {
        this.onFormReady.emit({...this.billingInfo, ...this.billingInfoForm.value});
      }
  }

  updatePhone(value) {
    this.billingInfo.phone = value;
    this.phoneNumberChanged.next(value);
  }


}
