import { AbstractControl, ValidatorFn } from '@angular/forms';

export class CustomValidators {

  static NoWhitespace(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : {'whitespace': 'value is only whitespace'};
    };
  }

}
