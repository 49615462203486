<ng-container *ngIf="target">
  <div class="status-label {{ target.status }}"
       [title]="target.audience_name + ' - ' + target.status">
    {{ target.status }}
  </div>
  <div class="status-circle {{ target.status }}"
       [title]="target.audience_name + ' - ' + target.status">
      <ng-container>
        <div class="inlineSVG" [inlineSVG]="'/assets/images/icon-offer-status-' + target.status + '.svg'"></div>
      </ng-container>
  </div>
</ng-container>
