<div class="search-bar-container">

  <div class="search-bar-name">
    <fa-icon [icon]="faSearch" class="side-magnifier"></fa-icon>
    <input (change)="searchQuery($event)"
           type="text"
           id="app-search-query"
           class="form-control"
           placeholder="Type campaign name to search...">
  </div>

  <div>
    <ng-select
      class="fc"
      [items]="statuses"
      [selectOnTab]="true"
      [searchable]="false"
      [ngModel]="filteredStatus"
      bindValue="id"
      bindLabel="name"
      [clearable]="false"
      (change)="filterBy($event)"
      placeholder="Filter by status...">
      <ng-template ng-label-tmp let-item="item">
        <b class="pull-left" style="line-height: 22px;">Status:&nbsp;&nbsp;</b>
        <span class="status-icon {{ item.id }}"></span>
        <span style="line-height: 22px;">{{item.name}}</span>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <span class="status-icon {{ item.id }}"></span>
        <span>{{item.name}}</span>
      </ng-template>
    </ng-select>
  </div>

  <div class="search-bar-direction">
    <ng-select
      class="fc w-100"
      [items]="order"
      [ngModel]="selected"
      [selectOnTab]="true"
      [searchable]="false"
      bindValue="sort"
      bindLabel="name"
      [clearable]="false"
      placeholder="Order"
      groupBy='group'
      (change)="orderBy($event)">
      <ng-template ng-label-tmp let-item="item">
        <span class="fa fa-arrow-{{item.arrow}}"></span>
        <b>{{item.name}}</b>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <span class="fa fa-arrow-{{item.arrow}}"></span>
        <span>{{item.name}}</span>
      </ng-template>
      <ng-template ng-optgroup-tmp let-item="item" let-index="index">
        Order campaigns by:
      </ng-template>
    </ng-select>

  </div>
</div>

<div class="offers-tiles"
     infiniteScroll
     [infiniteScrollDistance]="2"
     [infiniteScrollThrottle]="50"
     [scrollWindow]="false"
     (scrolled)="loadMore()">

  <div *ngIf="!offers || !offers.length; else tiles" class="empty-list">
    <div class="no-items empty-collection">
      <div *ngIf="!isLoading">No campaigns found</div>
    </div>
  </div>

  <ng-template #tiles>
    <app-tile
      *ngFor="let offer of offers"
      [offer]="offer"
      [formatsBlocks]="formatsBlocks"
      (selfDelete)="removeOffer(offer)"
      class="col-sm-4"
    ></app-tile>
  </ng-template>

  <div class="loading-more" *ngIf="isLoading">
    <div class="loading" style="display: block"></div>
  </div>

</div>
