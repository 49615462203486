// region BUILT-IN MODULES
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
// endregion

// region EXTERNAL MODULES

// endregion

// region COMPONENTS
import { LoadingComponent } from './simple/loading/loading.component';
import { BuyMoreCreditsComponent } from './modals/buy-more-credits/buy-more-credits.component';
import {DaterangePickerComponent} from './simple/daterange-picker/daterange-picker.component';
import {StripePaymentComponent} from './simple/stripe-payment/stripe-payment.component';
import {ArtworkPreviewComponent} from './simple/artwork-preview/artwork-preview.component';
// endregion

import {MainPipe} from '../pipes/main-pipe.module';
import { BillingInfoModalComponent } from './modals/billing-info/billing-info.component';
import { StripeModalComponent } from './modals/stripe-modal/stripe-modal.component';
import { UserInfoComponent } from './simple/user-info/user-info.component';
import { BillingInfoFormComponent } from './simple/billing-info-form/billing-info-form.component';
import { SvgIconComponent } from './simple/svg-icon/svg-icon.component';
import {InlineSVGModule} from 'ng-inline-svg';
import {SubmitIfValidDirective} from './directives/submit-if-valid.directive';
import {CheckPasswordDirective} from './directives/check-password.directive';
import {SaveOnBlurDirective} from './directives/save-on-blur.directive';
import {RangeStepSliderComponent} from './simple/range-step-slider/range-step-slider.component';
import {CurrencyMaskConfig, NgxCurrencyModule} from 'ngx-currency';

import { FcDatatableComponent } from './simple/fc-datatable/fc-datatable.component';
import { MatSliderModule } from '@angular/material/slider';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxDateRangePickerModule } from 'ngx-daterangepicker';
import { DateSortComponent } from '../additional/helpers/date-sort/date-sort.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { TemplateRefDirective } from './directives/template-ref.directive';
import { TemplateRefPipe } from './pipes/template-ref.pipe';
import { FlowcityTableComponent } from './components/flowcity-table/flowcity-table.component';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { HasProfileDirective } from './directives/has-profile.directive';
import { AppearDirective } from './directives/appear.directive';
import { HasSubscriptionDirective } from './directives/has-subscription.directive';
import { TrimOnKeyUpDirective } from './directives/trim-on-key-up.directive';
import { ArtworkConfirmationComponent } from './components/artwork-confirmation/artwork-confirmation.component';
import { BudgetGaugeComponent } from './components/budget-gauge/budget-gauge.component';
import { CampaignBudgetPreviewComponent } from './components/campaign-budget-preview/campaign-budget-preview.component';
import { CampaignCreatedInfoComponent } from './components/campaign-created-info/campaign-created-info.component';
import { CampaignPreviewComponent } from './components/campaign-preview/campaign-preview.component';
import { ScreenListComponent } from './components/screen-list/screen-list.component';
import { StatusIndicatorComponent } from './components/status-indicator/status-indicator.component';
import { CampaignPreviewPopupComponent } from './components/campaign-preview-popup/campaign-preview-popup.component';
import { RippleModule } from 'primeng/ripple';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { EstimatedDisplaysComponent } from './components/estimated-displays/estimated-displays.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SliderModule } from 'primeng/slider';
import { NgbButtonsModule, NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CampaignManagementService } from './services/campaign-management.service';
import { ArtworkPreviewPanelComponent } from './components/artwork-preview-panel/artwork-preview-panel.component';
import { GenerateQrcodeComponent } from './components/generate-qrcode/generate-qrcode.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { IconItemModule } from '../common/icon-item/icon-item.module';
import { AvailabilityGraphComponent } from '@shared/components/availability-graph/availability-graph.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { RangeSliderComponent } from './components/range-slider/range-slider.component';
import { SliderComponent } from './components/slider/slider.component';


export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'right',
  allowNegative: false,
  allowZero: true,
  decimal: ',',
  precision: 0,
  prefix: '',
  suffix: '',
  thousands: '',
  nullable: false,
};

@NgModule({
  declarations: [
    LoadingComponent,
    BuyMoreCreditsComponent,
    BillingInfoModalComponent,
    StripeModalComponent,
    DaterangePickerComponent,
    StripePaymentComponent,
    ArtworkPreviewComponent,
    UserInfoComponent,
    BillingInfoFormComponent,
    SvgIconComponent,
    SubmitIfValidDirective,
    CheckPasswordDirective,
    SaveOnBlurDirective,
    RangeStepSliderComponent,
    FcDatatableComponent,
    DateSortComponent,
    TemplateRefDirective,
    TemplateRefPipe,
    FlowcityTableComponent,
    HasProfileDirective,
    AppearDirective,
    HasSubscriptionDirective,
    TrimOnKeyUpDirective,
    ArtworkConfirmationComponent,
    BudgetGaugeComponent,
    CampaignBudgetPreviewComponent,
    CampaignCreatedInfoComponent,
    CampaignPreviewComponent,
    ScreenListComponent,
    StatusIndicatorComponent,
    CampaignPreviewPopupComponent,
    EstimatedDisplaysComponent,
    ArtworkPreviewPanelComponent,
    GenerateQrcodeComponent,
    FeedbackComponent,
    AvailabilityGraphComponent,
    ConfirmationComponent,
    RangeSliderComponent,
    SliderComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MainPipe,
    NgxDateRangePickerModule,
    InlineSVGModule.forRoot({baseUrl: '/'}),
    ReactiveFormsModule,
    MatSliderModule,
    NgxCurrencyModule,
    FontAwesomeModule,
    AngularMyDatePickerModule,
    TableModule,
    ButtonModule,
    RippleModule,
    InputTextModule,
    InputNumberModule,
    MatFormFieldModule,
    MatDatepickerModule,
    SliderModule,
    NgbTooltipModule,
    NgbDropdownModule,
    NgbAlertModule,
    IconItemModule,
    NgbButtonsModule,
  ],
  exports: [
    LoadingComponent,
    BuyMoreCreditsComponent,
    DaterangePickerComponent,
    StripePaymentComponent,
    ArtworkPreviewComponent,
    UserInfoComponent,
    StripeModalComponent,
    BillingInfoFormComponent,
    BillingInfoModalComponent,
    ReactiveFormsModule,
    SvgIconComponent,
    InlineSVGModule,
    SubmitIfValidDirective,
    CheckPasswordDirective,
    SaveOnBlurDirective,
    RangeStepSliderComponent,
    FcDatatableComponent,
    DateSortComponent,
    TemplateRefDirective,
    TemplateRefPipe,
    FlowcityTableComponent,
    HasProfileDirective,
    AppearDirective,
    HasSubscriptionDirective,
    TrimOnKeyUpDirective,
    ArtworkConfirmationComponent,
    BudgetGaugeComponent,
    CampaignBudgetPreviewComponent,
    CampaignCreatedInfoComponent,
    CampaignPreviewComponent,
    ScreenListComponent,
    StatusIndicatorComponent,
    CampaignPreviewPopupComponent,
    GenerateQrcodeComponent,
    EstimatedDisplaysComponent,
    ArtworkPreviewPanelComponent,
    FeedbackComponent,
    AvailabilityGraphComponent,
    ConfirmationComponent,
    RangeSliderComponent,
    SliderComponent,
  ],
  providers: [
    CampaignManagementService,
  ],
  entryComponents: [
    CampaignPreviewPopupComponent,
    ArtworkConfirmationComponent,
    GenerateQrcodeComponent,
    ConfirmationComponent,
  ]
})
export class SharedModule { }
