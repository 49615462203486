<div id="offerScheduleEPos" class="offer-schedule">

  <h5 class="text-left">I want to play my campaign when stock levels are:</h5>

  <div class="offer-schedule-content">
    <div class="row">
      <div class="col-sm-12 text-center">
        <div class="btn-group date-range-selector" data-toggle="buttons">
          <label id="offEPosStockLabel" class="btn btn-default btn-calendar">
            <input type="radio" name="stock_level" class="sky" autocomplete="off" value="">
            Off
          </label>
          <label id="lowEPosStockLabel" class="btn btn-default btn-calendar btn-low">
            <input type="radio" name="stock_level" class="sky" autocomplete="off" value="low">
            Low
          </label>
          <label id="mediumEPosStockLabel" class="btn btn-default btn-calendar btn-medium">
            <input type="radio" name="stock_level" class="sky" autocomplete="off" value="medium">
            Medium
          </label>
          <label id="highEPosStockLabel" class="btn btn-default btn-calendar btn-high">
            <input type="radio" name="stock_level" class="sky" autocomplete="off" value="high">
            High
          </label>
          <label id="lowerEPosStockLabel" class="btn btn-default btn-calendar ">
            <input type="radio" name="stock_level" class="sky" autocomplete="off" value="lower">
            <span>Lower than:</span>
            <input name="ePos" class="center-block in-button-window" autocomplete="off">
            Item(s)
          </label>
          <label id="higherEPosStockLabel" class="btn btn-default btn-calendar ">
            <input type="radio" name="stock_level" class="sky" autocomplete="off" value="higher">
            <span>Higher than:</span>
            <input name="ePos" class="center-block in-button-window" autocomplete="off">
            Item(s)
          </label>
        </div>
      </div>
    </div>
  </div>

  <h5 class="text-left">I want to play my campaign when shelf live is:</h5>

  <div class="offer-schedule-content">
    <div class="row">
      <div class="col-sm-12 text-center">
        <div class="btn-group date-range-selector" data-toggle="buttons">
          <label id="offEPosShelfLabel" class="btn btn-default btn-calendar">
            <input type="radio" name="shelf_live" class="sky" autocomplete="off" value="">
            Off
          </label>
          <label id="shortEPosShelfLabel" class="btn btn-default btn-calendar btn-calendar-short">
            <input type="radio" name="shelf_live" class="sky" autocomplete="off" value="short">
            Short
          </label>
          <label id="mediumEPosShelfLabel" class="btn btn-default btn-calendar btn-calendar-medium">
            <input type="radio" name="shelf_live" class="sky" autocomplete="off" value="medium">
            Medium
          </label>
          <label id="longEPosShelfLabel" class="btn btn-default btn-calendar btn-calendar-long">
            <input type="radio" name="shelf_live" class="sky" autocomplete="off" value="long">
            Long
          </label>
          <label id="shorterEPosShelfLabel" class="btn btn-default btn-calendar ">
            <input type="radio" name="shelf_live" class="sky" autocomplete="off" value="shorter">
            <span>Shorter than:</span>
            <input name="ePos" class="center-block in-button-window" autocomplete="off">
            Day(s)
          </label>
        </div>
      </div>
    </div>
  </div>

  <h5 class="text-left">I want to play my campaign when desirability from shoppers is:</h5>

  <div class="offer-schedule-content">
    <div class="row">
      <div class="col-sm-12 text-center">
        <div class="btn-group date-range-selector" data-toggle="buttons">
          <label id="offEPosDesirabilityLabel" class="btn btn-default btn-calendar">
            <input type="radio" name="desirability" class="sky" autocomplete="off" value="">
            Off
          </label>
          <label id="lowEPosDesirabilityLabel" class="btn btn-default btn-calendar btn-low">
            <input type="radio" name="desirability" class="sky" autocomplete="off" value="low">
            Low
          </label>
          <label id="mediumEPosDesirabilityLabel" class="btn btn-default btn-calendar btn-medium">
            <input type="radio" name="desirability" class="sky" autocomplete="off" value="medium">
            Medium
          </label>
          <label id="highEPosDesirabilityLabel" class="btn btn-default btn-calendar btn-high">
            <input type="radio" name="desirability" class="sky" autocomplete="off" value="high">
            High
          </label>
        </div>
      </div>
    </div>
  </div>

  <h5 class="text-left">Tracking most effective offers:</h5>

  <div class="offer-schedule-content">
    <div class="row">
      <div class="col-sm-12 text-center">
        <div class="btn-group date-range-selector" data-toggle="buttons">
          <label id="offEPosTrackingLabel" class="btn btn-default btn-calendar btn-on-off">
            <input type="radio" name="tracking" class="sky" autocomplete="off" value="">
            Off
          </label>
          <label id="onEPosTrackingLabel" class="btn btn-default btn-calendar btn-on-off">
            <input type="radio" name="tracking" class="sky" autocomplete="off" value="on">
            On
          </label>
        </div>
      </div>
    </div>
  </div>

  <h5 class="text-left">Monitor effectiveness:</h5>

  <div class="offer-schedule-content">
    <div class="row">
      <div class="col-sm-12 text-center">
        <div class="btn-group date-range-selector" data-toggle="buttons">
          <label id="offEPosMonitorLabel" class="btn btn-default btn-calendar btn-on-off">
            <input type="radio" name="monitor" class="sky" autocomplete="off" value="">
            Off
          </label>
          <label id="onEPosMonitorLabel" class="btn btn-default btn-calendar btn-on-off">
            <input type="radio" name="monitor" class="sky" autocomplete="off" value="on">
            On
          </label>
        </div>
      </div>
    </div>
  </div>
</div>