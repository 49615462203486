import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {InstagramService} from '../instagram.service';
import { filter, map } from 'rxjs/operators';
import {ApiService} from '../../../services/api-service/api.service';
import {OfferService} from '../../../services/offer/offer.service';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.sass']
})
export class PreviewComponent implements OnInit, OnDestroy {

  private config = [];

  public isbn: number;
  public processing = false;
  public cover = null;
  private sub;

  private data;
  public desc: string;
  public hashtags: string;
  public customData = {};
  public layout;

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private instagramService: InstagramService,
    private offerService: OfferService
  ) {}

  ngOnInit() {
    this.sub = this.instagramService.selected
      .pipe(
        filter(data => data !== null)
      )
      .subscribe(
      (data) => {
        if (data) {
          this.data = data;
          this.processing = true;
          if (this.config[this.instagramService.instagram_config.account]) {
            this.generateArtwork(data);
          } else {
            this.instagramService.getConfig()
              .subscribe(
                (config) => {
                  this.config[this.instagramService.instagram_config.account] = config;
                  this.generateArtwork(data);
                },
                (err) => console.log(err)
              );
          }
        }
      }
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private getLayout() {
    return this.config[this.instagramService.instagram_config.account][this.instagramService.getLayoutFormat()];
  }

  getFormat() {
    return this.instagramService.format;
  }

  refreshArtwork() {
    this.processing = true;
    console.log(this.data);
    console.log(this.config);
    this.generateArtwork(this.data, this.customData);
  }

  generateArtwork(data, customData = null) {

    const getByKeypath = (field, d) => {
      const fieldSrc = field.src.split('.');
      let root = d;
      fieldSrc.forEach(path => {
        root = root[path];
      });
      return root;
    };

    combineLatest([
      this.apiService.getImageAsBlob(data.display_url),
    ]).pipe(
      map(([display_url]) => {
        return { display_url };
      })
    ).subscribe(
        (images) => {
          this.layout = this.getLayout();

          if (this.layout.texts) {
            this.layout.texts.forEach(txt => {
              if (customData && customData[txt.field]) {
                if (customData[txt.field]) {
                  data[txt.field] = customData[txt.field];
                } else {
                  const value = getByKeypath(txt, data);
                  data[txt.field] = value ?
                    value.split(/[\.]/g)[0]
                      .replace(/[^\x00-\x7F]/g, '')
                      .replace(/ +(?= )/g, '')
                    : '';
                }
              } else {
                const value = getByKeypath(txt, data);
                if (typeof value !== 'string') {
                  // this is probably tags field
                  data[txt.field] = value.length ? '#' + value.join('   #') : '';
                } else {
                  data[txt.field] = value ?
                    value.split(/[\.]/g)[0]
                      .replace(/[^\x00-\x7F]/g, '')
                      .replace(/ +(?= )/g, '')
                    : '';
                }
              }
              this.customData[txt.field] = data[txt.field];
            });
          }

          // data.qrContent = `https://www.hachette.co.uk/books/detail.page?isbn=${this.isbn}`;
          console.log(data);

          this.instagramService.getArtwork({offer: {...data, ...images}, layout: this.getLayout()})
            .subscribe(
              (img) => this.cover = img
            );
          this.processing = false;
        }
    );
  }

  previewArtwork(src) {
    const image = new Image();
    image.src = src;
    const w = (<any>window).open('');
    w.document.write(image.outerHTML);
  }

  back() {
    this.instagramService.selected.next(null);
  }

  cancel() {
    this.instagramService.selected.next(null);
    this.instagramService.toggle();
  }

  saveArtwork() {
    this.offerService.currentOffer.offer_type = 'image';
    this.offerService.currentOffer.offer_origin = 'generated';
    this.offerService.updateCustomArtwork(this.cover);
    this.instagramService.selected.next(null);
    this.instagramService.toggle();
  }


}
