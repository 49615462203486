import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ArtworkGeneratorService } from '@module/artwork-generator/artwork-generator.service';

@Component({
  selector: 'app-artwork-generator-layout',
  templateUrl: './artwork-generator-layout.component.html',
  styleUrls: ['./artwork-generator-layout.component.scss']
})
export class ArtworkGeneratorLayoutComponent implements OnInit, OnDestroy {

  @HostBinding('class.is-open')
  isOpen = false;

  constructor(
    private service: ArtworkGeneratorService,
  ) {
    this.service.change.subscribe(isOpen => {
      this.isOpen = isOpen;
    });
  }

  ngOnInit(): void {
  }

  openGenerator(): void {
    this.service.toggle();
  }

  ngOnDestroy(): void {
    this.service.toggle(true);
  }

}
