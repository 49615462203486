import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { AudiencesService } from '../../../../../services/audiences/audiences.service';
import { IMultiEstimate } from '../../../../../services/estimator/estimator.service';
import { Audience } from '../../../../../models/DisplayGroups.model';


const ITEMS_PER_PAGE = 10;
const GENERIC_AUDIENCE_ICON = '/assets/images/icons/display-generic.svg';

@Component({
  selector: 'app-audience-selector',
  templateUrl: './audience-selector.component.html',
  styleUrls: ['./audience-selector.component.scss']
})
export class AudienceSelectorComponent implements OnInit {

  @Input() offerAudiences: any;
  @Input() location: Location;
  @Input() postcode: string;
  @Input() estimations: IMultiEstimate[];
  @Input('collapsible') collapsible = false;
  collapsed = true;

  @Output() selectAudience = new EventEmitter();


  currentPage = 0;

  @Input()
  get chosenAudience() {
    return this.chosenAud;
  }

  set chosenAudience(val: Audience) {
    this.chosenAud = val;
  }
  private chosenAud: Audience;

  isLoading = false;
  public audiences: Audience[];
  public searchString: string = null;
  private searchStringChanged: Subject<string> = new Subject<string>();

  constructor(
      private audiencesService: AudiencesService,
  ) {
    this.searchStringChanged
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
      )
      .subscribe(() => {
        this.getAudiences(false).subscribe();
      });

    this.audiencesService.audiences.subscribe(
      data => this.audiences = data
    );
  }

  ngOnInit() {
    this.getAudiences(false).subscribe();
  }

  getReach(audienceCode: string): number {
    if (this.estimations) {
    const currEst = this.estimations.find(est => est.audience === audienceCode);
       return currEst ? currEst.totalReach : 0;
    } else {
      return 0;
    }
  }

  getEstimation(audienceCode: string) {
    if (this.estimations) {
      return this.estimations.find(est => est.audience === audienceCode);
    } else {
      return null;
    }
  }

  audienceClicked(audience: Audience) {
    this.chosenAudience = audience;
    this.selectAudience.emit({
      audience: audience,
      reach: this.getReach(audience.display_name),
    });
  }

  public getAudiences(append: boolean = true): Observable<Audience[] | any> {
    this.isLoading = true;
    if (!append) {
      this.currentPage = 0;
    }
    return this.audiencesService.getAudiences(this.searchString, !append)
        .pipe(
            tap(() => this.isLoading = false),
            catchError((err) => {
              this.isLoading = false;
              return of(err);
            })
        );
  }

  get isCollapsed(): boolean {
    return this.collapsible && this.collapsed;
  }

  // Review below
  unselected(audience: Audience): boolean {
    return this.chosenAudience && this.chosenAudience !== audience;
  }
  selected(format: Audience): boolean {
    return this.chosenAudience && format.name === this.chosenAudience.name;
  }

  isAudienceSelected(audience) {
    if (audience && audience.uri) {
      const aud = this.offerAudiences.get(audience.uri);
      return aud !== undefined;
    } else {
      return false;
    }
  }

  audienceIcon(audience: Audience): string {
    if (audience.has_icon && audience.icon_link) {
      return audience.icon_link;
    } else {
      return GENERIC_AUDIENCE_ICON;
    }
  }

  getPages(): number {
    return Math.ceil(this.audiences.length / ITEMS_PER_PAGE);
  }

  get page(): Audience[] {
    const audiences = [
      ...this.audiences.filter(aud => this.getEstimation(aud.audience_code) !== null && this.getEstimation(aud.audience_code) !== undefined),
      ...this.audiences.filter(aud => this.getEstimation(aud.audience_code) == null || this.getEstimation(aud.audience_code) === undefined),
    ];
    return audiences.slice(this.currentPage * ITEMS_PER_PAGE, (this.currentPage * ITEMS_PER_PAGE) + ITEMS_PER_PAGE);
  }

  goToPage(step: number) {
    if (step < 0 && this.currentPage + step < 0) {
      return;
    } else if (step < 0) {
      this.currentPage = this.currentPage + step;
    }

    if (step > 0 && this.currentPage + step <= this.getPages() - 1) {
      this.currentPage = this.currentPage + step;
    } else if (step > 0 && this.audiencesService.nextUri) {
      this.getAudiences().subscribe(
          () => {
            this.currentPage = this.currentPage + step;
          }
      );
    } else {
      return;
    }
  }

  get isNext() {
    return this.getPages() > 0 && !(this.currentPage === this.getPages() - 1 && !this.audiencesService.nextUri);
  }

  onSearchBoxChange($event) {
    this.searchStringChanged.next($event);
  }

  isAudienceAvailable(audience: Audience): boolean {
    if (this.isAudienceSelected(audience)) {
      return true;
    } else {
      if (this.estimations && this.estimations.length > 0) {
        const audienceCode = audience.audience_code;
        const audienceEstimation = this.estimations.find(est => est.audience === audienceCode);
        return (audienceEstimation && audienceEstimation.overall_demand_spend) > 0;
      } else {
        return false;
      }
    }
  }

  public getPercentage(audience): number {
    return this.getEstimation(audience.audience_code)?.totalImpressions * 100 / this.getEstimation(audience.audience_code)?.totalRateCardImpressions || null;
  }

  public tooltipHelper(audience): string {
    const origPercentage = this.getPercentage(audience);
    const percentage = Math.round(origPercentage <= 100 ? origPercentage : 100);

    if (percentage > 49) {
      return `You will get <strong>${percentage}%</strong> of available impressions using this audience.`;
    } else {
      return `You will only get <strong>${percentage}%</strong> of available impressions using this audience.<br><small>You can increase number of impressions by setting the campaign ahead of time.</small>`;
    }
  }

}
