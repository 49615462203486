import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-password-instructions-sent',
  templateUrl: './password-instructions-sent.component.html',
  styleUrls: ['./password-instructions-sent.component.sass']
})
export class PasswordInstructionsSentComponent implements OnInit {

  readonly logo = 'assets/images/fc.png';

  constructor() { }

  ngOnInit() {
  }

}
