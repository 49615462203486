import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

const DEFAULT_MESSAGE = 'Are you sure?';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  @Input() message: string = DEFAULT_MESSAGE;
  @Output() response = new EventEmitter();

  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }

  close(): void {
    this.activeModal.dismiss();
  }

  confirm(): void {
     this.response.emit(true);
     this.activeModal.dismiss();
  }

}
