import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {OfferService} from '../../../../../../../services/offer/offer.service';
import { NgbCalendar, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment-timezone';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ApiService } from '../../../../../../../services/api-service/api.service';
import { UserModel } from '../../../../../../../models/session-init/user-model/user-model.model';

const DEFAULT_TIME_START = 25;
const DEFAULT_TIME_STOP = 69;

const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day < two.day : one.month < two.month : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day > two.day : one.month > two.month : one.year > two.year;

@Component({
  selector: 'app-date-time',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.sass']
})
export class DateTimeComponent implements OnInit {

  @ViewChild('fromLabel') fromLabel: ElementRef;
  @ViewChild('toLabel') toLabel: ElementRef;
  @ViewChild('ion') ion: ElementRef;

  hoveredDate: NgbDateStruct;

  _rangeValues: number[];

  canEditParameters = true;

  private _destroy$ = new Subject<any>();

  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  duration: number;

  minDate: NgbDateStruct = {
    day: 1,
    month: 1,
    year: 2000
  };

  showCalendar = true;
  hoursValues: string[] = HOURS_VALUES;
  hoursValuesFormatted: string[] = HOURS_VALUES_FORMATTED;

  everyday = true;

  days1: DaysCheckbox[] = DAYS.slice(0, 4);
  days2: DaysCheckbox[] = DAYS.slice(4);

  currentUser: UserModel;

  constructor(
    @Inject(OfferService) public service,
    private calendar: NgbCalendar,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private api: ApiService,
  ) {
    // this.fromDate = calendar.getToday();
    // this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);

    this.service.timeScheduleUpdated
        .pipe(
          takeUntil(this._destroy$),
          distinctUntilChanged(),
        )
        .subscribe(
            () => this.updateFromSchedule()
        );
  }

  ngOnInit(): void {
    this.currentUser = this.api.currentSessionData.user;
    const today = new Date();
    this.minDate = {
      day: today.getDate(),
      month: today.getMonth() + 1,
      year: today.getFullYear()
    };

    this.canEditParameters = this.service.currentOffer.offer_origin !== 'template' && this.service.currentOffer.offer_origin !== 'preconstructed';

    const _d = moment(this.service.scheduleTime.schedule_start);
    this.fromDate = {
      year: _d.year(),
      month: _d.month() + 1,
      day: _d.date(),
    };

    this.duration = this.service.getSchedulerTimeDuration();

    this.rangeValues = [
       this.sliderFromTo(this.service.scheduleTime.time_start) > -1 ? this.sliderFromTo(this.service.scheduleTime.time_start) : DEFAULT_TIME_START,
       this.sliderFromTo(this.service.scheduleTime.time_stop) > -1 ? this.sliderFromTo(this.service.scheduleTime.time_stop) : DEFAULT_TIME_STOP
    ];

  }

  get rangeValues(): any {
    return this._rangeValues;
  }

  set rangeValues(val: any) {
    const newVal = [
      val[0] < 0 ? DEFAULT_TIME_START : val[0],
      val[1] < 0 ? DEFAULT_TIME_STOP : val[1],
    ];
    this._rangeValues = newVal;
  }

  private timeIsoFormat(time): string {
    const timezone = this.currentUser.tz || 'Europe/London';
    return moment.tz(`${moment().tz(timezone).format('YYYY-MM-DD')}T${time}`, timezone).format('YYYY-MM-DDTHH:mm:ssZ');
  }

  updateFromSlider(): void {
    const val = this.rangeValues;
    const timeStart = this.hoursValuesFormatted[val[0]];
    const timeStop = this.hoursValuesFormatted[val[1]];
    const timezone = this.currentUser.tz || 'Europe/London';

    this.service.scheduleTime = {
      ...this.service.scheduleTime,
      time_start: timeStart,
      time_stop: timeStop,
      day_time_start: this.timeIsoFormat(timeStart),
      day_time_stop: this.timeIsoFormat(timeStop),
    };

    this.service.editTimeSchedule();
  }

  private isAfterOrSame(one: NgbDateStruct, two: NgbDateStruct): boolean {
    if (!one || !two) {
      return false;
    }

    const parsedFrom = this.ngbDateParserFormatter.format(one);
    const parsedTo = this.ngbDateParserFormatter.format(two);
    return moment(parsedFrom).isAfter(parsedTo) || moment(parsedFrom).isSame(parsedTo);
  }

  updateFromSchedule(): void {
    if (this.service.scheduleTime) {
      const from = moment(this.service.scheduleTime.schedule_start);
      const to = moment(this.service.scheduleTime.schedule_stop);
      this.fromDate = {
        year: from.year(),
        month: from.month() + 1,
        day: from.date(),
      };

      this.toDate = {
        year: to.year(),
        month: to.month() + 1,
        day: to.date(),
      };

      this.duration = this.service.getSchedulerTimeDuration();
    }
  }

  // region calendar
  onDateChange(date: NgbDateStruct) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && this.isAfterOrSame(date, this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    if (this.fromDate && this.toDate) {
      this.dateButton(
        'custom',
        new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day),
        new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day),
        true
      );
    }
  }

  isHovered = date => this.fromDate && !this.toDate && this.hoveredDate && after(date, this.fromDate) && before(date, this.hoveredDate);
  isInside = date => after(date, this.fromDate) && before(date, this.toDate);
  isFrom = date => equals(date, this.fromDate);
  isTo = date => equals(date, this.toDate);
  // endregion


  playEveryday(): boolean {
    // if (this.offerTimeExists()) {
      return this.service.scheduleTime.weekdays.length === 7;
    // }
    // return this.everyday;
  }

  // TODO repair toggiling
  toggleEveryday(): void {
    this.everyday = !this.everyday;

    if (this.everyday) {
      this.service.scheduleTime.weekdays = [0, 1, 2, 3, 4, 5, 6];
      this.service.editTimeSchedule();
    }
  }

  toggleAllDay(): void {
    const st = this.service.scheduleTime;
    st.all_day = !st.all_day;
    if (!st.time_start) {
      st.time_start = this.hoursValuesFormatted[DEFAULT_TIME_START];
    }
    if (!st.time_stop) {
      st.time_stop = this.hoursValuesFormatted[DEFAULT_TIME_STOP];
    }

    if (st.time_start === st.time_stop) {
      st.time_start = this.hoursValuesFormatted[DEFAULT_TIME_START];
      st.time_stop = this.hoursValuesFormatted[DEFAULT_TIME_STOP];
    }

    this.service.scheduleTime = {
      ...this.service.scheduleTime,
      day_time_start: this.timeIsoFormat(st.time_start),
      day_time_stop: this.timeIsoFormat(st.time_stop),
    };

    this.service.editTimeSchedule();
  }

  toggleDay(day: number): void {
    const index = this.service.scheduleTime.weekdays.indexOf(day);
    if (index > -1) {
      this.service.scheduleTime.weekdays.splice(index, 1);
    } else {
      this.service.scheduleTime.weekdays.push(day);
    }

    this.service.editTimeSchedule();
  }

  chosenDay(day: number): boolean {
    if (this.offerTimeExists()) {
      return this.service.scheduleTime.weekdays.indexOf(day) >= 0;
    }
    return true;
  }

  whenActive(): boolean {
    if (this.offerTimeExists()) {
      return this.service.scheduleTime.active;
    }
    return false;
  }

  campaignPlay(playNow: boolean): void {
    this.service.editTimeSchedule('active', playNow);
  }

  displayDays(): string {
    let display = 'none';
    const st = this.service.scheduleTime;
    if (st && st.weekdays.length < 7) {
      display = 'block';
    }

    return display;
  }

  dateButton(
    time: string,
    start = new Date(),
    end = new Date(),
    datesSet = false
  ): void {
    const self = this;
    self.showCalendar = time === 'custom';

    switch (time) {
      case 'this-week':
        start.setDate(start.getDate() - (start.getDay() - 1));
        end = new Date(start);
        end.setDate(start.getDate() + 6);
        break;
      case 'next-week':
        start.setDate(start.getDate() - (start.getDay() - 1) + 7);
        end = new Date(start);
        end.setDate(start.getDate() + 6);
        break;
      case 'this-month':
        start.setDate(1);
        end = new Date(start);
        end.setMonth(end.getMonth() + 1);
        end.setDate(end.getDate() - 1);
        break;
      case 'next-month':
        start.setDate(1);
        start.setMonth(start.getMonth() + 1);
        end = new Date(start);
        end.setMonth(end.getMonth() + 1);
        end.setDate(end.getDate() - 1);
        break;
      case 'until-unpublished':
        end = null;
        break;
      case 'custom':
        if (!datesSet) {
          return;
        }
    }

    if (!datesSet) {
      self.fromDate = {
        year: start.getFullYear(),
        month: start.getMonth() + 1,
        day: start.getDate()
      };
      if (time !== 'custom' && time !== 'until-unpublished') {
        self.toDate = {
          year: end.getFullYear(),
          month: end.getMonth() + 1,
          day: end.getDate()
        };
      }
    }

    self.service.scheduleTime.schedule_start = OfferService.formatDate(start, true);
    self.service.scheduleTime.schedule_stop = end ? OfferService.formatDate(end) : end;
    self.service.editTimeSchedule();
  }

  private offerTimeExists(): boolean {
    return this.service && this.service.scheduleTime;
  }

  slidersOverlap(): boolean {
    if (this.fromLabel && this.toLabel) {
      const from = this.fromLabel.nativeElement;
      const to = this.toLabel.nativeElement;
      return from.offsetLeft + from.offsetWidth > to.offsetLeft;
    }
    return false;
  }

  sliderFromTo(time: string): number {
    return this.hoursValuesFormatted.indexOf(time);
  }


  get fromValue(): string {
    if (this.rangeValues) {
      return this.hoursValues[this.rangeValues[0] / this.steps];
    } else {
      const timeStart = this.service.scheduleTime.time_start;
      const index = this.hoursValuesFormatted.indexOf(timeStart);
      return this.hoursValues[index];
    }
  }

  get toValue(): string {
    if (this.rangeValues) {
      return this.hoursValues[this.rangeValues[1] / this.steps];
    } else {
      const timeStop = this.service.scheduleTime.time_stop;
      const index = this.hoursValuesFormatted.indexOf(timeStop);
      return this.hoursValues[index];
    }
  }

  get fromThumb(): number {
    if (this.rangeValues) {
      return 100 * this.rangeValues[0] / this.hoursValuesFormatted.length;
    } else {
      const timeStart = this.service.scheduleTime.time_start;
      const hvf = this.hoursValuesFormatted;
      const index = hvf.indexOf(timeStart);
      return index / (hvf.length - 1) * 100;
    }
  }

  get toThumb(): number {
    if (this.rangeValues) {
      return 100 * this.rangeValues[1] / this.hoursValuesFormatted.length + 1;
    } else {
      const timeStop = this.service.scheduleTime.time_stop;
      const hvf = this.hoursValuesFormatted;
      const index = hvf.indexOf(timeStop);
      return index / (hvf.length - 1) * 100;
    }
  }

  onDateChangeMovePeriod(date: NgbDateStruct) {
    const _endDate = moment(`${date.year}-${date.month}-${date.day}`, 'YYYY-MM-DD').add(this.duration - 1, 'day');

    this.toDate = {
      year: _endDate.year(),
      month: _endDate.month() + 1,
      day: _endDate.date(),
    };

    this.service.scheduleTime.schedule_start = moment(`${date.year}-${date.month}-${date.day}`, 'YYYY-MM-DD').startOf('day').format('YYYY-MM-DDTHH:mm');
    this.service.scheduleTime.schedule_stop = moment(`${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`, 'YYYY-MM-DD').endOf('day').format('YYYY-MM-DDTHH:mm');
    console.log(this.service.scheduleTime);
    this.service.editTimeSchedule();
  }

  get steps(): number {
    return Math.round(100 / this.hoursValuesFormatted.length);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}

const HOURS_VALUES: string[] = [
  '12:00 AM', '12:15 AM', '12:30 AM', '12:45 AM',
  '1:00 AM', '1:15 AM', '1:30 AM', '1:45 AM',
  '2:00 AM', '2:15 AM', '2:30 AM', '2:45 AM',
  '3:00 AM', '3:15 AM', '3:30 AM', '3:45 AM',
  '4:00 AM', '4:15 AM', '4:30 AM', '4:45 AM',
  '5:00 AM', '5:15 AM', '5:30 AM', '5:45 AM',
  '6:00 AM', '6:15 AM', '6:30 AM', '6:45 AM',
  '7:00 AM', '7:15 AM', '7:30 AM', '7:45 AM',
  '8:00 AM', '8:15 AM', '8:30 AM', '8:45 AM',
  '9:00 AM', '9:15 AM', '9:30 AM', '9:45 AM',
  '10:00 AM', '10:15 AM', '10:30 AM', '10:45 AM',
  '11:00 AM', '11:15 AM', '11:30 AM', '11:45 AM',
  '12:00 PM', '12:15 PM', '12:30 PM', '12:45 PM',
  '1:00 PM', '1:15 PM', '1:30 PM', '1:45 PM',
  '2:00 PM', '2:15 PM', '2:30 PM', '2:45 PM',
  '3:00 PM', '3:15 PM', '3:30 PM', '3:45 PM',
  '4:00 PM', '4:15 PM', '4:30 PM', '4:45 PM',
  '5:00 PM', '5:15 PM', '5:30 PM', '5:45 PM',
  '6:00 PM', '6:15 PM', '6:30 PM', '6:45 PM',
  '7:00 PM', '7:15 PM', '7:30 PM', '7:45 PM',
  '8:00 PM', '8:15 PM', '8:30 PM', '8:45 PM',
  '9:00 PM', '9:15 PM', '9:30 PM', '9:45 PM',
  '10:00 PM', '10:15 PM', '10:30 PM', '10:45 PM',
  '11:00 PM', '11:15 PM', '11:30 PM', '11:45 PM',
  '12:00 AM'
];

const HOURS_VALUES_FORMATTED: string[] = [
  '00:00:00', '00:15:00', '00:30:00', '00:45:00',
  '01:00:00', '01:15:00', '01:30:00', '01:45:00',
  '02:00:00', '02:15:00', '02:30:00', '02:45:00',
  '03:00:00', '03:15:00', '03:30:00', '03:45:00',
  '04:00:00', '04:15:00', '04:30:00', '04:45:00',
  '05:00:00', '05:15:00', '05:30:00', '05:45:00',
  '06:00:00', '06:15:00', '06:30:00', '06:45:00',
  '07:00:00', '07:15:00', '07:30:00', '07:45:00',
  '08:00:00', '08:15:00', '08:30:00', '08:45:00',
  '09:00:00', '09:15:00', '09:30:00', '09:45:00',
  '10:00:00', '10:15:00', '10:30:00', '10:45:00',
  '11:00:00', '11:15:00', '11:30:00', '11:45:00',
  '12:00:00', '12:15:00', '12:30:00', '12:45:00',
  '13:00:00', '13:15:00', '13:30:00', '13:45:00',
  '14:00:00', '14:15:00', '14:30:00', '14:45:00',
  '15:00:00', '15:15:00', '15:30:00', '15:45:00',
  '16:00:00', '16:15:00', '16:30:00', '16:45:00',
  '17:00:00', '17:15:00', '17:30:00', '17:45:00',
  '18:00:00', '18:15:00', '18:30:00', '18:45:00',
  '19:00:00', '19:15:00', '19:30:00', '19:45:00',
  '20:00:00', '20:15:00', '20:30:00', '20:45:00',
  '21:00:00', '21:15:00', '21:30:00', '21:45:00',
  '22:00:00', '22:15:00', '22:30:00', '22:45:00',
  '23:00:00', '23:15:00', '23:30:00', '23:45:00',
  '23:59:00'
];

interface DaysCheckbox {
  day: number;
  label: string;
}

const DAYS: DaysCheckbox[] = [
  {
    day: 0,
    label: 'Mondays'
  },
  {
    day: 1,
    label: 'Tuesdays'
  },
  {
    day: 2,
    label: 'Wednesdays'
  },
  {
    day: 3,
    label: 'Thursdays'
  },
  {
    day: 4,
    label: 'Fridays'
  },
  {
    day: 5,
    label: 'Saturdays'
  },
  {
    day: 6,
    label: 'Sundays'
  }
];
