<div class="row">
  <div *ngFor="let book of books" class="col-md-6 col-lg-4 col-xl-3" style="padding-bottom: 15px">
    <div class="book"
         (click)="selectBook(book.isbn)">
      <div class="blur" [style.background-image]="'url(' + book.image + ')'"></div>
      <div class="content">
        <figure [style.background-image]="'url(' + book.image + ')'"></figure>
        <h2>{{ book.title }}<small>{{ book.author }}</small></h2>
      </div>
    </div>
  </div>
</div>

<div class="loading" *ngIf="processing"></div>