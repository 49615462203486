import { Component, Input } from '@angular/core';
import { OfferService } from '../../../services/offer/offer.service';
import { OfferData } from '../../../models/offers/offer-data/OfferData.model';

@Component({
  selector: 'app-screen-list',
  templateUrl: './screen-list.component.html',
  styleUrls: ['./screen-list.component.sass']
})
export class ScreenListComponent {

  state: 'active'| 'wait' | 'not-ready';

  @Input() offer: OfferData;

  constructor(
    private service: OfferService
  ) {
    this.state = 'active';
  }

  downloadReport(): void {
    if (this.state !== 'active') {
      return;
    }
    this.state = 'wait';
    this.service.downloadScreenReport(this.offer).subscribe(
      () => {
        this.state = 'active';
      },
      () => {
        this.state = 'not-ready';
        setTimeout(() => this.state = 'active', 2000);
      }
    );
  }

}
