export interface ICampaignSchedulerBase {
  active: boolean;
  archived: boolean;
  class: string[];
  created: string;
  description: string;
  id: number;
  text: string;
  type: string;
  uri: string;
  uris: {
    parent_resource: string;
    StatusOnTarget: string;
    schedules: string
  };
  _db_class: string[];
}

export const IOfferScheduleTimeFieldset = ['active', 'all_day', 'schedule_start', 'schedule_stop', 'time_start', 'time_stop', 'weekdays'];
export interface ICampaignScheduleTime extends ICampaignSchedulerBase {
  all_day: boolean;
  schedule_start: string;
  schedule_stop: string;
  time_start: string;
  time_stop: string;
  weekdays: number[];
}

export const IOfferScheduleWeatherFieldset = ['active', 'sky_condition', 'temperature'];
export interface ICampaignScheduleWeather extends ICampaignSchedulerBase {
  sky_condition: string;
  temperature: string;
}

export const IOfferScheduleDisruptionsFieldset = ['active', 'sky_condition', 'temperature'];
export interface ICampaignScheduleDisruptions extends ICampaignSchedulerBase{
  disruptions: string;
}

export const IOfferScheduleStockLevelFieldset = ['active', 'min_stock_level'];
export interface ICampaignScheduleStockLevel extends ICampaignSchedulerBase{
  min_stock_level: number;
}

export const IOfferScheduleAIMFieldset = ['active', 'age', 'age_range', 'gender', 'gender_range', 'min_visits'];
export interface ICampaignScheduleAIM extends ICampaignSchedulerBase {
  age: string;
  age_range: number[];
  gender: string;
  gender_range: number[];
  min_visits: number;
}

export class Scheduler implements ICampaignSchedulerBase, ICampaignScheduleTime, ICampaignScheduleWeather, ICampaignScheduleDisruptions, ICampaignScheduleStockLevel, ICampaignScheduleAIM {
  active: boolean;
  archived: boolean;
  class: string[];
  created: string;
  description: string;
  id: number;
  text: string;
  type: string;
  uri: string;
  uris: {
    parent_resource: string;
    StatusOnTarget: string;
    schedules: string
  };
  _db_class: string[];
  all_day: boolean;
  schedule_start: string;
  schedule_stop: string;
  time_start: string;
  time_stop: string;
  weekdays: number[];

  sky_condition: string;
  temperature: string;

  disruptions: string;

  min_stock_level: number;

  age: string;
  age_range: number[];
  gender: string;
  gender_range: number[];
  min_visits: number;

  constructor(schedule: ICampaignSchedulerBase) {

  }

}
