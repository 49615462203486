import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { LoadingService } from '@shared/services/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'flowcity-buyside-app';

  constructor(
    private loadingService: LoadingService,
  ) {
  }

  get isLoading(): Observable<boolean> {
    return this.loadingService.loading$;
  }

}
