import { Injectable } from '@angular/core';
import { CoreService } from '../../services/main/core.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../environments/environment';
import { TEMPLATE } from '@module/artwork-generator/artwork-generator.const';
import { tap } from 'rxjs/operators';
import { IFormat } from '../../models/format.model';

export const ARTWORK_GENERATOR_URL = '/data/artwork-generator';

@Injectable()
export class ArtworkGeneratorService {

  isOpen = false;
  private _change = new BehaviorSubject<boolean>(false);
  public change = this._change.asObservable();
  private _artworkData = new BehaviorSubject<any>(null);
  private _configJson = new BehaviorSubject<any>(null);
  private _generatorReady = new BehaviorSubject<boolean>(false);
  public generatorReady = this._generatorReady.asObservable();
  private _selectedImageId = 0;

  private _format: IFormat;

  constructor(
    private http: HttpClient,
    private coreService: CoreService,
  ) {}

  get format(): IFormat {
    return this._format;
  }

  set format(format: IFormat) {
    this._format = format;
    this._generatorReady.next(this.config !== undefined);
  }

  get formatCode(): string {
    return this._format && this._format.ooh_format;
  }

  get selectedImageId(): number {
    return this._selectedImageId;
  }

  set selectedImageId(id: number) {
    this._selectedImageId = id;
    this.artworkData = {...this.artworkData, image: {...this.artworkData.image, value: this.selectedImage.image}};
  }

  get artworkData(): any {
    return this._artworkData.getValue();
  }

  set artworkData(data: any) {
    this._artworkData.next({...this.artworkData, ...data});
  }

  get configJson(): any {
    return this._configJson.getValue();
  }

  set configJson(data: any) {
    this._configJson.next(data);
  }

  get config(): any {
    return this.configJson && this.configJson.config[this.formatCode];
  }

  getConfig(): Observable<any> {
    const payload = {
      template: TEMPLATE,
      layout: this.formatCode,
      getConfig: true,
    };
    return this.http.post<any>(`${env.apiUrl}${ARTWORK_GENERATOR_URL}`, payload)
      .pipe(
        tap(data => this.configJson = data),
        tap(response => {
          this.prepareArtworkData();
        })
      );
  }

  get selectedImage(): any {
    return this.configJson.images && this.configJson.images[this.selectedImageId];
  }

  generateArtwork(data: any): Observable<any> {
    const payload = {
      template: TEMPLATE,
      layout: this.formatCode,
      items: [data],
    };
    return this.http.post(`${env.apiUrl}${ARTWORK_GENERATOR_URL}`, payload);
  }

  private prepareArtworkData(): void {
    if (this.config && this.config.texts) {
      this.config.texts.forEach(txt => {
        this.artworkData = {...this.artworkData, [txt.src]: txt};
      });
    }
    if (this.config && this.config.images && this.config.images.image) {
      const img = this.config.images.image;
      this.artworkData = {...this.artworkData, [img.src]: {...img, value: this.selectedImage.image}};
    }
    this._generatorReady.next(this.config !== undefined);
  }

  toggle(forceClose: boolean = false): void {
    if (!forceClose) {
      this.isOpen = !this.isOpen;
      this.coreService.freezeMain.next(this.isOpen);
      this._change.next(this.isOpen);
    } else {
      this.isOpen = false;
      this.coreService.freezeMain.next(this.isOpen);
      this._change.next(this.isOpen);
    }
  }
}
