import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../../services/session/session.service';
import { environment as env } from '@env/environment';

@Component({
  selector: 'app-signout',
  templateUrl: './signout.component.html',
  styleUrls: ['./signout.component.scss']
})
export class SignoutComponent implements OnInit {

  constructor(
    private sessionService: SessionService,
  ) { }

  ngOnInit(): void {
    this.sessionService.firebaseSignOut()
      .subscribe(
        () => window.location.href = env.appUrl,
        reason => console.error('Could not log out. \n' + reason),
      );
  }

}
