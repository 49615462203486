import {Injectable, Injector} from '@angular/core';
import {SuperService} from '../super-service/super.service';
import {SessionService as Session} from '../session/session.service';
import {ApiService} from '../api-service/api.service';
import {PaymentsModel} from '../../models/session-init/session-data/company-model/payments/payments.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ICompanyBalance } from '../../models/currentCompany.model';
import { environment } from '@env/environment';


@Injectable({
  providedIn: 'root'
})
export class CoreService {

  sidebarShown = true;
  freezeMain = new BehaviorSubject(false);
  private _overlayShown$ = new BehaviorSubject<boolean>(false);
  overlayShown$ = this._overlayShown$.asObservable();
  private _isGoingBack = false;

  constructor(
    private http: HttpClient,
    private session: Session,
    private api: ApiService,
  ) {
  }

  set overlayShown(state: boolean) {
    this._overlayShown$.next(state);
  }

  set isGoingBack(state: boolean) {
    this._isGoingBack = state;
  }

  get isGoingBack(): boolean {
    const state = this._isGoingBack || false;
    setTimeout(() => this._isGoingBack = false);
    return state;
  }

  /**
   * Method passes logout request to session service.
   */
  requestLogout(): void {
    const self = this;

    self.session.signOut();
  }

  /**
   * This method sends 'POST' request to currentCompany.uris.Payments address.
   * @param credits How many credits is requested to buy.
   * @param onSuccess
   * @param onError
   */
  purchaseCredits(
    credits: number,
    onSuccess?: (msg?: PaymentsModel) => void,
    onError?: () => void
  ): void {
    const self = this;

    self.http.post<PaymentsModel>(
      Session.enrichApiUrl(self.api.currentCompany.uris.Payments),
      JSON.stringify({'credits': credits} )
    ).subscribe(
      value => {
        console.log(value);
        onSuccess(value);
      },
      error1 => {
        console.error(error1);
        onError();
      }
    );
  }

  makePayment(data, uri: string = null, token: string = null) {
    return this.http.post<PaymentsModel>(
      Session.enrichApiUrl(uri || this.api.currentCompany.uris.Payments),
      data
    );
  }

  getCurrentBalance(): Observable<ICompanyBalance> {
    return this.http.get<ICompanyBalance>(`${ environment.apiUrl }${ this.api.company.uris.CurrentBalance }`);
  }
}
