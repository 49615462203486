<form #checkout="ngForm" (ngSubmit)="onSubmit(checkout)" class="checkout">
  <div class="form-row">
    <input type="text"
           class="form-control"
           placeholder="Cardholder name"
           style="margin-top: 10px;">

    <div id="card-info" #cardInfo></div>

    <small class="error" id="card-errors" role="alert" *ngIf="error">{{ error }}</small>
  </div>

  <button class="btn btn-theme btn-block" type="submit">Pay &pound;{{payment.amount_gross}} and go ahead</button>
</form>