import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FileService } from '../../../services/file/file.service';
import { OfferService } from '../../../services/offer/offer.service';
import { SessionService as Session } from '../../../services/session/session.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';


@Component({
  selector: 'app-artwork-confirmation',
  templateUrl: './artwork-confirmation.component.html',
  styleUrls: ['./artwork-confirmation.component.sass']
})
export class ArtworkConfirmationComponent implements OnInit {

  @ViewChild('videoPlayer') videoPlayer: ElementRef;


  // set to true on advanced settings
  @Input() advanced = false;
  @Input() previewSrc: string = undefined;
  public canEditArtwork = true;
  public fileSrc = null;

  @Output() closePopup = new EventEmitter();
  @Output() artworkConfirmed = new EventEmitter();
  @Output() uploadNew = new EventEmitter();

  startingCampaign = false;

  readonly img = 'image';
  readonly video = 'video';

  constructor(
    private activeModal: NgbActiveModal,
    private service: OfferService,
    private fileService: FileService,
  ) {
    this.canEditArtwork = this.service.canUploadNewArtwork();
  }

  ngOnInit(): void {
    this.getFileSrc();
  }

  leaveVideo(): void {
    this.videoPlayer.nativeElement.removeAttribute('controls');
  }
  enterVideo(): void {
    this.videoPlayer.nativeElement.setAttribute('controls', 'controls');
  }

  imgOrVideo(mimeType: string): string {
    if (mimeType.includes(this.video)) {
      return this.video;
    } else if (mimeType.includes(this.img)) {
      return this.img;
         }

    return null;
  }

  get chosenFormat() {
    return this.service.chosenFormat;
  }

  get fileFeedback() {
    return this.service.fileFeedback;
  }

  get currentOffer() {
    return this.service.currentOffer;
  }

  get artworkType() {
    if (this.fileService.campaignFiles.length > 0) {
      return this.fileService.getMimeType(this.fileService.campaignFiles[0]);
    } else {
      return 'image';
    }
  }

  getFileSrc(): void {
    if (this.fileService.campaignFiles.length > 0) {
      this.fileService.getArtworkSrcAsync(this.fileService.campaignFiles[0])
        .subscribe(url => this.fileSrc = url);
    } else {
      this.fileSrc = null;
    }
  }

  addedObjectDimensions() {
    if (!this.service.chosenFormat) {
      return {'height': '175px'};
    } else {

      const w = this.service.chosenFormat.artwork_spec.resolution.width;
      const h = this.service.chosenFormat.artwork_spec.resolution.height;
      let height = 100,
        width = 100;

      if (w > h) {
        height *= h / w;
      } else {
        width *= w / h;
      }

      return {

        'min-height': '1px',
        'min-width': '1px',

        'height': height + '%',
        'width': width + '%'
      };
    }
  }

  get imageStyles(): any {
    let url: string;

    if (this.service.uploadedFiles) {
      url = this.service.uploadedFiles.download_link;
    } else {
      url = this.service.currentOffer.media_file;
    }

    url = Session.enrichApiUrl(url);

    return {'background-image': `url('${url}')`};
  }


  // get fileSrc() {
  //   let url: string;
  //
  //   if (this.service.uploadedFiles) {
  //     url = this.service.uploadedFiles.download_link;
  //   } else {
  //     url = this.service.currentOffer.media_file;
  //   }
  //
  //   return Session.enrichApiUrl(url);
  // }


  _closePopup(): void {
    this.activeModal.dismiss();
  }
  _confirmCpgn(): void {
    this.startingCampaign = true;
    this.artworkConfirmed.emit();
  }
  _uploadNew(): void {
    this.uploadNew.emit();
  }
}
