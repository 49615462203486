import {Injectable, Injector} from '@angular/core';
import { map, share } from 'rxjs/operators';
import {DisplayGroup} from '../../models/DisplayGroups.model';
import {SuperService} from '../super-service/super.service';
import { Observable } from 'rxjs';


@Injectable()
export class DisplayGroupService extends SuperService {

  private observableShare = new Map<string, Observable<DisplayGroup>>();
  private observableResult = new Map<string, DisplayGroup>();

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  public getDisplayGroup(uri): Observable<DisplayGroup> {


    if (this.observableResult.get(uri)) {
       return new Observable<DisplayGroup>(subscriber => {
         subscriber.next(this.observableResult.get(uri));
         subscriber.complete();
       });
    } else if (this.observableShare.get(uri)) {
        return this.observableShare.get(uri);
    } else {
      const observable$ = this.http.get<DisplayGroup>(uri)
        .pipe(
          map(res => {
           this.observableResult.set(uri, res);
           return res;
         }),
          share(),
        );
       this.observableShare.set(uri, observable$);
       return this.observableShare.get(uri);
    }

    // if (this.observableResult[uri]) {
    //    return new Observable<DisplayGroup>(subscriber => {
    //      subscriber.next(this.observableResult[uri]);
    //      subscriber.complete();
    //    });
    // } else if (this.observableShare[uri]) {
    //     return this.observableShare[uri];
    // } else {
    //    this.observableShare[uri] = this.http.get<DisplayGroup>(uri)
    //      .map(res => {
    //        this.observableResult[uri] = res;
    //        return res;
    //      })
    //      .share();
    //   return this.observableShare[uri];
    // }
  }
}
