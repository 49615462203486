import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import {InstagramService} from './instagram.service';
import {SharedModule} from '../../shared/shared.module';
import {SelectorComponent} from './selector/selector.component';
import {PreviewComponent} from './preview/preview.component';
import {InstagramLayoutComponent} from './instagram-layout/instagram-layout.component';
import {FormsModule} from '@angular/forms';

export const routes: Routes = [
  {
    path: '',
    component: InstagramLayoutComponent,
    children: [
      {path: '', component: SelectorComponent},
      {path: 'preview', component: PreviewComponent}
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    FormsModule
  ],
  declarations: [
    SelectorComponent,
    PreviewComponent,
    InstagramLayoutComponent
  ],
  exports: [
    InstagramLayoutComponent
  ],
  providers: [
    InstagramService
  ]
})
export class InstagramModule { }
