import { Component, Input, OnInit } from '@angular/core';
import { ArtworkGeneratorService } from '@module/artwork-generator/artwork-generator.service';
import { IFormat } from '../../../../models/format.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-artwork-generator-btn',
  templateUrl: './artwork-generator-btn.component.html',
  styleUrls: ['./artwork-generator-btn.component.scss']
})
export class ArtworkGeneratorBtnComponent implements OnInit {

  @Input() format: IFormat;

  constructor(
    private artworkGeneratorService: ArtworkGeneratorService,
  ) {}

  ngOnInit(): void {
    this.artworkGeneratorService.format = this.format;
    this.artworkGeneratorService.getConfig().subscribe();
  }

  openGenerator(): void {
    this.artworkGeneratorService.toggle();
  }

  get isGeneratorReady(): Observable<boolean> {
    return this.artworkGeneratorService.generatorReady;
  }

}
