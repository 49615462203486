export const SCHEDULE_PATTERN_FIELDS: string [] = [
    'active', 'archived', 'class', 'created', 'description', 'id', 'text', 'type', 'uri', 'uris', '_db_class'
];

class SchedulePattern {

  active?: boolean;
  archived?: boolean;
  class?: string[];
  created?: string;
  description?: string;
  id?: number;
  text?: string;
  type?: string;
  uri?: string;
  uris?: {
    parent_resource?: string;
    StatusOnTarget?: string;
    schedules?: string
  };
  _db_class?: string[];
}

interface SchTime {
  all_day?: boolean;
  schedule_start?: string;
  schedule_stop?: string;
  time_start?: string;
  time_stop?: string;
  weekdays?: number[];
}
interface SchWeather {
  sky_condition?: string;
  temperature?: string;
}
interface SchDisruptions {
  disruptions?: string;
}

interface SchStockLevel {
  min_stock_level?: number;
}

interface SchAIM {
  age?: string;
  age_range?: number[];
  gender?: string;
  gender_range?: number[];
  min_visits?: number;
}

export class ScheduleTime extends SchedulePattern implements SchTime {
  all_day?: boolean;
  schedule_start?: string;
  schedule_stop?: string;
  time_start?: string;
  time_stop?: string;
  day_time_start?: string;
  day_time_stop?: string;
  weekdays?: number[];
}

export class ScheduleWeather extends SchedulePattern implements SchWeather {
  sky_condition?: string;
  temperature?: string;
}

export class ScheduleDisruptions extends SchedulePattern implements SchDisruptions {
  disruptions?: string;
}

export class ScheduleAIM extends SchedulePattern implements SchAIM {
  age?: string;
  age_range?: number[];
  gender?: string;
  gender_range?: number[];
  min_visits?: number;
}

export class ScheduleStockLevel extends SchedulePattern implements SchStockLevel {
  min_stock_level?: number;
}

export class ScheduleModel implements SchedulePattern, SchTime, SchWeather, SchDisruptions, SchAIM, SchStockLevel {
  _db_class?: string[];
  active?: boolean;
  age?: string;
  age_range?: number[];
  all_day?: boolean;
  archived?: boolean;
  class?: string[];
  created?: string;
  description?: string;
  disruptions?: string;
  gender?: string;
  gender_range?: number[];
  id?: number;
  min_visits?: number;
  schedule_start?: string;
  schedule_stop?: string;
  sky_condition?: string;
  temperature?: string;
  text?: string;
  time_start?: string;
  time_stop?: string;
  type?: string;
  uri?: string;
  uris?: { parent_resource?: string; StatusOnTarget?: string; schedules?: string };
  weekdays?: number[];
  min_stock_level?: number;
}
