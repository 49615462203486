import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gm;

@Component({
  selector: 'app-generate-qrcode',
  templateUrl: './generate-qrcode.component.html',
  styleUrls: ['./generate-qrcode.component.scss']
})
export class GenerateQrcodeComponent {
  @Output() closed = new EventEmitter();

  error = false;

  private _content: string;

  constructor(
    private activeModal: NgbActiveModal,
  ) {
  }

  get content(): string {
    return this._content;
  }

  set content(value) {
    this._content = value;
    this.error = false;
  }

  close(): void {
    this.activeModal.dismiss();
  }

  trimEntry() {
    this.content = this.content && this.content.trim();
  }

  generate(): void {
    this.error = false;
    this.trimEntry();
    if (!this.content || !this.content.match(URL_REGEX)) {
      this.error = true;
    } else {
      this.closed.emit(this.content);
      this.activeModal.dismiss();
    }
  }

}
