import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {CreateCampaignComponent} from './main-content/create-campaign/create-campaign.component';
import {WeatherComponent} from './main-content/offers/offer/campaign-schedule/weather/weather.component';
import {OffersComponent} from './main-content/offers/offers.component';
import {SensorsComponent} from './main-content/offers/offer/campaign-schedule/sensors/sensors.component';
import {VolumetricComponent} from './main-content/offers/offer/campaign-schedule/volumetrics/volumetric.component';
import {DateTimeComponent} from './main-content/offers/offer/campaign-schedule/date-time/date-time.component';
import {DemographicsComponent} from './main-content/offers/offer/campaign-schedule/demographics/demographics.component';
import {TilesComponent} from './main-content/offers/tiles/tiles.component';
import {OfferComponent} from './main-content/offers/offer/offer.component';
import {TransportComponent} from './main-content/offers/offer/campaign-schedule/transport/transport.component';
import {EposComponent} from './main-content/offers/offer/campaign-schedule/epos/epos.component';
import {CoreComponent} from './core.component';
import {PollenComponent} from './main-content/offers/offer/campaign-schedule/pollen/pollen.component';
import {AuthorisedGuard} from '../../_guards/authorised.guard';
import {OfferResolver} from '../../resolvers/offer.resolver';
import {SessionDataResolver} from '../../resolvers/sessionData.resolver';
import { StartFirstCampaignComponent } from './main-content/start-first-campaign/start-first-campaign.component';
import { BackwardGuard } from '@guards/backward.guard';
import { FormatsResolver } from '../../resolvers/formats.resolver';

export const MAIN_ROUTES: Routes = [
  {
    path: '',
    component: CoreComponent,
    canActivate: [AuthorisedGuard],
    canActivateChild: [AuthorisedGuard],
    resolve: { message: SessionDataResolver, formats: FormatsResolver },
    children: [
      {
        path: 'profile',
        loadChildren: () => import('../../modules/profile/profile.module').then(m => m.ProfileModule),
      },
      {
        path: 'start-first-campaign',
        component: StartFirstCampaignComponent,
      },
      {
        path: 'dashboard',
        loadChildren: () => import('../../modules/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'new-campaign',
        component: CreateCampaignComponent,
        canDeactivate: [BackwardGuard],
      },
      {
        path: 'offers',
        component: OffersComponent,
        children: [
          { path: '', component: TilesComponent },
          {
            path: ':id',
            component: OfferComponent,
            resolve: { message: OfferResolver },
            canDeactivate: [BackwardGuard],
          },
          { path: '**', redirectTo: '' }
        ]
      },
      {
        path: 'reports',
        loadChildren: () => import('../../modules/reporting/reporting.module').then(m => m.ReportingModule),
      },
      {
        path: 'campaign-management',
        loadChildren: () => import('../../modules/campaign-management/campaign-management.module').then(m => m.CampaignManagementModule),
      },
      {
        path: 'creative-spec',
        loadChildren: () => import('../../modules/creative-specs/creative-specs.module').then(m => m.CreativeSpecsModule),
      },
      {
        path: 'media-planner',
        loadChildren: () => import('../../modules/media-planner/media-planner.module').then(m => m.MediaPlannerModule),
      },
      {
        path: '**',
        redirectTo: 'dashboard'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(MAIN_ROUTES)],
  exports: [RouterModule],
})
export class CoreRoutingModule { }

export const MAIN_COMPONENTS = [
  CreateCampaignComponent,
  WeatherComponent,
  OffersComponent,
  SensorsComponent,
  VolumetricComponent,
  DateTimeComponent,
  DemographicsComponent,
  TilesComponent,
  OfferComponent,
  TransportComponent,
  EposComponent,
  CoreComponent,
  PollenComponent
];
