<div class="offer-schedule">

  <h5>This campaign will play on Public Transport conditions:</h5>

  <div class="text-center">
    <div class="btn-group date-range-selector">

      <button *ngFor="let trans of transport"
              style="text-transform: capitalize;"
              class="btn btn-default btn-disruption {{trans.iconClass}}"
              [class.active]="isTransActive(trans)"
              (click)="changeTrans(trans)">
        {{trans.description}}
      </button>

    </div>
  </div>
</div>
