import { Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { OfferAudiences, OfferService, SelectedAudience } from '../../../../../services/offer/offer.service';
import {OfferTarget} from '../../../../../models/offer-target.model';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {FormatsBlockDispModel} from '../../../../../models/FormatsBlockDisp.model';
import {DisplayGroup} from '../../../../../models/DisplayGroups.model';
import {SessionService as Session} from '../../../../../services/session/session.service';
import {HttpEventType} from '@angular/common/http';
import {trigger} from '@angular/animations';
import {SlideInOutReverse, SlideInOutReverseToggle} from '../../../../../additional/animations/animations';
import {BuyMoreCreditsComponent} from '../../../../../shared/modals/buy-more-credits/buy-more-credits.component';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest } from 'rxjs';
import {
  EstimatorService,
  IMultiEstimate
} from '../../../../../services/estimator/estimator.service';
import { debounce, debounceTime, distinctUntilChanged, filter, skip, take, takeUntil, tap } from 'rxjs/operators';
import { NotifyService } from '../../../../../services/notify/notify.service';
import { InstagramService } from '../../../../../modules/instagram/instagram.service';
import { CoreService } from '../../../../../services/main/core.service';
import { ToastrService } from 'ngx-toastr';
import { IFormat } from '../../../../../models/format.model';
import { Observable, Subject } from 'rxjs';
import { FileService } from '../../../../../services/file/file.service';
import { FormatsService } from '../../../../../services/formats/formats.service';
import { FileSendingData } from '../../../../../models/fileSendingData.model';
import { CampaignManagementConfirmComponent } from '../../../../../modules/campaign-management/campaign-management-confirm/campaign-management-confirm.component';
import { ArtworkConfirmationComponent } from '../../../../../shared/components/artwork-confirmation/artwork-confirmation.component';
import { CompanyModel } from '../../../../../models/session-init/session-data/company-model/company-model.model';
import { FeedbackService } from '../../../../../shared/services/feedback.service';
import { CombineLatestOperator } from 'rxjs/internal-compatibility';



@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.sass'],
  animations: [
    trigger('schedulesFocused', SlideInOutReverse),
    trigger('budgetFocused', SlideInOutReverse),
    trigger('audienceFocused', SlideInOutReverse),
    trigger('formatFocused', SlideInOutReverse),
    trigger('audienceLocationFocused', SlideInOutReverseToggle)
  ]
})
export class OfferComponent implements OnInit, OnDestroy {

  @ViewChild('afterSubmitPopup') afterSubmitPopup: ElementRef;
  @ViewChild('buyMoreCredits') buyMoreCredits: BuyMoreCreditsComponent;
  @ViewChild('fileToUpload') fileToUpload: ElementRef;
  @ViewChild('advancedOptions') advancedOptions: ElementRef;

  schedulesFocused = true;
  budgetFocused = false;
  audienceFocused = false;
  formatFocused = false;

  viewReady = false;

  _updateEstimator = new Subject();

  private _destroy$ = new Subject<any>();

  public offerAudiences: any;

  // Estimator related properties
  private _loadingEstimates = new BehaviorSubject<boolean>(null);
  public loadingEstimates$ = this._loadingEstimates.asObservable();

  public offerEstimations: IMultiEstimate[];

  offerTarget: OfferTarget[];

  fileSrc: SafeUrl;

  currentDepartment: any;
  location: any;
  timeScheduleUpdated: any;

  processing = false;

  formatsBlocks: FormatsBlockDispModel[] = [];
  displayGroups: DisplayGroup[] = [];

  fileFeedback = {
    fileUploading: false,
    filePercent: 0
  };

  displayTime = '10 days';
  processingToDraft = false;

  confirmationModal: NgbModalRef;
  afterSubmitModal: NgbModalRef;

  changedToDraft = false;
  company: CompanyModel;

  @HostListener('window:popstate', ['$event'])
  unloadHandler(event): boolean {
    event && event.preventDefault();
    this.coreService.isGoingBack = true;
    this.feedbackService.message = 'Please use in-app navigation...';
    return false;
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event): boolean {
    return false;
  }

  constructor(
    @Inject(Session) private session,
    @Inject(OfferService) public service,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private estimatorService: EstimatorService,
    private notifyService: NotifyService,
    private coreService: CoreService,
    public instagramService: InstagramService,
    private toastr: ToastrService,
    public fileService: FileService,
    private formatsService: FormatsService,
    private feedbackService: FeedbackService,
  ) {

    this.offerAudiences = new OfferAudiences(service);

    this.offerAudiences.changed
      .pipe(takeUntil(this._destroy$))
      .subscribe(
      () => this.onOfferAudienceUpdate()
      );

    this.service.api.currentSessionData$
      .pipe(
        filter(data => data !== null),
        distinctUntilChanged(),
        takeUntil(this._destroy$),
      )
      .subscribe(
      (data) => {
        this.company = data.company;
      }
    );

    this._updateEstimator
      .pipe(
        takeUntil(this._destroy$),
        debounceTime(2000),
      ).subscribe(() => this.updateEstimatedDisplays());


    combineLatest([
      this.fileService.checkQRCodes,
      this.fileService.artworksNotReady(true)
    ]).pipe(
      takeUntil(this._destroy$)
    ).subscribe(([qrcodesReady, artworksNotReady]) => {
      const message = [];
      if (artworksNotReady) {
        message.push('Wait until all artworks are ready...');
      }
      if (!qrcodesReady) {
        message.push('There are artworks with missing QR codes. Please generate them before submission.');
      }
      this.feedbackService.message = message.join('<br>');
    });
  }

  ngOnInit(): void {
    this.service.api.currentLocation
      .pipe(takeUntil(this._destroy$))
      .subscribe(location => this.location = location);

    combineLatest([
      this.service.timeScheduleUpdated,
      this.service.api.getLocation(),
      this.service.getTargets(true),
    ]).pipe(
      takeUntil(this._destroy$),
      distinctUntilChanged(),
    ).subscribe(([_, loc, _targets]) => {
      this.location = loc;
      const targets = _targets as OfferTarget[];
      targets.forEach(offerTarget => {
        this.offerAudiences.set({
          audience: offerTarget.target,
          target: offerTarget
        }).pipe(takeUntil(this._destroy$))
          .subscribe();
      });

      if (this.service.currentOffer) {
          this.processingToDraft = true;
          this.service.unpublishCampaign()
            .pipe(takeUntil(this._destroy$))
            .subscribe(
              () => {
                console.log('schedule updated');
                this.processingToDraft = false;
                this.modifyBudgetAndDuration(null);
              }
            );
        }
    });

    this.service.resetOffer = true;

    this.fileService.init(this.service.currentOffer, this.isRTB);

    if (this.service.currentOffer.offer_variant) {
      this.formatsService.availableFormats
        .pipe(
          filter(v => v !== null),
          takeUntil(this._destroy$)
        )
        .subscribe(
        data => this.service.chosenFormat = data.find(af => af.ooh_format === this.service.currentOffer.offer_variant),
      );
    }
  }

  editionMode(): boolean {
    const currentOffer = this.service.currentOffer;

    if (!currentOffer) {
      return false;
    }
    if (!currentOffer.locked) {
      if (!this.changedToDraft) {
        this.changedToDraft = true;
      }
      return true;
    }
    if (!this.changedToDraft) {
      this.changeToDraft();
    }
    return false;
  }

  modifySchedule(data): void {
    if (data.hasOwnProperty('duration')) {
      const duration = parseInt(data.duration, 10);
      this.service.setSchedulerDatesBasedOnDuration(duration, true);
    }
    this.modifyBudgetAndDuration(data);
  }

  updateEstimatedDisplays(): void {
    const payload = {...this.service.getCampaignForEstimation(), media_file_thumbnail: this.fileService.getAllThumbnails()};
    this.estimatorService.getEstimatesV3(payload)
        .pipe(
          takeUntil(this._destroy$),
      ).subscribe(
        data => {
          console.log(data);
        }
    );
  }

  modifyBudgetAndDuration(data): void {
    const hours = this.service.getSchedulerTimeHours();
    const budget = data && data.budget ? data.budget : this.service.currentOffer.budget;
    const duration = data && data.duration ? data.duration : this.service.currentOffer.duration;
    const dailyCap = this.isRTB ? (data && data.dailyCap ? data.dailyCap : this.service.currentOffer.budget_daily) : null;

    // update campaign params
    if (data) {
      this.service.currentOffer.budget = budget;
      this.service.currentOffer.duration = duration;
      if (this.isRTB) {
        this.service.currentOffer.budget_daily = dailyCap;
      }
    }

    const payload = {...this.service.getCampaignForEstimation(), media_file_thumbnail: this.fileService.getAllThumbnails()};

    if (!this.canEditParameters()) {
      return;
    }

    if (budget && budget > 0 && hours && duration && this.location && this.service.api.currentDepartment.radius) {
      setTimeout(() => this._loadingEstimates.next(true));
      this.estimatorService.getEstimatesV3(payload)
        .pipe(
          take(1),
          takeUntil(this._destroy$),
          tap(() => this._loadingEstimates.next(false)),
      ).subscribe(
          (_data) => {
            _data.audience_reach_results.forEach(est => {
              est.totalReach = est.formats.reduce((sum, item) => sum + item.reach, 0);
              est.totalRateCardReach = est.formats.reduce((sum, item) => sum + item.rate_card_reach, 0);
              est.totalImpressions = est.formats.reduce((sum, item) => sum + item.impressions, 0);
              est.totalRateCardImpressions = est.formats.reduce((sum, item) => sum + item.rate_card_impressions, 0);
            });
            this.offerEstimations = _data.audience_reach_results;
            this.offerAudiences.updateEstimates(this.offerEstimations);
            this.formatsService.updateFormatsEstimates(this.offerAudiences.get(), this.offerEstimations);
            this.updateOfferEstimates(true);
          },
          err => {
            this._loadingEstimates.next(false);
            this.notifyService.generateNotification(
                [{
                  title: 'Estimator problem',
                  alertContent: 'Please reload the page if the problem still exists contact administrator.'
                }]);
          }
      );

    }

  }

  showConfirmationPopup(): void {
    if (this.company.active_plan !== 'PLUS') {
      this.confirmationModal = this.modalService.open(ArtworkConfirmationComponent, {size: 'lg'});
      this.confirmationModal.componentInstance.advanced = true;
      this.confirmationModal.componentInstance.artworkConfirmed.subscribe(
        _ => this.startNow()
      );
    } else {
      this.startNow();
    }
  }

  // changeAudience(audience: DisplayGroup): void {
  //   const self = this;
  //
  //   if (!audience) {
  //     self.service.currentOffer.audience = null;
  //   } else {
  //     self.service.currentOffer.audience = audience.audience_code;
  //   }
  //   this.updateOfferEstimates();
  //   self.modifyOffer();
  // }

  get budget(): string {
    return this.service.currentOffer ? this.service.currentOffer.budget : '0';
  }

  get duration(): string {
    return this.service.currentOffer ? this.service.currentOffer.duration : '0';
  }

  get dailyCap(): string {
    return this.service.currentOffer ? this.service.currentOffer.budget_daily : '0';
  }

  buyMoreCreditsOpen(): void {
    const self = this;

    self.buyMoreCredits.openOptions();
  }


  editSchedules(): void {
    this.colapseAll();
    this.schedulesFocused = true;
  }
  editBudget(): void {
    this.colapseAll();
    this.budgetFocused = true;
  }
  editAudience(): void {
    this.colapseAll();
    this.audienceFocused = true;
  }
  editFormat(): void {
    this.colapseAll();
    this.formatFocused = true;
  }
  colapseAll(): void {
    this.schedulesFocused = false;
    this.budgetFocused = false;
    this.audienceFocused = false;
    this.formatFocused = false;
  }

  // region Description

  sendFileName(file): void {
    const self = this;
    self.service.sendFileName(file,
        data => self.sendFile(data, file),
        err => console.log(err)
      );
  }

  sendFile(sendingData: FileSendingData, file): void {
    const self = this;

    self.fileSrc = self.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(self.fileToUpload.nativeElement.files[0]));

    if (sendingData.upload_method !== 'put') {
      alert('Couldn\'t load image');
      return;
    }

    self.fileFeedback.fileUploading = true;

    self.service.sendFile(sendingData, file,
        (data, fileType) => {
          if (data.type === HttpEventType.UploadProgress) {
            self.fileFeedback.filePercent = Math.round(data.loaded / data.total * 100);
          } else if (data.type === HttpEventType.Response) {
            self.informServerAboutFile(sendingData, data, fileType);
               }
        },
        error => console.log(error)
    );
  }

  informServerAboutFile(sendingData, data, fileType: string): void {

    const self = this;

    self.service.informServerAboutFile(sendingData, data, fileType,
        _data => console.log(_data),
        err => console.error(err),
        () => {
          self.fileFeedback.fileUploading = false;
          self.fileFeedback.filePercent = 0;
        }
      );
  }
  // endregion

  mediaOptionsDisabled(): boolean {
    return !!this.service.currentOffer && this.service.currentOffer.locked || this.fileFeedback.fileUploading;
  }

  private changeToDraft(): void {
    this.processingToDraft = true;
    this.service.unpublishCampaign
    (
      () => {
        this.processingToDraft = false;
        this.changedToDraft = true;
        },
      err => this.startingCampaignError(err)
    );
  }

  triggersActive(): string {
    const self = this;

    let activeSchedulesAmount = 0;
    const schedules = [
      self.service.scheduleTime,
      self.service.scheduleWeather,
      self.service.scheduleDisruptions,
      self.service.scheduleAIM,
    ];

    for (const schedule of schedules) {
      if (self.service.isActive(schedule)) {
        ++activeSchedulesAmount;
      }
    }
    const plural = activeSchedulesAmount === 1 ? '' : 's';
    return `${activeSchedulesAmount} trigger${plural} active`;
  }


  private modifyOffer(): void {
    // this.service.save(true).subscribe();
    this.service.campaignUpdated();
  }

  startNow(): void {
    const self = this;

    self.service.startCampaign(
      () => self.sendCurrentOfferState(),
      err => self.startingCampaignError(err),
      self.service.scheduleTime
    );
  }

  public get canSubmit(): Observable<boolean> {
    return new Observable<boolean>(subscriber => {
      const state = !(this.service.currentOffer == null ||
      parseInt(this.service.currentOffer.budget, 10) < 1 ||
      !this.service.currentOffer.offer_type ||
      !this.service.currentOffer.offer_variant ||
      !this.service.currentOffer.audience);

      combineLatest([
        this.fileService.checkQRCodes,
        this.fileService.artworksNotReady()
      ]).pipe(
        takeUntil(this._destroy$)
      ).subscribe(([qrcodesReady, artworksNotReady]) => {
        subscriber.next(state && qrcodesReady && !artworksNotReady);
        subscriber.complete();
      });
    });
  }

  submitCampaign(displayGroup: DisplayGroup): void {
    const status = 'submitted';
    const targets = this.offerAudiences.get().map(a => a.target.uri);

    this.service.setTargetsStatus(targets, status)
      .subscribe(
        () => {
          this.service.fetch().subscribe(
            () => {
              this.confirmationModal && this.confirmationModal.close();
              this.afterSubmitModal = this.modalService.open(this.afterSubmitPopup, {size: 'lg', backdrop: 'static'});
            },
            err => this.startingCampaignError(err)
          );
        },
        err => this.startingCampaignError(err)
      );
  }

  sendCurrentOfferState(): void {
    // const self = this;
    this.service.save(true)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
      () => this.submitCampaign(this.service.chosenAudience),
      (err) => this.startingCampaignError(err)
    );
  }

  private startingCampaignError(error): void {
    console.log('startingCampaignError');
    this.processing = false;
    console.error(error);
  }

  backToTiles(): void {
    if (this.afterSubmitModal) {
      this.afterSubmitModal.close();
      this.service.router.navigate(['offers']);
    } else {
      this.service.saveOffer().subscribe(
        () => this.service.router.navigate(['offers'])
      );
    }
  }

  /**
   * Determines if the campaing section can be edited by the user
   * Campaigns' parameters created from predefined templates should not be modified by the user by default
   * @returns {boolean}
   */
  canEditParameters(): boolean {
    return this.service.currentOffer.offer_origin !== 'template' && this.service.currentOffer.offer_origin !== 'preconstructed';
  }

  canEditArtwork(): boolean {
    return this.service.currentOffer.offer_origin !== 'preconstructed';
  }

  get notUploaded(): Observable<any> {
    return new Observable(observer => {
      if (this.service.getArtworkUrl() !== '') {
        observer.next(false);
        observer.complete();
      } else {
        this.fileService.uploadedFile
          .subscribe(val => {
            observer.next(val === null);
            observer.complete();
          });
      }
    });
  }

  ngOnDestroy() {
    this.service.resetCurrentOffer();
    this.fileService.reset();
    this.feedbackService.clear();
    this._destroy$.next();
    this._destroy$.complete();
  }

  get postcode(): string {
    return (this.service.currentOffer && this.service.currentOffer.offer_postcode) || this.location.postcode;
  }

  get latitude(): number {
    return (this.service.currentOffer && this.service.currentOffer.latitude) || this.location.latitude;
  }

  get longitude(): number {
    return (this.service.currentOffer && this.service.currentOffer.longitude) || this.location.longitude;
  }

  get locationMap(): string {
    return (this.service.currentOffer && this.service.currentOffer.location_map);
  }

  get radius(): number {
    return (this.service.currentOffer.offer_radius !== null && this.service.currentOffer.offer_radius >= 0)
      ? this.service.currentOffer.offer_radius
      : this.service.api.currentDepartment.radius;
  }

  onLocationChange(data) {
    this.service.currentOffer.latitude = data.latitude;
    this.service.currentOffer.longitude = data.longitude;
    this.service.currentOffer.offer_postcode = data.postalCode;
    this.service.currentOffer.offer_radius = data.radius;
    this.service.currentOffer.location_map = data.locationMap;
    this.modifyBudgetAndDuration(null);
  }

  get sidebarShown(): boolean {
    return this.coreService.sidebarShown;
  }

  get isRTB() {
    return this.service.impressionModel === 'rtb';
  }

  get isRegular() {
    return this.service.impressionModel === 'regular';
  }

  // TODO new multi audience select
  updateAudiences(selectedAudience: SelectedAudience): void {
    this.offerAudiences.toggle([selectedAudience])
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        () => {
          this.offerAudiences.updateEstimates(this.offerEstimations);
          this.updateOfferEstimates();
          const currAudience = this.offerAudiences.getSingle();
          if (currAudience) {
            this.service.estimatedBudget = {
              budget: parseInt(this.service.currentOffer.budget, 10),
              estimatedSpend: currAudience ? currAudience.overall_demand_spend : 0,
            };
          } else {
            this.service.estimatedBudget = null;
          }
        }
    );
  }

  private updateOfferEstimates(skipEstimation = false) {
    const currAudience = this.offerAudiences.getSingle();
    if (currAudience) {
      this.formatsService.updateFormatsEstimates(this.offerAudiences.get(), this.offerEstimations);
      this.service.currentOffer.audience = currAudience.audience.audience_code;
      this.service.currentOffer.reach = currAudience.reach;

      this.service.estimatedBudget = {
        budget: parseInt(this.service.currentOffer.budget, 10),
        estimatedSpend: currAudience.overall_demand_spend || 0,
      };

      if (!skipEstimation) {
        this._updateEstimator.next();
      }
    } else {
      this.service.currentOffer.audience = '';
      this.service.currentOffer.reach = null;
      this.service.currentOffer.offer_variant = '';
      if (!skipEstimation) {
        this.modifyBudgetAndDuration(null);
      }
    }

    if (this.service.chosenFormat) {
      this.service.chosenFormat = this.formatsService.getCurrentFormat(this.service.chosenFormat);
      this.service.currentOffer.num_of_screens = this.service.chosenFormat.number;
      this.service.currentOffer.ad_plays = this.service.chosenFormat.ad_plays;
      this.service.currentOffer.reach = this.service.chosenFormat.reach;

      this.service.estimatedBudget = {
        budget: parseInt(this.service.currentOffer.budget, 10),
        estimatedSpend: this.service.chosenFormat.demand_spend || 0,
      };

    } else {
      this.service.currentOffer.num_of_screens = null;
      this.service.currentOffer.ad_plays = null;
    }
  }

  private onOfferAudienceUpdate(): void {
    console.log('=== AUDIENCES CHANGED ===');
    if (this.offerEstimations) {
      const audienceUris = this.offerAudiences.get().map(aud => aud.audience.uri);

      // this.offerService.chosenFormat
      const formatList = [];
      this.offerEstimations
          .filter(oe => audienceUris.includes(oe.audience_uri))
          .map(a => { a.formats.map(f => f.format && formatList.push(f.format)); });
      console.log(formatList);
    }
  }

  selectFormat(chosenFormat: IFormat): void {
    this.service.campaignFormats = chosenFormat ? [chosenFormat.ooh_format] : null;
    this.onSelectedFormatsChanged();
  }

  get chosenFormat(): IFormat {
    return this.service.chosenFormat;
  }

  onSelectedFormatsChanged(): void {
    const audience = this.offerAudiences.getSingle();
    this.service.updateCampaignFormatData(audience);
    this._updateEstimator.next();
  }

  get isMultiArtwork(): boolean {
    return this.isRTB;
  }

  get selectedAudiencesNumber(): number {
    return this.offerAudiences.get().length;
  }

  get selectedFormatsNumber(): number {
    return this.service.campaignFormats ? this.service.campaignFormats.length : 0;
  }

  get allFormatsReady(): Observable<any> {
    return this.formatsService.allFormatsReady(this.service.campaignFormats, this.fileService.campaignFiles);
  }

}
