import { Component, EventEmitter, Input, Output, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { OfferService } from '../../../../../services/offer/offer.service';
import { Subject } from 'rxjs';
import { filter, skip, takeUntil } from 'rxjs/operators';


interface BudgetData {
  budget?: string;
  duration?: string;
  dailyCap?: string;
}


export interface ICampaignBudget {
  budget?: number;
  duration?: number;
  dailyCap?: number;
}

@Component({
  selector: 'app-rtb-budget',
  templateUrl: './rtb-budget.component.html',
  styleUrls: ['./rtb-budget.component.scss'],
})
export class RtbBudgetComponent implements OnInit, OnDestroy, OnChanges {

  @Input() collapsible = false;
  collapsed = true;
  @Input() disableDuration = false;
  @Output() data = new EventEmitter();

  skipEmit = true;

  private _destroy$ = new Subject<any>();

  public currentValues: ICampaignBudget = {
    budget: 0,
    duration: 1,
    dailyCap: 1,
  };

  constructor(
      private offerService: OfferService,
  ) {}

  ngOnInit(): void {
    this.currentValues = {
      budget: parseInt(this.offerService.currentOffer.budget, 10),
      duration: parseInt(this.offerService.currentOffer.duration, 10),
    };

    if (this.isRTB) {
      this.currentValues.dailyCap = this.offerService.currentOffer.budget_daily ? parseInt(this.offerService.currentOffer.budget_daily, 10) : 0;
    }

    if (this.disableDuration) {
      this.offerService.offerDuration$
        .pipe(
          skip(1),
          filter(d => d !== null),
          takeUntil(this._destroy$),
        )
        .subscribe(
          duration => this.updateData(duration, 'duration', this.skipEmit),
        );
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.prop && !changes.prop.isFirstChange()) {
      this.skipEmit = false;
    }
  }

  updateData(value, key = 'budget', skipEmit = false): void {
    if (!this.offerService.isLocked) {
      this.currentValues[key] = value;
      const data: BudgetData = {};

      const result = this.recalculateData(this.currentValues, key);

      // convert to string
      Object.keys(result).forEach(k => data[k] = result[k].toString());
      this.currentValues = {...this.currentValues, ...result};
      console.log(data);
      !skipEmit && this.data.emit(data);
    }
  }

  private recalculateData(data, trigger: string): ICampaignBudget {
    const result: ICampaignBudget = {};
    result[trigger] = data[trigger];
    if (this.isRTB) {
      if (trigger === 'budget' || trigger === 'duration') {
        // (overall budget / duration) + 20%
        result.dailyCap = Math.floor((data.budget / data.duration) * 1.2);
      }
    }
    return result;
  }

  isCollapsed(): boolean {
    return this.collapsible && this.collapsed;
  }

  get isRTB(): boolean {
    return this.offerService.isRTB;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
