<ng-template #deleteConfirmation let-d="dismiss">
  <div class="modal-content delete-offer-modal">
    <div class="modal-header">
      <h3>
        Confirmation
      </h3>
    </div>

    <div class="modal-body">
      <p>
        Do you really want to delete this campaign?
      </p>
    </div>

    <div class="modal-footer">
      <button (click)="requestDeletion()"
              [attr.disabled]="awaitingResponse ? '' : null"
              class="btn ok-btn">
        <span [style.visibility]="awaitingResponse ? 'hidden' : ''">Ok</span>
        <app-loading *ngIf="awaitingResponse"></app-loading>
      </button>
      <button (click)="d()"
              [attr.disabled]="awaitingResponse ? '' : null"
              class="btn cancel-btn">
        Cancel
      </button>
    </div>
  </div>
</ng-template>


<div class="inner {{ offer.offer_type }}"
     [class.inactive]="!isOnline"
     [@tileRemoval]="tileState">


  <div (click)="showOffer()" class="offer-tile">

    <ng-container *ngIf="campaignFormats">
      <div class="format"
           *ngFor="let format of campaignFormats"
           [title]="format.ooh_format_label + ' (' + format.artwork_spec.resolution.width + 'x' + format.artwork_spec.resolution.height + ')'">
        <div class="inlineSVG" [inlineSVG]="formatIcon(format)"></div>
      </div>
    </ng-container>

    <ng-container *ngIf="offerArtworksPreview.length > 1; else SINGLE_ARTWORK">
      <img *ngFor="let artwork of offerArtworksPreview; let i = index"
           class="offer-thumbnail"
           [src]="artwork"
           [class.active]="i === currentArtwork"
           alt="">
    </ng-container>
    <ng-template #SINGLE_ARTWORK>
      <img class="offer-thumbnail active"
           *ngIf="offerImagePreview"
           [src]="offerImagePreview"
           alt="">
      <div class="offer-thumbnail active"
           *ngIf="!offerImagePreview"></div>
    </ng-template>

    <app-status-indicator
      [targets]="offer.offer_targets">
    </app-status-indicator>

    <div (click)="deleteOffer($event);"
         *ngIf="!offerStatus || offerStatus === 'draft'"
         class="delete-offer">&#215;</div>
  </div>
  <div class="tile-info">
    <span class="campaign-name">{{ offer.name }}</span>
    <span class="audience-name">{{ audience?.display_name }}</span>
  </div>

  <div class="loading"
       style="display: block"
       *ngIf="awaitingResponse">
  </div>

</div>
