<div class="format">

<!--  <div class="activate-btn">-->
<!--    <mat-icon>-->
<!--        <fa-icon [icon]="['far', 'check-circle']"></fa-icon>-->
<!--    </mat-icon>-->
<!--  </div>-->

  <div class="header">
    <div class="format-icon">
      <div class="af_icon">
        <img [src]="formatIcon">
      </div>
    </div>
    <div class="format-desc">
      <div class="format-desc-details">
        <p>{{ format.ooh_format_label }}</p>
        <small [innerHTML]="formatDesc"></small>
      </div>

      <div class="format-stats">
        <p>Estimated Budget<span>&pound;{{ (format.demand_spend || 0) | number:'1.0-0'}}</span></p>
      </div>

      <div class="format-stats">
        <p>No of Screens<span>{{ format.number | number:'1.2-2'}}</span></p>
      </div>

      <div class="format-stats">
        <p>Artworks<span>{{ formatArtworks.length || 0 }}</span></p>
      </div>

    </div>
  </div>

  <div class="artworks">
    <ng-container *ngIf="service.isMulti && formatArtworks.length > 0; else NO_ARTWORKS">
      <div class="file-thumbnail-preview"
           *ngFor="let file of formatArtworks"
           (click)="onThumbnailClick(file)"
           [ngStyle]="thumbnailPreviewStyle"
           [style.backgroundImage]="thumbnailPreviewImage(file.thumbnail)">
        <div class="circular-progress"
             [class.hidden]="!file._progress"
             [attr.data-percent]="file._progress + '%'"
             [ngStyle]="uploadProgressStyle(file._progress)"></div>
        <div class="delete-btn" (click)="removeItem(file, $event)">&#10005;</div>
      </div>
    </ng-container>

    <ng-template #NO_ARTWORKS>
      <div class="no-artworks">No artworks for this format</div>
    </ng-template>

  </div>

</div>
