<a (click)="close()" class="close">
  <img class="inlineSVG" src="assets/images/icons/dashboard_2018/closing_cross.svg">
</a>

<div class="panel-heading">
  <h1>Generate QR Code</h1>
</div>

<div class="panel-body">
  <div class="data-row" style="position: relative">
    <div class="form-group"
         [class.has-error]="error">
      <label class="control-label">Enter URL</label>
      <input [(ngModel)]="content"
             class="form-control"
             name="content"
             (blur)="trimEntry()"
             required/>
      <span class="error" *ngIf="error">Please provide valid URL</span>
    </div>
  </div>
</div>
<div class="panel-footer">
  <button
      (click)="close()"
      class="btn fc-btn-big fc-btn-transparent">
    Cancel
  </button>
  <button
      (click)="generate()"
      [disabled]="!content || error"
      class="btn fc-btn-big">
    Generate
  </button>
</div>
