<div class="panel-header">
  <div class="back"
       *ngIf="format"
       (click)="onDismiss()">
    <fa-icon [icon]="['fas', 'chevron-right']"></fa-icon>
  </div>
  <h5>Artwork Preview</h5>
</div>

<div class="panel-body artwork-area">
    <app-artwork-preview
      [previewSrc]="src"
      [fileType]="mimeType"
      [chosenFormat]="format"
      [uploadButton]="false"
      [enableCarousel]="false">
    </app-artwork-preview>
</div>

<div class="panel-footer">
  <button class="btn fc-button">Remove artwork</button>
</div>
