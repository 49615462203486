import {
  Component, ElementRef, ViewChild, ChangeDetectorRef, AfterViewInit, OnDestroy, Input,
  Output, EventEmitter
} from '@angular/core';
import {StripePaymentService} from '../../../services/stripe-payment/stripe-payment.service';
import {NgForm} from '@angular/forms';
import {PaymentsModel} from '../../../models/session-init/session-data/company-model/payments/payments.model';

@Component({
  selector: 'app-stripe-payment',
  templateUrl: './stripe-payment.component.html',
  styleUrls: ['./stripe-payment.component.sass']
})
export class StripePaymentComponent implements AfterViewInit, OnDestroy {

  elements: any;
  @ViewChild('cardInfo') cardInfo: ElementRef;

  @Input() payment: PaymentsModel;
  @Input() initial = false;
  @Output() onComplete: EventEmitter<any> = new EventEmitter();
  @Output() pending: EventEmitter<any> = new EventEmitter();

  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;

  constructor(
    private sps: StripePaymentService,
    private cd: ChangeDetectorRef
  ) {
    this.elements = this.sps.stripe.elements({
      fonts: [{
          cssSrc: '/static/css/flow-ads-default-minified.css'
        }]
    });
  }

  ngAfterViewInit() {
    this.card = this.elements.create('card', {
      classes: {
        base: 'form-control'
      },
      style: {
        base: {
          fontFamily: 'GTWalsheim,Helvetica,\'sans-serif\'',
          borderRadius: '0'
        }
      },
      hidePostalCode: true
    });
    this.card.mount(this.cardInfo.nativeElement);

    this.card.addEventListener('change', this.cardHandler);
  }

  ngOnDestroy() {
    this.card.removeEventListener('change', this.cardHandler);
    this.card.destroy();
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  async onSubmit(form: NgForm) {
    this.error = null;
    this.pending.emit(true);
    const { token, error } = await this.sps.stripe.createToken(this.card);
    if (error) {
      console.log('Something is wrong:', error);
      this.pending.emit(false);
      if (error.type !== 'validation_error') {
        this.error = error.message;
      }
    } else {
      console.log('Success!', token);
      if (token.id) {
        this.sps.setPaymentToken(this.payment.uri, token.id, sessionStorage.getItem('tmp-session'), this.initial)
          .subscribe(
            (data) => {
              (<any>window).ga('gtm1.send', 'event', 'purchase', 'sent', `camden${this.payment.amount}`);
              this.pending.emit(false);
              this.onComplete.emit(data);
            },
            (response) => {
              this.pending.emit(false);
              this.error = response.error.reason;
              console.log(response);
            }
          );
      }
    }
  }

}
