<form [formGroup]="billingInfoForm">

  <div class="row">
    <div class="form-group col-sm-7">
      <label class="control-label">Company</label>
      <input type="text"
             class="form-control"
             name="company_name"
             formControlName="company_name"
             autocomplete="organization"
             maxlength="40"
             placeholder="Company name"/>

      <div *ngFor="let validation of validation_messages.company_name">
          <small class="error"
                 *ngIf="billingInfoForm.get('company_name').hasError(validation.type) &&
                        (billingInfoForm.get('company_name').dirty || billingInfoForm.get('company_name').touched)">
            {{validation.message}}</small>
      </div>
    </div>

    <div class="form-group col-sm-5">
        <label class="control-label">VAT ID</label>
        <input type="text"
               class="form-control"
               name="billing_taxid"
               formControlName="billing_taxid"
               placeholder="VAT ID"/>
      <div *ngFor="let validation of validation_messages.billing_taxid">
        <small class="error"
               *ngIf="billingInfoForm.get('billing_taxid').hasError(validation.type) &&
                      (billingInfoForm.get('billing_taxid').dirty || billingInfoForm.get('billing_taxid').touched)">
          {{validation.message}}</small>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-sm-6">
      <label class="control-label">First name</label>
      <input type="text"
             class="form-control"
             name="first_name"
             maxlength="50"
             formControlName="first_name"
             autocomplete="given-name"
             placeholder="First name"/>

      <div *ngFor="let validation of validation_messages.first_name">
          <small class="error"
                 *ngIf="billingInfoForm.get('first_name').hasError(validation.type) &&
                        (billingInfoForm.get('first_name').dirty || billingInfoForm.get('first_name').touched)">
            {{validation.message}}</small>
      </div>
    </div>

    <div class="form-group col-sm-6">
      <label class="control-label">Last name</label>
      <input type="text"
             class="form-control"
             name="last_name"
             maxlength="50"
             formControlName="last_name"
             autocomplete="family-name"
             placeholder="Last name"/>
      <div *ngFor="let validation of validation_messages.last_name">
          <small class="error"
                 *ngIf="billingInfoForm.get('last_name').hasError(validation.type) &&
                        (billingInfoForm.get('last_name').dirty || billingInfoForm.get('last_name').touched)">
            {{validation.message}}</small>
      </div>
    </div>
  </div>


    <div class="form-group">
      <label class="control-label">Address</label>
      <input type="text"
             class="form-control"
             name="billing_address1"
             maxlength="250"
             formControlName="billing_address1"
             autocomplete="address-line1"
             placeholder="Address line 1"/>
      <div *ngFor="let validation of validation_messages.billing_address1">
        <small class="error"
               *ngIf="billingInfoForm.get('billing_address1').hasError(validation.type) &&
                      (billingInfoForm.get('billing_address1').dirty || billingInfoForm.get('billing_address1').touched)">
          {{validation.message}}</small>
      </div>
    </div>

    <div class="form-group">
      <label class="control-label">Address - line 2</label>
      <input type="text"
             class="form-control"
             maxlength="250"
             name="billing_address2"
             autocomplete="address-line2"
             formControlName="billing_address2"
             placeholder="Address line 2"/>
    </div>

    <div class="row">
      <div class="form-group col-sm-4">
        <label class="control-label">Post code</label>
        <input type="text"
               class="form-control"
               name="billing_postcode"
               maxlength="15"
               formControlName="billing_postcode"
               autocomplete="postal-code"
               placeholder="Post code"/>
        <div *ngFor="let validation of validation_messages.billing_postcode">
          <small class="error"
                 *ngIf="billingInfoForm.get('billing_postcode').hasError(validation.type) &&
                        (billingInfoForm.get('billing_postcode').dirty || billingInfoForm.get('billing_postcode').touched)">
          {{validation.message}}</small>
        </div>
      </div>

      <div class="form-group col-sm-8">
        <label class="control-label">City</label>
        <input type="text"
               class="form-control"
               name="billing_town"
               formControlName="billing_town"
               autocomplete="address-level2"
               placeholder="City"/>
        <div *ngFor="let validation of validation_messages.billing_town">
          <small class="error"
                 *ngIf="billingInfoForm.get('billing_town').hasError(validation.type) &&
                        (billingInfoForm.get('billing_town').dirty || billingInfoForm.get('billing_town').touched)">
            {{validation.message}}</small>
        </div>
      </div>
    </div>
</form>

    <div class="row">
      <div class="form-group phone-field">
        <label class="control-label">Phone No.</label>

<!--        <international-phone-number-->
<!--                class="phone-input"-->
<!--                placeholder="Enter phone number" [maxlength]="20" [defaultCountry]="'gb'"-->
<!--                [ngModel]="billingInfo?.phone"-->
<!--                (ngModelChange)="updatePhone($event)"-->
<!--                name="phone_number">-->
<!--        </international-phone-number>-->

      </div>
    </div>
