import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { SafeUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class IconItemService {

  private imageCache = new Map<string, SafeUrl>();

  constructor(
    private http: HttpClient,
  ) { }

  public toCache(key: string, value: SafeUrl): void {
    this.imageCache.set(key, value);
  }

  public fromCache(key: string): SafeUrl {
    return this.imageCache.get(key);
  }

  public getIcon(path: string, genericIcon: string): Observable<any> {
    return this.http.head(path)
      .pipe(switchMap(() => this.http.get(path, {observe: 'response' as 'body', responseType: 'blob' as 'json'})),
        catchError(() => {
          if (genericIcon) {
            return this.http.get(genericIcon, {observe: 'response' as 'body', responseType: 'blob' as 'json'});
          } else {
            return of(null);
          }
        }));
  }

}
