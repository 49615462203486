
  <figure class="avatar-wrapper perm-active">
    <img class="avatar-image" [src]="avatar" *ngIf="user && isAvatarUploaded">
    <img class="avatar-image" src="/assets/images/avatar.svg" *ngIf="!user || !isAvatarUploaded">
  </figure>

  <div class="user-info--loading" *ngIf="!user && !company">
    <div class="animated-background">
      <div class="background-masker user-line"></div>
      <div class="background-masker user-line-left"></div>
      <div class="background-masker user-line-right"></div>
    </div>
  </div>

  <div class="user-info" *ngIf="user && company">
    <span class="user-info__desc" *ngIf="user">
      <span *ngIf="user.text !== ' '; else emailOnly">{{user.displayName}}</span>
      <ng-template #emailOnly>
        <span>{{user.email}}</span>
      </ng-template>
    </span>
    <span class="user-info__company" *ngIf="company">{{company.name}}</span>
  </div>


