import { Component, OnInit } from '@angular/core';
import { FeedbackService } from '../../services/feedback.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

  public message = null;

  constructor(
    private feedbackService: FeedbackService,
  ) {
    this.feedbackService.message$.subscribe(
      message => this.message = message,
    );
  }

  ngOnInit(): void {
  }

  public dismiss(): void {
    this.feedbackService.clear();
  }

}
