import {Injectable, Injector} from '@angular/core';
import 'fabric';
import {FormatsBlockDispModel} from '../../models/FormatsBlockDisp.model';
import {SuperService} from '../super-service/super.service';
import {Observable} from 'rxjs';
import * as WebFont from 'webfontloader';
import * as qrcode from './qrcode';
import { combineLatest } from 'rxjs';

declare const fabric: any;

@Injectable()
export class ArtworkGeneratorService extends SuperService {

  private canvas: any;
  private json: JSON;

  readonly layoutLansdcape = 'assets/json/layout-landscape.json';

  constructor(
    injector: Injector
  ) {
    super(injector);
    WebFont.load({
      google: {
        families: ['Open Sans']
      }
    });
  }

  prepare(config) {
    this.canvas = new fabric.Canvas('canvas', {
      hoverCursor: 'pointer',
      selection: false,
      selectionBorderColor: 'blue',
      selectionLineWidth: 5
    });

    this.canvas.setDimensions({width: config.layout.width, height: config.layout.height});
    this.canvas.backgroundColor = '#ffffff';
  }

  getImage() {
    if (!fabric.Canvas.supports('toDataURL')) {
      alert('This browser doesn\'t provide means to serialize canvas to an image');
    } else {
      return this.canvas.toDataURL('png');
    }
  }

  loadImage(url) {
    return new Observable(subscriber => {
      fabric.Image.fromURL(url, (image) => {
        subscriber.next(image.toDataUrl());
        subscriber.complete();
      });
    });
  }

  getArtwork(config): Observable<string> {
    // and load everything from the same json
    this.prepare(config);

    return new Observable(subscriber => {

      this.http.get<JSON>(config.layout.src)
        .subscribe(
        json => {
          this.json = json;

          this.setObjectProperty('agent-name', 'text', config.offer.agent_name.replace(' - ', '\n'));
          this.setObjectProperty('description', 'text', `${config.offer.num_bedrooms} bed ${config.offer.property_type.toLowerCase() || 'property'} ${config.layout.postcodeSeparate ? '\n' : ' '}in ${config.offer.outcode}`);
          this.setObjectProperty('price', 'text', `£ ${parseInt(config.offer.price, 10).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').split('.')[0]}${config.offer.status === 'to_rent' ? ' pw' : ''}`);
          this.setObjectProperty('transaction', 'text', config.offer.status.replace('_', config.layout.postcodeSeparate ? '\n' : ' ').toUpperCase());
          this.loadJSON(this.json, config).subscribe(
            () => {
              subscriber.next(this.getImage());
              subscriber.complete();
            }
          );
        },
        err => {
          console.error(err);
        }
      );
    });

  }

  private setObjectProperty(id: string, property: string, value: string) {
    const obj = this.json['objects'].find((o) => o.id === id);
    if (obj && obj[property]) {
      obj[property] = value;
    }
  }

  private setImage(url, params) {
    return new Observable(subscriber => {
      fabric.Image.fromURL(url, (image) => {
        const scale = params.baseline / (params.scaleWidth ? image.width : image.height);
        image.set({
          left: params.left,
          top: params.top,
          scaleX: scale,
          scaleY: scale
        });
        this.canvas.add(image);

        if (params.toBack) {
          image.sendToBack();
        }

        subscriber.next();
        subscriber.complete();
      });
    });
  }

  private generateQRCode(url, params) {
    const self = this;
    return new Observable(subscriber => {
      const QRCode = qrcode(0, 'M');
      QRCode.addData(url);
      QRCode.make();
      const data = QRCode.createDataURL(5, 20);
      console.log(data);
      self.setImage(data, params)
        .subscribe(
          () => {
            subscriber.next(data);
            subscriber.complete();
          });
    });
  }

  loadJSON(layoutJSON, config): Observable<any> {
    return new Observable(subscriber => {
      console.log(layoutJSON);
      this.canvas.loadFromJSON(layoutJSON, () => {

        combineLatest([
          this.setImage(config.offer.logo, config.layout.images.logo),
          this.setImage(config.offer.img, config.layout.images.photo),
          this.generateQRCode(config.offer.redirectUrl, config.layout.images.qrcode)
        ]).subscribe(() => {
            this.canvas.renderAll();
            console.log(this.canvas);
            subscriber.next({});
            subscriber.complete();
          });
      });
    });
  }

}


