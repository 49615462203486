import {Component, Inject} from '@angular/core';
import {OfferService} from '../../../../../../../services/offer/offer.service';

@Component({
  selector: 'app-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.sass']
})
export class WeatherComponent {

  weatherTemp: WeatherButton[] = WEATHER_TEMP;
  weatherSky: WeatherButton[] = WEATHER_SKY;

  constructor(
      @Inject(OfferService) public service
  ) { }

  changeSky(sky: WeatherButton) {
    this.service.editWeatherSchedule('sky_condition', sky.value);
  }

  changeTemp(temp: WeatherButton) {
    this.service.editWeatherSchedule('temperature', temp.value);
  }

  isSkyActive(sky: WeatherButton): boolean {
    const sw = this.service.scheduleWeather;
    if (sw) {
      return sw.sky_condition === sky.value;
    }

    return sky.value.length < 1;
  }

  isTempActive(temp: WeatherButton): boolean {
    const sw = this.service.scheduleWeather;
    if (sw) {
      return sw.temperature === temp.value;
    }

    return temp.value.length < 1;
  }
}

interface WeatherButton {
  value: string;
  iconClass: string;
}

const WEATHER_TEMP: WeatherButton[] = [
  {
    value: '',
    iconClass: 'icon-temperature-any'
  },
  {
    value: 'cold',
    iconClass: 'icon-temperature-cold'
  },
  {
    value: 'normal',
    iconClass: 'icon-temperature-normal'
  },
  {
    value: 'hot',
    iconClass: 'icon-temperature-hot'
  }
];

const WEATHER_SKY: WeatherButton[] = [
  {
    value: '',
    iconClass: 'icon-weather-any'
  },
  {
    value: 'clear',
    iconClass: 'icon-weather-clear'
  },
  {
    value: 'cloudy',
    iconClass: 'icon-weather-cloud'
  },
  {
    value: 'rain',
    iconClass: 'icon-weather-rain'
  },
  {
    value: 'snow',
    iconClass: 'icon-weather-snow'
  }
];
