<div class="budget-thumb-railway" [class.editable]="editable">
  <div class="budget-thumb-label"
    [style.left.%]="bubblePos">
    <ng-container *ngIf="editable; else valueOnly">
      <div class="input-control right"
           *ngIf="editable"
           (click)="toggleEditable(true)">
        <i class="fa fa-check"></i>
      </div>

      <input currencyMask
             *ngIf="editable"
             [(ngModel)]="manualValue"
             maxlength="7"
             max="1000000"
             [options]="currencyOptions">

      <div class="input-control left"
           *ngIf="editable"
           (click)="toggleEditable(false)">
        <i class="fa fa-times"></i>
      </div>

    </ng-container>

    <ng-template #valueOnly>
      <h3 (click)="toggleEditable(false)">{{sliderUsed ? scaleTransform(rangeValue) : displayedValue}}</h3>
    </ng-template>

    <p *ngIf="thumbPointerSubline">{{ thumbPointerSubline }}</p>
    <div class="budget-thumb-pointer"></div>
  </div>
</div>
<mat-slider
    (input)="hint($event)"
    [(ngModel)]="rangeValue"
    (change)="setValue()"
    [min]="min"
    [disabled]="disabled || editable"
    [max]="discretePoints"
></mat-slider>
