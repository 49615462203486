export class BillingInfoModel {
  billing_postcode: string;
  first_name: string;
  last_name: string;
  billing_address1: string;
  billing_address2: string;
  billing_taxid: string;
  text: string;
  billing_town: string;
  uri: string;
  id: any;
  phone: string;
  company_name: string;
  country_code: string;
  uris: {
    parent_resource: string
  };
  _db_class: string[];
}
