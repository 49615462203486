import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './container/layout/layout.component';
import { FeedSelectorComponent } from './components/feed-selector/feed-selector.component';
import { ArtworkSelectorComponent } from './components/artwork-selector/artwork-selector.component';
import { FeedChooserService } from './feed-chooser.service';
import { FormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TableModule
  ],
  declarations: [
    LayoutComponent,
    FeedSelectorComponent,
    ArtworkSelectorComponent
  ],
  exports: [
    LayoutComponent,
  ],
  providers: [
    FeedChooserService
  ]
})
export class FeedChooserModule { }
