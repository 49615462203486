<div id="signup-page">
  <div class="container">

    <ul class="signup-progress" *ngIf="step < 5">
      <li [ngClass]="{'current': step === 1, 'complete': step > 1}">
        <span>1</span>
      </li>
      <li [ngClass]="{'future': step === 1, 'current': step === 2, 'complete': step > 2}">
        <span>2</span>
      </li>
      <li [ngClass]="{'future': step === 1 || step === 2, 'current': step === 3, 'complete': step > 3}">
        <span>3</span>
      </li>
      <li [ngClass]="{'future': step === 1 || step === 2, 'current': step === 4, 'complete': step > 4}">
        <span>4</span>
      </li>
    </ul>

    <form *ngIf="step === 1 || step === 2"
        [formGroup]="userRegisterForm"
        class="form-login"
        (ngSubmit)="onSubmitUserRegisterForm(userRegisterForm.value)">
      <div class="login-wrap">

        <div [class.hidden]="step !== 1">

          <p class="see-again-header info extend">
            Pay only when someone sees your ad!<br/>Get a free account to reach 1000 people for £10
          </p>

          <div *ngIf="error" class="alert alert-error" role="alert">
            {{error}}
          </div>

          <input type="text"
                 formControlName="name"
                 class="form-control"
                 placeholder="Company name"
                 style="margin-top: 10px;">

          <div *ngFor="let validation of validation_messages.name">
            <small class="error" *ngIf="userRegisterForm.get('name').hasError(validation.type) && (userRegisterForm.get('name').dirty || userRegisterForm.get('name').touched)">{{validation.message}}</small>
          </div>

          <br>
          <input formControlName="email"
                 type="text"
                 id="signup-email"
                 class="form-control"
                 placeholder="Email address"
                 autocomplete="off">

          <div *ngFor="let validation of validation_messages.email">
            <small class="error" *ngIf="userRegisterForm.get('email').hasError(validation.type) && (userRegisterForm.get('email').dirty || userRegisterForm.get('email').touched)">{{validation.message}}</small>
          </div>

          <br>
          <input formControlName="password"
                 [type]="passwordInput"
                 type="password"
                 id="signup-password"
                 class="form-control"
                 placeholder="Password">

          <span (click)="showHidePassword()" class="show-password" style="float: right;">{{showHide}} Password</span>

          <div *ngFor="let validation of validation_messages.password">
            <small class="error" *ngIf="userRegisterForm.get('password').hasError(validation.type) && (userRegisterForm.get('password').dirty || userRegisterForm.get('password').touched)">{{validation.message}}</small>
          </div>

          <br>
          <input formControlName="accept_tnc"
                 type="checkbox"
                 id="signup-accept-tnc"
                 style="top: 3px; position: relative;">
          <label id='signup-accept-tnc-label' for='signup-accept-tnc'>
            I accept
            <a href="http://www.flow.city/app-terms-conditions/current" target="_blank">Flow City Terms and Conditions</a>
          </label>
          <small *ngIf="termsError" class="error">
            {{termsError}}
          </small>
        </div>

        <div [class.hidden]="step !== 2">

          <p class="see-again-header info extend">
            So that we can customise your account tell us about your<br/>business, product and type of ad you wish to run
          </p>

          <div *ngIf="error" class="alert alert-error" role="alert">
            {{error}}
          </div>

          <label>Business type</label>
          <ng-select
              *ngIf="categories"
              class="fc"
              formControlName="business_category"
              [items]="categories.business_type"
              [selectOnTab]="true"
              [searchable]="false"
              bindValue="id"
              bindLabel="name"
              [clearable]="false"
              placeholder="Select...">
          </ng-select>

          <label style="margin-top: 15px">Product type</label>
          <ng-select
              *ngIf="categories"
              class="fc"
              formControlName="product_type"
              [items]="categories.product_type"
              [selectOnTab]="true"
              [searchable]="false"
              bindValue="id"
              bindLabel="name"
              [clearable]="false"
              placeholder="Select...">
          </ng-select>

          <br>

          <label id='accept-content-label' for='accept-content'>
            <input formControlName="accept_content"
                 type="checkbox"
                 id="accept-content"
                 style="top: 3px; position: relative;">
            My ad will not include: nudity, political, hate speech, violence.
          </label>

        </div>

        <button id="std-signup-btn"
                (click)="step = 2"
                *ngIf="step === 1"
                [disabled]="!userRegisterForm.get('name').valid || !userRegisterForm.get('email').valid || !userRegisterForm.get('password').valid || !userRegisterForm.get('accept_tnc').valid || isRequestPending"
                class="btn btn-theme btn-block"
                type="button" style="margin-top: 5px;">
          Next <fa-icon [icon]="['fas', 'angle-right']" aria-hidden="true"></fa-icon>
          <fa-icon *ngIf="isRequestPending" [icon]="['fas', 'spinner']" [spin]="true"></fa-icon>
        </button>

        <button id="std-signup-btn"
                *ngIf="step === 2"
                [disabled]="!userRegisterForm.valid || isRequestPending"
                class="btn btn-theme btn-block"
                type="submit" style="margin-top: 5px;">
          Next <fa-icon [icon]="['fas', 'angle-right']" aria-hidden="true"></fa-icon>
          <fa-icon *ngIf="isRequestPending" [icon]="['fas', 'spinner']" [spin]="true"></fa-icon>
        </button>


      </div>
    </form>

    <div id="plan-page" *ngIf="step === 3">

      <p class="see-again-header info extend">
          Choose your credit plan to get your ad live<br/>on one of the screens!

      </p>

      <ng-container *ngFor="let package of packages">
        <div class="plan" [ngClass]="{'active': credits === package.amount}">
          <div class="credits-circle">
            <div class="credits-abs">
              <span>&pound;{{ package.amount }}<sup>*</sup></span>
            </div>
          </div>

          <div class="description" [innerHTML]="package.desc"></div>

          <button class="btn"
                  (click)="credits=package.amount"
                  *ngIf="!isRequestPending">
            <span *ngIf="credits === package.amount; else selectCredits">Selected <span class="fa fa-check"></span></span>
          </button>

          <ng-template #selectCredits>Choose</ng-template>

        </div>
      </ng-container>


      <button [disabled]="!credits || isRequestPending"
              class="btn btn-theme btn-block"
              (click)="createPayment()"
              style="margin: 20px auto; width: 264px">
        Next <fa-icon [icon]="['fas', 'angle-right']" aria-hidden="true"></fa-icon>
        <fa-icon *ngIf="isRequestPending" [icon]="['fas', 'spinner']" [spin]="true"></fa-icon>
      </button>

      <p class="exclude-vat">* Prices exclude VAT</p>

    </div>

    <div *ngIf="step === 4">
      <p class="see-again-header info extend">
        Enter your payment details
      </p>
      <app-stripe-payment
          [payment]="paymentData"
          [initial]="true"
          (onComplete)="onCompletePayment($event)"
          (pending)="toggleRequestPending($event)">
      </app-stripe-payment>
      <a href="#" class="back-link" (click)="goBack($event)">or go back and choose another plan</a>
    </div>

    <ng-container *ngIf="step === 5">
      <h3 class="see-again-header info extend thank-you">
        Thank you. We will contact you shortly.
      </h3>
    </ng-container>

    <div class="loading not-masked" *ngIf="isRequestPending"></div>

  </div>
</div>

<!--<div class="smartoutdoor-logo">-->
<!--  <div class="poweredby-flow-city">Powered by:</div>-->
<!--</div>-->
<div class="logo"></div>

