import { Component, Input, OnInit } from '@angular/core';
import { ISQLSearch } from '../../../models/sql-search.model';

const DEFAULT_DRAFT_TARGET = {
  audience_name: 'Not selected',
  status: 'draft'
};

@Component({
  selector: 'app-status-indicator',
  templateUrl: './status-indicator.component.html',
  styleUrls: ['./status-indicator.component.scss']
})
export class StatusIndicatorComponent {

  @Input() targets: ISQLSearch.IOfferTarget[];

  get target(): ISQLSearch.IOfferTarget {
    return this.targets[0] || DEFAULT_DRAFT_TARGET;
  }

}
