import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookSelectorComponent } from './book-selector/book-selector.component';
import { BookPreviewComponent } from './book-preview/book-preview.component';
import { HachetteLayoutComponent } from './hachette-layout/hachette-layout.component';
import { RouterModule, Routes } from '@angular/router';
import {HachetteService} from './hachette.service';
import {ArtworkPreviewComponent} from '../../shared/simple/artwork-preview/artwork-preview.component';
import {SharedModule} from '../../shared/shared.module';

export const routes: Routes = [
  {
    path: '',
    component: HachetteLayoutComponent,
    children: [
      {path: '', component: BookSelectorComponent},
      {path: ':isbn', component: BookPreviewComponent}
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule
  ],
  declarations: [
    BookSelectorComponent,
    BookPreviewComponent,
    HachetteLayoutComponent
  ],
  exports: [
    HachetteLayoutComponent
  ],
  providers: [
    HachetteService
  ]
})
export class HachetteModule { }
