import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { SignInValidators } from '../validators';
import { CoreService } from '../../../services/main/core.service';
import { SessionService } from '../../../services/session/session.service';
import { CONSTANTS } from '../../../additional/helpers/constants/constants.const';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { HttpClient } from '@angular/common/http';
import { CDN_URL } from '../authorization.const';
import { CompanyModel } from '../../../models/session-init/session-data/company-model/company-model.model';

export interface ISignupCategories {
  [key: string]: [
    {
      id: number;
      name: string;
    }
  ];
}

@Component({
  selector: 'app-signup-pro',
  templateUrl: './signup-pro.component.html',
  styleUrls: ['./signup-pro.component.scss']
})
export class SignupProComponent implements OnInit {

  readonly timestamp = new Date().getTime();

  userRegisterForm: FormGroup;
  companyData: object = null;
  error: string = null;

  credits: number = null;

  categories: ISignupCategories;

  step = 1;

  isRequestPending = false;
  passwordInput = 'password';


  validation_messages = {
    name: [
      { type: 'required', message: 'Please enter company name' },
      { type: 'companyTaken', message: 'Company already registered'}
    ],
    email: [
      { type: 'required', message: 'Please enter your email address' },
      { type: 'email', message: 'Valid email address is required' },
      { type: 'emailTaken', message: 'Email address already used'}
    ],
    password: [
      { type: 'required', message: 'Please enter password' },
    ],
    business_type: [
      { type: 'required', message: 'Please select business type' },
    ],
    product_type: [
      { type: 'required', message: 'Please select product type' },
    ],
    monthly_spend: [
      { type: 'required', message: 'Please select' },
    ]
  };

  constructor(
    private _location: Location,
    private fb: FormBuilder,
    public service: AuthenticationService,
    public coreService: CoreService,
    private session: SessionService,
    private http: HttpClient,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) { }

  ngOnInit() {
    this.loadCategories();
    this.createForms();

    // after company has been created on page refresh go to payment page
    if (sessionStorage.getItem('tmp-session')) {
      if (sessionStorage.getItem('tmp-company-data')) {
        this.companyData = JSON.parse(sessionStorage.getItem('tmp-company-data'));
      }
      this.step = 3;
    }
  }

  createForms() {
    this.userRegisterForm = this.fb.group({

      accept_tnc: ['', Validators.requiredTrue ],

      name: new FormControl('', {
        asyncValidators: [SignInValidators.validateCompanyNotTaken(this.service)],
        updateOn: 'blur'
      }),

      email: new FormControl('', {
        validators: [Validators.required, Validators.email],
        asyncValidators: [SignInValidators.validateEmailNotTaken(this.service)],
        updateOn: 'blur'
      }),
      data_1st: [],
      data_3rd: [],
      password: ['', Validators.required ],
      business_category: ['', Validators.required ],
      product_type: ['', Validators.required ],
      monthly_spend: ['', Validators.required ],
    });
  }

  get showHide(): string {
    return this.passwordInput === 'password' ? 'Show' : 'Hide';
  }

  showHidePassword(): void {
    this.passwordInput = this.passwordInput === 'password' ? 'text' : 'password';
  }

  onSubmitUserRegisterForm(registerCompanyData) {
    this.recaptchaV3Service.execute('request_pro')
      .subscribe((token) => {
        this.error = null;
        this.userRegisterForm.disable();
        this.isRequestPending = true;
        registerCompanyData['business_category'] = registerCompanyData['business_category'].toString();
        registerCompanyData['accept_tnc_timestamp'] = new Date().toISOString();
        registerCompanyData['profiles_set'] = 'rtb';
        registerCompanyData['token'] = token;
        registerCompanyData['requested_account'] = 'PRO';

        this.service.registerCompany(registerCompanyData, true)
          .subscribe(
            (request) => {
              this.userRegisterForm.enable();
              this.isRequestPending = false;
              this.step = 3;
              (<any>window).ga('gtm1.send', 'event', 'signup', 'sent', 'camden-signup');
            },
            () => {
              this.userRegisterForm.enable();
              this.isRequestPending = false;
              this.error = 'An error occurred while creating your account. Please contact administrator.';
            }
          );
      });
  }

  goBack(event) {
    event && event.preventDefault();
    this.step = 2;
  }

  toggleRequestPending(state) {
    this.isRequestPending = state;
  }

  loadCategories() {
    this.http.get<ISignupCategories>(`${CDN_URL}/json/categories.json?_t=${ this.timestamp }`).subscribe(
      categories => this.categories = categories,
    );
  }

}
