import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {ApiService} from '../../../services/api-service/api.service';
import {CoreService} from '../../../services/main/core.service';
import {BillingInfoModel} from '../../../models/session-init/billing-info/billing-info.model';
import {ProfileService} from '../../../services/profile/profile.service';

@Component({
  selector: 'app-billing-info-modal',
  templateUrl: './billing-info.component.html',
  styleUrls: ['./billing-info.component.sass']
})
export class BillingInfoModalComponent {

  @Output() saveBillingInfo = new EventEmitter();
  @Output() closePopup = new EventEmitter();

  billingInfo: BillingInfoModel;
  formValid = false;

  constructor(
    @Inject(ApiService) public api,
    @Inject(CoreService) public coreService,
    @Inject(ProfileService) public profileService
  ) {
    this.billingInfo = new BillingInfoModel();
  }

  _saveBillingInfo(): void {
    this.saveBillingInfo.emit(this.billingInfo);
  }

  _closePopup(): void {
    this.closePopup.emit();
  }

  isFormValid(state) {
    this.formValid = state;
    console.log(state);
  }

  onFormReady(billingInfo) {
    this.billingInfo = billingInfo;
  }
}
