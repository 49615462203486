import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'fc-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

  @ViewChild('label') fromLabel: ElementRef;

  @Input() rangeValues: any;
  @Input() value: number;
  @Input() max = 100;
  @Input() min = 0;
  @Input() disabled: boolean;

  @Output() updateFromSlider = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  get thumb(): number {
    const range = this.max - this.min;
    return ((Number(this.value) - this.min) / range) * 100;
  }

  onSliderUpdate($event): void {
    this.updateFromSlider.emit($event.value);
  }

}
