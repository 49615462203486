import {Injectable, Injector} from '@angular/core';
// import {CompanyModel} from '../../models/sessionData.model';
// import {SessionService} from '../../config/app.config';
// import {HttpClient} from '@angular/common/http';
// import {CookieService} from 'ngx-cookie-service';
// import {ReportModel, ReportOptionsModel, ReportParametersModel} from '../../models/report.model';
import {SuperService} from '../super-service/super.service';
// import {forkJoin} from 'rxjs/observable/forkJoin';
// import {SESSION_STORAGE, StorageService} from 'ngx-webstorage-service';

@Injectable()
export class ReportsService extends SuperService {

  // companyModel: CompanyModel;
  // reports: ReportModel[] = [];
  //
  // downloadingCompanyModel: boolean = false;
  //
  constructor(
    injector: Injector
    // @Inject(SessionService) private session,
    // protected http: HttpClient,
    // @Inject(CookieService) protected cookieService,
    // @Inject(SESSION_STORAGE) protected storage: StorageService
  ) {
    super(injector);
  }
  //
  // /**
  //  * Downloads company model ald writes it in field named 'companyModel'
  //  * @param {() => void} successCB
  //  * @param {(err) => void} errorCB
  //  * @param ignoreCurrent if set to 'true' it doesn't check if field 'companyModel' is already set
  //  */
  // getCompanyModel(
  //     successCB?: ()=>void,
  //     errorCB?: (err)=>void,
  //     ignoreCurrent: boolean = false
  // ): void {
  //   const self = this;
  //   if((!self.companyModel && !self.downloadingCompanyModel) || ignoreCurrent) {
  //     this.waitForAppField('sessionData',
  //         field => {
  //           self.downloadingCompanyModel = true;
  //           this.http.get<CompanyModel>(
  //               self.session.enrichApiUrl(field.company.uri),
  //               {headers: self.getTokenToHeader()}
  //           ).subscribe(
  //               value => {
  //                 self.downloadingCompanyModel = false;
  //                 self.companyModel = value;
  //                 if(successCB)
  //                   successCB()
  //               },
  //               err => {
  //                 console.error(err);
  //                 if(errorCB)
  //                   errorCB(err)
  //               }
  //           )
  //         })
  //   }
  //   else if(self.companyModel && !ignoreCurrent){
  //     if(successCB)
  //       successCB()
  //   }
  // }
  //
  // /**
  //  * Gets data needed to display overview summary.
  //  * If company model is not downloaded, it downloads it and returns in successCB
  //  * @param query JSON with additional data - timestamp_from and timestamp_to
  //  * @param {(data) => void} successCB
  //  * @param {(err) => void} errorCB
  //  */
  // getExtendedReports(
  //     query,
  //     successCB?: (data)=>void,
  //     errorCB?: (err)=>void
  // ): void {
  //   const self = this;
  //
  //   if(!this.companyModel){
  //     self.getCompanyModel(() => {
  //
  //       let extendedReports = this.companyModel.uris.ExtendedReports;
  //
  //       this.http.get<JSON>(
  //           this.session.enrichApiUrl( extendedReports + '?query=' + encodeURIComponent(JSON.stringify(query)) ),
  //           {headers: self.getTokenToHeader()}
  //       ).subscribe(
  //           data => {
  //             if(successCB)
  //               successCB(data)
  //           },
  //           err => {
  //             console.error(err);
  //             if(errorCB)
  //               errorCB(err)
  //           }
  //       );
  //     })
  //   }
  // }
  //
  //
  // /**
  //  * Downloads data needed to print rows with informations about generated reports
  //  * @param {(data: ReportModel[]) => void} successCB
  //  * @param {(err) => void} errorCB
  //  */
  // getReports(
  //     successCB?: (data: ReportModel[])=>void,
  //     errorCB?: (err)=>void
  // ): void {
  //   const self = this;
  //   let extendedReports = this.companyModel.uris.Reports;
  //
  //   this.http.get<ReportModel[]>(
  //       this.session.enrichApiUrl( extendedReports ),
  //       {headers: self.getTokenToHeader()}
  //   ).subscribe(
  //       reports => {
  //         reports.forEach(report => {
  //           report.optionsParsed = JSON.parse(report.options) as ReportOptionsModel;
  //           report.parametersParsed = JSON.parse(report.parameters) as ReportParametersModel;
  //           report.selected = false;
  //         });
  //         this.reports = reports;
  //         if(successCB)
  //           successCB(reports);
  //       },
  //       err => {
  //         console.error(err);
  //         if(errorCB)
  //           errorCB(err)
  //       }
  //   );
  //
  // }
  //
  // generateReport(
  //     report: ReportModel,
  //     successCB?: (data)=>void,
  //     errorCB?: (err)=>void
  // ): void {
  //   const self = this;
  //
  //   if(!this.companyModel){
  //     self.getCompanyModel(() => self.postReport(report, successCB, errorCB))
  //   }
  //   else
  //     self.postReport(report, successCB, errorCB);
  // }
  //
  // private postReport(
  //     report: ReportModel,
  //     successCB?: (data)=>void,
  //     errorCB?: (err)=>void
  // ): void {
  //   const self = this;
  //
  //   let reportsURL = this.companyModel.uris.Reports;
  //
  //   this.http.post<JSON>(
  //       this.session.enrichApiUrl( reportsURL ),
  //       report,
  //       {headers: self.getTokenToHeader()}
  //   ).subscribe(
  //       data => {
  //         self.refreshReports();
  //         if(successCB)
  //           successCB(data)
  //       },
  //       err => {
  //         console.error(err);
  //         if(errorCB)
  //           errorCB(err)
  //       }
  //   );
  // }
  //
  // /**
  //  * Deletes all passed reports using their uris.
  //  * @param reports
  //  * @param successCB
  //  * @param errorCB
  //  */
  // deleteReports(
  //     reports: ReportModel[],
  //     successCB?: ()=>void,
  //     errorCB?: (err)=>void
  // ): void {
  //
  //   let observables = [];
  //   for (let report of reports) {
  //     observables.push(this.setReportObservable(report));
  //   }
  //   let success = () => {
  //     this.getReports();
  //     if(successCB)
  //       successCB()
  //   };
  //
  //   forkJoin(observables).subscribe(
  //       () => success(),
  //       err => {
  //         if(err.status === 0)
  //           success();
  //         else {
  //           console.error(err);
  //           if (errorCB)
  //             errorCB(err)
  //         }
  //       }
  //   )
  // }
  //
  // /**
  //  * Downloads all selected reports
  //  * @param {ReportModel[]} reports
  //  */
  // downloadReports(
  //     reports: ReportModel[]
  // ) {
  //   const self = this;
  //
  //   let observables = [];
  //   for(let report of reports) {
  //     if(report.selected) {
  //       observables.push(this.getReportDownloadLink(report))
  //     }
  //   }
  //
  //   forkJoin<string>(observables)
  //       .subscribe(
  //       value => {
  //         for(let i=0; i<value.length; ++i){
  //           setTimeout(() => self.downloadFile(value[i]), 500 * i)
  //         }
  //       },
  //       error1 => console.error(error1)
  //   );
  // }
  //
  // private downloadFile(url: string){
  //   let link = document.createElement("a");
  //   link.download = "a";
  //   link.href = url;
  //   document.body.appendChild(link);
  //   link.click();
  //   link.remove();
  // }
  //
  // private getReportDownloadLink(report: ReportModel){
  //   const self = this;
  //
  //   return self.http.get(
  //       report.download_link,
  //       {
  //         responseType: 'text',
  //         headers: self.getTokenToHeader()
  //       }
  //   )
  // }
  //
  // private refreshReports(): void {
  //   this.getReports();
  // }
  //
  // private setReportObservable (
  //     report: ReportModel
  // ) {
  //   const self = this;
  //
  //   return self.http.delete(
  //       self.session.enrichApiUrl( report.uri ),
  //       {headers: self.getTokenToHeader()}
  //   )
  // }
}
