<ng-container *ngIf="offerService.currentOffer">
  <a (click)="refreshPage()" class="close-modal">&times;</a>

  <div class="panel-heading">
    <h1>Thank you!</h1>
    <p>
      Your campaign has now been submitted. We aim to approve all campaigns within 72hrs.
      You will receive notification when your campaign is approved and ready to go. <br>
      We will let you know via email when and where your ad will appear.
      You can always check the progress by going to My Campaigns.
    </p>
  </div>
  <div class="panel-body">

    <app-campaign-preview
      [formats]="service.api.formats"
      [offer]="service.currentOffer"
      [showSimpleInfo]="true"
      [resetOffer]="false">
    </app-campaign-preview>

  </div>
  <div class="panel-footer">
    <div>
      <p *ngIf="!advanced">
        Your campaign will run for <b><span>{{duration}}</span></b>
        from <b><span>{{formatDate(offerService.scheduleTime?.schedule_start)}}</span></b> to <b><span>{{formatDate(offerService.scheduleTime?.schedule_stop)}}</span></b>
        <br>
        Unsure about your dates? You can change them by
      </p>
      <button *ngIf="!advanced"
              (click)="refreshPage(true)"
              class="btn">
        Go to schedule
      </button>
      <button (click)="refreshPage()"
              class="btn finish-btn"
              [class.disabled]="offerService.fileFeedback.fileUploading">
        Finish
      </button>
    </div>

    <div style="clear: both;"></div>
  </div>
</ng-container>
