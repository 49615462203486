<div id="signup-page">
  <div class="container">

    <ul class="signup-progress">
      <li [ngClass]="{'current': step === 1, 'complete': step === 2 || step === 3}">
        <span>1</span>
      </li>
      <li [ngClass]="{'future': step === 1, 'current': step === 2, 'complete': step === 3}">
        <span>2</span>
      </li>
      <li [ngClass]="{'future': step === 1 || step === 2, 'current': step === 3}">
        <span>3</span>
      </li>
    </ul>



    <form *ngIf="step === 1"
        [formGroup]="userRegisterForm"
        class="form-login"
        (ngSubmit)="onSubmitUserRegisterForm(userRegisterForm.value)">
      <div class="login-wrap">

        <p class="see-again-header info extend">
          Register your account in three simple steps<br/>
           and reach new customers today!<br/>
          Already a Flow City user? <a routerLink="login"><b>Log in here.</b></a>
        </p>

        <div *ngIf="error" class="alert alert-error" role="alert">
          {{error}}
        </div>

        <input type="text"
               formControlName="name"
               class="form-control"
               placeholder="Company name"
               style="margin-top: 10px;">

        <div *ngFor="let validation of validation_messages.name">
          <small class="error" *ngIf="userRegisterForm.get('name').hasError(validation.type) && (userRegisterForm.get('name').dirty || userRegisterForm.get('name').touched)">{{validation.message}}</small>
        </div>

        <br>
        <input formControlName="email"
               type="text"
               id="signup-email"
               class="form-control"
               placeholder="Email address"
               autocomplete="off">

        <div *ngFor="let validation of validation_messages.email">
          <small class="error" *ngIf="userRegisterForm.get('email').hasError(validation.type) && (userRegisterForm.get('email').dirty || userRegisterForm.get('email').touched)">{{validation.message}}</small>
        </div>

        <br>
        <input formControlName="password"
               [type]="passwordInput"
               type="password"
               id="signup-password"
               class="form-control"
               placeholder="Password">

        <span (click)="showHidePassword()" class="show-password" style="float: right;">{{showHide}} Password</span>

        <div *ngFor="let validation of validation_messages.password">
          <small class="error" *ngIf="userRegisterForm.get('password').hasError(validation.type) && (userRegisterForm.get('password').dirty || userRegisterForm.get('password').touched)">{{validation.message}}</small>
        </div>

        <br>
        <input formControlName="accept_tnc"
               type="checkbox"
               id="signup-accept-tnc"
               style="top: 3px; position: relative;"
        />
        <label id='signup-accept-tnc-label' for='signup-accept-tnc'>
          I accept
          <a href="http://www.flow.city/app-terms-conditions/current" target="_blank">Flow City Terms and Conditions</a>
        </label>
        <small *ngIf="termsError" class="error">
          {{termsError}}
        </small>
        <button id="std-signup-btn"
                [disabled]="!userRegisterForm.valid || isRequestPending"
                class="btn btn-theme btn-block"
                type="submit" style="margin-top: 5px;">
          Next <i class="fa fa-angle-right" aria-hidden="true"></i>
          <fa-icon *ngIf="isRequestPending" [icon]="['fas', 'spinner']"></fa-icon>
        </button>


      </div>
    </form>

    <div id="plan-page" *ngIf="step === 2">

      <p class="see-again-header info extend">
          Choose your plan
      </p>

      <div class="plan" [ngClass]="{'active': credits === 50}">
        <div class="credits-circle">
          <div class="credits-abs">
            <span>&pound;50<sup>*</sup></span>
          </div>
        </div>

        <div class="description">
          Reach<br><strong>1000</strong> Shoppers<br>in Camden<br>over 1 month
        </div>

        <button class="btn"
                (click)="credits=50"
                *ngIf="!isRequestPending">
          <span *ngIf="credits === 50; else selectCredits">Selected <span class="fa fa-check"></span></span>
        </button>

      </div>

      <div class="plan" [ngClass]="{'active': credits === 300}">
        <div class="credits-circle">
          <div class="credits-abs">
            <span>&pound;300<sup>*</sup></span>
          </div>
        </div>

        <div class="description">
          Reach<br><strong>7000</strong> Shoppers<br>in Camden<br>over 6 months
        </div>

        <a class="btn"
           (click)="credits=300"
           *ngIf="!isRequestPending">
          <span *ngIf="credits === 300; else selectCredits">Selected <span class="fa fa-check"></span></span>
        </a>

      </div>

      <div class="plan" [ngClass]="{'active': credits === 600}">
        <div class="credits-circle">
          <div class="credits-abs">
            <span>&pound;600<sup>*</sup></span>
          </div>
        </div>

        <div class="description">
          Reach<br><strong>14000</strong> people<br>in Camden<br>over 12 months
        </div>

        <a class="btn"
           (click)="credits=600"
           *ngIf="!isRequestPending">
          <span *ngIf="credits === 600; else selectCredits">Selected <span class="fa fa-check"></span></span>
        </a>

        <ng-template #selectCredits>Choose</ng-template>

      </div>

      <button [disabled]="!credits || isRequestPending"
              class="btn btn-theme btn-block"
              (click)="createPayment()"
              style="margin: 20px auto; width: 264px">
        Next <i class="fa fa-angle-right" aria-hidden="true"></i>
        <fa-icon *ngIf="isRequestPending" [icon]="['fas', 'spinner']"></fa-icon>
      </button>

      <p class="exclude-vat">* Prices exclude VAT</p>

    </div>

    <div *ngIf="step === 3">
      <p class="see-again-header info extend">
        Enter your payment details
      </p>
      <app-stripe-payment
          [payment]="paymentData"
          (onComplete)="onCompletePayment($event)"
          (pending)="toggleRequestPending($event)">
      </app-stripe-payment>
      <a href="#" class="back-link" (click)="goBack($event)">or go back and choose another plan</a>
    </div>

    <div class="loading not-masked" *ngIf="isRequestPending"></div>

  </div>
</div>

<div class="logo"></div>
<div class="white-label"></div>

