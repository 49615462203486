import { Component, Inject, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.sass']
})
export class SignupComponent implements OnInit {

  signupBody = {
    companyName: '',
    email: '',
    password: '',
    token: '',
    terms: false
  };

  companyError: string;
  emailError: string;
  passwordError: string;
  termsError: string;

  passwordInput = 'password';
  signingUpPending = false;

  constructor(
    private _location: Location,
    private recaptchaV3Service: ReCaptchaV3Service,
    @Inject(AuthenticationService) public service
  ) { }

  ngOnInit() {
  }

  get showHide(): string {
    return this.passwordInput === 'password' ? 'Show' : 'Hide';
  }

  showHidePassword(): void {
    this.passwordInput = this.passwordInput === 'password' ? 'text' : 'password';
  }

  goBack(): void {
    this._location.back();
  }

  // checkEmail(): void {
  //   const self = this;
  //
  //   self.service.checkEmail(self.signupBody.email);
  // }
  //
  // checkCompany(): void {
  //   const self = this;
  //
  //   self.service.checkCompany(self.signupBody.companyName);
  // }


  verifyCaptcha() {
    this.recaptchaV3Service.execute('registration')
      .subscribe((token) => this.requestSignUp(token));
  }

  requestSignUp(token) {
    const self = this;
    delete self.companyError;
    delete self.emailError;
    delete self.passwordError;
    delete self.termsError;

    self.signupBody.token = token;
    if (!self.signupBody.companyName.length) {
      self.companyError = self.service.company_msg;
    } else if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(self.signupBody.email)) {
      self.emailError = self.service.email_msg;
    } else if (!self.signupBody.password.length) {
      self.passwordError = self.service.no_password;
    } else if (!self.signupBody.terms) {
      self.termsError = self.service.tnc_msg;
    } else {
      this.signingUpPending = true;
      self.service.requestSignUp(
        self.signupBody,
        success => console.log(success),
        (field, message) => {
          this.signingUpPending = false;
          switch (field) {
            case self.service.company:
              self.companyError = message;
              break;
            case self.service.email:
              self.emailError = message;
              break;
            case self.service.terms:
              self.termsError = message;
          }
        }
      );
    }
  }
}
