import {Injectable, Injector} from '@angular/core';
import {SuperService} from '../super-service/super.service';
import {SessionService as Session} from '../session/session.service';

@Injectable()
export class InstagramService extends SuperService {

  constructor(injector: Injector) {
    super(injector);
  }

  getFeed(username) {
    const uri = Session.enrichApiUrl(`/data/instagram?username=${username}`);
    return this.http.get(uri);
  }

}
