import {Component, Inject} from '@angular/core';
import {OfferService} from '../../../../../../../services/offer/offer.service';
// import {OfferService} from "../../../../../services/offer/offer.service";

@Component({
  selector: 'app-transport',
  templateUrl: './transport.component.html',
  styleUrls: ['./transport.component.sass']
})
export class TransportComponent {

  active: true;
  disruptions: 'all';
  type: 'DbOfferScheduleDisruptions';

  transport: TransportButton[] = TRANSPORT_TEMP;

  constructor(
      @Inject(OfferService) public service
  ) { }

  isTransActive(trans: TransportButton) {
    const sd = this.service.scheduleDisruptions;
    if (sd) {
      return sd.disruptions === trans.value;
    }

    return trans.value.length < 1;
  }

  changeTrans(trans: TransportButton) {
    this.service.editTransportSchedule('disruptions', trans.value);
  }
}

interface TransportButton {
  value: string;
  iconClass: string;
  description: string;
}

const TRANSPORT_TEMP: TransportButton[] = [
  {
    value: '',
    iconClass: '',
    description: 'Any'
  },
  {
    value: 'none',
    iconClass: 'icon-transport-no',
    description: 'No disruptions'
  },
  {
    value: 'all',
    iconClass: 'icon-transport-medium',
    description: 'Disruptions'
  },
  {
    value: 'important',
    iconClass: 'icon-transport-major',
    description: 'Major only disruptions'
  }
];
