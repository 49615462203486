<div class="budget-top no-margin">
  <div class="collapsible_budget" [class.collapse]="isCollapsed()">
    <h4 class="section-title">Campaign budget</h4>
    <app-range-step-slider
        [value]="currentValues?.budget"
        [min]="0"
        [max]="1000000"
        [steps]="[1, 2, 5, 10, 100, 1000, 10000]"
        [thumbPointerSubline]="'Credits'"
        [options]="{prefix: '£'}"
        (change)="updateData($event, 'budget')">
    </app-range-step-slider>
  </div>
</div>

<div class="budget-top no-margin" *ngIf="!disableDuration">
  <div class="collapsible_budget" [class.collapse]="isCollapsed()">
    <h4 class="section-title">Duration</h4>
    <app-range-step-slider
        [value]="currentValues?.duration"
        [min]="1"
        [max]="121"
        [steps]="[1]"
        [thumbPointerSubline]="'Days'"
        (change)="updateData($event, 'duration')">
    </app-range-step-slider>
  </div>
</div>

<div class="budget-top no-margin" *ngIf="isRTB">
  <div class="collapsible_budget" [class.collapse]="isCollapsed()">
    <h4 class="section-title">Daily budget</h4>
    <app-range-step-slider
        [value]="currentValues?.dailyCap"
        [min]="0"
        [max]="10000"
        [steps]="[1, 2, 5, 10, 100, 1000]"
        [thumbPointerSubline]="'Credits'"
        [options]="{prefix: '£'}"
        (change)="updateData($event, 'dailyCap')">
    </app-range-step-slider>
  </div>
</div>
