import {
    Directive,
    ElementRef,
    TemplateRef,
    ViewContainerRef,
    OnInit,
    Input,
} from '@angular/core';
import {ApiService} from '../../services/api-service/api.service';
import {UserModel} from '../../models/session-init/user-model/user-model.model';

@Directive({
    selector: '[appHasProfile]'
})
export class HasProfileDirective implements OnInit {
    private currentUser;
    private profile;
    private elseTemplateRef: TemplateRef<any> | null = null;

    constructor(private element: ElementRef,
                private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef,
                private api: ApiService) {
    }

    ngOnInit() {
        this.api.currentSessionData$.subscribe(sessionData => {
          if (sessionData) {
              this.currentUser = sessionData.user;
              this.updateView();
          }
        });
    }

    @Input()
    set appHasProfile(val) {
        this.profile = val;
        this.updateView();
    }

    @Input()
    set appHasProfileElse(templateRef: TemplateRef<any> | null) {
        assertTemplate('hasProfileElse', templateRef);
        this.elseTemplateRef = templateRef;
        this.updateView();
    }

    private updateView() {
        this.viewContainer.clear();
        if (this.checkProfile()) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            if (this.elseTemplateRef) {
                this.viewContainer.createEmbeddedView(this.elseTemplateRef);
            }
        }
    }

    private checkProfile() {
        if (this.currentUser && this.currentUser.profiles_names) {
            return this.currentUser.profiles_names.includes(this.profile);
        }
    }
}

function assertTemplate(property: string, templateRef: TemplateRef<any> | null): void {
    const isTemplateRefOrNull = !!(!templateRef || templateRef.createEmbeddedView);
    if (!isTemplateRefOrNull) {
        throw new Error(`${property} must be a TemplateRef, but received '${JSON.stringify(templateRef)}'.`);
    }
}
