<div class="modal-content">
  <div class="modal-header">
    <div class="bootstrap-dialog-header">
      <div class="bootstrap-dialog-title">Billing info</div>
    </div>
  </div>

  <div class="modal-body" style="min-height: 130px;">
    <app-billing-info-form
            (isValid)="isFormValid($event)"
            (onFormReady)="onFormReady($event)">
    </app-billing-info-form>
  </div>
  <div class="modal-footer">
    <button (click)="_closePopup()" class="btn cancel-btn">Cancel</button>
    <button (click)="_saveBillingInfo()" [disabled]="!formValid" class="btn">
      <span class="ladda-label">SAVE</span>
    </button>
  </div>
</div>
