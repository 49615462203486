import { ICampaignFile } from '../../../services/file/file.model';
import { OfferTarget } from '../../offer-target.model';
import { ISQLSearch } from '../../sql-search.model';
import IOfferTarget = ISQLSearch.IOfferTarget;
import { ScheduleModel } from '../../schedule.model';
import { ICampaignManager } from '../../cm.interface';

export class OfferData {
  archived: boolean;
  audience: string;
  budget: string;
  budget_spent: string;
  company_name: string;
  created: string;
  creator: any;
  department: string;
  display_time: number;
  duration: string;
  reach: number;
  impressions: number;
  campaign_impressions: number;
  ad_plays: number;
  num_of_screens: number;
  id: number;
  intensity: string;
  link_url;
  locked: boolean;
  media_file: string;
  media_file_last_updated: string;
  media_file_thumbnail: string;
  media_file_thumbnail_inprogress: boolean;
  media_file_transcode_inprogress: boolean;
  media_requirements;
  name: string;
  offer_type: string;
  offer_variant: string;
  offer_variant_options: string;
  priority: number;
  source_template: string;
  supported_schedules: string[];
  text: string;
  text1: string;
  text2: string;
  text3: string;
  uri: string;
  offer_origin: string;
  offer_postcode: string;
  offer_radius: number;
  impression_model?: string;
  budget_daily?: string;
  budget_daily_spent?: string;
  min_sov?: number;
  viooh_creative_id?: string;
  viooh_creative_status?: string;
  deal_id?: string;
  set_viooh_deal?: boolean;
  multi_offer_artworks?: ICampaignFile[];
  targets?: OfferTarget[];
  offer_targets?: IOfferTarget[];
  offer_schedules?: ScheduleModel[];
  schedule_start?: number;
  schedule_stop?: number;
  weekdays?: number[];
  campaign_formats: string[];
  bid_stats?: ICampaignManager.ICampaignBidsStats;
  uris: {
    Files: string;
    OfferTargets: string;
    Render: string;
    Schedules: string;
    UploadedFiles: string;
    offers: string;
    parent_resource: string;
    SubmitMultipleOfferTargets: string;
    ActivateOffer: string;
    MultiOfferTargets: string;
    UpdateMultipleOfferTargets: string;
    CreateFilesFromFeed: string;
    GetArtworkReadiness: string;
    Availability: string;
    ArtworkSequence: string;
  };
  _db_class: string[];
  _comment: string;
  latitude?: number;
  longitude?: number;
  location_map?: string;
}
