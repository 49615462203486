<div class="modal-content">
  <div class="modal-header">
    <div class="bootstrap-dialog-header">
      <div class="bootstrap-dialog-title">Card information</div>
    </div>
  </div>

  <form #checkout="ngForm" (ngSubmit)="onSubmit(checkout)">
  <div class="modal-body" style="min-height: 130px; margin-bottom:-15px;">

    <div class="alert alert-warning alert-dismissible fade show" role="alert"  *ngIf="error">
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      {{error}}
    </div>

    <div class="form-horizontal" id="stripe-form">


      <div class="form-group">
        <label class="col-sm-12">Card number</label>
        <div class="col-sm-12">
          <span #cardNumber class="form-control"></span>
          <small *ngIf="cardError" class="error">{{cardError}}</small>
        </div>
        <div class="col-sm-1"></div>
      </div>

      <div class="form-row">

        <div class="form-group col-sm-6">
          <label class="col-sm-12">Card expiry</label>
          <div class="col-sm-12">
            <span #cardExpiry class="form-control"></span>
            <small *ngIf="expiryError" class="error">{{expiryError}}</small>
          </div>
        </div>

        <div class="form-group col-sm-6">
          <label class="col-sm-12">CVC</label>
          <div class="col-sm-12">
            <span #cardCvc class="form-control"></span>
            <small *ngIf="cvcError" class="error">{{cvcError}}</small>
          </div>
        </div>
      </div>

      <div class="form-group form-inside">
        <label class="col-sm-12">Cardholder name</label>
        <div class="col-sm-12">
          <input type="text"
                 class="form-control"
                 name="name"
                 [(ngModel)]="cardholderName"
                 placeholder="Cardholder name"
                 (blur)="checkField($event)"
                 [class.StripeElement--invalid]="cardholderError">
          <small *ngIf="cardholderError" class="error">{{cardholderError}}</small>
        </div>
      </div>

    </div>


  </div>
  <div class="modal-footer">
    <button (click)="cancel()" type="button" class="btn btn-cancel">Cancel</button>
    <button type="submit" id="submitBtn" class="btn" [disabled]="cardholderError || !cardholderName">Submit</button>
  </div>
  </form>

  <div class="loading" *ngIf="processing"></div>
</div>
