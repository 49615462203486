export const TIMESCHEDULE_INPUT_DATE_FORMAT = 'YYYY-MM-DDTHH:mm';
export const TIMESCHEDULE_INPUT_TIME_FORMAT = 'HH:mm:ss';
export const TIMESCHEDULE_OUTPUT_DATE_FORMAT = 'Do MMM YYYY';
export const TIMESCHEDULE_OUTPUT_TIME_FORMAT = 'hh:mm A';
export const TIMESCHEDULE_WEEKDAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

import {
  IOfferScheduleAIMFieldset,
  IOfferScheduleDisruptionsFieldset,
  IOfferScheduleStockLevelFieldset,
  IOfferScheduleTimeFieldset,
  IOfferScheduleWeatherFieldset
} from './scheduler.model';

export const SCHEDULER_FIELDSET = {
  DbOfferScheduleTime: IOfferScheduleTimeFieldset,
  DbOfferScheduleWeather: IOfferScheduleWeatherFieldset,
  DbOfferScheduleDisruptions: IOfferScheduleDisruptionsFieldset,
  DbOfferScheduleStockLevel: IOfferScheduleStockLevelFieldset,
  DbOfferScheduleAIM: IOfferScheduleAIMFieldset,
};


