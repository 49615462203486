import {Injectable, Injector} from '@angular/core';
import {SuperService} from '../super-service/super.service';
import {SessionService as Session} from '../session/session.service';
import {HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { BehaviorSubject, from, Observable } from 'rxjs';
import * as moment from 'moment';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserState } from '../../models/userstate';
import firebase from 'firebase/app';

@Injectable()
export class AuthenticationService extends SuperService {

  readonly email_msg = 'Please enter a valid email address';
  readonly company_msg = 'Please provide your company name';
  readonly company_exists = 'This company is already registered in FlowCity. Request your Flow City admin to create an account for you.';
  readonly email_exists = 'This email address already registered in Flow City. Switch to Sign In page to login to this account.';
  readonly tnc_msg = 'Please accept Terms and Conditions by marking the checkbox.';
  readonly no_password = 'Please provide password.';

  readonly company = 'company';
  readonly email = 'email';
  readonly terms = 'terms';

  public firebaseUser$ = new BehaviorSubject<UserState>(new UserState(undefined));
  private _isAuth = new BehaviorSubject<any>(null);
  public isAuthenticated$ = this._isAuth.asObservable();

  constructor(
    injector: Injector,
    private session: Session,
    private angularFireAuth: AngularFireAuth,
  ) {
    super(injector);
  }

  public get isAuthenticated(): boolean {
    return this._isAuth.getValue();
  }

  requestLogin(loginBody: string): Observable<any> {
    return this.session.signIn(loginBody);
  }

  requestJWTLogin(loginBody): Observable<any> {
    return this.session.signInJWT(loginBody)
      .pipe(tap(user => this.authStateObserver(user)));
  }

  requestFBLogin(data) {

    const userId = data.id;

    this.http.post(
      Session.enrichApiUrl('/data/fb-login'),
      {
        facebook_id: userId
      },
      {
        observe: 'response'
      }
    ).subscribe(
      (response) => {
        this.session.initSession(response);
      }
    );
  }

  /**
   * Sends request to sign up.
   * @param signupBody
   * @param {(msg?: string) => void} success
   * @param {(field?: string, msg?: string) => void} error
   */
  requestSignUp(
    signupBody,
    success: (msg?: string) => void,
    error: (field?: string, msg?: string) => void
  ): void {
    const self = this;
    if (!signupBody.companyName.length) {
      error(self.company, self.company_msg);
      return;
    } else if (!signupBody.terms) {
      error(self.terms, self.tnc_msg);
      return;
    }

    self.checkEmail(signupBody.email,
      () => {
        self.checkCompany(signupBody.companyName,
            () => self.session.registerCompany(signupBody, err => self.couldNotCreateAccount(err)),
            err => error(self.company, err)
          );
      },
      () => error(self.email, self.email_exists)
    );
  }

  /**
   * Sends request to reset password.
   * @param {string} email
   */
  requestPasswordReset(
    email: string,
  ): Observable<any> {
    return this.http.post(
      Session.enrichApiUrl('/data/password-resets'),
      {email: email}
    );
  }

  changePassword(
    uri: string,
    password: string
  ): Observable<any> {
    return this.http.post(
      Session.enrichApiUrl(`/data${uri}`),
      {password: password},
      {observe: 'response'}
    );
  }

  /**
   * Check if this email is being used. If it is not, if calls 'success'. In the other case - 'error'
   * @param {string} email
   * @param {(msg?: string) => void} success
   * @param {() => void} error
   */
  private checkEmail<T>(
    email: string,
    success: (msg?: string) => void,
    error: () => void
  ): void {
    const self = this;

    self.http.get<string>(Session.enrichApiUrl('/email-checker/' + email))
      .subscribe(
        () => success(),
        (err: HttpErrorResponse) => {
          if (err.status >= 200 && err.status < 300) {
            success();
          } else {
            error();
          }
        }
      );
  }

  /**
   * Check if this company is being used. If it is not, if calls 'success'. In the other case - 'error'.
   * @param {string} companyName
   * @param {(msg?: string) => void} success
   * @param {(msg?: string) => void} error
   */
  private checkCompany(
    companyName: string,
    success: (msg?: string) => void,
    error: (msg?: string) => void
  ): void {
    const self = this;

    self.http.post(
      Session.enrichApiUrl('/company-checker'),
      JSON.stringify({company_name: companyName})
    )
      .subscribe(
        () => success(),
        err => error(self.company_exists)
      );
  }

  checkCompanyNotTaken(companyName: string) {
    return this.http.post(
      Session.enrichApiUrl('/company-checker'),
      JSON.stringify({company_name: companyName})
    )
      .pipe(
        map(res => res['success']),
        catchError(() => {
          return of(false);
        })
      );
  }

  checkEmailNotTaken(email: string) {
    return this.http.get(Session.enrichApiUrl('/email-checker/' + email), {responseType: 'text' as 'text'})
      .pipe(
        map(res => true),
        catchError(() => {
          return of(false);
        })
      );
  }

  registerCompany(data, skipSaveToken= false) {
    return this.session.sendRegisterCompanyRequest(data, skipSaveToken);
  }

  private couldNotCreateAccount(err): void {
    console.error(err);
  }

  public sendNewPayment(uri: string, payment: any, sessionKey): Observable<any> {
    return this.http.post(Session.enrichApiUrl(uri), payment, {
      headers: new HttpHeaders({'X-FlowCity-Session-User': sessionKey})
    });
  }

  public setupJWTSession(idToken): void {
    sessionStorage.setItem('token', idToken);
  }

  initFirebase(): void {
    this.angularFireAuth.authState.subscribe(user => this.authStateObserver(user));
  }

  authStateObserver(user: firebase.User | null): void {
    console.log(user);
    if (user) {
        this.firebaseUser$.next(new UserState(user));
        this._isAuth.next(true);
    } else {
        this.firebaseUser$.next(new UserState(null));
        this._isAuth.next(false);
    }
  }

  public getToken$(): Observable<firebase.auth.IdTokenResult> {
    const user = this.firebaseUser$.getValue().value;
    if (user) {
      return from(user.getIdTokenResult(true)).pipe(map(token => {
        return token;
      }));
    } else {
      return of(null);
    }
  }

}
