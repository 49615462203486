import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {FormGroup, NgForm} from '@angular/forms';

@Directive({
  selector: '[submitIfValid]'
})
export class SubmitIfValidDirective {
  // tslint:disable-line:no-input-rename
  @Output('submitIfValid') valid = new EventEmitter<void>();

  constructor(private formRef: NgForm) {
  }

  @HostListener('click')
  handleClick() {
    this.markFieldsAsDirty(this.formRef.controls);
    this.emitIfValid();
  }

  private markFieldsAsDirty(controls) {
    const self = this;
    Object.keys(controls)
      .forEach(controlName => {
          const control = controls[controlName];
          if (control instanceof FormGroup) {
            const group = control as FormGroup;
            self.markFieldsAsDirty(group.controls);
          } else {
            control.markAsDirty();
          }
        }
      );
  }

  private emitIfValid() {
    if (this.formRef.valid) {
      this.valid.emit();
    }
  }

}
