import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  HostBinding
} from '@angular/core';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { IFormat } from '../../../../../models/format.model';
import { from, Subject } from 'rxjs';
import { FormatsService } from '../../../../../services/formats/formats.service';
import { IMultiEstimate } from '../../../../../services/estimator/estimator.service';
import { DEFAULT_FILE_FEEDBACK } from '@shared/simple/artwork-preview/artwork-preview.component';
import { OfferService } from '../../../../../services/offer/offer.service';
import { FileService } from '../../../../../services/file/file.service';
import { ICampaignFile } from '../../../../../services/file/file.model';
import { trigger } from '@angular/animations';
import { SlideInOutReverse } from '../../../../../additional/animations/animations';

const BASE_ICON_PATH = 'https://storage.googleapis.com/fc-cdn/formats';

@Component({
  selector: 'app-multi-format-selector',
  templateUrl: './multi-format-selector.component.html',
  styleUrls: ['./multi-format-selector.component.scss'],
  animations: [
    trigger('toUpload', SlideInOutReverse),
    trigger('toFormats', SlideInOutReverse),
  ]
})
export class MultiFormatSelectorComponent implements OnInit, OnDestroy {

  @Input() estimations: IMultiEstimate[];
  @Input() offerAudiences: any;
  @Output() changed = new EventEmitter();

  @ViewChild('fileToUpload') fileToUpload: ElementRef;

  public uploadMode = false;

  private _destroy$ = new Subject<any>();
  allFormats: IFormat[];
  filesToUpload = [];
  fileFeedback = DEFAULT_FILE_FEEDBACK;
  previewFormat = null;

  @HostBinding('class.is-open')
  _selectedArtwork = null;

  constructor(
    private offerService: OfferService,
    private formatsService: FormatsService,
    private fileService: FileService,
  ) {
    this.formatsService.allFormats
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        data => this.allFormats = data,
    );
  }

  ngOnInit(): void {
  }

  get selectedArtwork(): ICampaignFile {
    return this._selectedArtwork;
  }

  set selectedArtwork(artwork) {
    this._selectedArtwork = artwork;
  }

  toggle(): void {
    this.selectedArtwork = null;
    this.previewFormat = null;
  }

  trackFn(index, item): number {
    return index;
  }

  get formats(): IFormat[] {
    if (this.allFormats && this.estimations && this.offerAudiences.get().length > 0) {
      let formats = [];

      this.offerAudiences.get().forEach(aud => {
        const estAudience = this.estimations.find(est => est.audience_uri === aud.audience.uri);
        if (estAudience) {
          formats = [...formats, ...estAudience.formats];
        }
      });

      const availFormats = [];
      formats.forEach(f => {
        const format = this.allFormats.find(af => af.ooh_format === f.format);
        if (format) {
          availFormats.push({
            ...f,
            ooh_format: f.format,
            ooh_format_label: format.ooh_format_label,
            artwork_spec: format.artwork_spec,
          });
        }
      });
      return availFormats;
    }
  }

  get selectedFormats(): IFormat[] {
    return this.formats.filter(f => this.selectedFormatsTypes.includes(f.format));
  }

  addFile(event): void {
    this.filesToUpload = [];
    let type = 'multi';
    Array.prototype.forEach.call(this.fileToUpload.nativeElement.files, file => {
      this.filesToUpload.push(file);
    });

    event.target.value = '';

    from(this.filesToUpload)
      .pipe(
        mergeMap(file => this.fileService.initFileUpload(file), null, 4)
      )
      .subscribe(
        file => {
          if (this.offerService.isRegular) {
            if (file['uploadedFile']) {
              this.fileFeedback = DEFAULT_FILE_FEEDBACK;
              // this.selectedArtwork = this.fileService.campaignFiles[0];
            } else {
              this.fileFeedback = {
                fileUploading: true,
                filePercent: file['progress']
              };
            }
          }
        }
      );

    if (this.offerService.isRegular) {
      const fileType = this.filesToUpload[0].type;
      type = fileType.includes('video') ? 'video' : 'image';
      this.offerService.currentOffer.offer_type = type;
      this.offerService.save().subscribe();
    }
  }

  get isMulti(): boolean {
    return this.fileService.isMulti;
  }

  get acceptedFileType(): string {
    if (this.offerService.currentOffer.offer_type === 'video') {
      return 'video/*';
    } else {
      return 'video/quicktime,video/*,image/*';
    }
  }

  selectFile(): void {
    this.fileToUpload.nativeElement.click();
  }

  get service() {
    return this.fileService;
  }

  uploadProgressStyle(percent) {
    let bg;
    if (percent < 50) {
      const deg = 90 + (3.6 * percent);
      bg = `linear-gradient(90deg, #E0E0E0 50%, transparent 50%, transparent), linear-gradient(${deg}deg, #03F7FF 50%, #E0E0E0 50%, #E0E0E0)`;
    } else {
      const deg = -90 + (3.6 * (percent - 50));
      bg = `linear-gradient(${deg}deg, #03F7FF 50%, transparent 50%, transparent), linear-gradient(270deg, #03F7FF 50%, #E0E0E0 50%, #E0E0E0)`;
    }
    return {background: bg};
  }

  thumbnailPreviewImage(img): string {
    return img ? `url(${img})` : '';
  }

  get noMatchingArtworks(): ICampaignFile[] {
    const availFormats = this.selectedFormats.map(f => `${f.artwork_spec.resolution.width}x${f.artwork_spec.resolution.height}`);
    return this.fileService.campaignFiles.filter(c => !availFormats.includes(`${c.width}x${c.height}`));
  }

  removeItem(artwork, event): void {
    event && event.stopImmediatePropagation();
    this.fileService.deleteFile(artwork);
  }

  click(format: IFormat): void {
    console.log(format.format);
    if (this.offerService.campaignFormats) {
      const idx = this.offerService.campaignFormats.findIndex(f => f === format.format);
      if (idx > -1) {
        this.offerService.campaignFormats.splice(idx, 1);
      } else {
        this.offerService.campaignFormats.push(format.format);
      }
    } else {
      this.offerService.campaignFormats = [format.format];
    }
    if (this.offerService.campaignFormats && this.offerService.campaignFormats.length === 0) {
      this.offerService.campaignFormats = null;
    }
    this.changed.emit(true);
  }


  unselected(format: IFormat): boolean {
    return this.offerService.campaignFormats && !this.offerService.campaignFormats.includes(format.format);
  }

  selected(format: IFormat): boolean {
    return this.offerService.campaignFormats && this.offerService.campaignFormats.includes(format.format);
  }

  public getIcon(format: IFormat): string {
    return `${BASE_ICON_PATH}/${format.ooh_format.split('_')[0]}.svg`;
  }

  public get isFormatSelected(): boolean {
    return this.selectedFormatsTypes.length > 0;
  }

  public get selectedFormatsTypes(): string[] {
    return this.offerService.campaignFormats || [];
  }

  public toggleMode(): void {
    this.uploadMode = !this.uploadMode;
  }

  public showPreview(artwork, format) {
    this.selectedArtwork = artwork;
    this.previewFormat = format;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
