import { Component, OnDestroy, OnInit } from '@angular/core';
import { SessionData } from '../../../../models/session-init/session-data/session-data.model';
import { Subject } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { OfferService } from '../../../../services/offer/offer.service';
import { ApiService } from '../../../../services/api-service/api.service';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

const IMPRESSION_MODELS = [
  {
    id: 'regular',
    title: 'Full Flexibility',
    desc: 'Build a Media Plan',
  },
  {
    id: 'rtb',
    title: 'Great Value',
    desc: 'Set Daily Cap & RTB',
    offer_type: 'multi',
  }
];

@Component({
  selector: 'app-start-first-campaign',
  templateUrl: './start-first-campaign.component.html',
  styleUrls: ['./start-first-campaign.component.scss']
})
export class StartFirstCampaignComponent implements OnInit, OnDestroy {

  public impressionModels = IMPRESSION_MODELS;
  public newOfferData = {
    name: '',
    impression_model: 'rtb',
    budget: '0',
    duration: '1',
  };

  sessionData: SessionData;
  private _destroy$ = new Subject<any>();

  constructor(
      private router: Router,
      private offerService: OfferService,
      private api: ApiService,
  ) {
    const isOnboarding = localStorage.getItem('_onboarding');
    if (!isOnboarding) {
      this.router.navigateByUrl('dashboard');
    }

    this.api.currentSessionData$
        .pipe(
          takeUntil(this._destroy$),
          distinctUntilChanged()
        )
        .subscribe(
        data => {
          this.sessionData = data;
          if (this.sessionData.company.active_plan === 'BASIC') {
            // set RTB impression model as default one
            this.setImpressionModel(null, IMPRESSION_MODELS[1]);
          }
          },
        );
  }

  trimEntry() {
    this.newOfferData.name = this.newOfferData.name.trim();
  }

  setImpressionModel(event, impressionModel) {
    event && event.preventDefault();
    this.newOfferData.impression_model = impressionModel.id;
    if (impressionModel.offer_type) {
      this.newOfferData['offer_type'] = impressionModel.offer_type;
    }
  }

  ngOnInit() {
  }

  createCampaign() {
    this.offerService.newOfferData = this.newOfferData;
    this.router.navigate(['new-campaign']);
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }


}
