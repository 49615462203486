import {Component, Inject} from '@angular/core';
import {AuthenticationService} from '../../../services/authentication/authentication.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.sass']
})
export class ForgotPasswordComponent {

  readonly logo = 'assets/images/fc.png';
  public processing = false;

  email = '';
  public error = null;

  constructor(
    @Inject(AuthenticationService) public service
  ) { }

  requestResetPassword() {
    this.error = null;
    this.processing = true;
    this.service.requestPasswordReset(this.email).subscribe(
      () => {
        this.processing = false;
        this.redirectPasswordInstructionsSent();
      },
      (err) => {
        this.processing = false;
        this.error = err.reason || 'Something went wrong. Please try again';
      }
    );

  }

  private redirectPasswordInstructionsSent(): void {
    this.service.router.navigate(['/password-instructions-sent'])
      .catch(
        reason => console.error('Could not redirect. \n' + reason)
      );
  }
}
