<div class="offer-schedule">

  <h5 class="text-left">I want to play my campaign on displays with specific sensors:</h5>

  <div class="offer-schedule-content">
    <div class="row">
      <div class="col-sm-12 text-center">
        <div class="btn-group date-range-selector" data-toggle="buttons">
          <label id="offSensorLabel" class="btn btn-default btn-sensor">
            <input type="radio" name="sensor" class="sky" autocomplete="off" value="">
            Off
          </label>
          <label id="anySensorLabel" class="btn btn-default btn-sensor">
            <input type="radio" name="sensor" class="sky" autocomplete="off" value="any">
            Any
          </label>
          <label id="motionSensorLabel" class="btn btn-default btn-sensor btn-sensor-motion">
            <input type="radio" name="sensor" class="sky" autocomplete="off" value="motion">
            Motion
          </label>
          <label id="touchSensorLabel" class="btn btn-default btn-sensor btn-sensor-touch">
            <input type="radio" name="sensor" class="sky" autocomplete="off" value="touch">
            Touch
          </label>
          <label id="eyeTrackingSensorLabel" class="btn btn-default btn-sensor btn-sensor-eye">
            <input type="radio" name="sensor" class="sky" autocomplete="off" value="eye_tracking">
            Eye Tracking
          </label>
        </div>
      </div>
    </div>
  </div>
</div>