<div class="collapse-container" [class.collapse]="isCollapsed">

  <div class="top-bar">
    <div class="search-bar">
      <div class="search-box pull-left">
        <label style="position: absolute;">
          <i class="fa fa-search"></i>
        </label>
        <input
          class="form-control"
          [(ngModel)]="searchString"
          (keyup)="onSearchBoxChange($event)"
          placeholder="Search audience...">
        </div>
    </div>
  </div>

  <div class="inner-container" *ngIf="audiences">

    <div class="inner-container__prev"
         (click)="goToPage(-1)"
         [class.disabled]="currentPage == 0">
      <i class="fa fa-angle-left" aria-hidden="true"></i>
    </div>
    <div class="inner-container__content">

      <ng-container *ngIf="audiences.length > 0; else NO_AUDIENCES_FOUND">

        <ng-container *ngFor="let audience of page">

          <app-icon-item
            [data]="audience"
            [selected]="isAudienceSelected(audience)"
            [disabled]="!isAudienceAvailable(audience)"
            [unselected]="unselected(audience)"
            [icon]="'audiences/' + audience.audience_code"
            [genericIcon]="'audiences/generic.svg'"
            [progressValue]="getPercentage(audience)"
            [class.warning]="getPercentage(audience) > 100"
            (clicked)="audienceClicked(audience)">

            <ng-template fc-iconitem-description>
              <div [innerHTML]="audience.value || audience.display_name"></div>
              <div class="font-weight-bold" [innerHTML]="getReach(audience.audience_code) | thousandSuffix"></div>
            </ng-template>

            <ng-template fc-iconitem-summary>
                <ng-container *ngIf="getEstimation(audience.audience_code)?.effective_cpm">
                  <ng-container *ngIf="getEstimation(audience.audience_code)?.effective_cpm === getEstimation(audience.audience_code)?.cpm; else DYNAMIC_AUDIENCE">
                    <span class="icon-badge"
                        ngbTooltip="Average CPM"
                        container="body"
                        [placement]="'bottom'">{{  getEstimation(audience.audience_code)?.cpm | currency: 'GBP' }}
                    </span>
                  </ng-container>
                  <ng-template #DYNAMIC_AUDIENCE>
                    <span class="icon-badge"
                        ngbTooltip="Effective CPM - calculation based on real-time data"
                        container="body"
                        [placement]="'bottom'"><span class="dot blink"></span>{{  getEstimation(audience.audience_code)?.effective_cpm | currency: 'GBP' }}
                    </span>
                  </ng-template>
                </ng-container>
            </ng-template>

            <ng-template fc-iconitem-tooltip>
              <span [innerHTML]="tooltipHelper(audience)"></span>

              <ng-container *appHasProfile="'viooh_mgmt'">
                <div style="color: red;" *ngIf="getPercentage(audience) > 100">Please verify data for this audience.</div>
              </ng-container>

            </ng-template>

            <ng-template fc-iconitem-percent>
              <div *ngIf="getPercentage(audience)" style="margin: 2px 6px;">
                <ng-container  *appHasProfile="'viooh_mgmt'; else REGULAR_USER">
                  {{ getPercentage(audience) | number:'1.0-0' }}%
                </ng-container>
                <ng-template #REGULAR_USER>
                  {{ (getPercentage(audience) <= 100 ? getPercentage(audience) : 100) | number:'1.0-0' }}%
                </ng-template>
              </div>
            </ng-template>

          </app-icon-item>

        </ng-container>
      </ng-container>
      <ng-template #NO_AUDIENCES_FOUND>
        <div class="no-audiences">No audiences found</div>
      </ng-template>
    </div>
    <div class="inner-container__next"
         (click)="goToPage(1)"
         [class.disabled]="!isNext">
      <i class="fa fa-angle-right" aria-hidden="true"></i>
    </div>
    <div class="loading" *ngIf="isLoading"></div>
  </div>

</div>
