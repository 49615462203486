import { Component, Inject, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { SessionService } from '../../../services/session/session.service';

@Component({
  selector: 'app-fire-login',
  templateUrl: './fire-login.component.html',
  styleUrls: ['./fire-login.component.scss']
})
export class FireLoginComponent implements OnInit {
  loginError = null;
  wrongFacebook = false;

  loginPending = false;

  loginBody = {
    email: '',
    password: ''
  };

  passwordInput = 'password';

  constructor(
    private service: AuthenticationService,
    private sessionService: SessionService,
  ) { }

  ngOnInit() {
  }

  get showHide(): string {
    return this.passwordInput === 'password' ? 'Show' : 'Hide';
  }

  showHidePassword(): void {
    this.passwordInput = this.passwordInput === 'password' ? 'text' : 'password';
  }

  requestLogin(): void {
    this.loginError = null;
    this.loginPending = true;
    this.service.requestJWTLogin(this.loginBody).subscribe(
      () => this.sessionService.redirectToDashboard(),
      err => {
        this.loginError = err.message;
        this.loginPending = false;
      }
    );
  }

}
