<div class="thumb-railway">

  <div #label
       [style.left.%]="thumb"
       class="thumb-label">
    <h3>{{ value }}</h3>
    <div class="thumb-pointer"></div>
  </div>

  <div class="slider">
    <p-slider (onSlideEnd)="onSliderUpdate($event)"
              [(ngModel)]="value"
              [max]="max"
              [min]="min"
              [disabled]="disabled">
    </p-slider>
  </div>

</div>
