<div class="menu-list-container">
  <div class="sidebar-switch" (click)="toggleSidebar()">
    <i class="fa fa-caret-left"></i>
  </div>

  <div data-region="user-info"></div>

  <div class="separator"></div>

  <div class="credits-info hidden" id="top-credits-area"></div>

  <ul class="menu-list">

    <li class="user-info">
      <app-user-info [routerLink]="['/profile']"></app-user-info>
    </li>

    <li>
      <a routerLink="/dashboard" routerLinkActive="active">
        <span>Dashboard</span>
      </a>
    </li>

    <li>
      <a routerLink="/offers" routerLinkActive="active">
        <span>My Campaigns</span>
      </a>
    </li>

    <li>
      <a routerLink="/reports" routerLinkActive="active">
        <span>Reports</span>
      </a>
    </li>

    <li *appHasProfile="'viooh_mgmt'">
      <a routerLink="/campaign-management" routerLinkActive="active">
        <span>Campaign Management</span>
      </a>
    </li>

    <li *appHasProfile="'campaign_mgmt'">
      <a routerLink="/campaign-management" routerLinkActive="active">
        <span>Campaign Management</span>
      </a>
    </li>

    <li>
      <a routerLink="/creative-spec" routerLinkActive="active">
        <span>Creative Spec</span>
      </a>
    </li>


    <li>
      <a routerLink="/profile/user-details" routerLinkActive="active">
        <span>Account Details</span>
      </a>
    </li>

    <li>
      <a routerLink="/profile/location" routerLinkActive="active">
        <span>Settings</span>
      </a>
    </li>
  </ul>
</div>

<div id="sign-out-btn">
  <a (click)="signOut()">
    <span>Sign out</span>
  </a>
</div>
