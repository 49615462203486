import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injector} from '@angular/core';
import {CookieService} from 'ngx-cookie';
import {Router} from '@angular/router';
import {CONSTANTS} from '../../additional/helpers/constants/constants.const';


export class SuperService {

  protected http: HttpClient;
  protected cookieService: CookieService;
  protected router: Router;

  readonly formatsSourceUrl_2018 = 'assets/json/campaignFormats_2018.json';

  constructor(
    injector: Injector
  ) {
    const self = this;

    self.http = injector.get(HttpClient);
    self.cookieService = injector.get(CookieService);
    self.router = injector.get(Router);
  }

  /**
   * Transforms date format to fit schedule time schedule_start and schedule_stop fields.
   * @param date Passed Date.
   * @param start If set to true, hour i set to 00:00. Otherwise to 23:59.
   * @param extended If set to true, string is extended with seconds and milliseconds (':00.000Z').
   */
  static formatDate(date: Date, start: boolean = false, extended: boolean = false): string {
    const year = date.getFullYear().toString();
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();
    let suffix = '23:59';
    if (start) {
      suffix = '00:00';
    }
    if (month.length < 2) {  month = '0' + month; }
    if (day.length < 2) {    day = '0' + day; }

    let formattedStr = year + '-' + month + '-' + day + 'T' + suffix;

    if (extended) {
      formattedStr += ':00.000Z';
    }

    return formattedStr;
  }

  /**
   * Returns standard headers which are 'X-FlowCity-Session-User': <token> and 'content-type': 'application/json'
   * @returns {HttpHeaders}
   */
  protected getTokenToHeader(customToken: string = null): HttpHeaders {
    const key = CONSTANTS.sessionKey;
    const token = this.cookieService.get(key);

    return new HttpHeaders({
      [key]: customToken || token,
      'content-type': 'application/json'
    });
  }
}
