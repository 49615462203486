import {Injectable, Injector} from '@angular/core';
import {environment as env} from '../../../environments/environment';
import {SuperService} from '../super-service/super.service';
import {CONSTANTS} from '../../additional/helpers/constants/constants.const';
import { switchMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import firebase from 'firebase';
import { AngularFireAuth } from '@angular/fire/auth';


@Injectable()
export class SessionService extends SuperService {

  constructor(
    injector: Injector,
    private auth: AngularFireAuth,
  ) {
    super(injector);
  }

  public static enrichApiUrl(postfix: string) {
    return env.apiUrl + postfix;
  }

  public static enrichAppUrl(postfix: string) {
    return env.appUrl + postfix;
  }

  public static enrichOffersService(postfix: string) {
    return env.offersServiceUrl + postfix;
  }

  public static enrichUtilsService(postfix: string) {
    return env.utilsServiceUrl + postfix;
  }

  public static enrichSQLService(postfix: string) {
    return env.sqlServiceUrl + postfix;
  }

  public static enrichGCSUrl(postfix: string, bucket: string = env.defaultBucket) {
    return `${env.storageUrl}/${bucket}${postfix}`;
  }

  public static enrichDisplaysService(postfix: string) {
    return env.displaysServiceUrl + postfix;
  }

  public static enrichReportService(postfix: string) {
    return env.reportsServiceUrl + postfix;
  }


  /**
   * If there is no session storage key with session user token name or forceInit is set true,
   * it checks if there is cookie with session storage user token.
   * If yes, it rewrites key and value to session storage,
   * if no, it displays warning.
   *
   * @param forceInit
   */
  initializeTabSession(forceInit) {
    const self = this;
    // add per-tab header to ajax request to recognize real os-session (multiple tabs conflict)
    if (!sessionStorage.getItem(CONSTANTS.sessionKey) || !! forceInit) {
      const session = self.cookieService.get(CONSTANTS.sessionKey);
      if (session) {
        sessionStorage.setItem(CONSTANTS.sessionKey, session);
      } else {
        console.warn('No session cookie - could not set tab session for ' + CONSTANTS.sessionKey);
      }
    }
  }

  signIn(loginBody: string): Observable<any> {
    return this.http.post(
      SessionService.enrichApiUrl('/data/login'),
      loginBody,
      {observe: 'response'}
    ).pipe(
      tap(response => this.initSession(response)),
    );
  }

  signInJWT(loginBody): Observable<any> {
    return new Observable(subscriber => {
      this.auth.signInWithCredential(firebase.auth.EmailAuthProvider.credential(loginBody.email, loginBody.password))
        .then(response => {
          const user = response.user;
          subscriber.next(user);
          subscriber.complete();
        }).catch(err => {
          // // TODO workaround for sell-side app users - to be removed in future !!!
          // if (err.code === 'auth/user-not-found') {
          //   this.signIn(loginBody).subscribe(
          //     () => { console.log('User authenticated in old way'); },
          //     error => subscriber.error({message: error.message}),
          //   );
          // } else {
          //   subscriber.error({message: err.message});
          // }
          subscriber.error({message: err.message});
        });
    });
  }

  public initSession(response) {
    this.saveToken(response.headers.get(CONSTANTS.sessionKey));
    this.redirectToDashboard();
  }

  sendRegisterCompanyRequest(data, skipSaveToken = false) {
    return this.http.post(
      SessionService.enrichApiUrl('/data/signup'),
      JSON.stringify(data),
      {observe: 'response'}
    ).pipe(
      tap((res) => !skipSaveToken && this.saveToken(res.headers.get(CONSTANTS.sessionKey)))
    );
  }

  /**
   * Signing up method. Requires signup body including email, password and company name. It adds current time to it and sends POST request.
   * If it passes, it saves the token and redirects user to the Dashboard.
   * @param signupBody
   * @param {(err) => void} error
   */
  registerCompany(signupBody, error: (err) => void): void {
    const self = this;

    const registrationData = {
      email: signupBody.email,
      password: signupBody.password,
      name: signupBody.companyName,
      token: signupBody.token,
      accept_tnc_timestamp: new Date().toISOString()
    };

    self.sendRegisterCompanyRequest(registrationData)
      .subscribe(
        response => {
          self.saveToken(response.headers.get(CONSTANTS.sessionKey));
          self.redirectToDashboard();
        },
        err => error(err)
      );

    // self.http.post(
    //   SessionService.enrichApiUrl('/data/signup'),
    //   JSON.stringify(registrationData),
    //   {observe: 'response'}
    // )
    //   .subscribe(
    //     response => {
    //       self.saveToken(response.headers.get(CONSTANTS.sessionKey));
    //       self.redirectToDashboard();
    //     },
    //     err => error(err)
    //   );
  }

  /**
   * Signing out method. Clears session storage and cookie with session token
   * and redirects to Login Page.
   */
  signOut(): void {

    const signOutReq = this.http.post(
      SessionService.enrichApiUrl('/data/logout'),
      '{}'
    );

    this.firebaseSignOut()
      .pipe(switchMap(() => signOutReq))
      .subscribe(
        () => {
          this.removeToken();
          SessionService.redirectToLoginPage();
        },
        reason => console.error('Could not log out. \n' + reason),
      );
  }

  firebaseSignOut(): Observable<any> {
    return new Observable(subscriber => {
      this.auth.signOut()
        .then(response => {
          subscriber.next(response);
          subscriber.complete();
        })
        .catch(err => {
          subscriber.error(err);
          subscriber.complete();
        });
    });
  }

  public forceRemoveSession(): void {
    this.removeToken();
    SessionService.redirectToLoginPage();
  }

  saveToken(key: string): void {
    const self = this;

    const expiration = new Date();
    expiration.setFullYear(expiration.getFullYear() + 10);

    self.cookieService.put(CONSTANTS.sessionKey, key, {expires: expiration});
    self.cookieService.put(CONSTANTS.sessionKey, key, {expires: expiration, domain: '.flow.city'});

    self.initializeTabSession(true);
  }

  private removeToken(): void {
    const self = this;

    self.cookieService.remove(CONSTANTS.sessionKey);
    self.cookieService.remove(CONSTANTS.sessionKey, {domain: '.flow.city'});
    sessionStorage.clear();
  }

  private static redirectToLoginPage(): void {
    // const self = this;

    window.location.href = env.appUrl;

    // self.router.navigate(['/login'])
    //   .catch(
    //     reason => console.error('Could not log out. \n' + reason)
    //   );
  }

  redirectToDashboard(): void {
    this.router.navigate(['/dashboard']).catch(
      reason => console.error('Could not log in. \n' + reason)
    );
  }

  hasCookieUserToken(): boolean {
    return !!this.cookieService.get(CONSTANTS.sessionKey);
  }

  hasSessionUserToken(): boolean {
    return !!sessionStorage.getItem(CONSTANTS.sessionKey);
  }


}
