import {Component, Inject, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../services/authentication/authentication.service';
import {Router} from '@angular/router';
import {SessionService} from '../../../services/session/session.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.sass']
})
export class ChangePasswordComponent implements OnInit {
  public processing = false;

  password = '';
  passwordRetyped = '';
  uri = '';
  public error = null;

  constructor(
    private service: AuthenticationService,
    private router: Router,
    private sessionService: SessionService
  ) { }

  ngOnInit() {
    this.uri = this.router.url;
  }

  changePassword() {
    this.error = null;
    this.processing = true;
    this.service.changePassword(
      this.uri, this.password
    ).subscribe(
      (response) => this.sessionService.initSession(response),
      (response) => {
        this.processing = false;
        this.error = response.error.reason;
      }
    );
  }

}
