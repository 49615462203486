import { Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {SessionService} from '../../../../services/session/session.service';
import {CurrentDepartment} from '../../../../models/currentDepartment.model';
import {DisplayGroup} from '../../../../models/DisplayGroups.model';
import { OfferAudiences, OfferService, SelectedAudience } from '../../../../services/offer/offer.service';
import {CurrentCompany} from '../../../../models/currentCompany.model';
import {Location} from '../../../../models/location.model';
import {OfferData} from '../../../../models/offers/offer-data/OfferData.model';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest } from 'rxjs';
import {
  EstimatorService,
  IMultiEstimate
} from '../../../../services/estimator/estimator.service';
import { debounceTime, distinctUntilChanged, filter, take, takeUntil, tap } from 'rxjs/operators';
import {LayoutService} from '../../../../services/layout/layout.service';
import {NotifyService} from '../../../../services/notify/notify.service';
import {InstagramService} from '../../../../modules/instagram/instagram.service';
import {CoreService} from '../../../../services/main/core.service';
import { Observable, Subject } from 'rxjs';
import { IFormat } from '../../../../models/format.model';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ICampaignBudget } from './rtb-budget/rtb-budget.component';
import { FileService } from '../../../../services/file/file.service';
import { FormatsService } from '../../../../services/formats/formats.service';
import { ArtworkConfirmationComponent } from '../../../../shared/components/artwork-confirmation/artwork-confirmation.component';
import { CompanyModel } from '../../../../models/session-init/session-data/company-model/company-model.model';
import { FeedbackService } from '../../../../shared/services/feedback.service';


@Component({
  selector: 'app-create-campaign',
  styleUrls: ['./create-campaign.component.sass'],
  templateUrl: './create-campaign.component.html'
})
export class CreateCampaignComponent implements OnInit, OnDestroy {

  @ViewChild('confirmationPopup') confirmationPopup: ElementRef;
  @ViewChild('afterSubmitPopup') afterSubmitPopup: ElementRef;

  // Estimator related properties
  public loadingEstimates = new BehaviorSubject<boolean>(false);
  public offerEstimations: IMultiEstimate[];

  private _destroy$ = new Subject<any>();

  _updateEstimator = new Subject();

  _data: ICampaignBudget;
  displayTime = '10 days';

  currentDepartment: CurrentDepartment;
  company: CompanyModel;
  location: Location;

  private _resetOffer = true;

  budget_wrapped = false;
  audiences_wrapped = true;
  formats_wrapped = true;

  audiences_unlocked = false;
  formats_unlocked = false;

  audiences_editable = false;
  formats_editable = false;

  startingCampaign = false;
  campaignCreated = false;
  campaignConfirm = false;

  chosenAudience: DisplayGroup;

  confirmationModal: NgbModalRef;
  afterSubmitModal: NgbModalRef;

  private _newOffer = false;
  private newOfferProcessing = false;

  fileType = '';
  acceptedFiles = 'video/*|image/*';

  readonly img = 'image';
  readonly video = 'video';

  public offerAudiences: any;

  @HostListener('window:popstate', ['$event'])
  unloadHandler(event): boolean {
    event && event.preventDefault();
    this.coreService.isGoingBack = true;
    this.feedbackService.message = 'Please use in-app navigation...';
    return false;
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event): boolean {
    return false;
  }

  constructor(
    @Inject(SessionService) public App,
    @Inject(OfferService) public service,
    private modalService: NgbModal,
    private estimatorService: EstimatorService,
    private layoutService: LayoutService,
    private notifyService: NotifyService,
    private coreService: CoreService,
    public instagramService: InstagramService,
    private toastr: ToastrService,
    private router: Router,
    public fileService: FileService,
    private formatsService: FormatsService,
    private feedbackService: FeedbackService,
  ) {

    if (!this.router.getCurrentNavigation().extras.state ||
        !this.router.getCurrentNavigation().extras.state.userAction) {
      this.router.navigateByUrl('/offers');
      return;
    }

    this.service.resetCurrentOffer();
    this.fileService.reset();

    service.api.currentLocation
      .pipe(takeUntil(this._destroy$))
      .subscribe(location => this.location = location);
    this.offerAudiences = new OfferAudiences(service);
    this.offerAudiences.changed
      .pipe(takeUntil(this._destroy$))
      .subscribe(
            () => this.onOfferAudienceUpdate()
        );

    this.service.timeScheduleUpdated
      .pipe(
        takeUntil(this._destroy$),
        distinctUntilChanged(),
      )
      .subscribe((_) => {
      // this.location = location;
      if (this.service.currentOffer) {
          console.log('schedule updated');
          this.modifyBudgetAndDuration(null);
        }
    });

    this.service.api.currentSessionData$
      .pipe(
        filter(data => data !== null),
        distinctUntilChanged(),
        takeUntil(this._destroy$),
      )
      .subscribe(
      (data) => {
        this.company = data.company;
      }
    );

    this._updateEstimator
      .pipe(
        takeUntil(this._destroy$),
        debounceTime(2000),
      ).subscribe(() => this.updateEstimatedDisplays());

    combineLatest([
      this.fileService.checkQRCodes,
      this.fileService.artworksNotReady(true)
    ]).pipe(
      takeUntil(this._destroy$)
    ).subscribe(([qrcodesReady, artworksNotReady]) => {
      const message = [];
      if (artworksNotReady) {
        message.push('Wait until all artworks are ready...');
      }
      if (!qrcodesReady) {
        message.push('There are artworks with missing QR codes. Please generate them before submission.');
      }
      this.feedbackService.message = message.join('<br>');
    });
  }

  get resetOffer() {
    return this.service.resetOffer && this._resetOffer;
  }

  set resetOffer(state) {
    this._resetOffer = state;
  }

  ngOnInit(): void {
    const self = this;
    self.service.currentOffer = undefined;
  }

  modifyBudget(): void {
    if (!this.audiences_editable) {
      this.audiences_unlocked = true;
    }
  }

  modifyDuration(): void {
    if (!this.audiences_editable) {
      this.audiences_unlocked = true;
    }
  }

  openAudiences() {
    this._data && this.modifyBudgetAndDuration(this._data);
    this._data = null;
    this.audiences_editable = true;
    this.editAudience();
  }
  openFormats() {
    this.formats_editable = true;
    this.editFormat();
  }

  startNow(): void {
    const self = this;

    self.service.startCampaign(
      () => self.sendCurrentOfferState(),
      err => self.startingCampaignError(err),
      self.service.scheduleTime
    );
  }

  get newOffer(): boolean {
    if (!this._newOffer) {
      this.sendNewOffer();
      return false;
    }
    return true;
  }
  set newOffer(newOffer: boolean) {
    this._newOffer = newOffer;
  }

  sendNewOffer(): void {
    const self = this;

    localStorage.removeItem('_onboarding');

    if (!this.service.newOfferData || !this.service.newOfferData.name) {
      this.router.navigateByUrl('/');
      return;
    }

    if (!self.service.api.currentDepartment || self.newOfferProcessing) {
      return;
    }
    this.newOfferProcessing = true;

    const d = String(new Date());
    const dArr = d.split(' ');

    let newOfferData = new OfferData();
    if (this.service.newOfferData) {
      newOfferData = this.service.newOfferData;
    } else {
      newOfferData.name = 'Campaign created on ' + (dArr[2] + ' ' + dArr[1] + ' ' + dArr[3] + ' ' + dArr[4]);
      newOfferData.budget = '0';
      newOfferData.duration = '1';
    }

    self.service.sendNewOffer(
        SessionService.enrichApiUrl(self.service.api.currentDepartment.uris.Offers),
        newOfferData,
        offerData => {
          self.service.currentOffer = offerData;
          this.setOfferLocation();
          this.fileService.init(this.service.currentOffer, this.isRTB);
          self.newOfferProcessing = false;
          self.newOffer = true;
        },
        err => {
          console.log(err);
          self.newOfferProcessing = false;
        }
    );
  }

  get budget(): string {
    return this.service.currentOffer && this.service.currentOffer.budget ? this.service.currentOffer.budget : '0';
  }

  get duration(): string {
    if (this.service.currentOffer && this.service.currentOffer.duration) {
      const duration = parseInt(this.service.currentOffer.duration, 10);
      return `${duration} <br>day${duration !== 1 ? 's' : ''}`;
    } else {
      return '-';
    }
  }

  public get canSubmit(): Observable<boolean> {
    return new Observable<boolean>(subscriber => {
      const state = !(this.service.currentOffer == null ||
      parseInt(this.service.currentOffer.budget, 10) < 1 ||
      !this.service.currentOffer.offer_type ||
      !this.service.currentOffer.offer_variant ||
      !this.service.currentOffer.audience);

      combineLatest([
        this.fileService.checkQRCodes,
        this.fileService.artworksNotReady()
      ]).pipe(
        takeUntil(this._destroy$)
      ).subscribe(([qrcodesReady, artworksNotReady]) => {
        subscriber.next(state && qrcodesReady && !artworksNotReady);
        subscriber.complete();
      });
    });
  }

  get notUploaded(): Observable<any> {
    return new Observable(observer => {
      if (this.service.getArtworkUrl() !== '') {
        observer.next(false);
        observer.complete();
      } else {
        this.fileService.uploadedFile
          .subscribe(val => {
            observer.next(val === null);
            observer.complete();
          });
      }
    });
  }

  submitCampaign(displayGroup: DisplayGroup): void {
    const status = 'submitted';
    const targets = this.offerAudiences.get().map(a => a.target.uri);

    this.service.setTargetsStatus(targets, status)
      .subscribe(
        () => {
          this.service.fetch().subscribe(
            () => {
              this.confirmationModal && this.confirmationModal.close();
              this.afterSubmitModal = this.modalService.open(this.afterSubmitPopup, {size: 'lg', backdrop: 'static'});
            },
            err => this.startingCampaignError(err)
          );
        },
        err => this.startingCampaignError(err)
      );
  }

  sendCurrentOfferState(): void {
    this.service.saveOffer()
      .pipe(takeUntil(this._destroy$))
      .subscribe(
      () => this.submitCampaign(this.service.chosenAudience),
      (err) => this.startingCampaignError(err)
    );
  }

  private startingCampaignError(error): void {
    this.startingCampaign = false;
    console.error(error);
  }

  redirectView(advanced = false): void {
    this.afterSubmitModal.close();

    if (advanced) {
      this.resetOffer = false;
      this.service.resetOffer = false;
      this.service.router.navigate(['offers', this.service.currentOffer.id])
        .catch(reason => console.error(reason));
    } else {
      this.service.router.navigate(['dashboard'])
        .catch(
          reason => console.error(reason)
        );
    }
  }

  showConfirmationPopup(): void {
    if (this.company.active_plan !== 'PLUS') {
      this.confirmationModal = this.modalService.open(ArtworkConfirmationComponent, {size: 'lg'});
      this.confirmationModal.componentInstance.advanced = true;
      this.confirmationModal.componentInstance.artworkConfirmed.subscribe(
        _ => this.startNow()
      );
    } else {
      this.startNow();
    }
  }

  editBudget(): void {
    this.colapseAll();
    this.budget_wrapped = false;
  }

  editAudience(): void {
    this._data && this.modifyBudgetAndDuration(this._data);
    this._data = null;
    this.colapseAll();
    this.audiences_wrapped = false;
  }

  editFormat(): void {
    this.colapseAll();
    this.formats_wrapped = false;
  }

  colapseAll(): void {
    this.budget_wrapped = true;
    this.audiences_wrapped = true;
    this.formats_wrapped = true;
  }

  private modifyOffer(): void {
    this.service.saveOffer()
      .pipe(takeUntil(this._destroy$))
      .subscribe();
  }

  modifySchedule(data, skipDateCalc = false) {
    if (data.hasOwnProperty('duration')) {
      const duration = parseInt(data.duration, 10);
      !skipDateCalc && this.service.setSchedulerDatesBasedOnDuration(duration, true);
    }
    this._data = data;
    this.modifyBudgetAndDuration(data, true);
  }

  updateEstimatedDisplays(): void {
    const payload = {...this.service.getCampaignForEstimation(), media_file_thumbnail: this.fileService.getAllThumbnails()};
    this.estimatorService.getEstimatesV3(payload)
        .pipe(
          take(1),
          takeUntil(this._destroy$),
      ).subscribe();
  }

  modifyBudgetAndDuration(data, skipEstimates = false): void {
    const hours = this.service.getSchedulerTimeHours(),
          budget = data && data.budget ? data.budget : this.service.currentOffer.budget,
          duration = data && data.duration ? data.duration : this.service.currentOffer.duration,
          dailyCap = this.isRTB ? (data && data.dailyCap ? data.dailyCap : this.service.currentOffer.budget_daily) : null,
          selectedBudget = parseInt(budget, 10);

    // update campaign params
    if (data) {
      this.service.currentOffer.budget = budget;
      this.service.currentOffer.duration = duration;
      if (this.isRTB) {
        this.service.currentOffer.budget_daily = dailyCap;
      }
    }

    if (selectedBudget > 0 && !this.audiences_editable) {
      this.audiences_unlocked = true;
    }

    const payload = {...this.service.getCampaignForEstimation(), media_file_thumbnail: this.fileService.getAllThumbnails()};

    if (budget && budget > 0 && hours && duration && this.location && this.service.api.currentDepartment.radius) {
      this.loadingEstimates.next(true);
      this.estimatorService.getEstimatesV3(payload)
        .pipe(
          take(1),
          tap(() => this.loadingEstimates.next(false)),
          tap(() => (selectedBudget > 0 && !this.audiences_editable) && (this.audiences_unlocked = true)),
          takeUntil(this._destroy$)
      ).subscribe(
          (_data) => {
            _data.audience_reach_results.forEach(est => {
              est.totalReach = est.formats.reduce((sum, item) => sum + item.reach, 0);
              est.totalRateCardReach = est.formats.reduce((sum, item) => sum + item.rate_card_reach, 0);
              est.totalImpressions = est.formats.reduce((sum, item) => sum + item.impressions, 0);
              est.totalRateCardImpressions = est.formats.reduce((sum, item) => sum + item.rate_card_impressions, 0);
            });
            this.offerEstimations = _data.audience_reach_results;
            this.offerAudiences.updateEstimates(this.offerEstimations);
            this.formatsService.updateFormatsEstimates(this.offerAudiences.get(), this.offerEstimations);
            this.updateOfferEstimates(true);
          },
          err => {
            this.loadingEstimates.next(false);
            this.notifyService.generateNotification(
                [{
                  title: 'Estimator problem',
                  alertContent: 'Please reload the page if the problem still exists contact administrator.'
                }]);
          }
      );

    }
  }

  ngOnDestroy() {
    if (this.resetOffer) {
      this.service.resetCurrentOffer();
      this.fileService.reset();
    }
    this._destroy$.next();
    this._destroy$.complete();
  }

  get postcode(): string {
    return (this.service.currentOffer && this.service.currentOffer.offer_postcode) || this.location.postcode;
  }

  get latitude(): number {
    return (this.service.currentOffer && this.service.currentOffer.latitude) || this.location.latitude;
  }

  get longitude(): number {
    return (this.service.currentOffer && this.service.currentOffer.longitude) || this.location.longitude;
  }

  get locationMap(): string {
    return (this.service.currentOffer && this.service.currentOffer.location_map);
  }

  get radius(): number {
    return (this.service.currentOffer.offer_radius !== null && this.service.currentOffer.offer_radius >= 0)
      ? this.service.currentOffer.offer_radius
      : this.service.api.currentDepartment.radius;
  }

  updateAudiences(selectedAudience: SelectedAudience): void {
    this.offerAudiences.toggle([selectedAudience])
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        () => {
          this.offerAudiences.updateEstimates(this.offerEstimations);
          this.formats_unlocked = true;
          this.updateOfferEstimates();
          const currAudience = this.offerAudiences.getSingle();
          if (currAudience) {
            this.service.estimatedBudget = {
              budget: parseInt(this.service.currentOffer.budget, 10),
              estimatedSpend: currAudience ? currAudience.overall_demand_spend : 0,
            };
          } else {
            this.service.estimatedBudget = null;
          }
        }
    );
  }

  private setOfferLocation(): void {
    if (!this.service.currentOffer.offer_postcode) {
      this.service.currentOffer.offer_postcode = this.location.postcode;
    }
    if (!this.service.currentOffer.offer_radius) {
      this.service.currentOffer.offer_radius = this.service.api.currentDepartment.radius;
    }
  }

  onLocationChange(data) {
    this.service.currentOffer.latitude = data.latitude;
    this.service.currentOffer.longitude = data.longitude;
    this.service.currentOffer.offer_postcode = data.postalCode;
    this.service.currentOffer.offer_radius = data.radius;
    this.service.currentOffer.location_map = data.locationMap;
    this.modifyBudgetAndDuration(null);
  }

  get sidebarShown(): boolean {
    return this.coreService.sidebarShown;
  }

  private onOfferAudienceUpdate(): void {
    console.log('=== AUDIENCES CHANGED ===');
    if (this.service.chosenFormat && this.offerEstimations) {
      const audienceUris = this.offerAudiences.get().map(aud => aud.audience.uri);

      // this.offerService.chosenFormat
      const formatList = [];
      this.offerEstimations
          .filter(oe => audienceUris.includes(oe.audience_uri))
          .map(a => { a.formats.map(f => f.format && formatList.push(f.format)); });
      console.log(formatList);
      if (!formatList.includes(this.service.chosenFormat.ooh_format)) {
        this.toastr.warning(
            `Previously selected format has been unselected as it is not available in currently selected audiences.`,
            'Format unselected',
            {
              enableHtml: true,
              timeOut: 10000,
              closeButton: true,
            }
        );
        this.selectFormat(null);
      }
    }
  }

  selectFormat(chosenFormat: IFormat): void {
    this.service.campaignFormats = chosenFormat ? [chosenFormat.ooh_format] : null;
    this.onSelectedFormatsChanged();
  }

  onSelectedFormatsChanged(): void {
    const audience = this.offerAudiences.getSingle();
    this.service.updateCampaignFormatData(audience);
    this._updateEstimator.next();
  }

  get selectedAudiencesNumber(): number {
    return this.offerAudiences.get().length;
  }

  get isRTB() {
    return this.service.impressionModel === 'rtb';
  }

  get isRegular() {
    return this.service.impressionModel === 'regular';
  }

  private updateOfferEstimates(skipEstimation = false) {
    const currAudience = this.offerAudiences.getSingle();
    if (currAudience) {
      this.formatsService.updateFormatsEstimates(this.offerAudiences.get(), this.offerEstimations);
      this.service.currentOffer.audience = currAudience.audience.audience_code;
      this.service.currentOffer.reach = currAudience.reach;

      this.service.estimatedBudget = {
        budget: parseInt(this.service.currentOffer.budget, 10),
        estimatedSpend: currAudience.overall_demand_spend || 0,
      };

      this.updateEstimatedDisplays();
    } else {
      this.service.currentOffer.audience = '';
      this.service.currentOffer.reach = null;
      this.service.currentOffer.offer_variant = '';
      if (!skipEstimation) {
        this.modifyBudgetAndDuration(null);
      }
    }

    if (this.service.chosenFormat) {
      this.service.chosenFormat = this.formatsService.getCurrentFormat(this.service.chosenFormat);
      this.service.currentOffer.num_of_screens = this.service.chosenFormat.number;
      this.service.currentOffer.ad_plays = this.service.chosenFormat.ad_plays;
      this.service.currentOffer.reach = this.service.chosenFormat.reach;

      this.service.estimatedBudget = {
        budget: parseInt(this.service.currentOffer.budget, 10),
        estimatedSpend: this.service.chosenFormat.demand_spend || 0,
      };

    } else {
      this.service.currentOffer.num_of_screens = null;
      this.service.currentOffer.ad_plays = null;
    }

  }

  get chosenFormat(): IFormat {
    return this.service.chosenFormat;
  }

  get allFormatsReady(): Observable<any> {
    return this.formatsService.allFormatsReady(this.service.campaignFormats, this.fileService.campaignFiles);
  }

}
