import {EventEmitter, Injectable, Output} from '@angular/core';

@Injectable()
export class LayoutService {

  isOpen = false;
  isLayoutEditorOpen = false;

  @Output() change: EventEmitter<boolean> = new EventEmitter();
  @Output() layoutEditorOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

  toggle() {
    this.isOpen = !this.isOpen;
    this.change.emit(this.isOpen);
  }

  /**
   * Layout editor toggler
   */
  toggleLayoutEditor() {
    this.isLayoutEditorOpen = !this.isLayoutEditorOpen;
    this.layoutEditorOpen.emit(this.isLayoutEditorOpen);
  }

}
