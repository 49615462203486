<div id="login-page">
  <div class="container">
    <form class="form-login" id="forgot-password-form" #formRef="ngForm">
      <div class="login-wrap">
        <div class="logo">Flow City</div>
          <h2 class="form-login-heading">password reset</h2>
            <p *ngIf="error" class="login-error">{{ error }}</p>
            <div class="login-form-body" [class.processing]="processing">
              <p class="password-reset-info">Enter your e-mail address used when registering. You will receive password reset instructions.</p>
              <input [(ngModel)]="email"
                     type="text"
                     name="email"
                     #emailRef="ngModel"
                     class="form-control"
                     placeholder="Your E-mail"
                     autocomplete="off"
                     autofocus
                     required>
              <small class="error" *ngIf="emailRef.dirty && emailRef.errors?.required">
                This field is required
              </small>
              <br>
              <button (submitIfValid)="requestResetPassword()" class="btn btn-theme btn-block">RESET PASSWORD</button>
              <br>
              <p class="text-center"><a routerLink="/login">Back to login page</a></p>
            </div>
            <div class="loading not-masked" *ngIf="processing"></div>
        </div>
    </form>
  </div>
</div>

<div id="forgot-background"></div>

