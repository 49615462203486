import { Directive, TemplateRef } from '@angular/core';

// tslint:disable-next-line:directive-selector
@Directive({ selector: '[fc-iconitem-description]' })
export class FCIconItemDescriptionDirective {
    constructor(public template: TemplateRef<any>) { }
}

// tslint:disable-next-line:directive-selector
@Directive({ selector: '[fc-iconitem-summary]' })
export class FCIconItemSummaryDirective {
    constructor(public template: TemplateRef<any>) { }
}

// tslint:disable-next-line:directive-selector
@Directive({ selector: '[fc-iconitem-tooltip]' })
export class FCIconItemTooltipDirective {
    constructor(public template: TemplateRef<any>) { }
}

// tslint:disable-next-line:directive-selector
@Directive({ selector: '[fc-iconitem-percent]' })
export class FCIconItemPercentDirective {
    constructor(public template: TemplateRef<any>) { }
}
