import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { OfferService } from '../../../../../services/offer/offer.service';

import { IMultiEstimate } from '../../../../../services/estimator/estimator.service';
import { FileService } from '../../../../../services/file/file.service';
import { FormatsService } from '../../../../../services/formats/formats.service';

import { ToastrService } from 'ngx-toastr';
import { FormatsBlockDispModel } from '../../../../../models/FormatsBlockDisp.model';
import { UploadedFiles } from '../../../../../models/UploadedFiles.model';
import { IFormat } from '../../../../../models/format.model';
import { InstagramService } from '../../../../../modules/instagram/instagram.service';
import { OfferData } from '../../../../../models/offers/offer-data/OfferData.model';
import { from, Observable, Subject } from 'rxjs';
import { first, mergeMap, takeUntil } from 'rxjs/operators';
import { ICampaignFile } from '../../../../../services/file/file.model';
import { FeedChooserService } from '../../../../../modules/feed-chooser/feed-chooser.service';
import { DEFAULT_FILE_FEEDBACK } from '../../../../../shared/simple/artwork-preview/artwork-preview.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GenerateQrcodeComponent } from '../../../../../shared/components/generate-qrcode/generate-qrcode.component';
import { ArtworkGeneratorService } from '@module/artwork-generator/artwork-generator.service';


const BASE_ICON_PATH = 'https://cdn.flow.city/formats';

export interface SelectedFormat {
  format: FormatsBlockDispModel;
  numOfScreens: number;
  ad_plays: number;
}

@Component({
  selector: 'app-format-selector',
  templateUrl: './format-selector.component.html',
  styleUrls: ['./format-selector.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormatSelectorComponent implements OnInit, OnDestroy {

  @Input() formatsBlocks: FormatsBlockDispModel[] = [];
  @Input() offerAudiences: any;
  @Input() estimations: IMultiEstimate[];

  @Output() selectFormat = new EventEmitter();
  @Output() selectTemplate = new EventEmitter();

  @Input()
  get chosenFormat() {
    return this.offerService.chosenFormat;
  }

  set chosenFormat(val) {
    if (val === null) {
      delete this.offerService.chosenFormat;
    } else {
      this.offerService.chosenFormat = val;
    }
  }

  @ViewChild('fileToUpload') fileToUpload: ElementRef;
  @ViewChild('videoPlayer') videoPlayer: ElementRef;

  private _destroy$ = new Subject<any>();
  uploadedFiles: UploadedFiles = null;
  thumbnailPreviewStyle: {};
  filesToUpload = [];

  private _selectedArtwork: ICampaignFile = null;
  private _selectedArtworkSrc: string;

  fileFeedback = DEFAULT_FILE_FEEDBACK;

  get formatPreviewChild(): string {
    const svg = document.querySelector('.format.selected svg');

    return svg ? svg.outerHTML : '';
  }

  fileType = '';
  acceptedFiles = 'video/*,image/*';
  previewSrc: string = undefined;
  incorrectFormat = false;


  allFormats: IFormat[];


  readonly rowLength: number = 4;
  readonly maxWidth: number = 300;
  readonly maxHeight: number = 175;
  comparisionTableState = 'out';

  constructor(
      public sanitizer: DomSanitizer,
      public offerService: OfferService,
      public fileService: FileService,
      private instagramService: InstagramService,
      private formatsService: FormatsService,
      private feedChooserService: FeedChooserService,
      private toastr: ToastrService,
      private modalService: NgbModal,
      private artworkGeneratorService: ArtworkGeneratorService,
  ) {
    // Get all available formats definitions
    this.formatsService.allFormats
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        data => this.allFormats = data,
    );

    this.offerService.formatChanged
      .pipe(takeUntil(this._destroy$))
      .subscribe(
      () => this.getAspectRatio()
    );

    if (this.offerService.isRegular) {
      this.fileService.campaignFiles$
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          files => {
            if (files.length > 0) {
              this.selectedArtwork = files[0];
            }
          }
        );
    }

    this.offerService.customArtwork
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data) => {
          if (data) {
            const file = this.blobToFile(this.dataURLToBlob(data), 'custom-file.png');

            this.fileType = 'image';
            this.offerService.save().subscribe();
            this.sendFileName(null, file, true);
            this.offerService.updateCustomArtwork(null);
          }
        }
    );
  }

  get selectedArtwork(): ICampaignFile {
    return this._selectedArtwork;
  }

  set selectedArtwork(artwork: ICampaignFile) {
    this._selectedArtwork = artwork;
    this.fileSrc
      .pipe(takeUntil(this._destroy$))
      .subscribe(url => this._selectedArtworkSrc = url);
  }

  get selectedArtworkSrc(): string {
    return this._selectedArtworkSrc;
  }

  ngOnInit(): void {
    //this.getAspectRatio();
  }

  trackFn(index, item) {
    return index;
  }

  get formats() {
    if (this.allFormats && this.estimations && this.offerAudiences.get().length > 0) {
      let formats = [];

      this.offerAudiences.get().forEach(aud => {
        const estAudience = this.estimations.find(est => est.audience_uri === aud.audience.uri);
        if (estAudience) {
          formats = [...formats, ...estAudience.formats];
        }
      });

      const availFormats = [];
      formats.forEach(f => {
        const format = this.allFormats.find(af => af.ooh_format === f.format);
        if (format) {
          availFormats.push({
            ...f,
            ooh_format: f.format,
            ooh_format_label: format.ooh_format_label,
            artwork_spec: format.artwork_spec,
          });
        }
      });
      return availFormats;
    }
  }

  getIcon(format: IFormat): string {
    return `${BASE_ICON_PATH}/${format.ooh_format.split('_')[0]}.svg`;
  }

  getFormatEstimatorData(format: IFormat, field: string = 'number'): number {
    if (!this.offerService.chosenAudience) {
      return 0;
    } else {
      if (this.estimations) {
        const currEstimation = this.estimations.find(est => est.audience === this.offerService.chosenAudience.audience_code);
        if (currEstimation) {
          const currFormat = currEstimation.formats.find(f => f.format === format.format);
          return currFormat && currFormat[field] ? currFormat[field] : 0;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    }
  }

  selectToUpload(format: IFormat): void {
    const self = this;
    this.selectFormat.emit(format);
    const formatName = format ? format.format : null;

    self.chosenFormat = format;
    // self.updateAcceptedFileTypes(formatName,
    //     () => {
    //   if (self.offerService.currentOffer) {
    //     self.offerService.currentOffer.offer_variant = formatName;
    //
    //   }
    //   else
    //     self.selectFile();
    // });
  }


  updateAcceptedFileTypes(formatName: string, CB: () => void): void {
    if (formatName === 'taxi_top') {
      this.acceptedFiles = 'image/*';
    } else {
      this.acceptedFiles = 'video/*,image/*';
    }

    this.waitForAttrChange(CB);
  }

  waitForAttrChange(CB: () => void): void {
    const self = this;
    const iv = setInterval(() => {
      if (self.fileToUpload.nativeElement.getAttribute('accept') === self.acceptedFiles) {
        clearInterval(iv);
        CB();
      }
    }, 100);

  }

  unsetFormat(): void {
    this.selectToUpload(undefined);
  }

  formatClicked(format: IFormat) {
    this.selectToUpload(format);
  }

  unselected(format: IFormat): boolean {
    return this.chosenFormat && this.chosenFormat !== format;
  }

  selected(format: IFormat): boolean {
    return format === this.chosenFormat;
  }


  selectFile(): void {
    this.incorrectFormat = false;
    this.fileToUpload.nativeElement.click();
  }

  addFileOld() {
    const file = this.fileToUpload.nativeElement.files[0];
    let type = file.type;

    if (type.includes('video')) { type = 'video'; } else if (type.includes('image')) { type = 'image'; } else {
      this.incorrectFormat = true;
      return false;
    }

    this.fileType = type;
    this.offerService.currentOffer.offer_type = type;
    this.offerService.save().subscribe();
    this.sendFileName(null, file, true);
  }

  get offer(): OfferData {
    return this.offerService.currentOffer;
  }

  addFile(event) {
    this.filesToUpload = [];
    let type = 'multi';
    Array.prototype.forEach.call(this.fileToUpload.nativeElement.files, file => {
      this.filesToUpload.push(file);
    });

    event.target.value = '';

    from(this.filesToUpload)
      .pipe(
        mergeMap(file => this.fileService.initFileUpload(file), null, 4)
      )
      .subscribe(
        file => {
          if (this.offerService.isRegular) {
            if (file['uploadedFile']) {
              this.fileFeedback = DEFAULT_FILE_FEEDBACK;
              this.selectedArtwork = this.fileService.campaignFiles[0];
            } else {
              this.fileFeedback = {
                fileUploading: true,
                filePercent: file['progress']
              };
            }
          }
        }
      );

    if (this.offerService.isRegular) {
      const fileType = this.filesToUpload[0].type;
      type = fileType.includes('video') ? 'video' : 'image';
      this.offerService.currentOffer.offer_type = type;
      this.offerService.save().subscribe();
    }
  }

  sendFileName(offerData: OfferData, file, reload: boolean = false): void {
    const self = this;
    if (!reload) {
      this.offerService.currentOffer = offerData;
    }

     this.initUpload(file);

  }

  private initUpload(file) {
    this.fileService.initFileUpload(file)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
            (data) => {
              this.fileService.fileFeedback.filePercent = data.progress;
              if (data.uploadedFile) {
                this.fileService.fileFeedback.filePercent = 0;
                this.fileToUpload.nativeElement.value = '';

                // refresh campaign
                this.offerService.fetch()
                  .pipe(takeUntil(this._destroy$))
                  .subscribe();
              }
            },
            (err) => {
              this.fileService.fileFeedback.fileUploading = false;
            }
        );
  }

  get fileSrc(): Observable<string> {
    return new Observable(subscriber => {
      let url: string;
      if (this.offerService.customArtwork.getValue()) {
         url = this.offerService.customArtwork.getValue();
         subscriber.next(url);
         subscriber.complete();
      } else {
         if (this.selectedArtwork) {
           this.fileService.getArtworkSrcAsync(this.selectedArtwork)
             .subscribe(
             redirectUrl => {
               subscriber.next(redirectUrl);
               subscriber.complete();
             }
           )
         } else {
           subscriber.next(this.offerService.getArtworkUrl());
           subscriber.complete();
         }
       }
    });
  }

  get fileMimeType() {
    if (this.selectedArtwork && this.selectedArtwork.mime_type) {
      return this.selectedArtwork.mime_type.split('/')[0];
    } else {
      return null;
    }
  }

  openChat(): void {
    return;
  }

  get acceptedFileType(): string {
    if (this.offerService.currentOffer.offer_type === 'video') {
      return 'video/*';
    } else {
      return 'video/quicktime,video/*,image/*';
    }
  }

  openGenerator(): void {
    this.artworkGeneratorService.toggle();
  }

  openFeedChooser(): void {
    this.feedChooserService.toggle();
  }

  blobToFile(theBlob: Blob, fileName: string): File {
    const b: any = theBlob;
    // A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    // Cast to a File() type
    return <File>theBlob;
  }

  dataURLToBlob(dataURL) {
    const BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) == -1) {
      const parts = dataURL.split(',');
      const contentType = parts[0].split(':')[1];
      const raw = decodeURIComponent(parts[1]);
      return new Blob([raw], {type: contentType});
    }
    const parts = dataURL.split(BASE64_MARKER);
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], {type: contentType});
  }

  getAspectRatio() {
    const height = 150;
    const width = 100;
    let ar = 1.78;
    if (this.chosenFormat) {
      const formatWidth = this.chosenFormat.artwork_spec.resolution.width;
      const formatHeight = this.chosenFormat.artwork_spec.resolution.height;
      ar = formatWidth / formatHeight;
    }

    if (ar > 1) {
      this.thumbnailPreviewStyle = {
        width: `${width}px`,
        height: `${width / ar}px`
      };
    } else {
      this.thumbnailPreviewStyle = {
        width: `${height * ar}px`,
        height: `${height}px`
      };
    }
  }

  uploadProgressStyle(percent) {
    let bg;
    if (percent < 50) {
      const deg = 90 + (3.6 * percent);
      bg = `linear-gradient(90deg, #E0E0E0 50%, transparent 50%, transparent), linear-gradient(${deg}deg, #03F7FF 50%, #E0E0E0 50%, #E0E0E0)`;
    } else {
      const deg = -90 + (3.6 * (percent - 50));
      bg = `linear-gradient(${deg}deg, #03F7FF 50%, transparent 50%, transparent), linear-gradient(270deg, #03F7FF 50%, #E0E0E0 50%, #E0E0E0)`;
    }
    return {background: bg};
  }

  openTemplates() {
    this.selectTemplate.emit(true);
  }

  onThumbnailClick(file: ICampaignFile) {
    if (!file.thumbnail_inprogress && !file.transcode_inprogress) {
      this.selectedArtwork = file;
    }
  }

  removeItem(artwork, event): void {
    event && event.stopImmediatePropagation();
    this.selectedArtwork = null;
    this.fileService.deleteFile(artwork);
  }

  thumbnailPreviewImage(img) {
    return img ? `url(${img})` : '';
  }

  get singleArtworkFile() {
    if (this.isRegular && this.selectedArtwork) {
      return this.selectedArtwork;
    } else {
      return null;
    }
  }

  get isRegular() {
    return this.offerService.isRegular;
  }

  get canQRCodeBeGenerated(): boolean {
    return this.selectedArtwork && this.selectedArtwork.qrcode_detected && !this.selectedArtwork.qrcode_rendered;
  }

  generateQRCode(): void {
    const modalRef = this.modalService.open(GenerateQrcodeComponent, {size: 'lg'});
    modalRef.componentInstance.closed.subscribe(
      content => {
        if (content) {
          this.selectedArtwork.thumbnail_inprogress = true;
          this.fileService.generateQRCode(this.selectedArtwork, content)
            .subscribe(
              file => this.selectedArtwork = file,
            );
        }
      });
  }

  getPercentage(format): number {
    return this.getFormatEstimatorData(format, 'impressions') * 100 / this.getFormatEstimatorData(format, 'rate_card_impressions');
  }

  public tooltipHelper(format): string {
    const origPercentage = this.getPercentage(format);
    const percentage = Math.round(origPercentage <= 100 ? origPercentage : 100);

    if (percentage > 49) {
      return `You will get <strong>${percentage}%</strong> of available impressions using this format.`;
    } else {
      return `You will only get <strong>${percentage}%</strong> of available impressions using this format.<br><small>You can increase number of impressions by setting the campaign ahead of time.</small>`;
    }
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

}

