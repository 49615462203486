import { environment as env } from '../../../environments/environment';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AutoSizeInputModule } from 'ngx-autosize-input';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ColorPickerModule } from 'ngx-color-picker';
import { FontPickerModule } from 'ngx-font-picker';
import { FONT_PICKER_CONFIG } from 'ngx-font-picker';
import { CoreRoutingModule, MAIN_COMPONENTS } from './core.routing';
import { SharedModule } from '../../shared/shared.module';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { TileComponent } from './main-content/offers/tiles/tile/tile.component';
import { CampaignScheduleComponent } from './main-content/offers/offer/campaign-schedule/campaign-schedule.component';
import { MainPipe } from '../../pipes/main-pipe.module';
import { LayoutEditorComponent } from './layout-editor/layout-editor.component';
import { OfferResolver } from '../../resolvers/offer.resolver';
import { InstagramService } from '../../services/instagram/instagram.service';
import { HachetteModule } from '../../modules/hachette/hachette.module';
import { InstagramModule } from '../../modules/instagram/instagram.module';
import { DisplayGroupService } from '../../services/display-group/display-group.service';
import { RedirectService } from '../../services/redirect/redirect.service';
import { OfferLocationComponent } from './main-content/offers/offer-location/offer-location.component';
import { SelectImpressionModelComponent } from './main-content/create-campaign/select-impression-model/select-impression-model.component';
import { RtbBudgetComponent } from './main-content/create-campaign/rtb-budget/rtb-budget.component';
import { AudienceSelectorComponent } from './main-content/create-campaign/audience-selector/audience-selector.component';
import { FormatSelectorComponent } from './main-content/create-campaign/format-selector/format-selector.component';
import { FileService } from '../../services/file/file.service';
import { LocationService } from '../../services/location.service';
import { StartFirstCampaignComponent } from './main-content/start-first-campaign/start-first-campaign.component';
import { FeedChooserModule } from '../../modules/feed-chooser/feed-chooser.module';
import { StockLevelComponent } from './main-content/offers/offer/campaign-schedule/stock-level/stock-level.component';
import { EstimatedBudgetComponent } from './main-content/create-campaign/estimated-budget/estimated-budget.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { JoyrideModule } from 'ngx-joyride';
import { FormatsService } from '../../services/formats/formats.service';
import { AudiencesService } from '../../services/audiences/audiences.service';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faBell,
  faCaretSquareLeft, faEnvelope, faEnvelopeOpen,
  faLifeRing,
  faPlusSquare,
  faCheckCircle,
} from '@fortawesome/free-regular-svg-icons';
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight, faChevronUp,
  faExclamationCircle, faExclamationTriangle,
  faTimesCircle,
  faTimes,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { SliderModule } from 'primeng/slider';
import { MultiFormatSelectorComponent } from './main-content/create-campaign/multi-format-selector/multi-format-selector.component';
import { FormatPanelComponent } from './main-content/create-campaign/multi-format-selector/format-panel/format-panel.component';
import { IconItemModule } from '../../common/icon-item/icon-item.module';
import { ArtworkGeneratorModule } from '@module/artwork-generator/artwork-generator.module';
import { CampaignLocationModule } from '@module/campaign-location/campaign-location.module';


@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    JoyrideModule,
    CoreRoutingModule,
    FormsModule,
    MatSliderModule,
    MatDialogModule,
    MatTabsModule,
    MatIconModule,
    AutoSizeInputModule,
    SharedModule,
    MainPipe,
    InfiniteScrollModule,
    ColorPickerModule,
    FontPickerModule,
    AngularMyDatePickerModule,
    HachetteModule,
    InstagramModule,
    FeedChooserModule,
    NgSelectModule,
    FontAwesomeModule,
    JoyrideModule.forRoot(),
    SliderModule,
    IconItemModule,
    ArtworkGeneratorModule,
    CampaignLocationModule,
  ],
  declarations: [
    MAIN_COMPONENTS,
    SideMenuComponent,
    TopBarComponent,
    TileComponent,
    CampaignScheduleComponent,
    LayoutEditorComponent,
    OfferLocationComponent,
    SelectImpressionModelComponent,
    RtbBudgetComponent,
    AudienceSelectorComponent,
    FormatSelectorComponent,
    StartFirstCampaignComponent,
    StockLevelComponent,
    EstimatedBudgetComponent,
    MultiFormatSelectorComponent,
    FormatPanelComponent,
  ],
  providers: [
    {
      provide: FONT_PICKER_CONFIG,
      useValue: {
        apiKey: env.fontsApiKey
      }
    },
    OfferResolver,
    InstagramService,
    DisplayGroupService,
    RedirectService,
    AudiencesService,
    FormatsService,
    FileService,
    LocationService,
  ],
  entryComponents: [
    SelectImpressionModelComponent,
  ]
})
export class CoreModule {
  constructor(
    library: FaIconLibrary,
  ) {
    library.addIcons(
      faBell,
      faLifeRing,
      faExclamationCircle,
      faCheckCircle,
      faTimesCircle,
      faPlusSquare,
      faCaretSquareLeft,
      faChevronLeft,
      faChevronRight,
      faChevronDown,
      faChevronUp,
      faExclamationTriangle,
      faEnvelope,
      faEnvelopeOpen,
      faTimes,
      faSpinner,
    );
  }
}
