export const FS_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DDTHH:mm:ss',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'YYYY-MM-DD',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const allWeek = [0, 1, 2, 3, 4, 5, 6];

export const allWeekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const CAMPAIGN_UPDATED_FIELDS = [
  'schedule_start',
  'schedule_stop',
  'offer_schedules',
  'budget',
  'daily_budget',
  'weekdays',
  'num_of_screens',
];

export const COLOR_CODES = {
  0: '#e74c3c',
  70: '#f39c12',
  90: '#2ecc71'
};

export const HOURS_VALUES: string[] = [
  '12:00 AM', '12:15 AM', '12:30 AM', '12:45 AM',
  '1:00 AM', '1:15 AM', '1:30 AM', '1:45 AM',
  '2:00 AM', '2:15 AM', '2:30 AM', '2:45 AM',
  '3:00 AM', '3:15 AM', '3:30 AM', '3:45 AM',
  '4:00 AM', '4:15 AM', '4:30 AM', '4:45 AM',
  '5:00 AM', '5:15 AM', '5:30 AM', '5:45 AM',
  '6:00 AM', '6:15 AM', '6:30 AM', '6:45 AM',
  '7:00 AM', '7:15 AM', '7:30 AM', '7:45 AM',
  '8:00 AM', '8:15 AM', '8:30 AM', '8:45 AM',
  '9:00 AM', '9:15 AM', '9:30 AM', '9:45 AM',
  '10:00 AM', '10:15 AM', '10:30 AM', '10:45 AM',
  '11:00 AM', '11:15 AM', '11:30 AM', '11:45 AM',
  '12:00 PM', '12:15 PM', '12:30 PM', '12:45 PM',
  '1:00 PM', '1:15 PM', '1:30 PM', '1:45 PM',
  '2:00 PM', '2:15 PM', '2:30 PM', '2:45 PM',
  '3:00 PM', '3:15 PM', '3:30 PM', '3:45 PM',
  '4:00 PM', '4:15 PM', '4:30 PM', '4:45 PM',
  '5:00 PM', '5:15 PM', '5:30 PM', '5:45 PM',
  '6:00 PM', '6:15 PM', '6:30 PM', '6:45 PM',
  '7:00 PM', '7:15 PM', '7:30 PM', '7:45 PM',
  '8:00 PM', '8:15 PM', '8:30 PM', '8:45 PM',
  '9:00 PM', '9:15 PM', '9:30 PM', '9:45 PM',
  '10:00 PM', '10:15 PM', '10:30 PM', '10:45 PM',
  '11:00 PM', '11:15 PM', '11:30 PM', '11:45 PM',
  '12:00 AM'
];

export const HOURS_VALUES_FORMATTED: string[] = [
  '00:00:00', '00:15:00', '00:30:00', '00:45:00',
  '01:00:00', '01:15:00', '01:30:00', '01:45:00',
  '02:00:00', '02:15:00', '02:30:00', '02:45:00',
  '03:00:00', '03:15:00', '03:30:00', '03:45:00',
  '04:00:00', '04:15:00', '04:30:00', '04:45:00',
  '05:00:00', '05:15:00', '05:30:00', '05:45:00',
  '06:00:00', '06:15:00', '06:30:00', '06:45:00',
  '07:00:00', '07:15:00', '07:30:00', '07:45:00',
  '08:00:00', '08:15:00', '08:30:00', '08:45:00',
  '09:00:00', '09:15:00', '09:30:00', '09:45:00',
  '10:00:00', '10:15:00', '10:30:00', '10:45:00',
  '11:00:00', '11:15:00', '11:30:00', '11:45:00',
  '12:00:00', '12:15:00', '12:30:00', '12:45:00',
  '13:00:00', '13:15:00', '13:30:00', '13:45:00',
  '14:00:00', '14:15:00', '14:30:00', '14:45:00',
  '15:00:00', '15:15:00', '15:30:00', '15:45:00',
  '16:00:00', '16:15:00', '16:30:00', '16:45:00',
  '17:00:00', '17:15:00', '17:30:00', '17:45:00',
  '18:00:00', '18:15:00', '18:30:00', '18:45:00',
  '19:00:00', '19:15:00', '19:30:00', '19:45:00',
  '20:00:00', '20:15:00', '20:30:00', '20:45:00',
  '21:00:00', '21:15:00', '21:30:00', '21:45:00',
  '22:00:00', '22:15:00', '22:30:00', '22:45:00',
  '23:00:00', '23:15:00', '23:30:00', '23:45:00',
  '23:59:00'
];
