<div class="row">
  <div class="col-6 welcome-img"></div>
  <div class="col-6 pt-4">
    <h1 class="title">Congrats!</h1>
    <h2 class="title">You are 3 steps away from getting your ad live.</h2>

    <div class="step">
      <span class="bullet">1</span>
      <h2>When & How much?</h2>
      Choose how much you want to spend and when
    </div>

    <div class="step">
      <span class="bullet">2</span>
      <h2>When & Where?</h2>
      How many people you want to reach an where
    </div>

    <div class="step">
      <span class="bullet">3</span>
      <h2>Your ad on the Screen!</h2>
      Upload your artwork Get it approved  and go live!
    </div>

    <h2 class="title mt-5">Let's create your first campaign:</h2>

    <form id="user-details-form" #formRef="ngForm" class="row">

        <div class="form-group col-12">
          <div class="input-group">
            <input [(ngModel)]="newOfferData.name"
                   type="text"
                   class="form-control editable"
                   name="firstname"
                   placeholder="Your campaign name"
                   #campaignNameFld="ngModel"
                   maxlength="30"
                   id="campaign-name"
                   (blur)="trimEntry()"
                   required/>
            <div class="input-group-append">
              <button (click)="createCampaign()"
                  class="btn"
                  [disabled]="!formRef.form.valid || newOfferData.name.trim().length === 0">
                  Create
              </button>
            </div>
          </div>

          <small *ngIf="campaignNameFld.dirty && campaignNameFld.errors?.required" class="error">Campaign name is required</small>
        </div>



      </form>


    </div>
</div>
