import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy, OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { OfferService } from '../../../../../../services/offer/offer.service';
import { SessionService as Session } from '../../../../../../services/session/session.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormatsBlockDispModel } from '../../../../../../models/FormatsBlockDisp.model';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Subject, timer } from 'rxjs';
import { filter, first, takeUntil, tap } from 'rxjs/operators';
import { OfferData } from '../../../../../../models/offers/offer-data/OfferData.model';
import { ISQLSearch } from '../../../../../../models/sql-search.model';
import { CampaignPreviewPopupComponent } from '../../../../../../shared/components/campaign-preview-popup/campaign-preview-popup.component';
import { DisplayGroup } from '../../../../../../models/DisplayGroups.model';
import { FormatsService } from '../../../../../../services/formats/formats.service';
import { IFormat } from '../../../../../../models/format.model';

const BASE_ICON_PATH = 'https://storage.googleapis.com/fc-cdn/formats';

const TARGET_COLOR_MAP = {
  'draft': 'gray',
  'submitted': 'orange',
  'rejected': 'red',
  'accepted': 'green',
  'booked': 'green',
  'online': 'blue',
  'paused': 'gray',
  'suspended': 'gray'
};

@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss'],
  animations: [
    trigger('tileRemoval', [
      state('deleting', style({
        transform: 'scale(0)'
      })),
      transition('* => deleting', animate('300ms ease-in'))
    ])
  ]
})

export class TileComponent implements OnInit, OnDestroy {
  @ViewChild('deleteConfirmation') deleteConfirmation: ElementRef;
  @ViewChild('previewPopup') previewPopup: ElementRef;

  target_title: string = null;

  @Input() formatsBlocks: FormatsBlockDispModel[] = [];

  @Input() offer: ISQLSearch.IOffer;
  @Output() selfDelete = new EventEmitter();

  currentArtwork = 0;

  offerData: OfferData;
  deletionModal: NgbModalRef;
  previewModal: NgbModalRef;
  awaitingResponse = false;
  tileState = null;

  audience: DisplayGroup;
  displayGroups: DisplayGroup[];
  currentFormat: IFormat = null;
  campaignFormats: IFormat[] = null;

  formatsReady = false;

  private _destroy$ = new Subject<any>();

  constructor(
    @Inject(OfferService) public service,
    private modalService: NgbModal,
    private formatsService: FormatsService,
  ) {
    this.service.api.getDisplayGroups();
  }

  ngOnInit(): void {
    this.service.api.displayGroupsData
      .pipe(
        takeUntil(this._destroy$),
        filter(groups => groups !== null),
        first()
      )
      .subscribe(
      (data) => {
        if (data) {
          this.displayGroups = data;
          this.audience = data.find(
            dg => dg.audience_code === this.offer.audience
          );
        }
      });

    this.getFormat();

    if (this.offerArtworksPreview.length > 1) {
      const startCarousel = () => {
        this.currentArtwork = this.currentArtwork + 1 > this.offerArtworksPreview.length - 1 ? 0 : this.currentArtwork + 1;
      };
      const change = timer(3000, 3000);
      change
        .pipe(
          takeUntil(this._destroy$)
        )
        .subscribe(
          () => startCarousel()
        );
    }
  }

  getFormat() {
    this.formatsService.allFormats
      .pipe(
        filter(d => d !== null),
        takeUntil(this._destroy$),
        tap(() => this.formatsReady = true)
      )
      .subscribe(
        allFormats => {
          if (this.offer.campaign_formats && this.offer.campaign_formats.length > 0) {
            this.campaignFormats = allFormats.filter(af => this.offer.campaign_formats.includes(af.ooh_format));
          }
        }
      );
  }

  formatIcon(format): string {
    return format && `${BASE_ICON_PATH}/${format.ooh_format.split('_')[0]}.svg`;
  }

  get draftOrLive(): boolean {
    const status = !this.offer.offer_targets ? 'draft' : this.offer.offer_targets[0] ? this.offer.offer_targets[0].status : 'draft';
    return status === 'online' || status === 'draft';
  }

  showOffer(): void {
    this.offerData = null;
    const previewModal = this.modalService.open(CampaignPreviewPopupComponent, {size: 'lg'});
    previewModal.componentInstance.offer = this.offer;
  }

  get offerStatus(): string {
    return !this.offer.offer_targets || this.offer.offer_targets.length === 0 ? 'draft' : this.offer.offer_targets[0] ? this.offer.offer_targets[0].status.toLowerCase() : null;
  }

  get statusColor(): string {
    const status = this.offer.offer_targets[0] ? this.offer.offer_targets[0].status : 'draft';
    return TARGET_COLOR_MAP[status.toLowerCase()];
  }

  deleteOffer(event): void {
    event.stopPropagation();
    const self = this;
    self.deletionModal = self.modalService.open(self.deleteConfirmation);
  }

  requestDeletion(): void {
    const self = this;
    self.deletionModal.close();
    self.awaitingResponse = true;
    self.service.http.delete(
      Session.enrichApiUrl(self.offer.uri)
      ).pipe(
        takeUntil(this._destroy$),
      ).subscribe(
        () => {
          this.tileState = 'deleting';
          self.awaitingResponse = false;
          setTimeout(() => { self.selfDelete.emit(); }, 300);
        },
        (err: HttpErrorResponse) => {
          if (err.status === 0) {
            self.selfDelete.emit();
          } else {
            console.log(err);
          }
          self.awaitingResponse = false;
        }
      );
  }

  get isOnline(): boolean {
    return this.offer.offer_targets.findIndex(t => t.status === 'online') > -1;
  }

  targetsTrackByFn(index, item) {
    if (!item) {
      return null;
    } else {
      return item.uri;
    }
  }

  get offerArtworksPreview(): string[] {
    return this.offer.media_file_thumbnail ? this.offer.media_file_thumbnail.split('|') : [];
  }

  get offerImagePreview(): string {
    return this.offer.media_file_thumbnail || null;
  }

  get userAvatar(): string {
    return Session.enrichApiUrl(this.offer.creator_avatar_uri);
  }

  // get offerNextScheduleStep(): string {
  //   const target = this.offerData.targets.find(t => t.status === 'booked' || t.status === 'paused' || t.status === 'online');
  //   if (target && target.next_change) {
  //     if (['booked', 'paused'].includes(target.status)) {
  //       if (!target.is_displayed && target.next_change) {
  //         return ` on ${moment(target.next_change).format('MMMM Do YYYY, h:mm:ss a')}`;
  //       } else {
  //         return ' shortly';
  //       }
  //     } else if (target && target.status === 'online') {
  //       return ` until ${moment(target.next_change).format('MMMM Do YYYY, h:mm:ss a')}`;
  //     }
  //   } else {
  //     return '';
  //   }
  // }

  // get rejectionReason(): string {
  //   const target = this.offerData.targets.find(t => t.status === 'rejected');
  //   return target && target.rejection_reason ? `<br>${target.rejection_reason}` : '';
  // }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }


}
