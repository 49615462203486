import {
  Component, ElementRef, ViewChild, ChangeDetectorRef, AfterViewInit, OnDestroy, Input,
  Output, EventEmitter
} from '@angular/core';
import {StripePaymentService} from '../../../services/stripe-payment/stripe-payment.service';
import {NgForm} from '@angular/forms';
import {PaymentsModel} from '../../../models/session-init/session-data/company-model/payments/payments.model';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';


const elementStyles = {
  base: {
    fontFamily: 'GTWalsheim,Helvetica,\'sans-serif\'',
  }
};

const elementClasses = {
  base: 'form-control'
};


@Component({
  selector: 'app-stripe-modal',
  templateUrl: './stripe-modal.component.html',
  styleUrls: ['./stripe-modal.component.sass']
})
export class StripeModalComponent implements AfterViewInit, OnDestroy {

  elements: any;
  @ViewChild('cardNumber') cardNumber: ElementRef;
  @ViewChild('cardExpiry') cardExpiry: ElementRef;
  @ViewChild('cardCvc') cardCvc: ElementRef;

  @Input() payment: PaymentsModel;
  @Input() modalRef: NgbModalRef;
  @Output() onComplete: EventEmitter<any> = new EventEmitter();

  processing = false;

  cardNumberElement: any;
  cardExpiryElement: any;
  cardCvcElement: any;

  cardError: string;
  expiryError: string;
  cvcError: string;
  cardholderError: string;
  error: string;

  cardholderName: string = null;

  cardNumberHandler = this.onCardChange.bind(this);
  cardExpiryHandler = this.onExpiryChange.bind(this);
  cardCvcHandler = this.onCvcChange.bind(this);


  constructor(
    private sps: StripePaymentService,
    private cd: ChangeDetectorRef
  ) {
    this.elements = this.sps.stripe.elements({
      fonts: [{
          cssSrc: '/assets/ext-css/styles.css'
        }]
    });
  }

  ngAfterViewInit() {
    this.cardNumberElement = this.elements.create('cardNumber', {
      style: elementStyles,
      classes: elementClasses,
    });
    this.cardNumberElement.mount(this.cardNumber.nativeElement);

    this.cardExpiryElement = this.elements.create('cardExpiry', {
      style: elementStyles,
      classes: elementClasses,
    });
    this.cardExpiryElement.mount(this.cardExpiry.nativeElement);

    this.cardCvcElement = this.elements.create('cardCvc', {
      style: elementStyles,
      classes: elementClasses,
    });
    this.cardCvcElement.mount(this.cardCvc.nativeElement);

    this.cardNumberElement.addEventListener('change', this.cardNumberHandler);
    this.cardExpiryElement.addEventListener('change', this.cardExpiryHandler);
    this.cardCvcElement.addEventListener('change', this.cardCvcHandler);
  }

  ngOnDestroy() {
    this.cardNumberElement.removeEventListener('change', this.cardNumberHandler);
    this.cardExpiryElement.removeEventListener('change', this.cardExpiryHandler);
    this.cardCvcElement.removeEventListener('change', this.cardCvcHandler);
    this.cardNumberElement.destroy();
    this.cardExpiryElement.destroy();
    this.cardCvcElement.destroy();
  }

  onCardChange({ error }) {
    if (error) {
      this.cardError = error.message;
    } else {
      this.cardError = null;
    }
    this.cd.detectChanges();
  }

  onExpiryChange({ error }) {
    if (error) {
      this.expiryError = error.message;
    } else {
      this.expiryError = null;
    }
    this.cd.detectChanges();
  }

  onCvcChange({ error }) {
    if (error) {
      this.cvcError = error.message;
    } else {
      this.cvcError = null;
    }
    this.cd.detectChanges();
  }

  cancel() {
    this.modalRef.close();
  }

  checkField($event) {
    const el = $event.target;
    const isWhitespace = (el.value || '').trim().length === 0;
    if (isWhitespace) {
      this.cardholderError = 'Please enter cardholder name.';
    } else {
      this.cardholderError = null;
    }
  }

  async onSubmit(form: NgForm) {

    this.processing = true;
    const { token, error } = await this.sps.stripe.createToken(this.cardNumberElement);
    if (error) {
      console.log('Something is wrong:', error);
      this.processing = false;
      if (error.type !== 'validation_error') {
        this.error = error.message;
      }
    } else {
      console.log('Success!', token);
      if (token.id) {
        this.sps.setPaymentToken(this.payment.uri, token.id, sessionStorage.getItem('tmp-session'))
          .subscribe(
            (data) => {
              (<any>window).ga('gtm1.send', 'event', 'purchase', 'sent', `camden${this.payment.amount}`);
              this.processing = false;
              this.onComplete.emit(data);
            },
            (response) => {
              this.processing = false;
              this.error = response.error.reason;
              console.log(response);
            }
          );
      }
    }
  }

}
