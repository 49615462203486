import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { OfferService } from '../services/offer/offer.service';
import { Observable, of } from 'rxjs';
import { LoadingService } from '@shared/services/loading.service';
import { switchMap, take, tap } from 'rxjs/operators';
import { OfferData } from '../models/offers/offer-data/OfferData.model';
import { ScheduleModel } from '../models/schedule.model';
import { EstimatorService } from '../services/estimator/estimator.service';


@Injectable()
export class OfferResolver implements Resolve<any> {
  constructor(
    private offerService: OfferService,
    private loadingService: LoadingService,
    private estimatorService: EstimatorService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    this.loadingService.loading = true;
    return this.offerService.api.currentSessionData$
      .pipe(
        take(1),
        switchMap(() => this.offerService.fetchOffer(route.paramMap.get('id'))
        .pipe(
          switchMap((offer: OfferData) => this.offerService.fetchOfferSchedules()
          .pipe(
            tap((schedules: ScheduleModel[]) => this.offerService.assignScheduleTypes(schedules)),
            switchMap((schedules: ScheduleModel[]) => {
              const _offer = this.offerService.getCampaignForEstimation();
              return this.estimatorService.getEstimatesV3(_offer)
                .pipe(
                  tap(() => this.loadingService.loading = false),
                  switchMap(() => of({...offer, offer_schedules: schedules}))
                );
            })
        ))
      ))
      );
  }
}

