import {Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '../../../services/api-service/api.service';
import {CoreService} from '../../../services/main/core.service';
import {ProfileService} from '../../../services/profile/profile.service';
import {PaymentsModel} from '../../../models/session-init/session-data/company-model/payments/payments.model';


@Component({
  selector: 'app-buy-more-credits',
  templateUrl: './buy-more-credits.component.html',
  styleUrls: ['./buy-more-credits.component.sass']
})
export class BuyMoreCreditsComponent {

  @ViewChild('buyCredits') buyCredits: ElementRef;
  @ViewChild('creditsBought') creditsBought: ElementRef;
  @ViewChild('billingInfo') billingInfo: ElementRef;
  @ViewChild('stripe') stripe: ElementRef;
  @ViewChild('waiting') waiting: ElementRef;

  packagesModal: NgbModalRef;
  billingInfoModal: NgbModalRef;
  stripeModal: NgbModalRef;
  waitingModal: NgbModalRef;
  chosenPackage: object;
  awaitingResponse = false;

  currentPayment: PaymentsModel;

  billingInfoData: any;
  newCredits = 0;

  constructor(
    @Inject(ApiService) public api,
    @Inject(CoreService) public coreService,
    @Inject(ProfileService) public profileService,
    private modalService: NgbModal
  ) {
    this.profileService.fetchBillingInfo();
    this.profileService.billingInfoData.subscribe(
      (data) => {
        this.billingInfoData = data;
      }
    );
  }

  getBillingInfo() {
    if (this.billingInfoData && this.waitingModal) {
      this.openActionModal();
      return true;
    } else {
      return null;
    }
  }

  openActionModal() {
    const self = this;

    if (self.waitingModal) {
      self.waitingModal.close();
    }

    // if (this.billingInfoData.company_name) {
    //   self.packagesModal = self.modalService.open(self.buyCredits, {size: 'lg', backdrop: 'static'});
    //
    //   self.packagesModal.result
    //     .then(() => {},
    //       () => {});
    // } else {
    //   self.billingInfoModal = self.modalService.open(self.billingInfo, {size: 'lg', backdrop: 'static'});
    // }

    self.packagesModal = self.modalService.open(self.buyCredits, {size: 'lg', backdrop: 'static'});
    self.packagesModal.result
      .then(() => {}, () => {});
  }

  openOptions() {
    const self = this;

    if (!this.billingInfoData) {
      self.waitingModal = self.modalService.open(self.waiting, {size: 'lg', backdrop: 'static'});
      return;
    }
    self.openActionModal();
  }

  creditFeeDescription(): object {
    if (this.api.currentCompany) {
      return this.api.sessionData.subscription_plans.default.subscriptionPlans[this.api.currentCompany.active_plan]
        .creditFeeDescription.GBP;
    } else {
      return [];
    }
  }

  selectPackage(creditFee) {
    this.chosenPackage = creditFee;
  }

  buyMoreCredits(): void {
    const self = this;

    const credits = parseInt(self.chosenPackage['key'], 10);
    self.awaitingResponse = true;
    self.coreService.purchaseCredits(
      credits,
      (data) => {
        self.awaitingResponse = false;
        self.packagesModal.close();
        const oldCredits = self.api.sessionData.company.credits;
        self.newCredits = oldCredits + data.credits;

        // self.profileService.intercom.trackEvent(
        //   'event_order_credits',
        //   {
        //     timestamp: (new Date()).toString(),
        //     old_credits: oldCredits,
        //     new_credits: self.newCredits
        //   }
        // );

        if (data.payment_provider === 'Stripe' && data.redirect_url && data.redirect_url.indexOf('stripe') !== -1) {
          self.currentPayment = data;
          self.handleStripe(data);
        } else if (data.redirect_url !== null && data.redirect_url.indexOf('stripe') === -1) {
          window.location.href = data.redirect_url;
        } else if (!data.redirect_url || data.redirect_url.indexOf('stripe') === -1) {
          self.api.deleteSessionCache().subscribe();
          self.api.sessionData.company.credits = self.newCredits;
          self.openCreditsBought();
        } else {
          console.error('error has occurred');
        }
        this.chosenPackage = null;
      }
    );

  }

  closePackagesModal() {
    this.chosenPackage = null;
    this.packagesModal.close();
  }

  showPaymentConfirmation(data) {
    this.stripeModal.close();
    this.api.sessionData.company.credits = this.newCredits;
    this.openCreditsBought();
  }

  private handleStripe(data) {
    const self = this;

    if (data.redirect_url && data.redirect_url.indexOf('stripe') !== -1) {
      self.stripeModal = self.modalService.open(self.stripe, {backdrop: 'static'});
    }
  }

  private openCreditsBought(): void {
    const self = this;

    self.modalService.open(self.creditsBought);
  }

  saveBillingInfo(billingInfo): void {
    const self = this;

    self.api.save(billingInfo)
      .subscribe(
        (data) => {
          self.profileService.billingInfoData.next(data);
          self.billingInfoModal.close();
          self.packagesModal = self.modalService.open(self.buyCredits, {size: 'lg', backdrop: 'static'});
        },
        (err) => {
          console.log(err);
          self.billingInfoModal.close();
        }
      );
  }

  closePopup(): void {
    this.billingInfoModal.close();
  }
}
