<ng-template #buyCredits let-c="close" let-d="dismiss">
  <div class="modal-content">
    <div class="modal-header">
      <div class="bootstrap-dialog-header">
        <div class="bootstrap-dialog-title">Buy more credits</div>
        <p>Choose the package</p>
      </div>
    </div>

    <div class="modal-body">

      <div class="credits-package row">

        <div *ngFor="let creditFee of creditFeeDescription() | keys"
               (click)="selectPackage(creditFee)"
               [class.active]="chosenPackage === creditFee"
               class="credit-fee col">
          <input type="radio" title="credits-package" autocomplete="off" [value]="creditFee.key">
          <div class="credits-circle">
            <div class="credits-abs">
              <span>{{creditFee.key}}</span>
              <br>credits
            </div>
          </div>
          <div class="credits-cost">&pound;{{creditFee.key}}</div>
          <p class="credits-description" [innerHTML]="creditFee.value"></p>
        </div>

      </div>
      <p>* All prices exclude VAT.</p>
    </div>

    <div class="modal-footer">
      <div *ngIf="chosenPackage" class="credits-info-container">
        <p>
          You are going to buy {{chosenPackage['key']}} credits.
          Request an invoice. Your credits will be updated automatically when payment is received.
        </p>
      </div>
      <button (click)="buyMoreCredits()"
              [disabled]="awaitingResponse || !chosenPackage"
              type="button"
              class="btn"
      >
        <span [style.visibility]="awaitingResponse ? 'hidden' : ''">Buy</span>
        <app-loading *ngIf="awaitingResponse"></app-loading>
      </button>
      <button (click)="closePackagesModal()"
              [attr.disabled]="awaitingResponse ? '' : null"
              type="button"
              class="btn cancel-credits-btn"
      >
        Cancel
      </button>
    </div>
    <div class="loading" *ngIf="awaitingResponse"></div>
  </div>
</ng-template>

<ng-template #creditsBought let-c="close">
  <div class="modal-content">
    <div class="modal-header">
      <span>INFORMATION</span>
    </div>

    <div class="modal-body">

      <p>
        You have successfully bought credits!
      </p>
      <button (click)="c()" type="button" class="btn ok-btn">OK</button>

    </div>

  </div>
</ng-template>

<ng-template #billingInfo>
  <app-billing-info-modal
    (saveBillingInfo)="saveBillingInfo($event)"
    (closePopup)="closePopup()"
  ></app-billing-info-modal>
</ng-template>

<ng-template #stripe>
  <app-stripe-modal
    [payment]="currentPayment"
    [modalRef]="stripeModal"
    (onComplete)="showPaymentConfirmation()">
  </app-stripe-modal>
</ng-template>

<ng-template #waiting>
  <app-loading></app-loading>
</ng-template>

<div *ngIf="getBillingInfo()"></div>
