import { OfferData } from './offers/offer-data/OfferData.model';

export class Campaign extends OfferData {

  constructor(obj?: any) {
    super();
    Object.assign(this, obj);
  }

  getSchedule() {
    return this.offer_schedules[0];
  }

}
