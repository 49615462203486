<a (click)="close()" class="close">
  <img class="inlineSVG" src="assets/images/icons/dashboard_2018/closing_cross.svg">
</a>

<div class="panel-heading">
  <h1>Create New Campaign</h1>
</div>
<div class="panel-body">

  <form id="user-details-form" #formRef="ngForm">

        <div class="form-group">
          <label for="campaign-name"><span class="bullet">1</span>Campaign Name</label>
          <input [(ngModel)]="newOfferData.name"
                 type="text"
                 class="form-control editable"
                 name="firstname"
                 placeholder="Your campaign name"
                 #campaignNameFld="ngModel"
                 maxlength="30"
                 id="campaign-name"
                 (blur)="trimEntry()"
                 required/>
          <small *ngIf="campaignNameFld.dirty && campaignNameFld.errors?.required" class="error">
          Campaign name is required
          </small>
        </div>

        <ng-container *ngIf="sessionData && sessionData.company.active_plan !== 'BASIC'">
            <div style="margin-top: 20px"></div>
            <label><span class="bullet">2</span>Select impression model</label>

            <div class="btn-group impression-model-selector ">
              <div *ngFor="let impressionModel of impressionModels" class="btn-selector">
                <a class="btn btn-default"
                   [class.active]="newOfferData.impression_model === impressionModel.id"
                   (click)="setImpressionModel($event, impressionModel)">
                  <span [inlineSVG]="'assets/images/campaign_type_' + impressionModel.id + '.svg'"></span>
                  <div>
                    <div>
                      <h4>{{ impressionModel.title }}</h4>
                      <p>{{ impressionModel.desc }}</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
        </ng-container>

  </form>

</div>
<div class="panel-footer">
  <button (click)="createCampaign()"
          class="btn confirm-btn"
          [disabled]="!formRef.form.valid || newOfferData.name.trim().length === 0">
    Create
  </button>
</div>
