import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {OfferService} from '../../../services/offer/offer.service';
import {Location} from '../../../models/location.model';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-campaign-created-info',
  templateUrl: './campaign-created-info.component.html',
  styleUrls: ['./campaign-created-info.component.sass']
})
export class CampaignCreatedInfoComponent {

  @Input() offerService: OfferService;
  @Input() location: Location;
  @Input() advanced = false;
  @Input() modal: NgbModalRef;

  @Output() finish = new EventEmitter();

  constructor(
    public service: OfferService,
  ) { }

  get duration(): string {
    const dur = this.offerService.currentOffer.duration + ' day';
    return parseInt(this.offerService.currentOffer.duration, 10) > 1 ? (dur + 's') : dur;
  }

  formatDate(_date: string) {
    const date = new Date(_date);
    const day = date.getDate();
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }

  refreshPage(advanced = false): void {
    if (advanced) {
      this.service.resetOffer = false;
    }
    this.finish.emit(advanced);
  }
}
