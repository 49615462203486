import { environment as env } from '../../../environments/environment';
import { Injectable, Injector } from '@angular/core';
import { SuperService } from '../super-service/super.service';
import { Observable } from 'rxjs';
import { PaymentsModel } from '../../models/session-init/session-data/company-model/payments/payments.model';
import { SessionService as Session } from '../session/session.service';

@Injectable()
export class StripePaymentService extends SuperService {

  stripe: any;

  constructor(
    injector: Injector,
  ) {
    super(injector);
    this.stripe = Stripe(env.stripeKey);
  }

  setPaymentToken(paymentUri: string, tokenId: string, sessionToken: string = null, initial = false): Observable<PaymentsModel> {
    const body = {
      token: tokenId
    };
    if (initial) {
      body['initial'] = initial;
    }
    return this.http.post<PaymentsModel>(Session.enrichApiUrl(paymentUri), body
      );
  }

}
