<div class="offer-schedule">

  <h5>
    I want to play my campaign in specific weather conditions:
  </h5>

  <div class="text-center">
    <div class="btn-group date-range-selector">
      <button *ngFor="let sky of weatherSky"
              style="text-transform: capitalize;"
              class="btn btn-default btn-weather {{sky.iconClass}}"
              [class.active]="isSkyActive(sky)"
              (click)="changeSky(sky)">
        {{sky.value.length < 1 ? 'any' : sky.value}}
      </button>
    </div>
  </div>

  <h5>and the temperature is:</h5>

  <div class="text-center">
    <div class="btn-group date-range-selector">
      <button *ngFor="let temp of weatherTemp"
              style="text-transform: capitalize;"
              class="btn btn-default btn-temperature {{temp.iconClass}}"
              [class.active]="isTempActive(temp)"
              (click)="changeTemp(temp)">
        {{temp.value.length < 1 ? 'any' : temp.value}}
      </button>
    </div>
  </div>


</div>
