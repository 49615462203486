import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  EventEmitter,
  Output
} from '@angular/core';
import {OfferService} from '../../../services/offer/offer.service';
import {Location} from '../../../models/location.model';
import {FormatsBlockDispModel} from '../../../models/FormatsBlockDisp.model';
import {OfferData} from '../../../models/offers/offer-data/OfferData.model';
import {DisplayGroup} from '../../../models/DisplayGroups.model';
import { FormatsService } from '../../../services/formats/formats.service';
import { IFormat } from '../../../models/format.model';
import { FileService } from '../../../services/file/file.service';
import { filter, first, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

const BASE_ICON_PATH = 'https://storage.googleapis.com/fc-cdn/formats';
const ARTWORK_AREA_PORTRAIT_WIDTH = 200;
const ARTWORK_AREA_LANDSCAPE_WIDTH = 280;

@Component({
  selector: 'app-campaign-preview',
  templateUrl: './campaign-preview.component.html',
  styleUrls: ['./campaign-preview.component.scss']
})
export class CampaignPreviewComponent implements OnInit, OnDestroy {

  @Input() offer: OfferData;
  @Input() formats: FormatsBlockDispModel[] = [];
  @Input() resetOffer = true;
  @Input() offerStatus;
  @Input() showSimpleInfo = false;
  @Output() closed = new EventEmitter();


  @ViewChild('videoPlayer') videoPlayer: ElementRef;

  readonly img = 'image';
  readonly video = 'video';
  formatsReady = false;

  fileSrc = null;

  private _destroy$ = new Subject<any>();

  audience: DisplayGroup;
  displayGroups: DisplayGroup[];
  currentFormat: IFormat = null;

  get location(): Location {
    return this.service.api.location;
  }

  constructor(
    @Inject(OfferService) public service,
    private formatsService: FormatsService,
    private fileService: FileService,
  ) {
    this.service.api.getDisplayGroups();
  }

  ngOnInit() {
    this.getFormat();
    this.service.api.displayGroupsData
      .pipe(
        takeUntil(this._destroy$),
        filter(groups => groups !== null),
        first()
      )
      .subscribe(
      (data) => {
        if (data) {
          this.displayGroups = data;
          this.audience = data.find(
            dg => dg.audience_code === this.offer.audience
          );
        }
      });

    // self.service.currentOffer = self.offer;
    this.fileService.init(this.offer, this.isRTB);
    this.fileService.campaignFiles$
      .pipe(
        takeUntil(this._destroy$),
        filter(files => files.length > 0)
      )
      .subscribe(
      data => {
        this.getFileSrc();
      },
    );
  }

  get isRTB(): boolean {
    return this.offer.impression_model === 'rtb';
  }

  getFormat() {
    this.formatsService.allFormats
      .pipe(
        filter(d => d !== null),
        takeUntil(this._destroy$),
        tap(() => this.formatsReady = true)
      )
      .subscribe(
        allFormats => {
          if (this.offer.offer_variant) {
            this.currentFormat = allFormats.find(af => af.ooh_format === this.offer.offer_variant);
          }
        }
      );
  }


  get duration() {
    const dur = this.offer.duration + ' day';
    return parseInt(this.offer.duration, 10) > 1 ? (dur + 's') : dur;
  }

  regularise(dg: DisplayGroup): string {
    let name = dg.display_name;
    if (dg.audience_group) {
      if (name.toLowerCase().includes('camden')) {
        name = 'camden';
      }
      return `/assets/images/icons/dashboard_2018/${name.toLowerCase()}.svg`;
    } else {
      return `/assets/images/icons/display-generic.svg`;
    }
  }

  get artworkType() {
    if (this.fileService.campaignFiles.length > 0) {
      return this.fileService.getMimeType(this.fileService.campaignFiles[0]);
    } else {
      return 'image';
    }
  }

  getFileSrc(): void {
    if (this.fileService.campaignFiles.length > 0) {
      this.fileService.getArtworkSrcAsync(this.fileService.campaignFiles[0])
        .subscribe(url => this.fileSrc = url);
    } else {
      this.fileSrc = null;
    }
  }

  get postcode(): string {
    return this.offer.offer_postcode || this.location.postcode;
  }

  get formatIcon(): string {
    return this.currentFormat && `${BASE_ICON_PATH}/${this.currentFormat.ooh_format.split('_')[0]}.svg`;
  }

  get selectedFormat(): string {
    return this.offer && this.offer.offer_variant;
  }

  _openOffer(): void {
    this.closed.emit(true);
    this.service.router.navigate(['offers', this.offer.id])
      .catch(reason => console.error(reason));
  }

  get artworkAreaWidth(): number {
    if (this.currentFormat.artwork_spec.resolution.width > this.currentFormat.artwork_spec.resolution.height) {
      return ARTWORK_AREA_LANDSCAPE_WIDTH;
    } else {
      return ARTWORK_AREA_PORTRAIT_WIDTH;
    }
  }

  ngOnDestroy(): void {
    if (this.service.resetOffer) {
      this.service.resetCurrentOffer();
      this.fileService.reset();
    }
    this._destroy$.next();
    this._destroy$.complete();
  }
}
