<div class="offer-schedule">

  <h5 class="text-left">I want to play my campaign during specific pollen index:</h5>

  <div class="offer-schedule-content">
    <div class="row">
      <div class="col-sm-12 text-center">
        <div class="btn-group date-range-selector" data-toggle="buttons">
          <label id="offPollenLabel" class="btn btn-default btn-pollen">
            <input type="radio" name="pollen_index" class="sky" autocomplete="off" value="">
            Off
          </label>
          <label id="anyPollenLabel" class="btn btn-default btn-pollen">
            <input type="radio" name="pollen_index" class="sky" autocomplete="off" value="any">
            Any
          </label>
          <label id="lowPollenLabel" class="btn btn-default btn-pollen btn-pollen-low">
            <input type="radio" name="pollen_index" class="sky" autocomplete="off" value="low">
            Low
          </label>
          <label id="mediumPollenLabel" class="btn btn-default btn-pollen btn-pollen-medium">
            <input type="radio" name="pollen_index" class="sky" autocomplete="off" value="medium">
            Medium
          </label>
          <label id="highPollenLabel" class="btn btn-default btn-pollen btn-pollen-high">
            <input type="radio" name="pollen_index" class="sky" autocomplete="off" value="high">
            High
          </label>
          <label id="vHighPollenLabel" class="btn btn-default btn-pollen btn-pollen-veryhigh">
            <input type="radio" name="pollen_index" class="sky" autocomplete="off" value="very_high">
            Very High
          </label>
        </div>
      </div>
    </div>
  </div>
</div>