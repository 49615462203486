import {AbstractControl} from '@angular/forms';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import {Observable} from 'rxjs';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

export class SignInValidators {

  static validateCompanyNotTaken(service: AuthenticationService) {
    return (control: AbstractControl) => {
      if (control.value) {
        return service.checkCompanyNotTaken(control.value)
          .pipe(
            map(res => {
              return res ? null : {companyTaken: true};
            })
          );
      } else {
        return of(null);
      }
    };
  }

  static validateEmailNotTaken(service: AuthenticationService) {
    return (control: AbstractControl) => {
      if (control.value) {
        return service.checkEmailNotTaken(control.value)
          .pipe(
            map(res => {
              return res ? null : {emailTaken: true};
            })
          );
      } else {
        return of(null);
      }
    };
  }

}
