import { Inject, Injectable, Injector } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SuperService } from '../super-service/super.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Audience } from '../../models/DisplayGroups.model';
import { map, tap } from 'rxjs/operators';
import { IMultiEstimate } from '../estimator/estimator.service';
import { SessionService as Session } from '../session/session.service';
import { OfferService } from "../offer/offer.service";
import * as Http from 'http';
import { ApiService } from '../api-service/api.service';
import { environment } from '@env/environment';

export interface SearchApiResponse {
  next_uri: string;
  next: string;
  objects: any[];
}

export const AUDIENCES_SEARCH_URL = '/data/sql-search/audiences/normal';
export const AUDIENCES_PER_REQUEST = 40;

@Injectable()
export class AudiencesService {

  private audiencesNextUri: string;
  private _prevSearchString: string;
  public audiences = new BehaviorSubject<Audience[]>(null);
  private RTBExternalConsumers;

  constructor(
    private http: HttpClient,
    private offerService: OfferService,
    private apiService: ApiService,
  ) {
    this.RTBExternalConsumers = this.apiService.RTBExternalConsumers;
  }

  getAudiences(searchString: string = null, reset: boolean = false): Observable<Audience[]> {
    if (searchString || reset) {
      if (reset || (searchString !== this._prevSearchString)) {
        this.audiencesNextUri = null;
        this._prevSearchString = searchString;
      }
    }

    let params = new HttpParams()
        .set('limit', AUDIENCES_PER_REQUEST.toString())
        .set('objects', '1');
    if (searchString) {
      params = params.set('query', JSON.stringify({name: searchString}));
    }
    if (this.offerService.isRTB) {
      params = params.set('rtb', 'true');
    }
    return this.http.get<SearchApiResponse>(`${environment.apiUrl}${this.audiencesNextUri || AUDIENCES_SEARCH_URL}`, {params: this.audiencesNextUri ? {} : params})
        .pipe(
          tap(data => {
            this.audiencesNextUri = data.next_uri;
          }),
          map(data => {
            return data.objects.map(dg => ({
              ...dg,
              display_name: dg.name,
              audience_code: dg.code
            }));
          }),
          tap(data => {
            if (data && data.length < AUDIENCES_PER_REQUEST) {
              this.audiencesNextUri = null;
            }
            if (reset) {
              this.audiences.next(data);
            } else {
              this.audiences.next([...this.audiences.getValue(), ...data]);
            }
          })
        );
  }

  get nextUri(): string {
    return this.audiencesNextUri;
  }

  isAudienceAvailable(estimations: IMultiEstimate[], audienceUri: string): boolean {
    const estimate = estimations.find(a => a.audience_uri === audienceUri);
    if (estimate && estimate.availability) {
      const availableTime = estimate.availability['total_available_seconds'];
      const bookedTime = estimate.availability['booked_domination_seconds']
          + estimate.availability['booked_sov_seconds'];
      return bookedTime < availableTime;
    } else {
      return true;
    }
  }

  searchAudiences(searchString: string): Observable<Audience[]> {
    const url = `/data/search/display-groups/normal?query=${JSON.stringify({'_global': searchString})}`;
    return this.http.get<Audience[]>(Session.enrichApiUrl(url));
  }


}
