import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { NotAuthorisedGuard } from '../../_guards/not-authorised.guard';
import { PasswordInstructionsSentComponent } from './password-instructions-sent/password-instructions-sent.component';
import { SignupCamdenComponent } from './signup-camden/signup-camden.component';
import { StripePaymentComponent } from '../../shared/simple/stripe-payment/stripe-payment.component';
import {SharedModule} from '../../shared/shared.module';
import {ChangePasswordComponent} from './change-password/change-password.component';
import {SignupPackageComponent} from './signup-package/signup-package.component';
import { SignupRtbComponent } from './signup-rtb/signup-rtb.component';
import { SignupProComponent } from './signup-pro/signup-pro.component';
import { FireLoginComponent } from './fire-login/fire-login.component';
import { SignoutComponent } from './signout/signout.component';

export const AUTHORISATION_ROUTES: Routes = [
  // {
  //   path: 'login',
  //   component: LoginComponent,
  //   canActivate: [NotAuthorisedGuard]
  // },
  {
    path: 'login',
    component: FireLoginComponent,
    canActivate: [NotAuthorisedGuard]
  },
  {
    path: 'camden',
    children: [
      {path: '**', component: SignupCamdenComponent}
    ],
    canActivate: [NotAuthorisedGuard]
  },
  {
    path: 'signup',
    children: [
      {path: '**', component: SignupRtbComponent}
    ],
    canActivate: [NotAuthorisedGuard]
  },
  {
    path: 'request-pro',
    children: [
      {path: '**', component: SignupProComponent}
    ],
    canActivate: [NotAuthorisedGuard]
  },
  // {
  //   path: 'signup/:package',
  //   component: SignupPackageComponent,
  //   canActivate: [NotAuthorisedGuard]
  // },
  // {
  //   path: 'signupfb',
  //   component: SignupFbComponent,
  //   canActivate: [NotAuthorisedGuard]
  // },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [NotAuthorisedGuard]
  },
  {
    path: 'password-instructions-sent',
    component: PasswordInstructionsSentComponent,
    canActivate: [NotAuthorisedGuard]
  },
  {
    path: 'password-resets/:skey',
    component: ChangePasswordComponent,
    canActivate: [NotAuthorisedGuard]
  },
  {
    path: 'signout',
    component: SignoutComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(AUTHORISATION_ROUTES)],
  exports: [RouterModule],
})
export class AuthorisationRoutingModule { }

export const AUTHORISATION_COMPONENTS = [
  LoginComponent,
  SignupComponent,
  SignupCamdenComponent,
  SignupPackageComponent,
  ForgotPasswordComponent
];
