import {Component, Inject} from '@angular/core';
import {OfferService} from '../../../../services/offer/offer.service';


@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html'
})
export class OffersComponent {

  constructor(
    @Inject(OfferService) public offerService
  ) { }

}
