import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { IFormat } from '../../../../../../models/format.model';
import { Subject } from 'rxjs';
import { FileService } from '../../../../../../services/file/file.service';
import { ICampaignFile } from '../../../../../../services/file/file.model';

const BASE_ICON_PATH = 'https://storage.googleapis.com/fc-cdn/formats';

@Component({
  selector: 'app-format-panel',
  templateUrl: './format-panel.component.html',
  styleUrls: ['./format-panel.component.scss']
})
export class FormatPanelComponent implements OnInit, OnDestroy {

  @Input() format: IFormat;
  @Output() artworkSelected = new EventEmitter<ICampaignFile>();

  private _destroy$ = new Subject<any>();
  thumbnailPreviewStyle: {};

  constructor(
    private fileService: FileService,
  ) { }

  ngOnInit(): void {
    this.setAspectRatio();
  }

  get files(): any {
    return this.fileService;
  }

  onThumbnailClick(file): void {
    this.artworkSelected.emit(file);
  }

  setAspectRatio(): void {
    const height = 100;
    const width = 100;
    let ar = 1.78;

    const formatWidth = this.format.artwork_spec.resolution.width;
    const formatHeight = this.format.artwork_spec.resolution.height;
    ar = formatWidth / formatHeight;

    if (ar > 1) {
      this.thumbnailPreviewStyle = {
        width: `${width}px`,
        height: `${width / ar}px`
      };
    } else {
      this.thumbnailPreviewStyle = {
        width: `${height * ar}px`,
        height: `${height}px`
      };
    }
  }

  thumbnailPreviewImage(img): string {
    return img ? `url(${img})` : '';
  }

  removeItem(artwork, event): void {
    event && event.stopImmediatePropagation();
    this.fileService.deleteFile(artwork);
  }

  uploadProgressStyle(percent): any {
    let bg;
    if (percent < 50) {
      const deg = 90 + (3.6 * percent);
      bg = `linear-gradient(90deg, #E0E0E0 50%, transparent 50%, transparent), linear-gradient(${deg}deg, #03F7FF 50%, #E0E0E0 50%, #E0E0E0)`;
    } else {
      const deg = -90 + (3.6 * (percent - 50));
      bg = `linear-gradient(${deg}deg, #03F7FF 50%, transparent 50%, transparent), linear-gradient(270deg, #03F7FF 50%, #E0E0E0 50%, #E0E0E0)`;
    }
    return {background: bg};
  }

  get formatArtworks(): ICampaignFile[] {
    return this.fileService.getCampaignFilesByResolution(this.format.width || 1920, this.format.height || 1080);
  }

  get service(): any {
    return this.fileService;
  }

  get formatIcon(): string {
    return `${BASE_ICON_PATH}/${this.format.ooh_format.split('_')[0]}.svg`;
  }

  get formatDesc(): string {
    return `${ this.format.artwork_spec.resolution.width } x ${ this.format.artwork_spec.resolution.height } px&nbsp;&nbsp;&nbsp;${ this.humanReadableAR }`;
  }

  get humanReadableAR(): string {
    const gcd = (a, b) => {
      if (b > a) {
        const temp = a;
        a = b;
        b = temp;
      }
      while (b !== 0) {
        const m = a % b;
        a = b;
        b = m;
      }
      return a;
    };

    const c = gcd(this.format.artwork_spec.resolution.width, this.format.artwork_spec.resolution.height);
    return `${this.format.artwork_spec.resolution.width / c}:${this.format.artwork_spec.resolution.height / c}`;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
