<app-side-menu class="sidenav" [class.sidebarShown]="coreService.sidebarShown"></app-side-menu>

<section id="content">
  <app-top-bar></app-top-bar>

  <app-feedback *ngIf="!(coreService.overlayShown$ | async)" [class.sidebarShown]="coreService.sidebarShown"></app-feedback>

  <main #main [class.sidebarShown]="coreService.sidebarShown" [class.disable-scroll]="coreService.freezeMain | async">
    <router-outlet></router-outlet>
  </main>

  <div class="loading" *ngIf="isLoading | async"></div>
</section>

<!--<app-layout-editor *ngIf="offerService.currentOffer"></app-layout-editor>-->


