
    <ng-template #LOADING>
      <div class="loading" style="display: block"></div>
    </ng-template>

    <ng-container *ngIf="offer; else LOADING">
      <a (click)="close()" class="close-modal">&times;</a>

      <div class="panel-heading">
        <app-status-indicator
          [targets]="offer.offer_targets">
        </app-status-indicator>

        <h3 class="title">{{ offer.name }}</h3>

        <div class="desc">
          <span [innerHTML]="scheduleDescription"></span><br>
          <span *ngIf="offerStatus; else DEFAULT_STATUS" [ngSwitch]="offerStatus">
            <ng-container *ngSwitchCase="'draft'">
              Campaign can be modified.
            </ng-container>
            <ng-container *ngSwitchCase="'submitted'">
              Campaign has been submitted for approval.
            </ng-container>
            <ng-container *ngSwitchCase="'rejected'">
              Campaign has been rejected by Approver and needs to be corrected.
              <span [innerHTML]="rejectionReason"
                    [style.color]="'red'">
              </span>
            </ng-container>
            <ng-container *ngSwitchCase="'accepted'">
              Campaign has been accepted by Approver.
            </ng-container>
            <ng-container *ngSwitchCase="'online'">
              Campaign has been published and is currently visible on the screen(s){{ offerNextScheduleStep}}.
            </ng-container>
            <ng-container *ngSwitchCase="'booked'">
              Campaign has been booked and will be visible on the screen(s){{ offerNextScheduleStep }}.
            </ng-container>
            <ng-container *ngSwitchCase="'paused'">
              Campaign has been paused and will be visible on the screen(s){{ offerNextScheduleStep }}.
            </ng-container>
            <ng-container *ngSwitchCase="'delivered'">
              Campaign has been delivered.
            </ng-container>
            <ng-container *ngSwitchCase="'suspended'">
              Campaign has been suspended due to insufficient credits.
            </ng-container>
          </span>
          <ng-template #DEFAULT_STATUS>
            Campaign can be modified.
          </ng-template>
        </div>
      </div>

      <div class="panel-body">

        <app-campaign-preview
          [formats]="formatsBlocks"
          [offer]="offer"
          [offerStatus]="offerStatus"
          (closed)="close()">
        </app-campaign-preview>

      </div>

    </ng-container>
