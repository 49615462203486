import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconItemService } from './icon-item.service';
import { IconItemComponent } from './components/icon-item.component';
import {
  FCIconItemDescriptionDirective,
  FCIconItemPercentDirective,
  FCIconItemSummaryDirective,
  FCIconItemTooltipDirective
} from './icon-item.directives';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    IconItemComponent,
    FCIconItemSummaryDirective,
    FCIconItemDescriptionDirective,
    FCIconItemTooltipDirective,
    FCIconItemPercentDirective,
  ],
  imports: [
    CommonModule,
    NgbTooltipModule
  ],
  exports: [
    IconItemComponent,
    FCIconItemSummaryDirective,
    FCIconItemDescriptionDirective,
    FCIconItemTooltipDirective,
    FCIconItemPercentDirective,
  ],
  providers: [
    IconItemService,
  ]
})
export class IconItemModule { }
