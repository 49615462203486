<div #artworkContainer class="artwork-wrapper" id="artworkContainer">
  <div id="added_object" [class.white-panel]="!previewSrc" [ngStyle]="artworkPreviewStyle" style="display: none">

    <div class="circular-progress"
         [class.hidden]="!fileFeedback || !fileFeedback.fileUploading"
         [attr.data-percent]="fileFeedback.filePercent + '%'"
         [ngStyle]="uploadProgressStyle(fileFeedback.filePercent)">
    </div>

    <div *ngIf="!previewSrc || !fileType; else preview" class="artwork-preview-text">
      <span>ARTWORK PREVIEW</span>
    </div>

    <div *ngIf="downloadButton"
         class="artwork-download-layer"
         (click)="download()">
    </div>

    <ng-template #preview>

      <ng-container *ngIf="isMultiArtworkCampaign; else SINGLE_ARTWORK">

        <ng-container *ngFor="let file of campaignFiles; let i = index">

            <div *ngIf="isImage; else video"
                 [ngStyle]="imageStyles(file)"
                 [class.hidden]="current !== i"></div>
            <ng-template #video>
              <video #videoPlayer *ngIf="isVideo"
                     [src]="getArtworkSrc(file)"
                     [class.hidden]="current !== i"
                     (mouseenter)="enterVideo()"
                     (mouseleave)="leaveVideo()">
                Your browser does not support HTML5 video.
              </video>
            </ng-template>

        </ng-container>

      </ng-container>

      <ng-template #SINGLE_ARTWORK>
        <div *ngIf="isImage; else video" [ngStyle]="imageStyles(campaignFiles[0], previewSrc)"></div>
        <ng-template #video>
          <video #videoPlayer *ngIf="isVideo"
                 [src]="previewSrc || getArtworkSrc(campaignFiles[0])"
                 (mouseenter)="enterVideo()"
                 (mouseleave)="leaveVideo()">
            Your browser does not support HTML5 video.
          </video>
        </ng-template>
      </ng-template>

    </ng-template>

    <ng-container *ngIf="file && !(fileFeedback && fileFeedback.fileUploading)">
      <div class="overlay overlay-invalid" *ngIf="file?.thumbnail && !file?.thumbnail_inprogress && !file?.transcode_inprogress && !file?._validResolution"></div>
      <div class="overlay overlay-processing" *ngIf="!file?.thumbnail || file?.thumbnail_inprogress || file?.transcode_inprogress">
        <div class="loading" style="display: block"></div>
      </div>
    </ng-container>

  </div>
  <a *ngIf="uploadButton" class="upload-button btn" [class.disabled]="!previewSrc" (click)="selectFile()" id="upload-new-button">Upload New</a>
</div>
