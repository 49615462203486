<ng-container *ngIf="estimatedBudget">
  <div class="indicator"
       [style.color]="color"
       [attr.data-percent]="percentStr">
    <svg
       class="progress-ring"
       width="40"
       height="40">
      <circle
          class="progress-ring__bg"
          stroke="#dddddd"
          stroke-width="3"
          fill="transparent"
          r="18"
          cx="20"
          cy="20"/>
      <circle
          #circle
          class="progress-ring__circle"
          [style.stroke]="color"
          stroke-width="3"
          fill="transparent"
          r="18"
          cx="20"
          cy="20"/>
    </svg>


  </div>
  <div class="desc">
    <p>Estimated budget spend</p>
    <p class="credits"><span [style.color]="color">{{ estimatedBudget.estimatedSpend | number: '1.0-0' }}</span> of <span>{{ estimatedBudget.budget | number: '1.0-0' }}</span> credits</p>
  </div>
</ng-container>
