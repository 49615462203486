<div id="login-page">
  <div class="container">


    <form class="form-login" id="forgot-password-form">
      <div class="login-wrap">
        <div class="logo">Flow City</div>
          <h2 class="form-login-heading">Password reset</h2>
          <div class="login-wrap">
            <h3>Check your e-mail!</h3>
            <p>You should have your <b>password reset instructions</b> in your inbox.</p>
            <br>
              <p class="text-center"><a routerLink="/login">Back to login page</a></p>
          </div>
      </div>
    </form>

  </div>
</div>

<div class="background"></div>
