import {Injectable, Injector} from '@angular/core';
import {SuperService} from '../super-service/super.service';
import {ApiService} from '../api-service/api.service';
import {NotifyService} from '../notify/notify.service';
import {SessionService as Session} from '../session/session.service';
import {Observable} from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class RedirectService extends SuperService {

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  generateRedirectUrl(targetUri: string): Observable<any> {
    const data = {
      'redirect_uri': targetUri
    };
    return this.http.post(Session.enrichApiUrl('/data/redirects'), data)
      .pipe(
        map(redirector => redirector['uri'] = Session.enrichApiUrl(redirector['uri']))
      );

  }

}
